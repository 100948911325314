// Packages
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';

// Components
import { KiteLoader, KiteSwitch } from '@kite/react-kite';

// Hooks
import {
  useAnalytics,
  useSelections,
  useScrollToTop,
  useQueryData,
} from 'hooks';

// Utils

// Types
import { IPromo } from 'types';

// Styles
import './PromoPage.scss';

const PromoPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { allPromos, currentScenarioPromos, currentTerm } = useQueryData();

  const { togglePromo, deleteScenarioPromosLoading, postScenarioPromoLoading } =
    useSelections();
  const pageRef = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref: pageRef, refNestLevel: 1 });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const scenarioPromos = useMemo(
    () => allPromos.filter((p) => !p.isLocationSpecific),
    [allPromos]
  );

  const locationPromos = useMemo(
    () => allPromos.filter((p) => p.isLocationSpecific),
    [allPromos]
  );

  const checkPromoIsOn = useCallback(
    (promo: IPromo) =>
      !!currentScenarioPromos?.find((sp) => sp.promoId === promo.id),
    [currentScenarioPromos]
  );

  const switchDisabled =
    deleteScenarioPromosLoading || postScenarioPromoLoading;

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleTogglePromo = useCallback(
    (promo: IPromo) => () => {
      if (switchDisabled) {
        return;
      }
      (!promo?.requiredTerm ||
        parseInt(promo.requiredTerm) <= parseInt(currentTerm)) &&
        !(
          (promo.id === 'd4cf26a3-5785-427a-905a-7705dc00df6b' && //3 Months Promo
            currentScenarioPromos?.some(
              (sp) => sp.promoId === '2200584f-9d50-4319-9a3a-fcb8eb58a944'
            )) ||
          (promo.id === '2200584f-9d50-4319-9a3a-fcb8eb58a944' && //1 Month Promo
            currentScenarioPromos?.some(
              (sp) => sp.promoId === 'd4cf26a3-5785-427a-905a-7705dc00df6b'
            ))
        ) &&
        togglePromo(promo);
    },
    [switchDisabled, togglePromo, currentTerm, currentScenarioPromos]
  );

  // =============================================
  // Render Methods
  // =============================================
  const generatePromoOptions = useCallback(
    (promos: IPromo[]) => {
      return promos.map((promo) => {
        const requirements = !!promo.requirements.length && (
          <>
            <h3 className="promo-page__list-header">Requirements</h3>
            <ul className="promo-page__list">
              {promo.requirements
                .filter((r) => !r.includes('http'))
                .map((r, i) => (
                  <li key={`requirement ${i}`}>{r}</li>
                ))}
              {promo.requirements
                .filter((r) => r.includes('http'))
                .map((r, i) => (
                  <li key={`ahref ${i}`}>
                    {React.createElement(
                      'a',
                      {
                        href: `${r}`,
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      },
                      'Document Link'
                    )}
                  </li>
                ))}
            </ul>
            <hr />
          </>
        );

        return (
          <section key={`${promo.name} promo section`}>
            <KiteSwitch
              className={classNames({
                'promo-page__switch': true,
                'promo-page__switch--disabled': switchDisabled,
              })}
              label={promo.name}
              isOn={checkPromoIsOn(promo)}
              onClick={handleTogglePromo(promo)}
            />
            <p>{promo.description}</p>
            {requirements}
          </section>
        );
      });
    },
    [handleTogglePromo, checkPromoIsOn, switchDisabled]
  );

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('PromoPage'), [trackPageView]);

  // =============================================
  // Early Return
  // =============================================

  // =============================================
  // Return
  // =============================================
  if (!currentScenarioPromos) {
    return <KiteLoader className="promo-page__loader" />;
  }

  // TODO: confirm business logic for commented out jsx below: only one scenario-wide promo with multiple location-specific promos?
  return (
    <div ref={pageRef} className="promo-page">
      <section>
        <h2>Scenario Promotions</h2>
        {generatePromoOptions(scenarioPromos)}
        {/* {scenarioPromos.length > 1 && ( */}
        {/*   <p>Only one promotion may be selected at a time</p> */}
        {/* )} */}
      </section>
      <section>
        <h2>Location Promotions</h2>
        {generatePromoOptions(locationPromos)}
        {/* {locationPromos.length > 1 && ( */}
        {/*   <p>Only one promotion may be selected at a time</p> */}
        {/* )} */}
      </section>
    </div>
  );
};

export default PromoPage;
