// Packages
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

// Redux

// Components
import { NavLink, useLocation, useParams } from 'react-router-dom';

// Hooks
import { useGetForms, useTrackNavClick } from 'hooks';

// Utils
import { nameToUrlParam, renderFormIcon, UCQFORMS } from 'utils';

// Types

// Styles
import './UcLinks.scss';
import { ISubmission } from 'types';

interface IUcLinks {
  locationId: string;
  allSubmissions?: ISubmission[];
}

/** Container with UC Question links */

const UcLinks = ({ locationId, allSubmissions }: IUcLinks) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackNavClick } = useTrackNavClick();
  const { estimateId = '' } = useParams();
  const { pathname } = useLocation();

  /*removed this code as part of performance enhancement for submissions api calls
    const { data: submissions } = useGetSubmissions({
    params: { locationId, estimateId },
  }); */

  const submissions = useMemo(
    () =>
      allSubmissions?.filter(
        (s) => s.estimateId === estimateId && s.locationId === locationId
      ),
    [estimateId, allSubmissions, locationId]
  );

  const { data: formsData } = useGetForms({
    params: { location: 1, type: UCQFORMS },
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderLinks = useCallback(() => {
    return formsData?.map((form) => {
      const { name, id, fields } = form;
      const formSubmissions =
        submissions?.filter((sub) => sub.formId === id && sub.answer) || [];
      const isComplete =
        formSubmissions?.length >=
        fields.filter((field) => field.required).length;

      return (
        <NavLink
          to={`/uc-questions/${estimateId}/${locationId}/${nameToUrlParam(
            name
          )}`}
          key={`${id}-${locationId}`}
          state={{ fromDomain: pathname }}
          onClick={trackNavClick(
            `Nav to UC Questions (Form: ${name}, Location Id: ${locationId})`
          )}
        >
          <div
            className={classNames({
              'uc-links__container': true,
              'uc-links__container--complete': isComplete,
            })}
          >
            {renderFormIcon(name, '24px')}
            <span>{name}</span>
          </div>
        </NavLink>
      );
    });
  }, [formsData, submissions, estimateId, locationId, pathname, trackNavClick]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return <div className="uc-links">{renderLinks()}</div>;
};

export default UcLinks;
