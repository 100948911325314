// Packages
import React from 'react';

// Redux

// Components

// Hooks

// Utils

// Types

// Styles
import './ConfiguratorDefault.scss';

/** Default message for configurator when no product category is selected */

const ConfiguratorDefault = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="configurator-default">
      Please select a product on the side to get started
    </div>
  );
};

export default ConfiguratorDefault;
