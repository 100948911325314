// Packages
import React, { useCallback, useMemo } from 'react';

// Redux

// Components
import { KiteInput, KiteLoader, KiteSelect } from '@kite/react-kite';

// Hooks
import { useGetProductFamily } from 'hooks';

// Utils
import { handleNumInputKeydown } from 'utils';

// Types
import {
  TProfileProductData,
  TProfileProductDataKeys,
  IProductFamily,
  TProfileData,
  TProfileDataKeys,
} from 'types';

// Styles
import './UcProfileForm.scss';

interface ISelectOption {
  optLabel: string;
  optValue: string;
}

interface IInput<T> {
  name: T;
  label: string;
  type: 'text' | 'number' | 'select';
  options?: ISelectOption[];
  required?: boolean;
}

type TFormErrors = {
  id: string;
  profileName: string;
  quantity: string;
};

export interface IUcProfileFormProps {
  /** Profile metadata values */
  profileData: TProfileData;
  /** Profile product selections */
  formData: TProfileProductData;
  /** Change handler for form inputs */
  onChange: (
    type: 'profile' | 'product'
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  /** Errors for any form inputs */
  formErrors: TFormErrors;
}

/** Equipment form for creating UC Profiles */

const UcProfileForm = ({
  profileData,
  formData,
  onChange,
  formErrors,
}: IUcProfileFormProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { data: ucProductData, isLoading: ucProductIsLoading } =
    useGetProductFamily({ params: { category: 'UC Products' } });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const getOptions = useCallback(
    (category: TProfileProductDataKeys) => {
      return (
        ucProductData?.reduce(
          (acc: ISelectOption[], productFamily: IProductFamily) => {
            const { products } = productFamily;
            acc.push(
              ...products.reduce(
                (acc: { optLabel: string; optValue: string }[], product) => {
                  if (product.profileCategory?.name === category) {
                    acc.push({ optLabel: product.name, optValue: product.id });
                  }
                  return acc;
                },
                []
              )
            );
            return acc;
          },
          []
        ) || []
      );
    },
    [ucProductData]
  );

  const formInputsData: IInput<TProfileProductDataKeys>[] = useMemo(
    () => [
      {
        name: 'voipHardware',
        label: 'VoIP Hardware',
        type: 'select',
        options: getOptions('voipHardware'),
      },
      {
        name: 'insideWiring',
        label: 'Inside Wiring',
        type: 'select',
        options: getOptions('insideWiring'),
      },
      {
        name: 'package',
        label: 'Package or A la Carte FP',
        type: 'select',
        options: getOptions('package'),
      },
      {
        name: 'recordingLicense',
        label: 'Call Recording License',
        type: 'select',
        options: getOptions('recordingLicense'),
      },
      {
        name: 'additionalSoftware',
        label: 'Additional Software',
        type: 'select',
        options: getOptions('additionalSoftware'),
      },
      {
        name: 'unityAddonLicense',
        label: 'Unity Add On License',
        type: 'select',
        options: getOptions('unityAddonLicense'),
      },
    ],
    [getOptions]
  );

  const profileInputsData: IInput<TProfileDataKeys>[] = useMemo(
    () => [
      {
        name: 'profileName',
        label: 'Profile Name',
        type: 'text',
        required: true,
      },
      {
        name: 'quantity',
        label: 'Quantity',
        type: 'number',
        required: true,
      },
    ],
    []
  );

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const generateProfileDataInputs = useCallback(() => {
    return profileInputsData.map((input: IInput<TProfileDataKeys>) => {
      const onKeyDown =
        input.name === 'quantity' ? handleNumInputKeydown : undefined;

      return (
        <KiteInput
          id={input.name}
          className={`uc-profile-form__input`}
          name={input.name}
          label={`${input.label}${!input.required ? ' (optional)' : ''}`}
          key={input.name}
          type={input.type as 'text' | 'number'}
          value={profileData[input.name]}
          min="1"
          onChange={onChange('profile')}
          onKeyDown={onKeyDown}
          errorMessage={formErrors[input.name]}
        />
      );
    });
  }, [profileInputsData, profileData, onChange, formErrors]);

  const generateFormInputs = useCallback(() => {
    return formInputsData.map((input: IInput<TProfileProductDataKeys>) => {
      if (input.options?.length) {
        const inputOptions = input.options?.map((option: ISelectOption) => {
          return (
            <option key={option.optValue} value={option.optValue}>
              {option.optLabel}
            </option>
          );
        });

        return (
          <KiteSelect
            id={input.name}
            name={input.name}
            label={`${input.label}${!input.required ? ' (optional)' : ''}`}
            key={input.name}
            value={formData[input.name]}
            onChange={onChange('product')}
          >
            <option value="None" key="None">
              None
            </option>
            {inputOptions}
          </KiteSelect>
        );
      } else if (input.type === 'number') {
        return (
          <KiteInput
            id={input.name}
            name={input.name}
            label={`${input.label}${!input.required ? ' (optional)' : ''}`}
            key={input.name}
            type={input.type}
            value={formData[input.name]}
            onChange={onChange('product')}
            onKeyDown={handleNumInputKeydown}
          />
        );
      }
      return null;
    });
  }, [formData, formInputsData, onChange]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Early Return
  // =============================================
  if (ucProductIsLoading) {
    return <KiteLoader />;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <form className="uc-profile-form">
      <div className="uc-profile-form__input-container">
        {generateProfileDataInputs()}
      </div>
      {generateFormInputs()}
    </form>
  );
};

export default UcProfileForm;
