import { RootState } from './store';
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

export const userNameSlice = createSlice<string, SliceCaseReducers<string>>({
  name: 'userNameData',
  initialState: '',
  reducers: {
    storeUserName: (_, action) => {
      return action.payload;
    },
  },
});

// Actions
export const { storeUserName } = userNameSlice.actions;

// State
export const userNameData = (state: RootState): string => state.userNameData;

// Reducer
export default userNameSlice.reducer;
