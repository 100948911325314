import { configureStore } from '@reduxjs/toolkit';
import { enhancer } from 'addon-redux-strh';

import productFocusSlice from './productFocusSlice';
import partnerLinkUserSlice from './partnerLinkUserSlice';
import userIdSlice from './userIDSlice';
import currentSelectionIdsSlice from './currentSelectionIds';
import isAdminSlice from './isAdminSlice';
import headerLinkDataSlice from './headerLinkDataSlice';
import userNameSlice from './userNameSlice';
import scenarioTabSlice from './scenarioTabSlice';

export const reducer = {
  currentSelectionIds: currentSelectionIdsSlice,
  userIdData: userIdSlice,
  productFocus: productFocusSlice,
  partnerLinkUser: partnerLinkUserSlice,
  isAdmin: isAdminSlice,
  headerLinkData: headerLinkDataSlice,
  userNameData: userNameSlice,
  scenarioTabs: scenarioTabSlice,
};

export type StoreReducer = keyof typeof reducer;

const store = configureStore({ reducer, enhancers: [enhancer] });

export default store;

// TODO: get this type inference working - for some reason the reduces are being inferred as any
// export type RootState = ReturnType<typeof store.getState>;
export type RootState = any;
