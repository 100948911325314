// Packages
import React from 'react';
import classNames from 'classnames';

// Redux

// Components

// Hooks

// Utils

// Types

// Styles
import './PriceTotalBadge.scss';

export interface IPriceTotalBadgeProps {
  /** Total of all product prices for a scenario/bundle */
  priceTotal: string;
}

/** Badge for displaying price totals for a bundle/scenario */

const PriceTotalBadge = ({ priceTotal = '' }: IPriceTotalBadgeProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={classNames({
        'price-total-badge': true,
        'price-total-badge--empty': !priceTotal,
      })}
    >
      {priceTotal}
    </div>
  );
};

export default PriceTotalBadge;
