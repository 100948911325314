import { ILocation } from 'types';
import { locationBySfId, queryKeys } from 'utils';
import { ILocationRequest } from 'utils/api/locationRequest';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetLocationBySfId = (requestConfig: ILocationRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<ILocation>({
    queryKey: queryKeys(params).details.locationBySfId,
    apiCall: locationBySfId,
    requestConfig,
    opts: { enabled: !!params?.sfLocationId && !!params?.estimateId },
  });
};
