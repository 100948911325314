// Packages
import React, { useCallback, useState } from 'react';

// Components
import { KiteButton } from '@kite/react-kite';
import {
  CustomDropdown,
  CustomSwitch,
  DropdownSwitch,
  CustomInput,
} from 'components';

// Hooks
import { useQueryDataContext } from 'hooks';

// Types
import { ISelectionController, TProductList } from 'types';

// Styles
import './BandwidthOptions.scss';

const options: TProductList[] = [
  'DFI Speed',
  'Ethernet Speed',
  'Business Internet',
  'Business Wifi',
  'Bandwidth Options',
];

export interface IBandwidthOptionsProps extends ISelectionController {
  /** Calculated value based on DFI, Ethernet, BusInt values */
  recommendedBandwidth: string;
}

/** Network Edge section for Additional Bandwidth Options */

const BandwidthOptions = ({ recommendedBandwidth }: IBandwidthOptionsProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const { allProductFamilies } = useQueryDataContext();
  const [showOptions, setShowOptions] = useState(false);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleToggleOptions = useCallback(() => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  // =============================================
  // Render Methods
  // =============================================
  const generateDataInputs = useCallback(() => {
    return options.map((familyName) => {
      const productFamily = allProductFamilies.find(
        (f) => f.name === familyName
      );
      if (productFamily?.type === 'enum') {
        return (
          <CustomDropdown
            key={`${productFamily.name} dropdown`}
            productFamily={productFamily}
          />
        );
      } else if (productFamily?.type === 'enum-switch') {
        return (
          <DropdownSwitch
            key={`${productFamily.name} dropdown switch`}
            productFamily={productFamily}
          />
        );
      } else if (productFamily?.type.includes('boolean')) {
        return (
          <CustomSwitch
            key={`${productFamily.name} switch`}
            productFamily={productFamily}
          />
        );
      } else if (productFamily?.type === 'number') {
        return productFamily.products.map((product) => {
          return (
            <CustomInput
              key={`${product.name} input`}
              label={product.name}
              productFamily={productFamily}
              product={product}
            />
          );
        });
      } else return null;
    });
  }, [allProductFamilies]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  if (!showOptions) {
    return (
      <KiteButton
        type="outline"
        onClick={handleToggleOptions}
      >{`Show Additional Bandwidth Options (${recommendedBandwidth})`}</KiteButton>
    );
  }

  return (
    <div className="bandwidth-options">
      {generateDataInputs()}

      <KiteButton
        type="outline"
        onClick={handleToggleOptions}
      >{`Hide Additional Bandwidth Options (${recommendedBandwidth})`}</KiteButton>
    </div>
  );
};

export default BandwidthOptions;
