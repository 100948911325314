// Packages
import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

// Redux

// Components
import { KiteIcon, KiteLink, KiteLoader } from '@kite/react-kite';

// Hooks
import { useGetActiveDirectory } from 'hooks';

// Utils
import { formatTeamMember } from 'utils';

// Types
import { TSalesTeamRole } from 'types';

// Styles
import './SalesTeamCard.scss';

export interface ISalesTeamCardProps {
  pid?: string;
  role?: TSalesTeamRole;
  dateUpdated?: string | Date;
  isUCQ?: boolean;
}

/** Displays information about individual team members. Includes an avatar, name, title, and links to email and webex chats.*/

const SalesTeamCard = ({
  pid,
  role,
  dateUpdated: initDate,
  isUCQ,
}: ISalesTeamCardProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const [imgError, setImgError] = useState(false);

  const {
    data: activeDirectoryData,
    isLoading: activeDirectoryLoading,
    isError: activeDirectoryError,
  } = useGetActiveDirectory({ pid });

  const teamMember = formatTeamMember({
    activeDirectoryData,
    dateUpdated: initDate,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const roleLabel = useMemo(() => {
    // If role use role
    if (role) {
      return isUCQ && role === 'Owner' ? 'O' : role;
    }

    // If no role use AD title
    if (teamMember?.title) {
      return teamMember.title
        .split(' ')
        .map((w) => w[0].toUpperCase())
        .join('');
    }

    return '';
  }, [isUCQ, role, teamMember]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const avatarImage = useMemo(() => {
    if (!teamMember?.imageURI || imgError)
      return (
        <KiteIcon
          name="account"
          className="sales-team-card__member-avatar--generic"
          margin="0"
          size="48px"
        />
      );
    return React.createElement('img', {
      src: teamMember.imageURI,
      alt: `${teamMember.fullName} avatar`,
      className: 'sales-team-card__member-avatar',
      onError: () => setImgError(true),
    });
  }, [teamMember, imgError]);

  const renderCardContent = useCallback(() => {
    if (activeDirectoryLoading) {
      return <KiteLoader />;
    }

    if (activeDirectoryError) {
      return (
        <>
          <div className="sales-team-card__member">
            {avatarImage}
            <div
              className="sales-team-card__member-desc"
              style={{
                display: 'flex',
                flexDirection: isUCQ ? 'row' : 'column',
                gap: isUCQ ? '5px' : 'initial',
              }}
            >
              <p>{pid ? pid.toUpperCase() : 'N/A'}</p>
              <p className="sales-team-card__member-title">{roleLabel}</p>
              {initDate && isUCQ && (
                <span className="sales-team-card__date-updated">
                  {dayjs(initDate).format('MM-DD-YYYY hh:mma')}
                </span>
              )}
            </div>
          </div>
        </>
      );
    }

    if (teamMember) {
      const { fullName, dateUpdated, email } = teamMember;
      return (
        <>
          <div className="sales-team-card__member">
            {avatarImage}
            <div
              className="sales-team-card__member-desc"
              style={{
                display: 'flex',
                flexDirection: isUCQ ? 'row' : 'column',
                gap: isUCQ ? '5px' : 'initial',
              }}
            >
              <p>{fullName}</p>
              <p className="sales-team-card__member-title">{roleLabel}</p>
              {dateUpdated && isUCQ && (
                <span className="sales-team-card__date-updated">
                  {dateUpdated}
                </span>
              )}
            </div>
          </div>
          <div className="sales-team-card__link-container">
            {email && (
              <>
                <KiteLink
                  className="sales-team-card__link"
                  href={`webexteams://im?email=${email}`}
                  type="standalone"
                  aria-label={`Chat with ${fullName} on Webex Teams`}
                >
                  <KiteIcon
                    className="sales-team-card__icon"
                    name="chat"
                    size="48px"
                  />
                </KiteLink>

                <KiteLink
                  href={`mailto:${email}`}
                  type="standalone"
                  aria-label={`Email ${fullName}`}
                >
                  <KiteIcon
                    className="sales-team-card__icon"
                    name="mail"
                    size="48px"
                  />
                </KiteLink>
              </>
            )}
          </div>
        </>
      );
    }

    return null;
  }, [
    activeDirectoryLoading,
    activeDirectoryError,
    teamMember,
    avatarImage,
    isUCQ,
    pid,
    roleLabel,
    initDate,
  ]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={classNames({
        'sales-team-card': true,
        'sales-team-card--ucq': isUCQ,
      })}
    >
      {renderCardContent()}
    </div>
  );
};

export default SalesTeamCard;
