import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IBundle, ILocationSelection } from 'types';
import {
  bundleSelectionRequest,
  NONE,
  queryKeys,
  sortLocationSelections,
} from 'utils';
import { useQueryData } from 'hooks';

interface IUpdateBundleProductsParams {
  canSave: boolean;
}

export const useUpdateBundleProducts = ({
  canSave,
}: IUpdateBundleProductsParams) => {
  const { allProducts } = useQueryData();
  const queryClient = useQueryClient();
  const allBundlesKey = queryKeys().all.bundles;
  const featuredBundlesKey = queryKeys({ featured: true, published: true })
    .filter.bundles;
  const moreBundlesKey = queryKeys({ featured: false, published: true }).filter
    .bundles;
  const productFamilyKey = queryKeys().all.productFamilies;

  const optimisticUpdate = useCallback(
    (prevBundles: IBundle[], selections: Partial<ILocationSelection>[]) => {
      // const newId = (selection: Partial<ILocationSelection>) =>
      //   selection.id?.replace('newSelection', '');
      const selectionsByBundleId = selections.reduce(
        (
          acc: { [bundleId: string]: Partial<ILocationSelection>[] },
          selection
        ) => {
          const { bundleId } = selection;
          if (bundleId) {
            if (!acc[bundleId]) {
              acc[bundleId] = [selection];
            } else {
              acc[bundleId].push(selection);
            }
          }
          return acc;
        },
        {}
      );

      const newBundles = prevBundles.map((b) => {
        if (selectionsByBundleId[b.id]) {
          return {
            ...b,
            selections: sortLocationSelections({
              selections: selectionsByBundleId[b.id].filter(
                (s) => s.productId !== NONE
              ) as ILocationSelection[],
              products: allProducts,
            }),
          };
        }
        return b;
      });

      return newBundles;
    },
    [allProducts]
  );

  const localHandler = useCallback(
    async (selections: Partial<ILocationSelection>[]) => {
      await queryClient.cancelQueries(allBundlesKey);
      const prevBundles =
        queryClient.getQueryData<IBundle[]>(allBundlesKey) || [];

      const newBundles = optimisticUpdate(prevBundles, selections);
      queryClient.setQueryData(allBundlesKey, newBundles);

      return newBundles;
    },
    [allBundlesKey, optimisticUpdate, queryClient]
  );

  const {
    mutate: updateBundleProducts,
    isLoading: updateBundleProductsLoading,
    isSuccess: updateBundleProductsSuccess,
    isError: updateBundleProductsError,
  } = useMutation(
    (selections: Partial<ILocationSelection>[]) => {
      if (canSave) {
        return bundleSelectionRequest({
          endpoint: 'bundle-selections',
          method: 'PUT',
          data: selections,
        });
      } else {
        return localHandler(selections);
      }
    },
    {
      onMutate: async (selections: Partial<ILocationSelection>[]) => {
        await queryClient.cancelQueries(allBundlesKey);
        const prevBundles =
          queryClient.getQueryData<IBundle[]>(allBundlesKey) || [];

        const newBundles = optimisticUpdate(prevBundles, selections);
        queryClient.setQueryData(allBundlesKey, newBundles);
        return { prevBundles };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(productFamilyKey);
        if (canSave) {
          queryClient.invalidateQueries(allBundlesKey);
          queryClient.invalidateQueries(featuredBundlesKey);
          queryClient.invalidateQueries(moreBundlesKey);
        }
      },
    }
  );

  return {
    updateBundleProducts,
    updateBundleProductsLoading,
    updateBundleProductsSuccess,
    updateBundleProductsError,
  };
};
