import { ILocation } from 'types';

export interface IDetailedAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  name?: string;
}

export const formatAddress = (address: IDetailedAddress) => {
  const { address1, address2, city, state, zipcode } = address;
  return `${[address1, address2, city, state].filter(Boolean).join(', ')}${
    zipcode ? ` ${zipcode}` : ''
  }`;
};

export const formatLocationAddress = (location: ILocation) => {
  const { address1, address2, city, state, zipcode } = location;
  return formatAddress({ address1, address2, city, state, zipcode });
};
