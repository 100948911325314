// Packages
import React, { useCallback, useState } from 'react';

// Redux

// Components
import { KiteAlert, KiteCheckbox, KiteModal } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './SandboxModal.scss';

export interface ISandboxModalProps {
  /** Determines if modal is visible */
  isOpen: boolean;
  /** Callback to trigger when opportunity id is entered and user hits "continue" button */
  onSubmit: (ignoreValue: boolean) => void;
  /** Callback to close modal */
  onClose: () => void;
}

/** Update this description, which will appear in the Storybook documentation */

const SandboxModal = ({ isOpen, onSubmit, onClose }: ISandboxModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [isIgnore, setIsIgnore] = useState(false);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const toggleIgnore = useCallback(() => {
    setIsIgnore(!isIgnore);
  }, [isIgnore, setIsIgnore]);

  const handleSubmit = useCallback(() => {
    onSubmit(isIgnore);
    setIsIgnore(false);
  }, [isIgnore, onSubmit, setIsIgnore]);

  const handleClose = useCallback(() => {
    onClose();
    setIsIgnore(false);
  }, [onClose, setIsIgnore]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      modalId="sandBoxModal"
      title="Open Sandbox"
      className="sandbox-modal"
      canShow={isOpen}
      ctaAction={handleSubmit}
      ctaCopy="Continue"
      secondaryCtaAction={handleClose}
      secondaryCtaCopy="Close"
      onHide={handleClose}
    >
      <KiteAlert
        type="alert"
        description="Configurations in Sandbox cannot be linked to an Opportunity, and your product configurations will not be saved. Would you like to continue?"
      />
      <KiteCheckbox
        id="ignoreSandboxWarning"
        label="Don't show this message again"
        name="Ignore sandbox warning"
        checked={isIgnore}
        onChange={toggleIgnore}
      />
    </KiteModal>
  );
};

export default SandboxModal;
