// Packages
import React, { useCallback, useMemo, useState } from 'react';

// Redux

// Components
import { KiteButton, KiteCard, KiteSelect } from '@kite/react-kite';
import { SalesTeamCard, AutobuildPill, AutosaveBadge } from 'components';
import { UCQHistoryDrawer } from 'components';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';
import { useAnalytics, useQueryData, useTrackOpenState } from 'hooks';

// Utils
import { formatLocationAddress, isEstimateAutobuild } from 'utils';

// Types
import { ILocation } from 'types';

// Styles
import './UCQHeader.scss';

interface IUCQHeaderLocationData {
  name: string;
  id: string;
  address: string;
  isAutobuild: boolean;
}

export interface IUCQHeaderProps {
  // Name of the currently linked salesforce opportunity
  opptyName: string;
  // Locations within currently linked opportunity
  locations: ILocation[];
  // User info for team member who last updated UC Questions form
  lastUpdatedBy?: { pid: string; dateUpdated: string };
}

/** Upper header area for UC Questions form layout, allows switching between locations and displays last updated by card */

const UCQHeader = ({
  opptyName,
  locations,
  lastUpdatedBy,
}: IUCQHeaderProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const { trackOpenState } = useTrackOpenState();
  const navigate = useNavigate();
  const { currentEstimate, updateLocationId } = useQueryData();
  const { estimateId, locationId = 'general', formName } = useParams();

  const [historyDrawerIsOpen, setHistoryDrawerIsOpen] = useState(false);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const updatedByData = useMemo(() => {
    return {
      pid: lastUpdatedBy?.pid,
      role: currentEstimate?.salesTeam[lastUpdatedBy?.pid || ''],
      dateUpdated: lastUpdatedBy?.dateUpdated,
    };
  }, [currentEstimate, lastUpdatedBy]);

  const locationData: IUCQHeaderLocationData[] = useMemo(() => {
    const options = locations.map((l) => ({
      name: l.name,
      id: l.id,
      isAutobuild: l.isAutobuild,
      address: formatLocationAddress(l),
    }));
    const general = {
      name: 'General',
      id: 'general',
      address: '',
      isAutobuild: true,
    };
    return [general, ...options];
  }, [locations]);

  const isAutobuild = isEstimateAutobuild(currentEstimate);

  const currentAddress = useMemo(() => {
    return locationData.find((l) => l.id === locationId)?.address || '';
  }, [locationData, locationId]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onLocationChange = useCallback(
    (id: string) => {
      if (id === 'general') {
        updateLocationId('');
        navigate(`/uc-questions/${estimateId}/general/general-discovery`, {
          replace: true,
        });
      } else {
        updateLocationId(id);
        navigate(
          `/uc-questions/${estimateId}/${id}/${
            formName === 'general-discovery' || formName === 'network-draft'
              ? 'intro'
              : formName
          }`,
          { replace: true }
        );
      }

      trackSelectAction(`UC Questions Selected Location Id: ${id}`, {
        opType: 'dropDown',
      });
    },
    [estimateId, formName, navigate, trackSelectAction, updateLocationId]
  );

  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      onLocationChange(value);
    },
    [onLocationChange]
  );

  const toggleHistoryDrawer = useCallback(() => {
    setHistoryDrawerIsOpen(!historyDrawerIsOpen);
    trackOpenState({ name: 'History Drawer', isOpen: historyDrawerIsOpen });
  }, [historyDrawerIsOpen, trackOpenState]);

  // =============================================
  // Render Methods
  // =============================================
  const locationOptions = useMemo(() => {
    return locationData.map((opt) => (
      <option key={opt.id} value={opt.id}>
        {opt.name}
      </option>
    ));
  }, [locationData]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ucq-header">
      <div className="ucq-header__upper">
        <div className="ucq-header__upper-name">
          <h1>{!opptyName ? 'No Name Provided' : opptyName}</h1>
          <AutobuildPill isAutobuild={isAutobuild} />
          <AutosaveBadge />
        </div>
      </div>
      <div className="ucq-header__lower">
        <div className="ucq-header__lower-section">
          <span className="ucq-header__label">Location</span>
          <KiteSelect
            id="currentLocationId"
            name="current location id"
            value={locationId}
            onChange={handleSelect}
            maxWidth="100%"
          >
            {locationOptions}
          </KiteSelect>
        </div>
        <div
          className="ucq-header__lower-section ucq-header__lower-section--address"
          style={{ visibility: !currentAddress ? 'hidden' : 'visible' }}
        >
          <span className="ucq-header__label">Address</span>
          <KiteCard>
            <span>{currentAddress}</span>
          </KiteCard>
        </div>
        <div className="ucq-header__lower-section">
          <div className="ucq-header__lower-section__label-container">
            <span className="ucq-header__label">Last Updated</span>
            <KiteButton
              className="ucq-header__see-all"
              type="standalone-link"
              onClick={toggleHistoryDrawer}
            >
              See All
            </KiteButton>
          </div>
          <SalesTeamCard
            pid={updatedByData.pid}
            role={updatedByData.role}
            dateUpdated={updatedByData.dateUpdated}
            isUCQ
          />
        </div>
      </div>
      {historyDrawerIsOpen && (
        <UCQHistoryDrawer
          isOpen={historyDrawerIsOpen}
          onClose={toggleHistoryDrawer}
        />
      )}
    </div>
  );
};

export default UCQHeader;
