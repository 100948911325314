import { ILocationSelection, INewSelection } from 'types';

export const convertSelectionForApi = (selection: ILocationSelection) => {
  const {
    id,
    productId,
    locationId,
    scenarioId,
    bundleId,
    profileId,
    quantity,
    discretionValue12,
    discretionValue24,
    discretionValue36,
    discretionValue48,
    discretionValue60,
    discretionValue84,
    rateCard,
    eipTerm,
    filter,
    fieldOpsAccurate,
    fieldOpsComments,
  } = selection;

  const newValue: INewSelection =
    // if selection id is 'newSelection', post w/o selection id
    !id?.includes('newSelection')
      ? {
          id,
          productId,
          locationId,
          scenarioId,
          bundleId,
          profileId,
          quantity,
          discretionValue12,
          discretionValue24,
          discretionValue36,
          discretionValue48,
          discretionValue60,
          discretionValue84,
          rateCard,
          eipTerm,
          filter,
          fieldOpsAccurate,
          fieldOpsComments,
        }
      : {
          productId,
          locationId,
          scenarioId,
          bundleId,
          profileId,
          quantity,
          discretionValue12,
          discretionValue24,
          discretionValue36,
          discretionValue48,
          discretionValue60,
          discretionValue84,
          rateCard,
          eipTerm,
          filter,
          fieldOpsAccurate,
          fieldOpsComments,
        };

  return newValue;
};
