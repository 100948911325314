// Packages
import React from 'react';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks
import { useQueryData } from 'hooks';

// Utils

// Types

// Styles
import './AutosaveBadge.scss';

/** Displays wether data has been saved or is currently saving */

const AutosaveBadge = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { isSaved } = useQueryData();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return isSaved ? (
    <div className="autosave-badge">
      Saved <KiteIcon name="checkmark-circle" />
    </div>
  ) : null;
};

export default AutosaveBadge;
