// External Packages
import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Redux

// Pages

// Components
import { KiteCard, KiteLoader, KiteModal } from '@kite/react-kite';
import { BundleDataBar, PricingTable, LeftNav, OutputTotals } from 'components';

// Utils
import { queryKeys, removeEmojis } from 'utils';

// Hooks
import { useQueryClient } from 'react-query';
import { useParams, useNavigate, Outlet, useLocation } from 'react-router-dom';
import {
  useAnalytics,
  useErrorCheck,
  useGenerateProductTotals,
  useGetBundles,
  usePostBundle,
  useQueryData,
  useUpdateBundle,
} from 'hooks';

// Types
import { IBundle } from 'types';

// Styles
import './BundleEditor.scss';

export type DataBarValues = {
  [key: string]: string;
};

// Component
const BundleEditor = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  // This also ensures allBundles is available from queryData
  const { isLoading } = useGetBundles();

  const navigate = useNavigate();
  const { bundleId } = useParams();
  const { pathname } = useLocation();
  const { trackPageView } = useAnalytics();
  const queryClient = useQueryClient();

  const queryData = useQueryData();

  const {
    currentBundle,
    allBundles,
    locationId,
    currentSelections,
    updateScenarioId,
    updateLocationId,
    updateBundleTerm,
    loadEmptyBundle,
  } = queryData;

  const { checkAllErrors } = useErrorCheck(currentSelections);

  const [bundleName, setBundleName] = useState<string | null>(null);
  const [initBundle, setInitBundle] = useState<IBundle | null>(null);
  const [isSaved, setIsSaved] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [alert, setAlert] = useState('');

  useEffect(() => {
    if (currentBundle && currentBundle.id !== initBundle?.id) {
      setInitBundle(currentBundle);
    }
  }, [currentBundle, initBundle]);

  useEffect(() => {
    // clear scenario/locationIds to prevent bundle updated errors
    updateScenarioId('');
    updateLocationId('');
  }, [updateScenarioId, updateLocationId]);

  const { generateProductTotals } = useGenerateProductTotals({
    isBundle: true,
  });

  const onSaveSuccess = useCallback(
    (bundle: IBundle) => {
      const page = pathname.split('/')[4];
      queryClient.invalidateQueries();

      const postBundleRedir = `/admin/bundles/editor/${page}/${bundle.id}`;

      if (!showCancelModal && pathname !== postBundleRedir) {
        navigate(postBundleRedir, {
          replace: true,
        });
      }

      if (!showCancelModal) {
        setInitBundle(bundle);
        setIsSaved(true);
      }
    },
    [navigate, pathname, queryClient, showCancelModal]
  );

  const onSaveError = useCallback(() => {
    if (!showCancelModal) {
      setAlert('Something went wrong saving your bundle.');
    }
  }, [showCancelModal]);

  const { updateBundle, updateBundleLoading } = useUpdateBundle(
    onSaveSuccess,
    onSaveError
  );
  const { postBundle, postBundleLoading } = usePostBundle(
    onSaveSuccess,
    onSaveError
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const outputTotals = useMemo(() => {
    return generateProductTotals({ locationId });
  }, [locationId, generateProductTotals]);

  const hasChanges = useMemo(() => {
    if (!currentBundle || !initBundle) {
      return false;
    }

    if (currentBundle.name !== bundleName) {
      return true;
    }

    if (currentBundle.selections.length !== initBundle.selections.length) {
      return true;
    }

    return !initBundle.selections.every(
      (s, i) => currentSelections[i].productId === s.productId
    );
  }, [currentBundle, initBundle, bundleName, currentSelections]);

  const checkNameRepeat = useCallback(
    (newName: string) => {
      return !!Object.values(allBundles).find((bundle) => {
        return (
          bundle.name.trim() === newName.trim() &&
          bundle.id !== currentBundle?.id
        );
      });
    },
    [allBundles, currentBundle]
  );

  const errorMessage = useMemo(() => {
    const isNotReady =
      !currentBundle || !initBundle || postBundleLoading || updateBundleLoading;

    if (isNotReady) {
      return '';
    }

    if (!bundleName || bundleName.trim() === '') {
      return 'Bundle name field can not be empty';
    }

    if (!currentSelections.length) {
      return 'Bundle must have product selections before saving';
    }

    const selectionErrors = Object.values(checkAllErrors()).filter(Boolean);
    if (selectionErrors.length) {
      return 'Bundle selection errors must be resolved before saving';
    }

    const nameWithoutEmojis = removeEmojis(bundleName);
    if (nameWithoutEmojis.length !== bundleName.length) {
      return 'Bundle name can not include emojis';
    }

    const nameRepeat = checkNameRepeat(bundleName);
    if (nameRepeat) {
      return 'Bundle names must be unique';
    }

    return '';
  }, [
    checkAllErrors,
    currentBundle,
    initBundle,
    postBundleLoading,
    updateBundleLoading,
    bundleName,
    currentSelections.length,
    checkNameRepeat,
  ]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSave = useCallback(() => {
    if (bundleId === 'new-bundle' && bundleName) {
      const newBundle = {
        name: bundleName,
        featured: currentBundle?.featured,
        published: currentBundle?.published,
        description: currentBundle?.description,
        selections: currentSelections,
      };
      postBundle(newBundle);
    } else if (bundleName) {
      const updatedBundle = {
        ...currentBundle,
        name: bundleName,
        selections: currentSelections,
      };
      updateBundle(updatedBundle);
    }

    setShowCancelModal(false);
  }, [
    bundleName,
    bundleId,
    currentBundle,
    currentSelections,
    postBundle,
    updateBundle,
  ]);

  const closeAlert = useCallback(() => setAlert(''), []);

  const handleModalSave = useCallback(() => {
    handleSave();
    navigate('/admin/bundles');
  }, [handleSave, navigate]);

  const handleBundleName = useCallback((value: string) => {
    setBundleName(value);
  }, []);

  const handleBackClick = useCallback(() => {
    if (hasChanges) {
      setShowCancelModal(true);
    } else {
      navigate('/admin/bundles');
    }
  }, [hasChanges, navigate]);

  const handleCancelChanges = useCallback(() => {
    // Reset any temporary changes to allBundles
    const allBundlesKey = queryKeys().all.bundles;
    queryClient.invalidateQueries(allBundlesKey);

    setShowCancelModal(false);
    navigate('/admin/bundles');
  }, [navigate, queryClient]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('BundleEditorPage'), [trackPageView]);

  useEffect(() => {
    if (currentBundle?.name && bundleName === null) {
      setBundleName(currentBundle.name);
    }
  }, [bundleName, currentBundle]);

  useEffect(() => {
    if (bundleId === 'new-bundle' && !currentBundle) {
      loadEmptyBundle();
    }
  }, [bundleId, currentBundle, loadEmptyBundle]);

  useEffect(() => {
    if (hasChanges && isSaved) {
      setIsSaved(false);
    }
    if (!hasChanges && !isSaved && bundleId !== 'new-bundle') {
      setIsSaved(true);
    }
  }, [bundleId, hasChanges, isSaved, errorMessage]);

  useEffect(() => {
    updateBundleTerm('36');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =============================================
  // Early Return
  // =============================================
  if (!currentBundle || isLoading) {
    return (
      <KiteLoader
        className="bundle-editor__loader"
        loaderTitle="Loading Solution Pricing Tool"
      />
    );
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="bundle-editor">
      <KiteModal
        canShow={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        title="Are you sure you would like to go back without saving changes?"
        ctaCopy="Discard Changes"
        ctaAction={handleCancelChanges}
        secondaryCtaCopy={errorMessage ? '' : 'Save Changes'}
        secondaryCtaAction={errorMessage ? undefined : handleModalSave}
      >
        <p>This action cannot be undone.</p>
      </KiteModal>

      <BundleDataBar
        errorMessage={errorMessage}
        handleSave={handleSave}
        handleBack={handleBackClick}
        onNameChange={handleBundleName}
        bundleName={bundleName || ''}
        hasChanges={hasChanges}
        isSaved={isSaved}
        alert={alert}
        closeAlert={closeAlert}
      />

      <div className="bundle-editor__content">
        <LeftNav homeView={false} />

        <KiteCard className="bundle-editor__outlet">
          <Outlet context={queryData} />
        </KiteCard>

        <OutputTotals isBundle bundleTotals={outputTotals} />

        <PricingTable isExpanded={true} disableBanner={true} />
      </div>
    </div>
  );
};

export default BundleEditor;
