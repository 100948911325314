import React from 'react';
import { Text, View } from '@react-pdf/renderer';

// Styles
import { styles } from '../../components/pdf/PdfComponents';

export const generateTextComponents = (cellValues: string[], style: string) =>
  cellValues.map((value, i) => (
    <Text key={`text ${value} ${i}`} style={styles[style]}>
      {value}
    </Text>
  ));

export const generateViewComponent = (
  value: string,
  style: string,
  id: string
) => {
  const multiLine = value.split('; ');

  return (
    <View key={`view ${value} ${id}`} style={styles[style]}>
      {multiLine.map((line, i) => (
        <Text key={`${line} ${i}`}>{line}</Text>
      ))}
    </View>
  );
};
