import { RootState } from './store';
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

export const userIDSlice = createSlice<string, SliceCaseReducers<string>>({
  name: 'userIdData',
  initialState: '',
  reducers: {
    storeUserID: (_, action) => {
      return action.payload;
    },
  },
});

// Actions
export const { storeUserID } = userIDSlice.actions;

// State
export const userIdData = (state: RootState): string => state.userIdData;

// Reducer
export default userIDSlice.reducer;
