import { ISfFailedProduct } from 'types';
import { failedProductsRequest, queryKeys } from 'utils';
import { IFailedProductsRequest } from 'utils/api/failedProductsRequest';
import { useGenerateQuery } from '../useGenerateQuery';

export const useGetFailedProducts = (
  requestConfig: IFailedProductsRequest = {}
) => {
  const { params } = requestConfig;
  return useGenerateQuery<ISfFailedProduct[]>({
    queryKey: queryKeys(params).all.failedProducts,
    apiCall: failedProductsRequest,
    requestConfig,
  });
};
