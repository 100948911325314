// Packages
import React, { ChangeEvent } from 'react';

// Redux

// Components
import { KiteCard, KiteInput } from '@kite/react-kite';

// Hooks

// Utils

// Types
import { TEstimateFields } from 'types';

// Styles
import './EstimateDataForm.scss';

export interface IEstimateDataFormProps {
  estimateData: TEstimateFields;
  errorMessages: TEstimateFields;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

/** Form to handle inputs for  */

const EstimateDataForm = ({
  estimateData,
  errorMessages,
  handleChange,
}: IEstimateDataFormProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { opportunityId, estimateName, customerName } = estimateData;

  const { estimateName: nameError, customerName: customerError } =
    errorMessages;

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="estimate-data-form">
      <span>Opportunity ID</span>
      <p>{opportunityId}</p>

      <KiteInput
        value={estimateName}
        onChange={handleChange}
        name="estimateName"
        label="Estimate Name"
        placeholder="Estimate Name"
        errorMessage={nameError}
      />
      <KiteInput
        value={customerName}
        onChange={handleChange}
        name="customerName"
        label="Customer/ Business Name"
        placeholder="Customer Name"
        errorMessage={customerError}
      />
    </KiteCard>
  );
};

export default EstimateDataForm;
