import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { IEstimate, IEstimateUpdate } from 'types';
import { convertSelectionForApi, estimateRequest, queryKeys } from 'utils';

export const useArchiveEstimate = () => {
  const { estimateId } = useParams();
  const queryClient = useQueryClient();
  const allEstimatesKey = queryKeys().all.estimates;
  const estimateKey = queryKeys({ estimateId }).details.estimate;

  const {
    mutate: archiveEstimate,
    isLoading: archiveEstimateLoading,
    isSuccess: archiveEstimateSuccess,
    isError: archiveEstimateError,
  } = useMutation(
    (estimate: IEstimate) => {
      const newEstimate: IEstimateUpdate = {
        ...estimate,
        archived: true,
        scenarios: estimate.scenarios.reduce(
          (acc: IEstimateUpdate['scenarios'], scenario) => {
            const newScenario = {
              ...scenario,
              selections: scenario.selections.map((s) =>
                convertSelectionForApi(s)
              ),
            };
            acc.push(newScenario);
            return acc;
          },
          []
        ),
      };
      return estimateRequest({
        endpoint: `estimates/${estimate.id}`,
        method: 'PUT',
        data: newEstimate,
      });
    },
    {
      onMutate: async (estimate: IEstimate) => {
        await queryClient.cancelQueries(allEstimatesKey);
        await queryClient.cancelQueries(estimateKey);

        const oldEstimates: IEstimate[] =
          queryClient.getQueryData(allEstimatesKey) || [];
        const oldEstimate = queryClient.getQueryData(estimateKey) as IEstimate;

        const archivedEstimate: IEstimate = { ...estimate, archived: true };
        const updatedEstimates = [
          ...oldEstimates.filter((e) => e.id !== archivedEstimate.id),
          archivedEstimate,
        ];

        queryClient.setQueryData(allEstimatesKey, updatedEstimates);
        queryClient.setQueryData(estimateKey, archivedEstimate);

        return { oldEstimates, oldEstimate };
      },
      onSettled: () => {
        queryClient.invalidateQueries(allEstimatesKey);
        queryClient.invalidateQueries(estimateKey);
      },
      onError: async (err, _, context) => {
        console.log(err);

        await queryClient.cancelQueries(allEstimatesKey);
        await queryClient.cancelQueries(estimateKey);

        queryClient.setQueryData(allEstimatesKey, context?.oldEstimates);
        queryClient.setQueryData(estimateKey, context?.oldEstimate);
      },
    }
  );

  return {
    archiveEstimate,
    archiveEstimateLoading,
    archiveEstimateSuccess,
    archiveEstimateError,
  };
};
