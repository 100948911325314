import axios from 'axios';
import { env } from './env';
import { paramsSerializer } from 'utils';
import { throwQueryError } from './throwQueryError';

import { IBundle, IRequestConfig, IRequestParams, IUpdateBundle } from 'types';

export interface IBundleParams extends IRequestParams {
  featured?: IBundle['featured'];
  published?: IBundle['published'];
}

export interface IBundleRequest
  extends IRequestConfig<IBundle | IUpdateBundle> {
  params?: IBundleParams;
}

export const bundleRequest = async (config?: IBundleRequest) => {
  const { endpoint = 'bundles', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with bundle ${method}`,
      })
    );
};
