import axios from 'axios';
import { env } from './env';

export const getFeatureFlagData = async () => {
  const url = `${env.apiUrl}/feature-flags`;
  try {
    const res = await axios({
      method: 'GET',
      url,
    });

    if (res.status !== 200 || !res.data) {
      return { error: 'failed' };
    }

    return res.data.data;
  } catch (err) {
    console.log('err', err);
    return { error: 'failed' };
  }
};
