import { useCallback } from 'react';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { NONE } from 'utils';
import { ILocationSelection, ISelectionValues } from 'types';
import { useQueryData } from 'hooks';

// Generate a new selection based on productId + additional values
export const useGenerateSelection = () => {
  const { estimateId, bundleId } = useParams();
  const {
    locationId,
    scenarioId,
    currentSelections,
    allProductFamilies,
    allProducts,
  } = useQueryData();

  const generateSelection = useCallback(
    (config: {
      productId: string;
      selectionValues?: ISelectionValues;
      selectionId?: string;
      isDelete?: boolean;
    }) => {
      const {
        productId: initProductId,
        selectionId,
        isDelete,
        selectionValues = { quantity: 1 },
      } = config;

      const product = allProducts.find((p) => p.id === initProductId);

      const selection = currentSelections.find(
        (s) =>
          s.id === selectionId &&
          (bundleId === s.bundleId || !selectionId.includes('newSelection'))
      );

      const productFamily = allProductFamilies.find(
        (pf) =>
          pf.id === product?.productFamilyId || pf.id === selection?.familyId
      );

      const productId = isDelete ? NONE : !initProductId ? NONE : initProductId;

      const newSelection: Partial<ILocationSelection> = {
        locationId: !locationId ? undefined : locationId,
        scenarioId: !scenarioId ? undefined : scenarioId,
        bundleId: !bundleId ? undefined : bundleId,
        productId: !productFamily ? NONE : productId,
        familyName: productFamily?.name,
        familyCategory: productFamily?.category,
        familyId: productFamily?.id,
        name: product?.name,
        prices: product?.prices,
        discretionValue36: selection?.discretionValue36,
        discretionValue48: selection?.discretionValue48,
        discretionValue60: selection?.discretionValue60,
        ...selectionValues,
      };

      if (estimateId === 'sandbox') {
        newSelection.id = selectionId || `newSelection${v4()}`;
      } else {
        newSelection.id = !selectionId ? `newSelection${v4()}` : selectionId;
      }

      return newSelection as ILocationSelection;
    },
    [
      allProductFamilies,
      allProducts,
      bundleId,
      currentSelections,
      estimateId,
      locationId,
      scenarioId,
    ]
  );

  return generateSelection;
};
