import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ISubmission } from 'types';
import { queryKeys, submissionsRequest } from 'utils';

interface IUpdateSubmission {
  estimateId: string;
  locationId: string | null;
}

export const useDeleteSubmissions = ({
  estimateId,
  locationId,
}: IUpdateSubmission) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(() => {
    if (estimateId && locationId) {
      return queryKeys({ estimateId, locationId }).filter.submissions;
    }
    if (estimateId && !locationId) {
      return queryKeys({ estimateId }).filter.submissions;
    }
    return queryKeys().all.submissions;
  }, [estimateId, locationId]);

  const submissionQueryKeys = [
    queryKeys({ estimateId, locationId: locationId || '' }).filter.submissions,
    queryKeys({ estimateId }).filter.submissions,
    queryKeys().all.submissions,
  ];

  // Match & update any pre-existing submissions for optimistic changes
  const {
    mutate: deleteSubmissions,
    isLoading: deleteSubmissionsLoading,
    isSuccess: deleteSubmissionsSuccess,
    isError: deleteSubmissionsError,
  } = useMutation(
    async (submissionIds: string[]) =>
      await submissionsRequest({
        endpoint: 'submissions',
        method: 'DELETE',
        data: submissionIds,
      }),
    {
      onMutate: async (submissionIds: string[]) => {
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Get previous data in case of mutation error (see onError below)
        const previousSubmissions =
          queryClient.getQueryData<ISubmission[]>(queryKey) || [];
        // Optimistically set data
        queryClient.setQueryData(
          queryKey,
          previousSubmissions.filter((s) => !submissionIds.includes(s.id))
        );

        return { previousSubmissions };
      },
      onError: async (err, _, context) => {
        console.log(err);
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Reset query data to pre-mutation if mutation error
        queryClient.setQueryData(queryKey, context?.previousSubmissions);
      },
      onSettled: () => {
        submissionQueryKeys.forEach((key) =>
          queryClient.invalidateQueries(key)
        );
        queryClient.invalidateQueries(queryKeys().all.estimates);
        queryClient.invalidateQueries(
          queryKeys({ estimateId }).details.estimate
        );
      },
    }
  );

  return {
    deleteSubmissions,
    deleteSubmissionsLoading,
    deleteSubmissionsSuccess,
    deleteSubmissionsError,
  };
};
