import axios from 'axios';
import { IRequestConfig, IPromo } from 'types';
import { paramsSerializer } from 'utils/format/paramSerializer';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

export const promosRequest = async (config?: IRequestConfig<IPromo>) => {
  const { endpoint = 'promos', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with promos ${method}`,
      })
    );
};
