// Packages
import React from 'react';

// Redux

// Components
import { ButtonLink } from 'components';

// Hooks
import { useLocation, useParams } from 'react-router-dom';

// Utils

// Types

// Styles
import './UcProgressButton.scss';

interface IUcProgressButtonParams {
  type?: 'outline' | 'primary';
}
/** UC Questions button displaying question progress */

const UcProgressButton = ({ type = 'outline' }: IUcProgressButtonParams) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { pathname } = useLocation();
  const { estimateId = '' } = useParams();

  // const { data: submissions = [] } = useGetSubmissions({
  //   params: { estimateId },
  // });

  // const { completePercent } = useUCQuestionsTotal({
  //   submissions,
  //   questionType: 'ucq',
  // });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  // Team not sure if they want to show this or not
  // const progressBar = useMemo(
  //   () => (
  //     <div
  //       className={classNames({
  //         'uc-progress-button__progress': true,
  //         'uc-progress-button__progress--full': completePercent === 100,
  //       })}
  //       style={{ width: `${completePercent}%` }}
  //     ></div>
  //   ),
  //   [completePercent]
  // );
  // const linkContent = useMemo(() => {
  //   return (
  //     <>
  //       {`UC Questions (${completePercent}%)`}
  //       {progressBar}
  //     </>
  //   );
  // }, [completePercent, progressBar]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <ButtonLink
      className="uc-progress-button"
      type={type}
      to={`/uc-questions/${estimateId}/general/general-discovery`}
      state={{ fromDomain: pathname }}
      linkContent="UC Questions"
    />
  );
};

export default UcProgressButton;
