import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { KitePagination } from '@kite/react-kite';
import { useAnalytics } from './useAnalytics';

const usePagination = (itemsPerPage: number, dataLength: number) => {
  const { trackSelectAction } = useAnalytics();
  const [currentPage, setCurrentPage] = useState(1);
  const prevCurrentPage = useRef(currentPage);
  const totalPages = Math.ceil(dataLength / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [dataLength]);

  useEffect(() => {
    if (prevCurrentPage.current && prevCurrentPage.current !== currentPage) {
      trackSelectAction(`Pagination Change: Page ${currentPage}`, {
        opType: 'paginationClick',
      });
    }
  }, [currentPage, trackSelectAction]);

  const startIndex = useMemo(
    () => (currentPage - 1) * itemsPerPage,
    [currentPage, itemsPerPage]
  );

  const endIndex = useMemo(
    () => startIndex + itemsPerPage,
    [startIndex, itemsPerPage]
  );

  const onNext = useCallback(() => {
    prevCurrentPage.current = currentPage;
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  const onPrev = useCallback(() => {
    prevCurrentPage.current = currentPage;
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  const onPageSelect = useCallback(
    (page: number) => {
      prevCurrentPage.current = currentPage;
      setCurrentPage(page);
    },
    [currentPage]
  );

  const viewStart = dataLength ? startIndex + 1 : 0;

  const viewEnd = endIndex < dataLength ? endIndex : dataLength;

  const paginationComponent = useMemo(
    () =>
      totalPages > 1 ? (
        <KitePagination
          currentPage={currentPage}
          onNextPage={onNext}
          onPrevPage={onPrev}
          onPageSelect={onPageSelect}
          totalPages={totalPages}
        />
      ) : null,
    [currentPage, onNext, onPageSelect, onPrev, totalPages]
  );

  const paginateData = useCallback(
    <T,>(data: T[]): T[] => data.slice(startIndex, endIndex),
    [endIndex, startIndex]
  );

  return {
    paginationComponent,
    currentPage,
    viewStart,
    viewEnd,
    paginateData,
  };
};

export default usePagination;
