import { bundleRequest, queryKeys } from 'utils';

import { IBundle } from 'types';
import { IBundleRequest } from 'utils/api/bundleRequest';

import { useGenerateQuery } from '../useGenerateQuery';

export const useGetBundles = (requestConfig: IBundleRequest = {}) => {
  const { params } = requestConfig;
  const { data, isLoading, error } = useGenerateQuery<IBundle[]>({
    queryKey: params
      ? queryKeys(params).filter.bundles
      : queryKeys().all.bundles,
    apiCall: bundleRequest,
    requestConfig,
    opts: { refetchOnWindowFocus: false },
  });

  return { data, isLoading, error };
};
