import { useMutation, useQueryClient } from 'react-query';
import { IFieldOpsPicture } from 'types';
import { fieldOpsPictureRequest, queryKeys } from 'utils';

export const useUpdateFieldOpsPictures = (locationId: string) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys({ locationId }).details.location;

  const {
    mutate: updateFieldOpsPictures,
    isLoading: updateFieldOpsPicturesLoading,
    error: updateFieldOpsPicturesError,
    data: updateFieldOpsPicturesRes,
  } = useMutation(
    (fieldOpsPics: IFieldOpsPicture[]) =>
      fieldOpsPictureRequest({
        method: 'PUT',
        data: fieldOpsPics,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    updateFieldOpsPictures,
    updateFieldOpsPicturesLoading,
    updateFieldOpsPicturesError,
    updateFieldOpsPicturesRes,
  };
};
