// External Packages
import React, { useState, ChangeEvent, MouseEvent } from 'react';
import {
  KiteCard,
  KiteInput,
  KitePassword,
  KiteButton,
  KiteAlert,
} from '@kite/react-kite';

// Styles
import './LoginForm.scss';

// =============================================
// Interfaces
// =============================================
interface ILoginFormProps {
  loginError: boolean;
  loginLoading: boolean;
  onSubmit: (email: string, password: string) => void;
}

// =============================================
// Component
// =============================================
const LoginForm = ({ loginError, loginLoading, onSubmit }: ILoginFormProps) => {
  // =============================================
  // State
  // =============================================
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSubmit = (e: MouseEvent<HTMLButtonElement | MouseEvent>) => {
    e.preventDefault();

    // Validataion
    let hasErrors = false;

    if (email.length === 0) {
      setEmailError(true);
      hasErrors = true;
    }

    if (password.length === 0) {
      setPasswordError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      setEmail('');
      setPassword('');
      return;
    }

    onSubmit(email, password);
    setEmail('');
    setPassword('');
  };

  // =============================================
  // Return
  // =============================================
  return (
    <div className="login-form">
      <KiteCard className="login-form__card kite-theme-dark kite-typography">
        <div className="login-form__inner">
          <h2 className="login-form__title kite-h2">PartnerLink</h2>
          {loginError && (
            <KiteAlert
              className="login-form__page-alert"
              level="page"
              description="The info you entered doesn't match our records. Please try again."
              type="alert"
              useDark
            />
          )}
          <form className="login-form__form">
            <KiteInput
              label="PartnerLink Email ID"
              name="email"
              maxWidth="100%"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
                setEmailError(false);
              }}
              value={email}
              errorMessage={
                !emailError ? undefined : 'You must provide an email'
              }
              tooltip={
                <div>
                  <p>
                    Please use your PartnerLink Email ID and Password to log in.{' '}
                  </p>
                  <p>
                    These are the same credentials you would use to log in to
                    partnerlink.spectrum.com
                  </p>
                </div>
              }
            />
            <KitePassword
              label="Password"
              name="password"
              maxWidth="100%"
              enableShowPassword={false}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
                setPasswordError(false);
              }}
              value={password}
              errorMessage={
                !passwordError ? undefined : 'You must provide an email'
              }
            />
            <KiteButton
              size="full"
              buttonType="submit"
              loading={loginLoading}
              onClick={(e) => handleSubmit(e)}
            >
              Login
            </KiteButton>
          </form>
          <a
            href="https://partnerlink.spectrum.com/login#forgot_password"
            className="login-form__forgot-password kite-link"
            target="_blank"
            rel="noreferrer noopener"
          >
            Forgot Password?
          </a>
          <a
            href="https://partnerlink.spectrum.com/wp/registration"
            className="kite-link login-form__create-email"
            target="_blank"
            rel="noreferrer noopener"
          >
            Request Access
          </a>
        </div>
      </KiteCard>
      <div className="login-form__contact-container">
        <p className="login-form__contact-text">
          For PartnerLink help using this site, please contact
        </p>
        <a
          className="kite-link"
          href="mailto:DL-Solution-Pricing-Tool-CP-Support@charter.com"
        >
          DL-Solution-Pricing-Tool-CP-Support@charter.com
        </a>
      </div>
    </div>
  );
};

export default LoginForm;
