// Packages
import React, { useEffect } from 'react';

// Components
import { KiteLoader } from '@kite/react-kite';

// Hooks
import { useAnalytics } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useLocalStorage } from 'hooks/useLocalStorage';

// Utils
import { getSalesforceAccessToken } from 'utils';

// Types
import { IGetSalesforceAccessTokenArgs } from 'utils/api/salesforceRequests';
import { ISaleforceLocalStorage } from 'types/Salesforce';

// Styles
import './SalesforceLogin.scss';

const SalesforceLogin = () => {
  // React Router
  const location = useLocation();
  const navigate = useNavigate();

  // Local Storage importing only set state variable
  const [, setSfLocalStorage] = useLocalStorage<ISaleforceLocalStorage | ''>(
    'jid',
    ''
  );

  // Parse URL Params
  const getParams = () => {
    const rawParams = location.search.slice(1).split('&');

    const params = rawParams.reduce((acc, raw) => {
      const key = raw.split('=')[0];
      const val = raw.split('=')[1];

      acc[key as keyof IGetSalesforceAccessTokenArgs] = val;
      return acc;
    }, {} as IGetSalesforceAccessTokenArgs);

    return params;
  };

  // Quantum
  const { trackPageView } = useAnalytics();
  useEffect(() => trackPageView('SalesforceLogin'), [trackPageView]);

  // React Query
  useQuery('sfAccessToken', () => getSalesforceAccessToken(getParams()), {
    enabled: !!getParams().code && !!getParams().state,
    onSuccess: (res) => {
      setSfLocalStorage({
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
        issuedAt: res.data.issued_at,
      });

      const state = getParams().state;

      const parsedState = state && JSON.parse(decodeURIComponent(state));

      const navOpts = { state: { fromDomain: '/salesforce-login' } };

      // TODO: This is working, but another redirect is taking over
      parsedState?.location
        ? navigate(parsedState.location, navOpts)
        : navigate('/dashboard', navOpts);
    },
  });

  // Loading
  return (
    <div className="salesforce-login">
      <KiteLoader loaderTitle="Logging into Salesforce..." />
    </div>
  );
};

export default SalesforceLogin;
