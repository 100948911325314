import { IProfile } from 'types';
import { generateDefaultConfiguration } from './generateDefaultConfiguration';

const { newEstimate: sandboxEstimate } = generateDefaultConfiguration({
  userId: '1',
  estimateId: 'sandbox',
  scenarioId: 'sandboxScenario1',
  locationId: 'sandboxLocation1',
});

export const sandboxData = {
  profiles: [] as IProfile[],
  estimate: sandboxEstimate,
};
