import { IFeatureFlag } from 'types';
import { IApiResponse } from './types';

import { mockFeatureFlags } from 'utils/mocks';

export const featureFlags: IApiResponse<IFeatureFlag> = {
  get: {
    200: {
      message: 'success',
      total: mockFeatureFlags.length,
      data: mockFeatureFlags,
    },
  },
  getOne: {},
  post: {},
  put: {},
};
