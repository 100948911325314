// Packages
import React, { useCallback, useEffect, useState } from 'react';

// Redux

// Components
import { KiteInput } from '@kite/react-kite';
import { YesNoSwitch } from 'components';

// Hooks
import { useDebouncedCallback } from 'use-debounce/lib';

// Utils

// Types

// Styles
import './ValidationYesNo.scss';

export interface IValidationYesNoProps {
  isAccurate: boolean | null;
  comment: string | null;
  locationId: string;
  onAccurateSelect: (isAccurate: boolean) => void;
  onCommentChange: (value: string) => void;
}

/** Generic Yes/No with comment section for field validation */

const ValidationYesNo = ({
  isAccurate,
  comment,
  onAccurateSelect,
  onCommentChange,
  locationId,
}: IValidationYesNoProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [localAccurate, setLocalAccurate] = useState(isAccurate);
  const [localComment, setLocalComment] = useState(comment);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const debounceAccuracy = useDebouncedCallback(onAccurateSelect, 300);
  const debounceComment = useDebouncedCallback(onCommentChange, 300);

  const handleAccurateSelect = useCallback(
    (isAccurate: boolean) => {
      debounceComment.cancel();
      setLocalAccurate(isAccurate);
      debounceAccuracy(isAccurate);
    },
    [debounceAccuracy, debounceComment]
  );

  const handleCommentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      debounceAccuracy.cancel();
      setLocalComment(value);
      debounceComment(value);
    },
    [debounceAccuracy, debounceComment]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (
      localComment !== comment &&
      localAccurate !== isAccurate &&
      !debounceComment.isPending() &&
      !debounceAccuracy.isPending()
    ) {
      setLocalAccurate(isAccurate);
      setLocalComment(comment);
    }
  }, [
    comment,
    debounceAccuracy,
    debounceComment,
    isAccurate,
    localAccurate,
    localComment,
  ]);

  useEffect(() => {
    debounceAccuracy.cancel();
    debounceComment.cancel();
    setLocalAccurate(isAccurate);
    setLocalComment(comment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="validation-yes-no">
      <span>Is this information accurate?</span>
      <YesNoSwitch handleSelect={handleAccurateSelect} value={localAccurate} />
      {localAccurate === false && (
        <KiteInput
          maxWidth="none"
          margin="0"
          placeholder="Enter changes or discrepancies"
          value={localComment}
          onChange={handleCommentChange}
        />
      )}
    </div>
  );
};

export default ValidationYesNo;
