import axios from 'axios';
import { IRequestConfig, IRequestParams } from 'types';
import { IFieldOpsPicture } from 'types';
import { paramsSerializer } from 'utils/format/paramSerializer';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IFieldOpsPictureRequestParams extends IRequestParams {
  locationId?: string;
  id?: string;
}

export interface IFieldOpsPictureRequest
  extends IRequestConfig<IFieldOpsPicture | IFieldOpsPicture[]> {
  params?: IFieldOpsPictureRequestParams;
}

export const fieldOpsPictureRequest = async (
  config?: IFieldOpsPictureRequest
) => {
  const {
    endpoint = 'field-ops-pictures',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with field ops picture ${method}`,
      })
    );
};
