// Packages
import React, { MutableRefObject, useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';

// Redux

// Components
import {
  UcReviewLocationHeader,
  UcReviewCpwCostTable,
  CpwCostApprovalBadge,
} from 'components';
import Logo from 'components/Header/Logo';
import { KiteLoader } from '@kite/react-kite';

// Hooks
import { useParams } from 'react-router-dom';
import { useGetLocationById, useRenderFields, useScrollToTop } from 'hooks';

// Utils
import { formatPrice } from 'utils';

// Types
import { IForm, ISubmission } from 'types';

// Styles
import './UcReviewCpwTab.scss';

export interface IUcReviewCpwTabProps {
  opptyName: string;
  locationName: string;
  formData: Partial<IForm>;
  businessName: string;
  address1: string;
  address2: string;
  submissions: ISubmission[];
  refForPrint: MutableRefObject<HTMLDivElement | null>;
  isLoading: boolean;
}

/** CPW Quote tab on the read only page */

const UcReviewCpwTab = ({
  opptyName,
  locationName,
  formData,
  businessName,
  address1,
  address2,
  submissions,
  refForPrint,
  isLoading,
}: IUcReviewCpwTabProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { locationId = '' } = useParams();

  const {
    data: locationData,
    isLoading: locationDataIsLoading,
    error: locationDataError,
  } = useGetLocationById(locationId);

  const { fields } = formData;

  const { fieldComponents } = useRenderFields({
    fields: fields ?? [],
    submissions,
    isCpw: true,
  });

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useScrollToTop({ ref: scrollRef, refNestLevel: 0, locationId });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }, []);

  // =============================================
  // Render Methods
  // =============================================
  const totalCost = useMemo(() => {
    const sumCost = locationData?.fieldOpsCosts?.reduce((sum: number, cost) => {
      return sum + (cost.lineItemPayout || 0) * (cost.quantity || 0);
    }, 0);
    return Math.round((sumCost || 0) * 100) / 100;
  }, [locationData]);

  const renderCpwCostTable = useMemo(() => {
    if (locationDataIsLoading || isLoading) return <KiteLoader />;
    else if (locationDataError) return <div>Something went wrong.</div>;
    else if (locationData)
      return <UcReviewCpwCostTable locationData={locationData} />;
  }, [isLoading, locationData, locationDataError, locationDataIsLoading]);

  const renderCostDiffItem = useCallback(
    (label: string, cost: number, approvalStatus?: boolean) => {
      return (
        <div
          className={classNames({
            'uc-review-cpw-tab__sub-header__top__totals__cost-diffs__item':
              true,
            'uc-review-cpw-tab__sub-header__top__totals__cost-diffs__item--approved':
              approvalStatus,
            'uc-review-cpw-tab__sub-header__top__totals__cost-diffs__item--not-approved':
              approvalStatus === false,
          })}
        >
          <span className="uc-review-cpw-tab__sub-header__top__totals__cost-diffs__item--label">
            {label}:
          </span>
          <span className="uc-review-cpw-tab__sub-header__top__totals__cost-diffs__item--amount">
            {formatPrice(cost)}
          </span>
        </div>
      );
    },
    []
  );

  const shipToAddress = useMemo(() => {
    const { shipTo } = locationData || {};

    if (!shipTo) return null;

    const { name, address1, address2, city, state, zipcode } = shipTo;
    return (
      <div>
        <p>Ship To: </p>
        {name && <p>{name}</p>}
        <p>
          {address1}
          {address2.length > 0 && ', ' + address2}
        </p>
        <p>
          {city}
          {state.length > 0 && ', ' + state}
          {zipcode.length > 0 && ' ' + zipcode}
        </p>
      </div>
    );
  }, [locationData]);
  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="uc-review-cpw-tab" ref={refForPrint}>
      <UcReviewLocationHeader
        opptyName={opptyName}
        locationName={locationName}
      />
      <div className="uc-review-cpw-tab__sub-header">
        <div className="uc-review-cpw-tab__sub-header__top">
          <h1>{formData.name}</h1>
          <div className="uc-review-cpw-tab__sub-header__top__right">
            <div className="uc-review-cpw-tab__sub-header__top__totals">
              <div className="uc-review-cpw-tab__sub-header__top__totals__cost-diffs">
                <div className="uc-review-cpw-tab__sub-header__top__totals__cost-diffs__item uc-review-cpw-tab__sub-header__top__totals__cost-diffs--title">
                  Totals:
                </div>
                {renderCostDiffItem('Cost Billed', totalCost)}
                {renderCostDiffItem('Approved Cost', 2500)}
                {renderCostDiffItem(
                  'Difference',
                  totalCost - 2500,
                  totalCost <= 2500
                )}
              </div>
              <CpwCostApprovalBadge approved={totalCost <= 2500} />
            </div>
            <Logo />
          </div>
        </div>
        <div className="uc-review-cpw-tab__sub-header__bottom">
          <div className="uc-review-cpw-tab__sub-header__bottom-loc">
            <p>{businessName}</p>
            <p>
              {address1}
              {address2.length > 0 && ', ' + address2}
            </p>
          </div>
          {shipToAddress}
        </div>
      </div>
      <div className="uc-review-cpw-tab__content" ref={scrollRef}>
        <form onSubmit={handleSubmit}>
          <div className="uc-review-cpw-tab__content__fields">
            {fieldComponents.slice(0, fieldComponents.length - 1)}
          </div>
          <div className="uc-review-cpw-tab__content__notes-field">
            {fieldComponents[fieldComponents.length - 1]}
          </div>
        </form>
        {renderCpwCostTable}
      </div>
    </div>
  );
};

export default UcReviewCpwTab;
