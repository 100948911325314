import { mockOpportunity } from 'utils/mocks';

export const opportunities = {
  get: {
    200: {
      message: 'success',
      data: mockOpportunity,
    },
    500: {
      message:
        'Something went wrong retrieving estimate from Salesforce with opportunity Id',
    },
  },
};
