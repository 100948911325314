import dayjs from 'dayjs';
import { ISalesTeamMember, IUseGetActiveDirectoryRes } from 'types';

export const formatTeamMember = (config: {
  dateUpdated?: string | Date;
  activeDirectoryData?: IUseGetActiveDirectoryRes;
}) => {
  const { activeDirectoryData, dateUpdated } = config;
  if (activeDirectoryData) {
    const { first_name, last_name, email, title, avatar } = activeDirectoryData;

    const teamMember: ISalesTeamMember = {
      firstName: first_name,
      lastName: last_name,
      fullName: `${first_name} ${last_name}`,
      email,
      title,
      imageURI: `data:image/jpeg;base64,${avatar}`,
    };

    if (dateUpdated) {
      teamMember.dateUpdated = dayjs(dateUpdated).format('MM-DD-YYYY hh:mma');
    }

    return teamMember;
  }
};
