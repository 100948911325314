export const mockFailedProducts = [
  {
    sfOpportunityId: '0067j0000089y1J',
    sfLocationId: '0017j00000jJZh3AAG',
    productName: 'Mock Product',
    productId: 'dfb86137-6d7c-4c84-be50-0a6fba9b77ce',
    serviceCode: 'AA100',
    term: '12 Months',
    quantity: 1,
    productFamily: 'Mock Family',
    message: 'Mock Reason',
  },
];
