// External Packages
import React, { useCallback, useMemo } from 'react';

// Components
import {
  PdfPageWrapper,
  PdfTotalsTable,
  PdfProductTable,
} from '../PdfComponents';

// Utils
import { generateDisclaimers } from 'utils';

// Types
import {
  TTermLengthMonths,
  TEipTermLengthMonths,
  ILocation,
  IProductFamily,
  TableValues,
  IScenario,
  ILocationSelection,
  IGenerateProductTotals,
  TNavigationCategory,
  IProfile,
  IPromo,
} from 'types';

export interface ILocationProductsPdf {
  quoteName: string;
  customerName: string;
  currentScenario?: IScenario;
  currentLocation: ILocation;
  termMonth: TTermLengthMonths;
  eipTermMonth: TEipTermLengthMonths;
  allProductFamilies: IProductFamily[];
  allProfiles: IProfile[];
  allPromos: IPromo[];
  generateProductTotals: ({
    selections,
    locationId,
    category,
    subCategory,
    format,
  }: IGenerateProductTotals) => TableValues<number | string>;
}

const LocationProductsPdf = ({
  quoteName,
  customerName,
  currentScenario,
  currentLocation,
  termMonth,
  eipTermMonth,
  allProductFamilies,
  allProfiles,
  generateProductTotals,
  allPromos,
}: ILocationProductsPdf) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const locationId = currentLocation.id;
  const productTotals = useMemo(() => {
    return generateProductTotals({
      term: currentScenario?.term,
      eipTerm: currentScenario?.eipTerm,
      locationId,
    }) as TableValues<string>;
  }, [locationId, currentScenario, generateProductTotals]);

  const locationSelections = useMemo(() => {
    return (
      currentScenario?.selections.reduce(
        (acc: ILocationSelection[], selection) => {
          if (selection.locationId === locationId) {
            acc.push(selection);
          }
          return acc;
        },
        []
      ) || []
    );
  }, [locationId, currentScenario]);

  const disclaimers = useMemo(() => {
    return generateDisclaimers({
      locationSelections,
      scenarioPromos: currentScenario?.promos || [],
      promos: allPromos,
      locationId,
    });
  }, [allPromos, locationId, currentScenario, locationSelections]);

  // =============================================
  // Render Methods
  // =============================================
  const generateTables = useCallback(() => {
    const categories: TNavigationCategory[] = [
      'Data',
      'Trunks',
      'UC Profiles',
      'UC Addons',
      'MNE',
      'ENE',
      'SBB 1-19 Pub/Priv',
    ];

    return categories.map((category) => {
      const filteredSelections = locationSelections.filter((s) => {
        if (category === 'UC Addons') {
          return s.familyCategory === 'UC Products' && !s.profileId;
        } else if (category === 'UC Profiles') {
          return s.familyCategory === 'UC Products' && s.profileId;
        }
        return s.familyCategory === category;
      });

      return (
        <PdfProductTable
          key={`pdf product row ${category}`}
          filteredSelections={filteredSelections}
          allProductFamilies={allProductFamilies}
          allProfiles={allProfiles}
          category={category}
          termMonth={termMonth}
          eipTermMonth={
            filteredSelections.find((f) => f.eipTerm)?.eipTerm || null
          }
          currentScenario={currentScenario}
          generateProductTotals={generateProductTotals}
          allPromos={allPromos}
          locationId={locationId}
          locationSelections={locationSelections}
        />
      );
    });
  }, [
    locationId,
    allProductFamilies,
    allProfiles,
    allPromos,
    termMonth,
    eipTermMonth,
    currentScenario,
    locationSelections,
    generateProductTotals,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <PdfPageWrapper
      customerName={customerName}
      locationName={currentLocation.name}
      quoteName={quoteName}
    >
      {/* Tables */}
      {generateTables()}

      {/* Totals Wrapper */}
      <PdfTotalsTable tableTotals={productTotals} />

      {disclaimers}
    </PdfPageWrapper>
  );
};

export default LocationProductsPdf;
