// Packages
import React, { useCallback } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

// Redux

// Components
import { KiteModal } from '@kite/react-kite';
import { UCQHistoryRow } from 'components';

// Hooks

// Utils
import { capitalizeFirstLetter, isValidJsonString } from 'utils';

// Types
import {
  IPostFileToS3Response,
  ISalesTeamMember,
  ISubmissionHistory,
} from 'types';

// Styles
import './UCQHistoryModal.scss';

export interface IUCQHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentHistory?: ISubmissionHistory | null;
  currentHistoryTeamMember?: ISalesTeamMember;
}

/** Displays history item for a ucq submission */

const UCQHistoryModal = ({
  isOpen,
  onClose,
  currentHistory,
  currentHistoryTeamMember,
}: IUCQHistoryModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { fieldOrder, fieldLabel, inputType } =
    currentHistory || ({} as ISubmissionHistory);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const labelPrefix = fieldLabel !== 'Notes' ? `${fieldOrder}. ` : '';
  const questionLabel = `${labelPrefix}${fieldLabel}`;

  const renderAnswer = useCallback(
    (config: { valueType: 'previousValue' | 'updatedValue' }) => {
      if (!currentHistory) {
        return null;
      }

      const { valueType } = config;
      const value = currentHistory[valueType];

      if (value && value.length && isValidJsonString(value)) {
        const parsedValue = JSON.parse(value);

        if (inputType === 'text-quantity') {
          return (
            <ul aria-label="Yes">
              {parsedValue.map((answer: string[], i: number) => (
                <li key={i}>{answer.join(' x ')}</li>
              ))}
            </ul>
          );
        }

        if (inputType === 'image' || inputType === 'file') {
          const type = `${capitalizeFirstLetter(inputType)}s:`;
          return (
            <ul aria-label={`Uploaded ${type}`}>
              {parsedValue.map((answer: IPostFileToS3Response, i: number) => (
                <li key={i}>{answer.fileName}</li>
              ))}
            </ul>
          );
        }

        if (inputType === 'time') {
          const formatTime = (time: string) =>
            dayjs(time, 'HH:mm').format('hh:mm a');
          const { start, end } = parsedValue;
          return (
            <p>
              {formatTime(start)} to {formatTime(end)}
            </p>
          );
        }
      }

      return (
        <p
          className={classNames({
            'ucq-history-modal__answer': true,
            'ucq-history-modal__answer--none': !value?.length,
          })}
        >
          {value?.length ? value : 'No answer'}
        </p>
      );
    },
    [currentHistory, inputType]
  );
  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="ucq-history-modal"
      canShow={isOpen && !!currentHistory}
      onHide={onClose}
      title="Question Change"
    >
      <UCQHistoryRow
        historyData={currentHistory}
        teamMember={currentHistoryTeamMember}
      />
      <div className="ucq-history-modal__body">
        <p
          dangerouslySetInnerHTML={{
            __html: questionLabel,
          }}
        />
        <h2>Previous</h2>
        {renderAnswer({ valueType: 'previousValue' })}
        <h2>Changed to</h2>
        {renderAnswer({ valueType: 'updatedValue' })}
      </div>
    </KiteModal>
  );
};

export default UCQHistoryModal;
