import { IEstimate, IReportRequest } from 'types';
import { reportsRequest, queryKeys } from 'utils';
import { useGenerateQuery } from '../useGenerateQuery';

export const useGetReports = (requestConfig: IReportRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<IEstimate[]>({
    queryKey: params
      ? queryKeys(params).filter.reports
      : queryKeys().all.reports,
    apiCall: reportsRequest,
    requestConfig,
  });
};
