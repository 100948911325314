import { useMutation, useQueryClient } from 'react-query';
import { IFieldOpsPictureBody } from 'types';
import { fieldOpsPictureRequest, queryKeys } from 'utils';

export const usePostFieldOpsPicture = (locationId: string) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys({ locationId }).details.location;

  const {
    mutate: postFieldOpsPicture,
    isLoading: postFieldOpsPictureLoading,
    error: postFieldOpsPictureError,
    data: postFieldOpsPictureRes,
  } = useMutation(
    (newFieldOpsPic: IFieldOpsPictureBody) =>
      fieldOpsPictureRequest({
        method: 'POST',
        data: newFieldOpsPic,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    postFieldOpsPicture,
    postFieldOpsPictureLoading,
    postFieldOpsPictureError,
    postFieldOpsPictureRes,
  };
};
