import { IBundle } from 'types';
import { IApiResponse } from './types';

import { mockBundles } from 'utils/mocks';

export const bundles: IApiResponse<IBundle> = {
  get: {
    200: {
      message: 'success',
      total: Object.keys(mockBundles).length,
      data: Object.values(mockBundles),
    },
  },
  post: {
    201: {
      message: 'success',
      data: Object.values(mockBundles)[0],
    },
  },
  getOne: {},
  put: {},
};
