// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Node, useNodes } from 'react-flow-renderer';

// Redux

// Components
import { NavSwitch } from 'components';
import { Drawer, TextArea } from '@kite/react-kite-plus';
import {
  KiteButton,
  KiteCard,
  KiteInput,
  KiteModal,
  KiteSelect,
} from '@kite/react-kite';
import { NodeLabel } from '..';

// Hooks
import { useParams } from 'react-router-dom';
import { useGetEstimateDetails } from 'hooks';

// Utils
import {
  formatLocationAddress,
  handleNumInputKeydown,
  connectorTypes,
  interconnectTypes,
  interconnectResponsibilityTypes,
  conduitTypes,
  collatorSort,
} from 'utils';

// Types
import { IDistro, IUCQDesignFlowNode } from 'types';

// Styles
import './EditNodeDrawer.scss';

export interface IEditNodeDrawerProps {
  /** Determines if drawer is open */
  isOpen: boolean;
  /** Determines which form data will be displayed */
  nodeType: IDistro['nodeType'] | null;
  /** Callback for when drawer is closed */
  onClose: () => void;
  /** Callback to save distro changes */
  onSave: (distrosToUpdate: Partial<IDistro>[]) => void;
  /** Callback to delete distros */
  onDelete: (distroIds: string[]) => void;
  /** Currently selected node */
  currentNode: Node<IUCQDesignFlowNode> | null;
}

/** Drawer allowing user to edit/delete a distro node */

const EditNodeDrawer = ({
  isOpen,
  nodeType,
  onClose,
  currentNode,
  onSave,
  onDelete,
}: IEditNodeDrawerProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId } = useParams();
  const { estimateData } = useGetEstimateDetails(estimateId);
  const nodes = useNodes<IUCQDesignFlowNode>();

  const [formData, setFormData] = useState<IUCQDesignFlowNode | null>(null);
  const [isDeleteWarn, setIsDeleteWarn] = useState(false);
  const [warningNodes, setWarningNodes] = useState<string[] | null>(null);

  const {
    nodeId: currentNodeId,
    parentNodeId: currentNodeParentId,
    name = '',
    locationId = '',
    interconnectType = '',
    connectorType = '',
    switchPorts = '',
    phones = '',
    notes = '',
    isDemarc = false,
    distance = '0',
    interconnectTypeIdf = '',
    interconnectResponsibility = '',
    conduit = '',
  } = formData || {};

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const currentAddress = useMemo(() => {
    const location = estimateData?.locations.find((l) => l.id === locationId);
    if (location) {
      return formatLocationAddress(location);
    }

    return '';
  }, [estimateData, locationId]);

  const isBdg = nodeType === 'bdg';
  const isIdf = nodeType === 'idf';

  const hasExternalChildren = useMemo(() => {
    const { toNodeIds = [], parentNodeId } = currentNode?.data || {};
    return !!nodes.find(
      (n) => toNodeIds.includes(n.id) && parentNodeId !== n.parentNode
    );
  }, [currentNode, nodes]);

  const isParentNodeMDF = useMemo(() => {
    const { nodeId } = currentNode?.data || {};
    return isIdf
      ? !!nodes.find(
          (n) =>
            n.type === 'mdf' &&
            isIdf &&
            n.data.toNodeIds?.includes(nodeId ?? '')
        )
      : false;
  }, [currentNode, nodes, isIdf]);

  const hasInterconnect = !isIdf || hasExternalChildren;

  const nodeTypeLabel = isBdg ? 'Building' : nodeType?.toUpperCase();

  const isDirty = useMemo(
    () =>
      !formData || !currentNode
        ? false
        : !Object.entries(formData).every(([key, value]) => {
            const data = currentNode?.data || {};
            const currentNodeValue = data[key as keyof IUCQDesignFlowNode];
            if (!currentNodeValue && value) {
              return false;
            } else return value === currentNodeValue;
          }),
    [currentNode, formData]
  );

  const distroToUpdate = useMemo((): Partial<IDistro> | null => {
    if (isDirty && formData && currentNodeId && isOpen) {
      const updatedDistro = { ...formData };
      delete updatedDistro.addNode;
      delete updatedDistro.editNode;

      return {
        ...updatedDistro,
        // Null defaults to false on the API call so set to undefined to avoid incorrect false
        fieldOpsAccurate: updatedDistro.fieldOpsAccurate ?? undefined,
        toNodeIds:
          updatedDistro.interconnectType === 'Single Building'
            ? []
            : updatedDistro.toNodeIds,
      };
    }

    return null;
  }, [isDirty, formData, currentNodeId, isOpen]);

  // Recursively check if node has children/parent buildings to delete
  const getNodeIdsToDelete = useCallback(
    (
      node: Node<IUCQDesignFlowNode> | undefined | null,
      nodesToDelete: string[]
    ): string[] => {
      if (!node) {
        return nodesToDelete;
      }

      const { toNodeIds = [], parentNodeId, nodeType } = node.data;

      // If deleting building, get all child nodes of that building & recurse through all building children
      if (nodeType === 'bdg') {
        const bdgChildren = nodes.filter((n) => n.parentNode === node.id);
        return bdgChildren.reduce((acc, n) => {
          getNodeIdsToDelete(n, acc);
          return acc;
        }, nodesToDelete);
      }

      // Delete container bdg node if is main MDF
      if (
        nodeType === 'mdf' &&
        parentNodeId &&
        !nodesToDelete.includes(parentNodeId)
      ) {
        nodesToDelete.push(parentNodeId);
      }

      const nodesInBdg = nodes.filter((n) => n.parentNode === parentNodeId);

      const isOnlyChild = nodesInBdg.length === 1;
      const isNoMdf = !nodesInBdg.filter((n) => n.type === 'mdf').length;

      // Delete container bdg node if only child or if no MDF in building (connected IDF)
      if (
        (isOnlyChild || isNoMdf) &&
        parentNodeId &&
        !nodesToDelete.includes(parentNodeId)
      ) {
        nodesToDelete.push(parentNodeId);
      }

      // Add node id if not already included in array
      if (!nodesToDelete.includes(node.id)) {
        nodesToDelete.push(node.id);
      }

      // Add toNodeIds that aren't already in array and recurse to get any nested toNodeIds
      if (toNodeIds.length) {
        nodesToDelete.push(
          ...toNodeIds.filter((id) => !nodesToDelete.includes(id))
        );
        return toNodeIds.reduce((acc, id) => {
          const node = nodes.find((n) => n.id === id);
          getNodeIdsToDelete(node, acc);
          return acc;
        }, nodesToDelete);
      }

      return nodesToDelete;
    },
    [nodes]
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleDemarc = useCallback(() => {
    setFormData({ ...formData, isDemarc: !isDemarc });
  }, [isDemarc, formData]);

  const toggleDeleteWarn = useCallback(() => {
    setIsDeleteWarn(!isDeleteWarn);
  }, [isDeleteWarn]);

  const deleteNodes = useCallback(
    (nodeIds: string[]) => {
      const distroIds = nodeIds.reduce((acc: string[], nodeId) => {
        const distroId = nodes.find((n) => n.id === nodeId)?.data.id;
        if (distroId) {
          acc.push(distroId);
        }
        return acc;
      }, []);
      onDelete(distroIds);
      if (isDeleteWarn) {
        toggleDeleteWarn();
      }
    },
    [onDelete, isDeleteWarn, nodes, toggleDeleteWarn]
  );

  const handleDelete = useCallback(
    (
        params: {
          onContinue?: () => void;
          warningNodes?: string[] | null;
          isFullDelete?: boolean;
        } = {}
      ) =>
      () => {
        const { onContinue, warningNodes, isFullDelete } = params;
        if (currentNodeId) {
          const nodesToDelete = warningNodes
            ? warningNodes
            : Array.from(
                new Set(getNodeIdsToDelete(currentNode, [currentNodeId]))
              ).filter((id) => {
                if (isFullDelete) {
                  return true;
                }
                if (currentNode?.data?.interconnectType === 'Single Building') {
                  return id !== currentNodeParentId && id !== currentNodeId;
                }
                return true;
              });

          const showWarning = !!nodesToDelete.length && !isDeleteWarn;

          if (showWarning) {
            setWarningNodes(nodesToDelete);
            toggleDeleteWarn();
            return;
          }

          deleteNodes(nodesToDelete);
          setWarningNodes(null);

          if (onContinue) {
            onContinue();
          }
        }
      },
    [
      currentNode,
      currentNodeId,
      currentNodeParentId,
      deleteNodes,
      getNodeIdsToDelete,
      isDeleteWarn,
      toggleDeleteWarn,
    ]
  );

  const handleSave = useCallback(
    (params: { shouldClose: boolean }) => () => {
      if (distroToUpdate) {
        if (distroToUpdate.interconnectType === 'Single Building') {
          handleDelete({
            onContinue: () => {
              onSave([distroToUpdate]);
            },
            warningNodes,
          })();
          return;
        } else {
          onSave([distroToUpdate]);
        }
      }
      if (params.shouldClose) {
        onClose();
        setFormData(null);
      }
    },
    [distroToUpdate, handleDelete, warningNodes, onSave, onClose]
  );

  const handleClose = useCallback(() => {
    if (isDeleteWarn) {
      toggleDeleteWarn();
    }

    handleSave({ shouldClose: true })();
  }, [isDeleteWarn, handleSave, toggleDeleteWarn]);

  const onChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { name: inputName, value } = e.target;
      if (inputName === 'interconnectType' && value === 'Single Building') {
        const nodesToDelete = Array.from(
          new Set(
            getNodeIdsToDelete(currentNode, []).filter(
              (nodeId) =>
                nodeId !== currentNodeParentId && nodeId !== currentNodeId
            )
          )
        ).filter(
          (id) => id !== currentNodeId && id !== currentNode?.data?.parentNodeId
        );
        setWarningNodes(nodesToDelete);
      }
      const isNumberValue =
        inputName === 'phones' || inputName === 'switchPorts';
      if (
        isNumberValue &&
        (isNaN(parseInt(value)) ||
          (parseInt(value) <= 999 && parseInt(value) > -1))
      ) {
        setFormData({
          ...formData,
          [inputName]: isNaN(parseInt(value)) ? undefined : parseInt(value),
        });
      } else if (inputName === 'distance') {
        const d =
          value.indexOf('.') >= 0
            ? parseFloat(value.slice(0, value.indexOf('.') + 3))
            : parseFloat(value);
        setFormData({ ...formData, [inputName]: d });
      } else if (
        inputName === 'interconnectResponsibility' &&
        value === 'Customer Provided'
      ) {
        setFormData({ ...formData, [inputName]: value, conduit: null });
      } else if (!isNumberValue) {
        setFormData({ ...formData, [inputName]: value });
      }
    },
    [
      currentNode,
      currentNodeId,
      currentNodeParentId,
      formData,
      getNodeIdsToDelete,
    ]
  );

  // =============================================
  // Render Methods
  // =============================================
  const locationOptions = useMemo(() => {
    const locations = estimateData?.locations || [];
    return locations.map((l) => (
      <option key={l.id} value={l.id}>
        {l.name}
      </option>
    ));
  }, [estimateData]);

  const connectorOptions = useMemo(() => {
    return connectorTypes.map((ct) => (
      <option key={ct} value={ct}>
        {ct}
      </option>
    ));
  }, []);

  const interconnectOptions = useMemo(() => {
    return interconnectTypes.map((ict) =>
      isIdf && ict === 'Single Building' ? null : (
        <option key={ict} value={ict}>
          {ict}
        </option>
      )
    );
  }, [isIdf]);

  const interconnectResponsibilityOption = useMemo(() => {
    return interconnectResponsibilityTypes.map((icr) =>
      !isIdf && !isParentNodeMDF ? null : (
        <option key={icr} value={icr}>
          {icr}
        </option>
      )
    );
  }, [isIdf, isParentNodeMDF]);

  const conduitOption = useMemo(() => {
    return conduitTypes.map((conduit) =>
      !isIdf && !isParentNodeMDF ? null : (
        <option key={conduit} value={conduit}>
          {conduit}
        </option>
      )
    );
  }, [isIdf, isParentNodeMDF]);

  const currentNodeLabel = useMemo(
    () => (
      <NodeLabel
        label={
          currentNodeId
            ? nodeType === 'idf'
              ? currentNodeId
              : currentNodeId?.toUpperCase()
            : ''
        }
        type={nodeType as NonNullable<IUCQDesignFlowNode['nodeType']>}
      />
    ),
    [currentNodeId, nodeType]
  );

  const warningContent = useMemo(() => {
    if (!warningNodes) {
      return null;
    }
    const idsToDelete = warningNodes
      .filter((nodeId) => nodeId !== currentNodeId)
      // Sort alphabetically but place mdf before idf
      .sort((a, b) => {
        const firstA = a[0].toLowerCase();
        const firstB = b[0].toLowerCase();
        if (firstA === 'm' && firstB !== 'm') {
          return firstB === 'i' ? -1 : 1;
        }

        if (firstB === 'm' && firstA !== 'm') {
          return firstA === 'i' ? 1 : -1;
        }

        return collatorSort(a, b);
      });
    const deleteListItems = idsToDelete.reduce((acc: JSX.Element[], id) => {
      const firstLetter = id[0];
      const nodeLabel = firstLetter.match(/i/i) ? (
        <li key={id}>
          <NodeLabel label={id} type="idf" />
        </li>
      ) : (
        <li key={id}>
          <NodeLabel
            label={id.toUpperCase()}
            type={firstLetter.match(/m/i) ? 'mdf' : 'bdg'}
          />
        </li>
      );
      acc.push(nodeLabel);
      return acc;
    }, []);
    return (
      <div className="edit-node-drawer__warning-content">
        <span className="edit-node-drawer__warning-content-message">
          This action on {currentNodeLabel} will delete these connected sources:
        </span>
        <ul>{deleteListItems}</ul>
      </div>
    );
  }, [currentNodeId, currentNodeLabel, warningNodes]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (currentNode) {
      setFormData(currentNode.data);
    }
  }, [currentNode]);

  // =============================================
  // Early Return
  // =============================================
  if (!formData) {
    return (
      <Drawer
        id="edit-node-drawer"
        isOpen={isOpen}
        title={isBdg ? 'Edit Building' : 'Edit Distribution Frame'}
        onClose={handleClose}
      ></Drawer>
    );
  }

  // =============================================
  // Return
  // =============================================
  return (
    <Drawer
      id="edit-node-drawer"
      isOpen={isOpen}
      title={isBdg ? 'Edit Building' : 'Edit Distribution Frame'}
      onClose={handleClose}
      disableBackgroundClose={true}
    >
      <div className="edit-node-drawer">
        <h1>
          {nodeTypeLabel}
          {currentNodeLabel}
        </h1>
        <div className="edit-node-drawer__sub-header">
          <h2>Location Information</h2>
          {!isBdg && (
            <div className="edit-node-drawer__demarc-switch">
              DEMARC
              <NavSwitch isOn={isDemarc} onClick={toggleDemarc} />
            </div>
          )}
        </div>
        <div className="edit-node-drawer__inputs">
          <KiteInput
            id="name"
            name="name"
            value={name}
            label="Name"
            onChange={onChange}
          />
          {!isBdg && (
            <>
              <KiteSelect
                value={locationId}
                onChange={onChange}
                id="locationId"
                name="locationId"
                placeholder={locationId ? '' : 'Make Selection'}
                label="Service Location"
              >
                {locationOptions}
              </KiteSelect>
              <div className="edit-node-drawer__address">
                <span>Selected Location Address</span>
                <KiteCard>
                  <span>
                    {currentAddress || <em>No Service Location Selected</em>}
                  </span>
                </KiteCard>
              </div>
              <div className="edit-node-drawer__sub-header">
                <h2>Connection Information</h2>
              </div>
              <div className="edit-node-drawer__inputs">
                {isIdf && (
                  <KiteSelect
                    id="connectorType"
                    name="connectorType"
                    value={connectorType || ''}
                    onChange={onChange}
                    placeholder={connectorType ? '' : 'Make Selection'}
                    label="Connector Type"
                  >
                    {connectorOptions}
                  </KiteSelect>
                )}
                {hasInterconnect && (
                  <KiteSelect
                    id="interconnectType"
                    name="interconnectType"
                    value={interconnectType || ''}
                    onChange={onChange}
                    placeholder={interconnectType ? '' : 'Make Selection'}
                    label="Interconnect Type"
                  >
                    {interconnectOptions}
                  </KiteSelect>
                )}
                <KiteInput
                  id="switchPorts"
                  name="switchPorts"
                  value={switchPorts}
                  type="number"
                  label="Number of Switch Ports"
                  onChange={onChange}
                  onKeyDown={handleNumInputKeydown}
                />
                <KiteInput
                  id="phones"
                  name="phones"
                  value={phones}
                  type="number"
                  label="Number of Phones/ATAs"
                  onChange={onChange}
                  onKeyDown={handleNumInputKeydown}
                />
                {isIdf && isParentNodeMDF && (
                  <>
                    <KiteInput
                      id="distance"
                      name="distance"
                      value={distance}
                      type="number"
                      label="Distance (in feet)"
                      onChange={onChange}
                      onKeyDown={(e) =>
                        ['-', 'e', '+'].includes(e.key) && e.preventDefault()
                      }
                      inputProps={{ min: 0, step: '0.01' }}
                    />
                    <KiteSelect
                      id="interconnectTypeIdf"
                      name="interconnectTypeIdf"
                      value={interconnectTypeIdf || ''}
                      onChange={onChange}
                      placeholder={interconnectTypeIdf ? '' : 'Make Selection'}
                      label="Interconnect Type (MDF to IDF)"
                    >
                      {interconnectOptions}
                    </KiteSelect>
                    <KiteSelect
                      id="interconnectResponsibility"
                      name="interconnectResponsibility"
                      value={interconnectResponsibility || ''}
                      onChange={onChange}
                      placeholder={
                        interconnectResponsibility ? '' : 'Make Selection'
                      }
                      label="Who is responsible for interconnect?"
                    >
                      {interconnectResponsibilityOption}
                    </KiteSelect>
                    {interconnectResponsibility === 'Spectrum Provided' && (
                      <KiteSelect
                        id="conduit"
                        name="conduit"
                        value={conduit || ''}
                        onChange={onChange}
                        placeholder={conduit ? '' : 'Make Selection'}
                        label="Does Spectrum need to provide conduit?"
                      >
                        {conduitOption}
                      </KiteSelect>
                    )}
                    {conduit === 'Yes' && (
                      <p>
                        Please provide conduit footage needed in Additional
                        notes
                      </p>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          <div className="edit-node-drawer__sub-header">
            <h2>Additional Notes</h2>
          </div>
          <TextArea
            id="notes"
            name="notes"
            value={notes || ''}
            onChange={onChange}
            rows="5"
          />
          <div className="edit-node-drawer__btn-container">
            <KiteButton
              className="edit-node-drawer__delete-btn"
              onClick={handleDelete({ isFullDelete: true })}
              maxWidth="none"
              minWidth="min-content"
              type="outline"
            >
              Delete
            </KiteButton>
            <KiteButton
              className="edit-node-drawer__save-btn"
              onClick={handleSave({ shouldClose: true })}
              maxWidth="none"
              minWidth="min-content"
              type="primary"
            >
              Save
            </KiteButton>
          </div>
        </div>
      </div>
      <KiteModal
        className="edit-node-drawer__warning-modal"
        canShow={isDeleteWarn}
        onHide={toggleDeleteWarn}
        ctaCopy="Continue"
        ctaAction={handleDelete({
          onContinue: () => {
            distroToUpdate && onSave([distroToUpdate]);
          },
          warningNodes,
          isFullDelete: true,
        })}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={toggleDeleteWarn}
      >
        {warningContent}
      </KiteModal>
    </Drawer>
  );
};

export default EditNodeDrawer;
