// External Packages
import React, { useMemo, ReactNode, useCallback } from 'react';
import { Document as _Document } from '@react-pdf/renderer';

// Components
import { LocationSummaryPdf } from './LocationSummaryPdf';
import LocationProductsPdf from './LocationProductsPdf';
import { OfferCodesPdf } from './OfferCodesPdf';

// Types
import {
  ILocation,
  TTermLengthMonths,
  IServiceCodes,
  IProductFamily,
  IScenario,
  IEstimate,
  TableValues,
  IGenerateProductTotals,
  IProfile,
  IPromo,
  TEipTermLengthMonths,
} from 'types';

interface IPdfWrapper {
  pdfMulti: boolean;
  quoteName: string;
  customerName: string;
  locations: ILocation[];
  locationId: string;
  currentScenario?: IScenario;
  currentEstimate?: IEstimate;
  allProductFamilies: IProductFamily[];
  allProfiles: IProfile[];
  allPromos: IPromo[];
  termMonth: TTermLengthMonths;
  eipTermMonth: TEipTermLengthMonths;
  serviceCodes: IServiceCodes[];
  generateProductTotals: ({
    selections,
    locationId,
    category,
    subCategory,
    format,
  }: IGenerateProductTotals) => TableValues<number | string>;
}

function componentWithChildren<Props>(Component: React.ComponentType<Props>) {
  return Component as React.ComponentType<Props & { children: ReactNode }>;
}
const Document = componentWithChildren(_Document);

export const PdfWrapper = ({
  pdfMulti,
  quoteName,
  customerName,
  locations,
  locationId,
  currentScenario,
  currentEstimate,
  allProductFamilies,
  allProfiles,
  termMonth,
  eipTermMonth,
  serviceCodes,
  allPromos,
  generateProductTotals,
}: IPdfWrapper) => {
  // =============================================
  // Render Methods
  // =============================================
  const generateLocationPdf = useCallback(
    (location: ILocation) => {
      return (
        <LocationProductsPdf
          key={`${location.name} pdf`}
          quoteName={quoteName}
          customerName={customerName}
          currentLocation={location}
          currentScenario={currentScenario}
          allProductFamilies={allProductFamilies}
          allProfiles={allProfiles}
          termMonth={termMonth}
          eipTermMonth={eipTermMonth}
          generateProductTotals={generateProductTotals}
          allPromos={allPromos}
        />
      );
    },
    [
      quoteName,
      customerName,
      currentScenario,
      allProductFamilies,
      allProfiles,
      termMonth,
      eipTermMonth,
      generateProductTotals,
      allPromos,
    ]
  );

  const generateOffercodesPdf = useCallback(
    (location: ILocation) => {
      const locationProducts =
        currentScenario?.selections.filter(
          (s) => s.locationId === locationId
        ) || [];
      const sbbScenarioProduct = locationProducts?.find(
        (p) => p.familyCategory === 'SBB 1-19 Pub/Priv'
      );
      if (sbbScenarioProduct && locationProducts) {
        return (
          <OfferCodesPdf
            key={`${location.address1} offer codes pdf`}
            customerName={customerName}
            quoteName={quoteName}
            locationName={location.name}
            locationProducts={locationProducts}
            serviceCodes={serviceCodes}
          />
        );
      }
    },
    [currentScenario, customerName, locationId, quoteName, serviceCodes]
  );

  const { locationProductPdfs, offerCodesPdf } = useMemo(() => {
    const locationProductPdfs: JSX.Element[] = [];
    const offerCodesPdf: JSX.Element[] = [];

    if (pdfMulti) {
      Object.values(locations).forEach((location) => {
        locationProductPdfs.push(generateLocationPdf(location));

        const offerCodes = generateOffercodesPdf(location);
        offerCodes && offerCodesPdf.push(offerCodes);
      });
    } else {
      const currentLocation = locations.find(
        (loc) => loc.id === locationId
      ) as ILocation;

      locationProductPdfs.push(generateLocationPdf(currentLocation));

      const offerCodes = generateOffercodesPdf(currentLocation);
      offerCodes && offerCodesPdf.push(offerCodes);
    }

    return { locationProductPdfs, offerCodesPdf };
  }, [
    locations,
    locationId,
    pdfMulti,
    generateLocationPdf,
    generateOffercodesPdf,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <Document>
      {pdfMulti && (
        <LocationSummaryPdf
          quoteName={quoteName}
          customerName={customerName}
          termMonth={termMonth}
          eipTermMonth={eipTermMonth}
          currentEstimate={currentEstimate}
          currentScenario={currentScenario}
          generateProductTotals={generateProductTotals}
          allPromos={allPromos}
        />
      )}
      {locationProductPdfs}
      {offerCodesPdf}
    </Document>
  );
};
