import { StyleSheet } from '@react-pdf/renderer';

const tableCellStep = {
  width: 65,
  padding: 3,

  borderTop: 0.5,
  borderTopColor: 'gray',
  borderRight: 1,
  borderRightColor: 'gray',
  borderBottom: 0.5,
  borderBottomColor: 'gray',

  fontSize: 9,
};

const productCell = {
  padding: 3,

  borderTop: 0.5,
  borderRight: 1,
  borderBottom: 0.5,
  borderLeft: 1,
  borderColor: 'gray',

  fontSize: 9,
};

// Styles
export const styles: { [key: string]: any } = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 75,
    paddingHorizontal: 35,
    position: 'relative',
  },
  // HEADER
  headerMainWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headerMain: {
    paddingBottom: 24,
    fontSize: 24,
    fontFamily: 'Helvetica-Bold',
  },
  headerLeft: {
    fontSize: 16,
    paddingBottom: 16,
  },
  internalUseWrapper: {
    marginTop: 10,
    marginBottom: 30,
    textAlign: 'center',
  },
  internalUse: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  subHeaderWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',

    height: 19,

    textAlign: 'justify',
    fontSize: 13,
  },
  subHeader: {
    position: 'absolute',
    left: 4,

    padding: 3,
  },
  subHeaderBackground: {
    position: 'absolute',

    height: 20,
    width: 520,
    padding: 3,

    backgroundColor: 'grey',
    opacity: 0.3,
  },
  // PRODUCT TABLE
  overviewSummaryBold: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    marginLeft: 28,
  },
  tableCell: {
    ...tableCellStep,
    height: 26,
    textAlign: 'center',
  },
  tableCellStep1: {
    ...tableCellStep,
    height: 30,
    textAlign: 'center',
  },
  tableCellStep2: {
    ...tableCellStep,
    height: 40,
    textAlign: 'center',
  },
  tableCellStep3: {
    ...tableCellStep,
    height: 50,
    textAlign: 'center',
  },
  tableCellStep4: {
    ...tableCellStep,
    height: 60,
    textAlign: 'center',
  },
  tableCellStep5: {
    ...tableCellStep,
    height: 70,
    textAlign: 'center',
  },
  tableCellStep6: {
    ...tableCellStep,
    height: 80,
    textAlign: 'center',
  },
  tableCellStep7: {
    ...tableCellStep,
    height: 90,
    textAlign: 'center',
  },
  tableCellStep8: {
    ...tableCellStep,
    height: 100,
    textAlign: 'center',
  },
  tableCellStep9: {
    ...tableCellStep,
    height: 110,
    textAlign: 'center',
  },
  tableCellStep10: {
    ...tableCellStep,
    height: 120,
    textAlign: 'center',
  },
  tableCellStep11: {
    ...tableCellStep,
    height: 130,
    textAlign: 'center',
  },
  tableCellStep12: {
    ...tableCellStep,
    height: 140,
    textAlign: 'center',
  },
  tableCellStep13: {
    ...tableCellStep,
    height: 150,
    textAlign: 'center',
  },
  productCell: {
    ...productCell,
    height: 26,
    width: 230,
    textAlign: 'justify',
  },
  offerCodeCell: {
    ...productCell,
    height: 26,
    width: 280,
    textAlign: 'justify',
  },
  productCellSubtext: {
    marginLeft: '10',
    textOverflow: 'ellipsis',
  },
  productCellStep1: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 30,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep2: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 40,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep3: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 50,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep4: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 60,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep5: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 70,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep6: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 80,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep7: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 90,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep8: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 100,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep9: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 110,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep10: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 120,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep11: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 130,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep12: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 140,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep13: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 150,
    width: 230,
    textAlign: 'justify',
  },
  productCellHeader: {
    display: 'flex',
    flexDirection: 'row',

    height: 26,
    width: 230,
    padding: 3,

    borderTop: 1,
    borderTopColor: 'gray',
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,
    borderLeft: 1,
    borderLeftColor: 'gray',

    textAlign: 'justify',
  },
  profileCellHeader: {
    display: 'flex',
    flexDirection: 'row',

    height: 26,
    width: 230,
    padding: 3,

    borderTop: 1,
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 1,
    borderBottomColor: 'gray',
    borderLeft: 1,
    borderLeftColor: 'gray',

    textAlign: 'justify',
  },
  offerCodeCellHeader: {
    display: 'flex',
    flexDirection: 'row',

    height: 26,
    width: 280,
    padding: 3,

    borderTop: 1,
    borderTopColor: 'gray',
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,
    borderLeft: 1,
    borderLeftColor: 'gray',

    textAlign: 'justify',
  },
  productTerm: {
    height: 16,
    fontSize: 9,
    textAlign: 'justify',
    marginLeft: 4,
    marginRight: 4,
  },
  eipProductTerm: {
    height: 16,
    fontSize: 9,
    textAlign: 'justify',
    paddingLeft: 8,
    paddingRight: 8,
  },
  productLabel: {
    height: 16,
    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
    textAlign: 'justify',
  },
  tableCellHeader: {
    height: 26,
    width: 65,
    padding: 3,

    borderTop: 1,
    borderTopColor: 'gray',
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,

    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
    textAlign: 'center',
  },
  profilePriceCell: {
    height: 26,
    width: 65,
    padding: 3,

    borderTop: 1,
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 1,
    borderBottomColor: 'gray',

    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
    textAlign: 'center',
  },
  productWrapper: {
    marginLeft: 24,
    marginTop: 10,
  },
  productRow: {
    flexDirection: 'row',
  },
  productStepRow: {
    flexDirection: 'row',
    marginLeft: 24,
  },
  promoText: {
    marginBottom: 1,
    marginTop: 10,
    fontSize: 10,
    marginLeft: 30,
  },
  // TOTALS TABLE
  totalWrapper: {
    flexDirection: 'row',
    marginLeft: 201,
    marginTop: 12,
  },
  totalTextWrapper: {
    marginRight: 12,

    fontFamily: 'Helvetica-Bold',
    fontSize: 13,
  },
  totalCellHeaderFirst: {
    height: 26,
    width: 65,
    padding: 3,

    borderTop: 1,
    borderTopColor: 'gray',
    borderTopWidth: 1,
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,
    borderLeft: 1,
    borderLeftColor: 'gray',

    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    fontSize: 9,
  },
  totalCellHeader: {
    height: 26,
    width: 65,
    padding: 3,
    borderTop: 1,
    borderTopColor: 'gray',
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,

    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
    textAlign: 'center',
  },
  totalTableCellFirst: {
    ...productCell,
    height: 26,
    width: 65,
    textAlign: 'center',
  },
  totalTableCell: {
    ...tableCellStep,
    height: 26,
    textAlign: 'center',
  },
  // DISCLAIMERS
  disclaimerWrapper: {
    marginTop: 30,
    marginBottom: 30,
    fontSize: 10,
    width: 500,
  },
  disclaimer: {
    marginBottom: 8,
    fontSize: 10,
    marginLeft: 30,
  },
  // FOOTER
  footerWrapper: {
    position: 'absolute',
    bottom: 40,
    left: 30,
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  footerText: {
    opacity: 0.4,
    fontSize: 10,
    width: 230,
  },
  footerTextUpper: {
    width: 500,
    marginBottom: 10,
    fontSize: 10,
  },
  // WATERMARK
  waterMark: {
    position: 'absolute',
    left: 80,
    top: 320,

    opacity: 0.04,
    fontFamily: 'Helvetica-Bold',

    transform: 'rotate(-46deg)',
  },
  waterMarkText1: {
    marginTop: 190,

    left: 240,
    fontSize: 60,
  },
  waterMarkText2: {
    marginTop: 35,
    left: 230,
    fontSize: 60,
  },
  image: {
    position: 'absolute',
    left: 15,
    top: 248,

    height: '150',
    width: '500',

    transform: 'rotate(-45deg)',
  },
});
