// Packages
import React, { useCallback } from 'react';

// Redux

// Components
import { FilterButton, SearchBar } from 'components';

// Hooks

// Utils

// Types
import { IFilterButtonProps, ISearchBarProps } from 'types';

// Styles
import './PageInputs.scss';

export interface IPageInputsProps {
  className?: string;
  /** Renders search bar if passed, requires onSearch method and optional placeholder text*/
  search?: ISearchBarProps;
  /** Renders array of FilterButton components if passed */
  filters?: IFilterButtonProps[];
}

/** Page inputs used to search + filter table data */

const PageInputs = ({ className = '', search, filters }: IPageInputsProps) => {
  // =============================================
  // State/Refs
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderFilterButtons = useCallback(() => {
    if (!filters) {
      return null;
    }

    return filters.map((filter) => (
      <FilterButton key={filter.text} {...filter} />
    ));
  }, [filters]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  if (!search && !filters?.length) {
    return null;
  }

  return (
    <div className={`page-inputs ${className}`}>
      <div className="page-inputs__filters">{renderFilterButtons()}</div>
      {search && <SearchBar {...search} />}
    </div>
  );
};

export default PageInputs;
