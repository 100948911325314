// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

// Components
import { KiteCard } from '@kite/react-kite';
import { Outlet, useParams } from 'react-router-dom';
import { HelpBox, PricingTable, OutputTotals, LeftNav } from 'components';

// Hooks
import {
  useFeatureFlags,
  useGenerateProductTotals,
  useGetProfiles,
  useQueryData,
} from 'hooks';

// Utils

// Types

// Styles
import './EstimateBuilderContent.scss';

/** Main content for estimate builder page with nav, configurator, and table */

const EstimateBuilderContent = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [pricingIsExpanded, setPricingIsExpanded] = useState(true);
  const [helpIsExpanded, setHelpIsExpanded] = useState(false);

  const {
    featureFlags: { pqeFlag },
  } = useFeatureFlags();

  const { estimateId } = useParams();

  const isSandbox = estimateId === 'sandbox';

  // Make sure we have profile data in query bank

  const queryData = useQueryData();
  const {
    allProductFamilies,
    currentProductFocus,
    locationId,
    scenarioTabLocationIds,
    scenarioId,
    updateLocationId,
    currentScenario,
    currentEstimate,
    updateScenarioId,
  } = queryData;
  useGetProfiles({
    params: { scenarioId },
  });
  const { generateProductTotals } = useGenerateProductTotals();

  const helpInfo = useMemo(() => {
    const focusedFamily = allProductFamilies.find(
      (f) => f.name === currentProductFocus[0]
    );
    if (focusedFamily?.description) {
      return focusedFamily.description.split(';');
    }
    return [];
  }, [allProductFamilies, currentProductFocus]);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const scenarioTotals = generateProductTotals({});
  const locationTotals = generateProductTotals({ locationId });

  // =============================================
  // Interaction Handlers
  // =============================================
  const togglePricingExpand = useCallback(() => {
    if (pricingIsExpanded && !helpIsExpanded) {
      setHelpIsExpanded(true);
    } else {
      setHelpIsExpanded(false);
    }
    setPricingIsExpanded(!pricingIsExpanded);
  }, [pricingIsExpanded, setPricingIsExpanded, helpIsExpanded]);

  const toggleHelpExpand = useCallback(() => {
    if (helpIsExpanded && !pricingIsExpanded) {
      setPricingIsExpanded(true);
    } else {
      setPricingIsExpanded(false);
    }
    setHelpIsExpanded(!helpIsExpanded);
  }, [helpIsExpanded, setHelpIsExpanded, pricingIsExpanded]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    // Ensure that a scenario is always active on the page
    if (!scenarioId && currentEstimate?.scenarios.length) {
      updateScenarioId(currentEstimate.scenarios[0].id);
    }
  }, [currentEstimate, scenarioId, updateScenarioId]);

  useEffect(() => {
    // If navigating from dashboard, there may not be a valid location id in redux
    // This will properly set the two values if missing, based on recent scenario tab location
    // Fallback is first id
    const recentTabLocation = scenarioTabLocationIds[scenarioId];
    if (
      recentTabLocation &&
      currentScenario &&
      recentTabLocation !== locationId &&
      !isSandbox
    ) {
      updateLocationId(recentTabLocation || currentScenario.locations[0].id);
    }
  }, [
    locationId,
    scenarioId,
    scenarioTabLocationIds,
    updateLocationId,
    currentScenario,
    isSandbox,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={classNames({
        'estimate-builder-content': true,
        'estimate-builder-content--sandbox': estimateId === 'sandbox',
      })}
    >
      <LeftNav />
      <KiteCard className="estimate-builder-content__outlet">
        <Outlet context={queryData} />
      </KiteCard>
      <OutputTotals
        scenarioTotals={scenarioTotals}
        locationTotals={locationTotals}
      />
      <PricingTable
        isExpanded={pricingIsExpanded}
        onToggleExpand={togglePricingExpand}
        disableBanner={!pqeFlag}
      />
      <HelpBox
        darkBanner
        helpInfo={helpInfo}
        isExpanded={helpIsExpanded}
        onToggleExpand={toggleHelpExpand}
      />
    </div>
  );
};

export default EstimateBuilderContent;
