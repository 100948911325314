// Packages
import React, { useCallback, useEffect, useState } from 'react';

// Redux

// Components
import { KiteInput } from '@kite/react-kite';
import { YesNoSwitch } from 'components';

// Hooks
import { useDebouncedCallback } from 'use-debounce/lib';

// Utils
import { isValidJsonString } from 'utils';

// Types
import { ISubmission } from 'types';

// Styles
import './SubmissionYesNo.scss';

export interface ISubmissionYesNoProps {
  submission: ISubmission;
  onAccurateSelect: (isAccurate: boolean) => void;
  onCommentChange: (value: string) => void;
  locationId: string;
  order: number;
}

/** Yes/No selector with comment set up specifically for validating UCQ Submissions */

const SubmissionYesNo = ({
  submission,
  onAccurateSelect,
  onCommentChange,
  locationId,
  order,
}: ISubmissionYesNoProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { fieldOpsAccurate = null, fieldOpsComments = '' } = submission;
  const [localAccurate, setLocalAccurate] = useState(fieldOpsAccurate);
  const [localComment, setLocalComment] = useState(fieldOpsComments);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const debounceAccuracy = useDebouncedCallback(onAccurateSelect, 300);
  const debounceComment = useDebouncedCallback(onCommentChange, 300);

  const handleAccurateSelect = useCallback(
    (isAccurate: boolean) => {
      debounceComment.cancel();
      setLocalAccurate(isAccurate);
      debounceAccuracy(isAccurate);
    },
    [debounceAccuracy, debounceComment]
  );

  const handleCommentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      debounceAccuracy.cancel();
      setLocalComment(value);
      debounceComment(value);
    },
    [debounceAccuracy, debounceComment]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (
      (localComment !== fieldOpsComments ||
        localAccurate !== fieldOpsAccurate) &&
      !debounceComment.isPending() &&
      !debounceAccuracy.isPending()
    ) {
      setLocalAccurate(fieldOpsAccurate);
      setLocalComment(fieldOpsComments);
    }
  }, [
    debounceAccuracy,
    debounceComment,
    fieldOpsAccurate,
    fieldOpsComments,
    localAccurate,
    localComment,
  ]);

  useEffect(() => {
    debounceAccuracy.cancel();
    debounceComment.cancel();
    setLocalAccurate(fieldOpsAccurate);
    setLocalComment(fieldOpsComments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  // =============================================
  // Early Return
  // =============================================
  if (
    submission.locationId !== locationId ||
    !submission.answer ||
    submission.answer.length === 0
  ) {
    return null; // Skip if submission belongs to a different location or doesnt have an answer
  }

  const displayAnswer =
    isValidJsonString(submission.answer) && JSON.parse(submission.answer).length
      ? JSON.parse(submission.answer)[0].join(', ')
      : submission.answer;
  const contentHtmlString = `
            ${order}. ${submission.label || ''}
            <ul>
              <li>${displayAnswer}</li>
            </ul>
            `;

  // =============================================
  // Return
  // =============================================
  return (
    <div className="submission-yes-no" key={submission.id}>
      <li
        className="submission-yes-no__question"
        dangerouslySetInnerHTML={{
          __html: contentHtmlString,
        }}
      ></li>
      <YesNoSwitch handleSelect={handleAccurateSelect} value={localAccurate} />
      {localAccurate === false && (
        <KiteInput
          maxWidth="none"
          margin="0"
          placeholder="Enter changes or discrepancies"
          onChange={handleCommentChange}
          value={localComment}
        />
      )}
    </div>
  );
};

export default SubmissionYesNo;
