// Packages
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Redux

// Components
import { KiteInput } from '@kite/react-kite';

// Hooks
import {
  useDebounceInputSave,
  useErrorCheck,
  useQueryDataContext,
  useGetSubmissions,
} from 'hooks';

// Utils
import {
  defaultProductQuantity,
  handleNumInputKeydown,
  NONE,
  ucSeatsProductFamilies,
  rcSeatsProductFamilies,
  disabledProductConfig,
  rcPhonesAndDevicesId,
  rcIswId,
  inFootprintIswId,
  outOfFootprintIswId,
} from 'utils';

// Types
import {
  IProduct,
  IProductFamily,
  ISelectionController,
  TProductList,
  TUcProductRateCards,
  TRcProductRateCards,
  TEipTermLengthMonths,
} from 'types';

// Styles
import './CustomInput.scss';

export interface ICustomInputProps extends ISelectionController {
  /** Optional label for input, defaults to Quantity */
  label?: string;
  /** Optional max width value for input */
  maxWidth?: string;
  /** Name of product for location product updates */
  product?: IProduct;
  /** Product family input is being generated from */
  productFamily: IProductFamily;
  /** Override value for tooltip text */
  tooltip?: string[];
  /** Minimum value the input can have */
  minValue?: string;
  /** Maximum value the input can have */
  maxValue?: string;
}

/** Custom KiteInput component with custom handler function */

const CustomInput = ({
  label = 'Quantity',
  maxWidth = 'none',
  product,
  productFamily,
  tooltip,
  minValue = '0',
  maxValue,
  onChange,
}: ICustomInputProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const {
    currentSelections,
    locationId,
    allSelections,
    scenarioId: currentScenarioId,
    bundleId,
    duplicateScenario,
    currentEstimate,
    currentEipTerm,
  } = useQueryDataContext();
  const { debounceSave, saveSelection } = useDebounceInputSave({
    onChange,
  });
  const { checkRangeError } = useErrorCheck(currentSelections);
  const id = duplicateScenario?.id;
  const isDuplicate = duplicateScenario?.isDuplicate;

  const selection = useMemo(() => {
    return currentSelections.find((s) => {
      return (
        s.productId === product?.id &&
        !s.profileId &&
        ((s.locationId === locationId && s.scenarioId === currentScenarioId) ||
          s.bundleId === bundleId)
      );
    });
  }, [currentSelections, locationId, product, bundleId, currentScenarioId]);

  const defaultSaved = useRef(true);
  const defaultValue = useMemo(() => {
    return defaultProductQuantity.find(
      (prod) =>
        prod.id === product?.id &&
        !(id === currentScenarioId && isDuplicate) &&
        !currentSelections.find(
          (s) =>
            s.familyCategory === prod.category &&
            ((s.locationId === locationId &&
              s.scenarioId === currentScenarioId) ||
              s.bundleId === bundleId)
        )
    )?.defaultValue;
  }, [
    id,
    isDuplicate,
    currentSelections,
    product,
    locationId,
    bundleId,
    currentScenarioId,
  ]);

  const selectionQuantity = selection ? selection.quantity.toString() : '';

  const [inputValue, setInputValue] = useState({
    scenarioId: currentScenarioId,
    quantity: selectionQuantity,
  });
  const [rateCard, setRateCard] = useState<
    TUcProductRateCards | TRcProductRateCards | null
  >(selection?.rateCard || null);
  const eipTermRef = useRef<TEipTermLengthMonths | null>(
    currentEipTerm ?? selection?.eipTerm
  );
  if (productFamily.id === rcPhonesAndDevicesId || product?.id === rcIswId) {
    eipTermRef.current = currentEipTerm ?? selection?.eipTerm;
  } else if (
    product?.id === inFootprintIswId ||
    product?.id === outOfFootprintIswId
  ) {
    const finalEipTerm =
      currentEipTerm === 'Purchase' ? 'One-time Payment' : currentEipTerm;
    eipTermRef.current = finalEipTerm ?? selection?.eipTerm;
  } else {
    eipTermRef.current = null;
  }

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const otherSeats = useMemo(() => {
    return currentSelections.reduce((seats, prod) => {
      const productFamily = prod.familyName as TProductList;

      if (
        ucSeatsProductFamilies.includes(productFamily) &&
        prod.id !== product?.id
      ) {
        seats += prod.quantity;
      } else if (
        rcSeatsProductFamilies.includes(productFamily) &&
        prod.id !== product?.id
      ) {
        seats += prod.quantity;
      }
      return seats;
    }, 0);
  }, [currentSelections, product]);

  const tooltipText = useMemo(() => {
    if (tooltip?.length) {
      return tooltip.flat();
    }
  }, [tooltip]);

  const errorMessage = useMemo(() => {
    if (productFamily.name === 'Equipment') {
      const location = currentSelections.find(
        (s) => s.familyName === 'Location'
      );
      if (location && !selection) {
        return 'Value must be at least 1';
      }
    } else if (selection) {
      return checkRangeError(selection);
    }
    return '';
  }, [selection, currentSelections, productFamily, checkRangeError]);

  const isInputDisabled = useMemo(() => {
    return (
      disabledProductConfig[productFamily.name]?.dependentOption.reduce(
        (isDisabled: boolean | undefined, dependentFamily) => {
          if (!dependentFamily[product?.name!]) return false;

          const rule = disabledProductConfig[productFamily.name]?.rule;

          const families =
            rule === 'scenario'
              ? allSelections?.filter((p) =>
                  dependentFamily[product?.name!].find((dp1) => {
                    return dp1.find((dp) => {
                      return (
                        dp.productFamilyName === p.familyName &&
                        (dp.selectedProductName.includes(p.name) ||
                          dp.selectedProductName.length === 0)
                      );
                    });
                  })
                ) // checks for all location
              : currentSelections?.filter((p) =>
                  dependentFamily[product?.name!].find((dp1) => {
                    return dp1.find((dp) => {
                      return (
                        dp.productFamilyName === p.familyName &&
                        (dp.selectedProductName.includes(p.name) ||
                          dp.selectedProductName.length === 0)
                      );
                    });
                  })
                ); // checks for single location

          if (families.length === 0) {
            return dependentFamily[product?.name!].reduce(
              (isd: boolean, dp1) => {
                return (
                  isd &&
                  dp1?.some((dp) => {
                    return dp.inverse;
                  })
                );
              },
              true
            );
          }

          if (families.length !== 0) {
            return dependentFamily[product?.name!].reduce(
              (isd: boolean, dp1) => {
                return (
                  isd &&
                  dp1?.some((dp) => {
                    return dp.inverse
                      ? !families.some((p) => {
                          return (
                            dp.productFamilyName === p.familyName &&
                            (dp.selectedProductName.includes(p.name) ||
                              dp.selectedProductName.length === 0)
                          );
                        })
                      : families.every((p) => {
                          return (
                            dp.productFamilyName === p.familyName &&
                            (dp.selectedProductName.includes(p.name) ||
                              dp.selectedProductName.length === 0)
                          );
                        });
                  })
                );
              },
              true
            );
          }
          return false;
        },
        false
      ) || false
    );
  }, [productFamily, currentSelections, allSelections, product]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSave = useCallback(
    (params: { scenarioId: string; value: string; isDebounce?: boolean }) => {
      const { scenarioId, value, isDebounce } = params;
      if (value !== selectionQuantity && product) {
        isDebounce
          ? debounceSave({
              selectionId: selection?.id,
              productId: !value || value === '0' ? NONE : product.id,
              selectionValues: {
                quantity: parseInt(value),
                rateCard,
                eipTerm: eipTermRef.current,
              },
              opType: 'inputFieldUpdate',
              scenarioId,
            })
          : saveSelection({
              selectionId: selection?.id,
              productId: !value || value === '0' ? NONE : product.id,
              selectionValues: {
                quantity: parseInt(value),
                rateCard,
                eipTerm: eipTermRef.current,
              },
              opType: 'inputFieldUpdate',
            });
      }
    },
    [
      debounceSave,
      product,
      rateCard,
      saveSelection,
      selection,
      selectionQuantity,
    ]
  );

  const handleMinMax = useCallback(
    (inputValue: string) => {
      const quantity = inputValue ? parseInt(inputValue) : 0;
      const max = maxValue ? parseInt(maxValue) : Infinity;
      const min = parseInt(minValue);
      if (!quantity || quantity < min) {
        return min === 0 ? '' : minValue;
      } else if (quantity > max) {
        return maxValue || inputValue;
      } else {
        return inputValue;
      }
    },
    [maxValue, minValue]
  );

  const handleSave = useCallback(
    (value: string) => {
      const isDebounce = !!value && value !== '0';
      if (!isDebounce) {
        debounceSave.cancel();
      }
      onSave({
        scenarioId: currentScenarioId,
        value,
        isDebounce,
      });
    },
    [currentScenarioId, debounceSave, onSave]
  );

  const handleRateCard = useCallback(
    (value: string) => {
      const quantity = parseInt(value);
      if (productFamily.name === 'UC Connect w/ Webex') {
        if (quantity + otherSeats > 49) {
          setRateCard('50-99 Seats');
        } else if (quantity + otherSeats > 10) {
          setRateCard('11-49 Seats');
        } else {
          setRateCard('1-10 Seats');
        }
      } else if (productFamily.name === 'RC Seats') {
        if (quantity > 999) {
          setRateCard('1000+ Seats');
        } else if (quantity > 499) {
          setRateCard('500-999 Seats');
        } else {
          setRateCard('1-499 Seats');
        }
      }
    },
    [otherSeats, productFamily.name]
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const quantity = handleMinMax(value);
      handleRateCard(quantity);
      setInputValue({ scenarioId: currentScenarioId, quantity });
      handleSave(quantity);
      defaultSaved.current = false;
    },
    [handleMinMax, handleRateCard, currentScenarioId, handleSave]
  );

  const isDisabled = useMemo(() => {
    // Ids of the products to be disabled
    const productsToBeDisabled = [
      'd05f16a6-4744-4bfe-8ddb-cabbaa8c5cbb',
      'b303611d-4679-4f0d-8e97-65594c12b599',
      'efdb5054-8a1c-4316-b268-ac5513348c96',
      '4d5a234a-ae4d-4621-8ba8-015ff164189f',
    ];
    if (product?.id && productsToBeDisabled.includes(product.id)) {
      return true;
    }
    return isInputDisabled; // Default to not disabled if none of the above conditions are met
  }, [product, isInputDisabled]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    // Keeps input value in sync with selection value after input change
    if (
      inputValue.scenarioId === currentScenarioId &&
      inputValue.quantity !== selectionQuantity &&
      !debounceSave.isPending()
    ) {
      setInputValue({
        scenarioId: currentScenarioId,
        quantity: selectionQuantity,
      });
    }
  }, [
    currentScenarioId,
    debounceSave,
    inputValue.quantity,
    inputValue.scenarioId,
    selectionQuantity,
  ]);

  useEffect(() => {
    // Keeps input value in sync with selection value on scenario change
    if (currentScenarioId !== inputValue.scenarioId) {
      setInputValue({
        scenarioId: currentScenarioId,
        quantity: selectionQuantity,
      });
    }
  }, [
    currentScenarioId,
    inputValue.scenarioId,
    selectionQuantity,
    currentEipTerm,
  ]);

  useEffect(() => {
    // Save on unmount
    return () => debounceSave.flush();
  }, [debounceSave]);

  useEffect(() => {
    const defaultTimer = setTimeout(() => {
      if (defaultValue && defaultSaved.current) {
        const quantity = handleMinMax(defaultValue);
        handleRateCard(quantity);
        handleSave(quantity);
      } else defaultSaved.current = true;
    }, 3500);
    return () => clearTimeout(defaultTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScenarioId, inputValue.scenarioId, locationId, selectionQuantity]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteInput
      className="custom-input"
      label={label}
      type="number"
      onChange={handleInputChange}
      onKeyDown={handleNumInputKeydown}
      value={inputValue.quantity}
      maxWidth={maxWidth}
      tooltip={tooltipText}
      errorMessage={errorMessage}
      disabled={!product || isDisabled}
      inputProps={{
        min: minValue,
        max: maxValue,
      }}
    />
  );
};

export default CustomInput;
