import {
  IPrice,
  IProduct,
  IProductFamily,
  TNrcTableData,
  TPriceType,
} from 'types';

export type TPricingTableData = {
  [key: string]: IPrice[];
};

export const generateTableData = (
  products: IProduct[],
  priceType: TPriceType
) => {
  return products.reduce((acc: TPricingTableData, product) => {
    acc[product.name] = product.prices.filter((p) => p.type === priceType);
    return acc;
  }, {});
};

export const generateNrcTableData: (
  productFamily: IProductFamily
) => TNrcTableData = (productFamily: IProductFamily) => {
  return {
    list: productFamily.rateNrc,
    discount: productFamily.discountNrc,
  };
};

export const generateUniqueNrcTableData = (productFamily: IProductFamily) => {
  const uniqueProducts = productFamily.products.reduce(
    (products: IProduct[], product) => {
      if (product.rateNrc || product.rateMrc) {
        products.push(product);
      }
      return products;
    },
    []
  );

  return uniqueProducts;
};
