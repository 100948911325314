import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { TProductList } from 'types';
import { RootState } from './store';

export type TProductFocus = TProductList[] | '';

export const productFocusSlice = createSlice<
  TProductFocus,
  SliceCaseReducers<TProductFocus>
>({
  name: 'productFocus',
  initialState: '',
  reducers: {
    updateProductFocus: (_, action) => {
      return action.payload;
    },
  },
});

// Actions

export const { updateProductFocus } = productFocusSlice.actions;

// State
export const productFocus = (state: RootState): TProductFocus =>
  state.productFocus;

// Reducer
export default productFocusSlice.reducer;
