import dagre from 'dagre';
import { Node, Edge, Position } from 'react-flow-renderer';
import { IUCQDesignFlowNode } from 'types';

export const getNodeLayout = (
  nodes: Node<IUCQDesignFlowNode>[],
  edges: Edge[],
  bdgIndex: number,
  bdgHeight: number,
  nodeSize: { width: number; height: number }
) => {
  const { width: nodeWidth, height: nodeHeight } = nodeSize;
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'TB' });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const graph = dagreGraph.graph();

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = Position.Top;
    node.sourcePosition = Position.Bottom;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: node.type === 'bdg' ? 0 : nodeWithPosition.x - nodeWidth / 2,
      y:
        node.type === 'bdg'
          ? !bdgIndex
            ? nodeWithPosition.y - nodeHeight / 2
            : nodeWithPosition.y - nodeHeight / 2 + bdgHeight
          : nodeWithPosition.y - nodeHeight / 2 + 100,
    };

    return node;
  });

  return { layoutNodes: nodes, layoutEdges: edges, graph };
};

export const getBdgLayout = (nodes: Node<IUCQDesignFlowNode>[]) => {
  return nodes;
};
