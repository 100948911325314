import packageJson from '../../../package.json';

const getEnvName = () => {
  if (typeof window === 'undefined') {
    return 'local';
  }
  if (window.location.host.split('.')[0] === 'solution-pricing-tool') {
    return 'prod';
  }
  return 'staging';
};

export default {
  collector: {
    domain: 'internal_apps',
    customer: 'Charter',
    appName: 'BundlePricingTool',
    appType: 'Web',
    appVersion: packageJson.version,
    deviceType: 'webBrowser',
    requirementsVersion: '1.54',
    msgTriggeredBy: 'user',
    startSession: 'BundlePricingTool_startSession_generic',
  },
  helix: {
    settings: {
      logLevel: 'error',
    },
  },
  appEnv: getEnvName(),
};
