import axios from 'axios';
import { IRequestConfig } from 'types';
import { IFieldOpsCost } from 'types';
import { paramsSerializer } from 'utils/format/paramSerializer';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

export const fieldOpsCostRequest = async (
  config?: IRequestConfig<Partial<IFieldOpsCost>[] | string[]>
) => {
  const {
    endpoint = 'field-ops-costs',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with field ops cost ${method}`,
      })
    );
};
