import { TProductList } from 'types';

export type PMinMaxConfig = {
  [Property in TProductList]: {
    [productName: string]: { minValue?: string; maxValue?: string };
  };
};
export const minMaxValueMap: any = {
  'RC Additional NRC': {
    'Remote Professional Install': { minValue: '0', maxValue: '1' },
    'On Site Professional Install': {
      minValue: '0',
      maxValue: '1',
    },
  },
  'MNE WiFi': {
    'Managed Network Wifi - MR28': {
      minValue: '0',
      maxValue: '3',
    },
  },
};
export const getMinMaxValue = (fname: string, pname: string) => {
  if (minMaxValueMap[fname] && minMaxValueMap[fname][pname]) {
    return minMaxValueMap[fname][pname];
  }
  return {};
};
