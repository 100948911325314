import { useCallback, useMemo } from 'react';
import { useFormRules, useGetForms, useQueryData } from 'hooks';
import { IField, IForm, ISubmission } from 'types';
import { CPWFORMS, UCQFORMS } from 'utils';
import { useParams } from 'react-router-dom';

interface IUseUCQuestionsTotal {
  submissions: ISubmission[];
  questionType?: 'ucq' | 'cpw';
  formId?: IForm['id'];
}

export const useUCQuestionsTotal = ({
  submissions,
  questionType = 'ucq',
  formId,
}: IUseUCQuestionsTotal) => {
  const { currentEstimate } = useQueryData();
  const { locationId = '' } = useParams();

  // Used to separate required fields/rules by each location
  const estimateLocationIds = useMemo(
    () => currentEstimate?.locations.map((l) => l.id) || [],
    [currentEstimate]
  );

  // Location-specific forms
  const { data: locationForms } = useGetForms({
    params: { location: 1, type: questionType === 'ucq' ? UCQFORMS : CPWFORMS },
  });

  // Estimate-specific forms
  const { data: estimateForms } = useGetForms({
    params: { location: 0, type: questionType === 'ucq' ? UCQFORMS : CPWFORMS },
  });

  // Get fields from forms, will be filtered by formId param if passed into hook
  const reduceFormFields = useCallback(
    (forms?: IForm[]) => {
      return (
        forms?.reduce((acc: IField[], form) => {
          if (formId && form.id !== formId) {
            return acc;
          }
          acc.push(...form.fields);
          return acc;
        }, []) || []
      );
    },
    [formId]
  );

  const locationFields = useMemo(
    () => reduceFormFields(locationForms),
    [locationForms, reduceFormFields]
  );

  const estimateFields = useMemo(
    () => reduceFormFields(estimateForms),
    [estimateForms, reduceFormFields]
  );

  const allFields = useMemo(
    () => [...estimateFields, ...locationFields],
    [estimateFields, locationFields]
  );

  const formRules = useMemo(
    () => allFields.map((f) => f.rules).flat(),
    [allFields]
  );

  // Merge fields w/ submission answers, specific to location
  const locationFieldValues = useMemo(() => {
    return estimateLocationIds.reduce(
      (
        acc: { [locationId: string]: { [fieldId: string]: string | null } },
        locationId
      ) => {
        const fieldValues = locationFields.reduce(
          (acc: { [fieldId: string]: string | null }, field) => {
            const subMatch =
              submissions.find(
                (sub) =>
                  sub.locationId &&
                  sub.locationId === locationId &&
                  sub.fieldId === field.id
              )?.answer || null;
            acc[field.id] = subMatch;
            return acc;
          },
          {}
        );
        acc[locationId] = fieldValues;

        return acc;
      },
      {}
    );
  }, [estimateLocationIds, locationFields, submissions]);

  // Merge fields w/ submission answers, specific to estimate
  const estimateFieldValues = useMemo(() => {
    return estimateFields.reduce(
      (acc: { [fieldId: string]: string | null }, field) => {
        const subMatch =
          submissions.find((sub) => sub.fieldId === field.id)?.answer || null;
        acc[field.id] = subMatch;
        return acc;
      },
      {}
    );
  }, [estimateFields, submissions]);

  // Estimate-specific rules
  const { ruleMap: estimateRuleMap, ruleKeys: estimateRuleKeys } = useFormRules(
    {
      fieldValues: estimateFieldValues,
      formRules,
    }
  );

  // Location-specific rules
  const { locationRuleMap, locationRuleKeys } = useFormRules({
    fieldValues: {},
    formRules,
    locationFieldValues,
  });

  const requiredEstimateFieldIds = useMemo(() => {
    return estimateFields.reduce((acc: IField['id'][], field) => {
      const { id, required } = field;
      const isRule = estimateRuleKeys.includes(id);
      const canShow = isRule ? estimateRuleMap[id] : true;
      if (required && canShow) {
        acc.push(id);
      }
      return acc;
    }, []);
  }, [estimateFields, estimateRuleKeys, estimateRuleMap]);

  const requiredLocationFieldIds = useMemo(() => {
    return Object.entries(locationFieldValues).reduce(
      (acc: { [locationId: string]: string[] }, [locationId, fieldValue]) => {
        const fields = Object.keys(fieldValue).reduce(
          (acc: IField[], fieldId) => {
            const field = locationFields.find((lf) => lf.id === fieldId);
            if (field) {
              acc.push(field);
            }
            return acc;
          },
          []
        );

        const requiredFieldIds = fields.reduce((acc: IField['id'][], field) => {
          const { id, required } = field;
          const ruleKeys = locationRuleKeys[locationId] || [];
          const isRule = ruleKeys.includes(id);
          const canShow = isRule ? locationRuleMap[locationId][id] : true;
          if (required && canShow) {
            acc.push(id);
          }
          return acc;
        }, []);

        acc[locationId] = requiredFieldIds;

        return acc;
      },
      {}
    );
  }, [locationFieldValues, locationFields, locationRuleKeys, locationRuleMap]);

  const requiredFieldIds = useMemo(() => {
    // If locationId url param, only include that location's required ids,
    // else include all required locationIds (global totals)
    const locationRequired =
      requiredLocationFieldIds[locationId] ||
      Object.values(requiredLocationFieldIds).flat();

    const required =
      // if locationId is 'general', only use estimate-specific ids
      locationId === 'general'
        ? requiredEstimateFieldIds
        : [...requiredEstimateFieldIds, ...locationRequired];
    return required;
  }, [locationId, requiredEstimateFieldIds, requiredLocationFieldIds]);

  const requiredSubmissions = useMemo(
    () =>
      submissions.filter(
        (sub) => requiredFieldIds.includes(sub.fieldId) && sub.answer
      ),
    [requiredFieldIds, submissions]
  );

  const completePercent = Math.round(
    (requiredSubmissions.length / requiredFieldIds.length) * 100
  );

  return {
    completePercent,
    requiredEstimateFieldIds,
    requiredLocationFieldIds,
    requiredFieldIds,
    requiredSubmissions,
  };
};
