import { IRequestConfig, IFeatureFlag } from 'types';
import { getFeatureFlagData, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetFeatureFlagData = (
  requestConfig: IRequestConfig<IFeatureFlag> = {}
) => {
  return useGenerateQuery<IFeatureFlag[]>({
    queryKey: queryKeys().all.featureFlags,
    apiCall: getFeatureFlagData,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
