import { useQueryData } from 'hooks/useQueryData';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { IScenario } from 'types';
import { scenarioPromosRequest, queryKeys, sandboxData } from 'utils';

export const useDeleteScenarioPromos = () => {
  const queryClient = useQueryClient();
  const { estimateId } = useParams();
  const { currentScenario } = useQueryData();
  const scenarioKey = queryKeys({ estimateId }).filter.estimateScenarios;

  const isSandbox = estimateId === 'sandbox';

  const optimisticUpdate = useCallback(
    (promoIds: string[], oldScenarios: IScenario[]) => {
      return oldScenarios.map((s) => {
        if (s.id === currentScenario?.id) {
          const newPromos = s.promos?.filter((p) => !promoIds.includes(p.id));
          return { ...s, promos: newPromos };
        }
        return s;
      });
    },
    [currentScenario]
  );

  const sandboxHandler = useCallback(
    async (promoIds: string[]) => {
      const { scenarios, scenarioPromos } = sandboxData.estimate;
      const updatedScenarios = optimisticUpdate(promoIds, scenarios);
      sandboxData.estimate.scenarios = updatedScenarios;
      sandboxData.estimate.scenarioPromos = scenarioPromos.filter(
        (sp) => !promoIds.includes(sp.id)
      );
      return sandboxData.estimate.scenarios;
    },
    [optimisticUpdate]
  );

  const {
    mutate: deleteScenarioPromos,
    isLoading: deleteScenarioPromosLoading,
    error: deleteScenarioPromosError,
    data: deleteScenarioPromosRes,
  } = useMutation(
    isSandbox
      ? sandboxHandler
      : (scenarioPromoIds: string[]) =>
          scenarioPromosRequest({
            method: 'DELETE',
            data: scenarioPromoIds,
          }),
    {
      onMutate: async (scenarioPromoIds: string[]) => {
        if (!currentScenario) {
          return;
        }
        await queryClient.cancelQueries(scenarioKey);

        const oldScenarios: IScenario[] =
          queryClient.getQueryData(scenarioKey) || [];

        const updatedScenarios = optimisticUpdate(
          scenarioPromoIds,
          oldScenarios
        );

        queryClient.setQueryData(scenarioKey, updatedScenarios);

        return { oldScenarios };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(scenarioKey);
        queryClient.setQueryData(scenarioKey, context?.oldScenarios);
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  return {
    deleteScenarioPromos,
    deleteScenarioPromosLoading,
    deleteScenarioPromosError,
    deleteScenarioPromosRes,
  };
};
