// Packages
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

// Components
import { KiteRadio } from '@kite/react-kite';

// Hooks
import {
  useDebounceInputSave,
  useQueryDataContext,
  useUpdateFocus,
} from 'hooks';

// Utils
import { NONE } from 'utils';

// Types
import { IProductFamily, ISelectionController } from 'types';

// Styles
import './CustomRadio.scss';

export interface ICustomRadioProps extends ISelectionController {
  /** Product family you want the radio options to build from */
  productFamily: IProductFamily;
}

/** Radio options generated based on a Product Family */

const CustomRadio = ({ productFamily, onChange }: ICustomRadioProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const { updateFocus } = useUpdateFocus();
  const { currentSelections, scenarioId: currentScenarioId } =
    useQueryDataContext();
  const { saveSelection, debounceSave } = useDebounceInputSave({ onChange });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const currentSelection = useMemo(() => {
    return currentSelections.find((p) => p.familyId === productFamily.id);
  }, [currentSelections, productFamily.id]);

  const [inputValue, setInputValue] = useState({
    scenarioId: currentScenarioId,
    value: currentSelection?.name || NONE,
  });

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSave = useCallback(
    (params: {
      scenarioId: string;
      productId: string;
      isDebounce?: boolean;
    }) => {
      const { scenarioId, productId, isDebounce } = params;
      isDebounce
        ? debounceSave({
            selectionId: currentSelection?.id,
            productId,
            selectionValues: { quantity: 1 },
            opType: 'radioSelect',
            scenarioId,
          })
        : saveSelection({
            selectionId: currentSelection?.id,
            productId,
            selectionValues: { quantity: 1 },
            opType: 'radioSelect',
          });
    },
    [currentSelection, debounceSave, saveSelection]
  );

  const handleRadioSelection = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { id: productId } = e.target;
      onSave({ scenarioId: currentScenarioId, productId, isDebounce: true });
      const productName =
        productFamily.products.find((p) => p.id === productId)?.name || NONE;
      setInputValue({ scenarioId: currentScenarioId, value: productName });
    },
    [currentScenarioId, onSave, productFamily.products]
  );

  // =============================================
  // Render Methods
  // =============================================
  const radioButtons = useMemo(() => {
    const options = productFamily.products.map((p) => {
      return {
        id: p.id,
        label: p.name,
      };
    });
    productFamily.name === 'Location' &&
      options.unshift({
        id: NONE,
        label: NONE,
      });

    return options.map((option) => {
      return {
        ...option,
        checked: option.label === inputValue.value,
      };
    });
  }, [inputValue.value, productFamily.name, productFamily.products]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (inputValue.scenarioId !== currentScenarioId) {
      setInputValue({
        scenarioId: currentScenarioId,
        value: currentSelection?.name || NONE,
      });
    }
  }, [currentScenarioId, currentSelection, inputValue.scenarioId]);

  useEffect(() => {
    if (
      inputValue.value !== (currentSelection?.name || NONE) &&
      !debounceSave.isPending()
    ) {
      setInputValue({
        scenarioId: currentScenarioId,
        value: currentSelection?.name || NONE,
      });
    }
  }, [
    currentScenarioId,
    currentSelection,
    debounceSave,
    inputValue.scenarioId,
    inputValue.value,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className="custom-radio"
      onFocus={() => updateFocus([productFamily.name])}
    >
      <KiteRadio
        name={productFamily.name}
        onChange={handleRadioSelection}
        radioButtons={radioButtons}
      />
    </div>
  );
};

export default CustomRadio;
