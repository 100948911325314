import React, {
  useState,
  useEffect,
  createContext,
  FunctionComponent,
  ReactNode,
} from 'react';

// Analytics
import AnalyticsService from './Analytics';
import analyticsConfig from './analyticsConfig';
import packageJson from '../../../package.json';

export interface IAnalyticsContextValue {
  analytics?: AnalyticsService;
}

export const AnalyticsContext =
  createContext<IAnalyticsContextValue | undefined>(undefined);

interface IProps {
  overrides?: Partial<IAnalyticsContextValue>;
  children: ReactNode;
  isTest?: boolean;
}

const AnalyticsProvider: FunctionComponent<IProps> = ({
  children,
  overrides,
  isTest,
}: IProps) => {
  const [analytics, setAnalytics] =
    useState<AnalyticsService | undefined>(undefined);

  useEffect(() => {
    if (!isTest && !process.env.JEST_WORKER_ID) {
      const newAnalytics = new AnalyticsService(
        analyticsConfig,
        packageJson.version
      );

      setAnalytics(newAnalytics);
    }
  }, [isTest]);

  return (
    <AnalyticsContext.Provider value={{ analytics, ...overrides }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
