// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteInput } from '@kite/react-kite';
import { UCQPrecheck, UCQRepeatButton } from '..';

// Hooks

// Utils
import { handleNumInputKeydown, isValidJsonString } from 'utils';

// Types
import { IUCQInputProps } from 'types';

// Styles
import '../UCQFieldStyles.scss';

type TTextQuantity = [text: string, quantity: string];

interface IUCQTextQuantityInputProps {
  tq: TTextQuantity;
  index: number;
  repeatable: boolean;
  isLast: boolean;
  isOnly: boolean;
  onRepeat: () => void;
  onDelete: (index: number) => () => void;
  onChange: (tq: TTextQuantity, index: number) => void;
}

// CHILD FOR `UCQTextQuantity` BELOW (renders multiple inputs)
const UCQTextQuantityInput = ({
  tq,
  onDelete,
  onRepeat,
  isLast,
  index,
  repeatable,
  onChange,
  isOnly,
}: IUCQTextQuantityInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [textQuantity, setTextQuantity] = useState(tq);
  const [text, quantity] = textQuantity;

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleChange = useCallback(
    (input: 'text' | 'quantity') => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const newTQ: TTextQuantity =
        input === 'quantity' ? [text, value] : [value, quantity];
      setTextQuantity(newTQ);
      onChange(newTQ, index);
    },
    [index, onChange, quantity, text]
  );

  // =============================================
  // Effects
  // =============================================
  // Make sure correct values are in inputs on TQ delete
  useEffect(() => {
    setTextQuantity(tq);
  }, [tq]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ucq-field__text-quantity-container">
      <KiteInput
        value={text}
        onChange={handleChange('text')}
        maxWidth="none"
        placeholder="Make and Model"
      />
      <KiteInput
        value={quantity}
        onChange={handleChange('quantity')}
        onKeyDown={handleNumInputKeydown}
        maxWidth="none"
        placeholder="Quantity"
        inputProps={{ type: 'number', min: 0 }}
      />
      {repeatable && (
        <UCQRepeatButton
          text="Add Device"
          onRepeat={onRepeat}
          onDelete={onDelete(index)}
          isLast={isLast}
          isOnly={isOnly}
        />
      )}
    </div>
  );
};

/** Custom input for 'text-quantity' inputType with repeatable inputs on UCQ Field */
const UCQTextQuantity = ({ fieldInput, onFieldChange }: IUCQInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { value, id, isPrecheck, repeatable = true } = fieldInput;
  const blankTQ: TTextQuantity = useMemo(() => ['', ''], []);
  const defaultValue: TTextQuantity[] = useMemo(() => {
    if (value && value !== 'No') {
      const tqValues: TTextQuantity[] = isValidJsonString(value)
        ? JSON.parse(value)
        : value;
      return tqValues;
    }
    return [blankTQ];
  }, [value, blankTQ]);

  const [textQuantities, setTextQuantities] = useState(defaultValue);

  const [precheckValue, setPrecheckValue] = useState(
    value === 'No' ? 'No' : 'Yes'
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const isVisible = isPrecheck ? precheckValue === 'Yes' : true;
  const getCompletedTqs = useCallback(
    (tqs: TTextQuantity[]) => tqs.filter((tq) => tq.every(Boolean)),
    []
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const onPrecheckChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPrecheckValue(e.target.value);
      if (e.target.value === 'No') {
        onFieldChange(id, 'No');
      } else {
        const hasLocalTQ = getCompletedTqs(textQuantities).length;
        onFieldChange(id, hasLocalTQ ? JSON.stringify(textQuantities) : null);
      }
    },
    [getCompletedTqs, id, onFieldChange, textQuantities]
  );

  const onChange = useCallback(
    (tq: TTextQuantity, index: number) => {
      const tqCopy = Array.from(textQuantities);
      tqCopy.splice(index, 1, tq);
      setTextQuantities(tqCopy);
      // Check currently changing tq to see if both fields have value
      const completedTqs = getCompletedTqs([tq]);
      if (completedTqs.length) {
        onFieldChange(id, JSON.stringify(tqCopy));
      }
    },
    [getCompletedTqs, id, onFieldChange, textQuantities]
  );

  const onRepeat = useCallback(() => {
    const newTQs = [...textQuantities, blankTQ];
    setTextQuantities(newTQs);
  }, [blankTQ, textQuantities]);

  const onDelete = useCallback(
    (index: number) => () => {
      const tqCopy = Array.from(textQuantities);
      tqCopy.splice(index, 1);
      setTextQuantities(tqCopy);
      const completedTqs = getCompletedTqs(tqCopy);
      onFieldChange(id, completedTqs.length ? JSON.stringify(tqCopy) : null);
    },
    [getCompletedTqs, id, onFieldChange, textQuantities]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderTQs = useCallback(() => {
    return textQuantities.map((tq, i) => {
      return (
        <UCQTextQuantityInput
          key={i}
          tq={tq}
          index={i}
          onDelete={onDelete}
          onRepeat={onRepeat}
          isLast={i === textQuantities.length - 1}
          repeatable={repeatable}
          onChange={onChange}
          isOnly={textQuantities.length === 1}
        />
      );
    });
  }, [textQuantities, onDelete, onRepeat, repeatable, onChange]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setTextQuantities(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setPrecheckValue(value === 'No' ? 'No' : 'Yes');
  }, [value]);

  // =============================================
  // Early Return (no precheck)
  // =============================================
  if (!isPrecheck) {
    return <>{renderTQs()}</>;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ucq-field__precheck-container">
      <UCQPrecheck
        fieldInput={fieldInput}
        precheckValue={precheckValue}
        onChange={onPrecheckChange}
      />
      {isVisible && renderTQs()}
    </div>
  );
};

export default UCQTextQuantity;
