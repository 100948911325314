import { IForm } from 'types';

export const TableCategoryIconMap = {
  Data: 'internet',
  Trunks: 'phone-office',
  'UC Products': 'phone',
  'RingCentral Products': 'phone-f',
  MNE: 'shield',
  ENE: 'shield-half-f',
  'SBB 1-19 Pub/Priv': 'tv',
};

export const NavigationIconMap = {
  ...TableCategoryIconMap,
  Promos: 'lightning',
  PromosActive: 'lightning-f',
  Bundles: 'grid',
  'Business Rules': 'notes',
  'Add Products': 'plus-circle',
  'UC Profiles': 'phone',
  'UC Addons': 'plus-circle',
};

export const ProductCategoriesMap = {
  ...TableCategoryIconMap,
  NE: null,
};

export const UcQuestionIconsMap: { [formName: IForm['name']]: string } = {
  'General Discovery': 'info-circle-f',
  General: 'info-circle-f',
  Intro: 'internet',
  Discovery: 'info-circle',
  'SE Discovery': 'lightbulb',
  Workflow: 'checkmark',
  'LAN/WAN': 'network-alt',
  'MDF/IDF': 'network',
  'Network Draft': 'map',
  'Contact Info': 'person',
  'SME Form': 'info-circle',
};
