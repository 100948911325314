import { IEstimate } from 'types';
import { mockLocation } from './mockLocation';
import { mockSubmissions } from './mockSubmissions';

export const mockEstimates: { [key: string]: IEstimate } = {
  '0e65b59d-7803-4504-9b9d-1af3e7660e84': {
    customerName: 'Charter',
    name: 'Business utilities phones',
    createdBy: 'P3059592',
    sfOpportunityId: '0000',
    id: '0e65b59d-7803-4504-9b9d-1af3e7660e84',
    createdAt: '2022-04-15T16:57:12.004Z',
    updatedAt: '2022-04-15T16:57:12.004Z',
    status: 'Unqualified Opportunity',
    archived: false,
    lastRefreshed: '',
    salesTeam: {},
    scenarios: [],
    locations: [],
    submissions: [],
    pushedToSf: false,
    scenarioPromos: [],
  },
  testOne: {
    customerName: 'Chris',
    name: 'McDonalds',
    status: 'Qualified Opportunity',
    createdBy: 'P3059592',
    sfOpportunityId: '0000',
    id: '0e65b59d-7803-4504-9b9d-1af3e7660e84',
    createdAt: '2022-04-15T16:57:12.004Z',
    updatedAt: '2022-04-15T16:57:12.004Z',
    archived: false,
    lastRefreshed: '',
    salesTeam: {},
    scenarioPromos: [],
    scenarios: [
      {
        id: 'scenario1',
        name: 'Mock Scenario',
        createdBy: '',
        primary: true,
        order: 1,
        term: '36',
        estimateId: 'testOne',
        createdAt: '',
        updatedAt: '',
        promos: [],
        locations: [{ id: 'location1' }],
        selections: [
          {
            id: 'ef8ba2aa-98a9-4b7e-b3a2-e5a7e51c6c50',
            discretionValue36: 449,
            discretionValue48: 430,
            discretionValue60: 405,
            quantity: 1,
            filter: 'Interstate',
            fieldOpsAccurate: null,
            fieldOpsComments: '',
            locationId: 'ac7937b1-dd7d-4166-bfb6-2acf533fb20b',
            productId: '8a121c4b-1954-4617-af41-070744909f7a',
            scenarioId: '355fd476-7729-4d9a-963f-156cddbf66f3',
            profileId: null,
            prices: [
              {
                updatedBy: 'P3059592',
                productId: '8a121c4b-1954-4617-af41-070744909f7a',
                term: '36',
                type: 'rate',
                price: 449,
                id: '3962138e-5c64-4b3f-a9ef-16e1bd9bb031',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
              {
                updatedBy: 'P3059592',
                productId: '8a121c4b-1954-4617-af41-070744909f7a',
                term: '36',
                type: 'discount',
                price: 379,
                id: '472251a4-9a76-42ae-b1c4-773c8f52edf7',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
            ],
            name: '30M',
            familyName: 'DFI Speed',
            familyCategory: 'Data',
            familyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
            profileCategoryId: null,
          },
          {
            id: '87d12ed5-dc64-4b6d-a5ea-36d350b05d3c',
            discretionValue36: 0,
            discretionValue48: 0,
            discretionValue60: 0,
            quantity: 1,
            filter: 'Interstate',
            fieldOpsAccurate: null,
            fieldOpsComments: '',
            locationId: 'ac7937b1-dd7d-4166-bfb6-2acf533fb20b',
            productId: 'c49eb90c-def9-4f5d-8cdf-055441c57e75',
            scenarioId: '355fd476-7729-4d9a-963f-156cddbf66f3',
            profileId: null,
            prices: [
              {
                updatedBy: 'P3059592',
                productId: 'c49eb90c-def9-4f5d-8cdf-055441c57e75',
                term: '36',
                type: 'rate',
                price: 20,
                id: '882d5d0e-96ba-40aa-bf37-8bc7aaddcaaa',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
              {
                updatedBy: 'P3059592',
                productId: 'c49eb90c-def9-4f5d-8cdf-055441c57e75',
                term: '36',
                type: 'discount',
                price: 20,
                id: '193f876f-a242-4d77-8bc5-5d319db94d60',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
            ],
            name: '1',
            familyName: 'DFI Static IP',
            familyCategory: 'Data',
            familyId: 'fdf7290f-83e8-43d2-9632-da687858fbf0',
            profileCategoryId: null,
          },
          {
            id: 'c63fae96-bb8d-4cf1-b568-87c5cba4803e',
            discretionValue36: 0,
            discretionValue48: 0,
            discretionValue60: 0,
            quantity: 1,
            filter: 'Interstate',
            fieldOpsAccurate: null,
            fieldOpsComments: '',
            locationId: 'ac7937b1-dd7d-4166-bfb6-2acf533fb20b',
            productId: 'f59721b2-af54-493b-a911-a5b1081b9e5b',
            scenarioId: '355fd476-7729-4d9a-963f-156cddbf66f3',
            profileId: null,
            prices: [
              {
                updatedBy: 'P3059592',
                productId: 'f59721b2-af54-493b-a911-a5b1081b9e5b',
                term: '36',
                type: 'rate',
                price: 100,
                id: 'e6cd0700-1b9d-4da1-9b67-5cb503802da5',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
              {
                updatedBy: 'P3059592',
                productId: 'f59721b2-af54-493b-a911-a5b1081b9e5b',
                term: '36',
                type: 'discount',
                price: 100,
                id: 'e5105549-9b71-4173-96f4-646ccc6e4c23',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
            ],
            name: '30 Mbps',
            familyName: 'MNE Network Speed',
            familyCategory: 'MNE',
            familyId: '492a55d9-71ba-46b5-af56-e36c3dee663a',
            profileCategoryId: null,
          },
          {
            id: '1803275f-5b57-410c-babf-fb1f7ded3083',
            discretionValue36: 0,
            discretionValue48: 0,
            discretionValue60: 0,
            quantity: 2,
            filter: '',
            fieldOpsAccurate: null,
            fieldOpsComments: '',
            locationId: 'ac7937b1-dd7d-4166-bfb6-2acf533fb20b',
            productId: '1bdabfe4-3c5d-48fc-8d5c-75c5fb20fd7e',
            scenarioId: '355fd476-7729-4d9a-963f-156cddbf66f3',
            profileId: null,
            prices: [
              {
                updatedBy: 'P3059592',
                productId: '1bdabfe4-3c5d-48fc-8d5c-75c5fb20fd7e',
                term: '36',
                type: 'rate',
                price: 39,
                id: '65d859b4-b9b8-4c00-b1e8-11bedd1d6c01',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
              {
                updatedBy: 'P3059592',
                productId: '1bdabfe4-3c5d-48fc-8d5c-75c5fb20fd7e',
                term: '36',
                type: 'discount',
                price: 39,
                id: 'd87d94ab-a310-426d-a7c4-8c9d9de5e2eb',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
            ],
            name: 'General Purpose',
            familyName: 'MNE WiFi',
            familyCategory: 'MNE',
            familyId: '3f884a0e-3b94-4678-8c03-b7a52756e441',
            profileCategoryId: null,
          },
          {
            id: '9104dea9-31b9-4830-9fc2-52af487d589b',
            quantity: 2,
            fieldOpsAccurate: null,
            fieldOpsComments: null,
            locationId: 'ac7937b1-dd7d-4166-bfb6-2acf533fb20b',
            productId: '0097500d-234d-4881-969b-4d6d102454b9',
            scenarioId: '355fd476-7729-4d9a-963f-156cddbf66f3',
            profileId: null,
            prices: [
              {
                updatedBy: 'P3059592',
                productId: '0097500d-234d-4881-969b-4d6d102454b9',
                term: '36',
                type: 'rate',
                price: 59,
                id: '11fbb8d5-ab6a-4a36-93b1-b4fb9a792ead',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
              {
                updatedBy: 'P3059592',
                productId: '0097500d-234d-4881-969b-4d6d102454b9',
                term: '36',
                type: 'discount',
                price: 59,
                id: '29d3cc71-ae47-40a8-96df-54315758614b',
                createdAt: '2022-11-10T21:35:25.144Z',
                updatedAt: '2022-11-10T21:35:25.144Z',
              },
            ],
            name: 'High Performance',
            familyName: 'MNE WiFi',
            familyCategory: 'MNE',
            familyId: '3f884a0e-3b94-4678-8c03-b7a52756e441',
            profileCategoryId: null,
          },
        ],
      },
    ],
    locations: [mockLocation.location1],
    submissions: mockSubmissions,
    pushedToSf: false,
  },
  testTwo: {
    customerName: 'Alia',
    name: 'Taco Bell',
    status: 'Unqualified Opportunity',
    createdBy: 'P3059592',
    sfOpportunityId: '0000',
    id: '0e65b59d-7803-4504-9b9d-1af3e7660e84',
    createdAt: '2022-04-15T16:57:12.004Z',
    updatedAt: '2022-04-15T16:57:12.004Z',
    archived: false,
    lastRefreshed: '',
    salesTeam: {},
    scenarios: [],
    locations: [],
    submissions: [],
    pushedToSf: false,
    scenarioPromos: [],
  },
};
