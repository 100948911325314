// External Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Components
import { KiteSelect } from '@kite/react-kite';

// Hooks
import { useQueryData, useSelections } from 'hooks';
import { useDebouncedCallback } from 'use-debounce/lib';

// Utils
import { TermMonths } from 'utils';

// Types
import { TTermLengthMonths } from 'types';

// Styles
import './TermInput.scss';

const TermInput = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    currentTerm: term,
    updateBundleTerm,
    bundleId,
    scenarioId: currentScenarioId,
  } = useQueryData();
  const { updateScenarioTerm } = useSelections();

  const [inputValue, setInputValue] = useState({
    scenarioId: currentScenarioId,
    term,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const displayOptions = useMemo(() => {
    return Object.keys(TermMonths).map((value) => (
      <option key={`Term Option ${value}`} value={value}>
        {value}
      </option>
    ));
  }, []);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSave = useCallback(
    (term: TTermLengthMonths) => {
      if (bundleId && updateBundleTerm) {
        updateBundleTerm(term);
      } else {
        updateScenarioTerm(term);
      }
    },
    [bundleId, updateBundleTerm, updateScenarioTerm]
  );
  const debounceSave = useDebouncedCallback(onSave, 350);

  const handleDebounce = useCallback(
    (scenarioId: string, term: TTermLengthMonths) => {
      if (scenarioId === currentScenarioId) {
        debounceSave(term);
      }
    },
    [currentScenarioId, debounceSave]
  );

  const onTermChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value },
      } = e;
      const newTerm = value as unknown as TTermLengthMonths;
      setInputValue({ scenarioId: currentScenarioId, term: newTerm });
      handleDebounce(currentScenarioId, newTerm);
    },
    [currentScenarioId, handleDebounce]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (inputValue.scenarioId !== currentScenarioId) {
      debounceSave.cancel();
      setInputValue({ scenarioId: currentScenarioId, term });
    }
  }, [currentScenarioId, term, debounceSave, inputValue]);

  useEffect(() => {
    if (inputValue.term !== term && !debounceSave.isPending()) {
      setInputValue({ scenarioId: currentScenarioId, term });
    }
  }, [currentScenarioId, term, debounceSave, inputValue]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="term-input">
      <KiteSelect
        id="termOptions"
        name="termOptions"
        label="Select Term"
        value={inputValue.term}
        onChange={onTermChange}
        inputProps={{
          'data-testid': 'Term',
        }}
      >
        {displayOptions}
      </KiteSelect>
    </div>
  );
};

export default TermInput;
