// Packages
import React, { useMemo, useCallback } from 'react';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';
import { ButtonLink } from 'components';

// Hooks
import { useLocation, useParams } from 'react-router-dom';
import { useQueryData } from 'hooks';

// Utils

// Types
import { IEstimate } from 'types';

// Styles
import './Breadcrumbs.scss';

type TCrumb =
  | 'Dashboard'
  | 'Details'
  | 'Estimate Builder'
  | 'UC Questions'
  | 'Salesforce Review'
  | 'Estimate Review'
  | 'Salesforce Results'
  | 'Edit Estimate'
  | 'New Estimate';

type IBreadcrumbLinks = {
  [Property in TCrumb]: string;
};

/** Displays a hierarchy of pages to navigate based on the current page */

const Breadcrumbs = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { pathname } = useLocation();
  const { estimateId = '', opportunityId } = useParams();
  const { currentEstimate = {} as IEstimate } = useQueryData();
  const { name } = currentEstimate;

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const pathHas = useCallback(
    (string: string) => pathname.includes(string),
    [pathname]
  );

  const currentCrumb: TCrumb = useMemo(() => {
    if (pathHas(`dashboard/${estimateId}`)) {
      return 'Details';
    }

    if (pathHas(`estimate-builder/${estimateId}`)) {
      return 'Estimate Builder';
    }

    if (pathHas(`uc-questions/${estimateId}`)) {
      return 'UC Questions';
    }

    if (pathHas(`update-salesforce/${estimateId}/review`)) {
      return 'Salesforce Review';
    }

    if (pathHas(`view-estimate-products/${estimateId}/review`)) {
      return 'Estimate Review';
    }

    if (pathHas(`update-salesforce/${estimateId}/results`)) {
      return 'Salesforce Results';
    }

    if (pathHas(`dashboard/edit-estimate/${opportunityId}/${estimateId}`)) {
      return 'Edit Estimate';
    }

    return 'Dashboard';
  }, [estimateId, opportunityId, pathHas]);

  const links: IBreadcrumbLinks = useMemo(
    () => ({
      Dashboard: '/dashboard',
      Details: estimateId ? `/dashboard/${estimateId}` : '/dashboard',
      'Estimate Builder': estimateId
        ? `/estimate-builder/${estimateId}`
        : '/dashboard',
      'UC Questions': estimateId
        ? `/uc-questions/${estimateId}/general/intro`
        : '/dashboard',
      'Salesforce Review': estimateId
        ? `/update-salesforce/${estimateId}/review`
        : '/dashboard',
      'Estimate Review': estimateId
        ? `/view-estimate-products/${estimateId}/review`
        : '/dashboard',
      'Salesforce Results': estimateId
        ? `/update-salesforce/${estimateId}/results`
        : '/dashboard',
      'Edit Estimate': opportunityId
        ? `/dashboard/edit-estimate/${opportunityId}/${estimateId}`
        : '/dashboard',
      'New Estimate': opportunityId
        ? `/dashboard/edit-estimate/${opportunityId}`
        : '/dashboard',
    }),
    [estimateId, opportunityId]
  );

  const crumbs: TCrumb[] = useMemo(() => {
    const baseCrumbs = ['Dashboard', currentCrumb];

    if (name) {
      baseCrumbs.splice(1, 0, 'Details');
    }

    const formattedCrumbs = Array.from(new Set(baseCrumbs)) as TCrumb[];
    const index = formattedCrumbs.indexOf(currentCrumb);
    return formattedCrumbs.slice(0, index + 1);
  }, [currentCrumb, name]);

  const crumbIsHidden =
    !currentCrumb || currentCrumb === 'Dashboard' || estimateId === 'sandbox';

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderCrumbLabel = useCallback(
    (crumb: TCrumb) => {
      if (crumb === 'Details') {
        return name || 'New Estimate';
      }
      return crumb;
    },
    [name]
  );

  const renderBreadcrumbs = useCallback(
    () =>
      crumbs.map((crumb) => {
        const isStatic = currentCrumb === crumb;
        return isStatic ? (
          <span
            key={crumb}
            className="breadcrumbs__crumb--current breadcrumbs__crumb-label"
          >
            {renderCrumbLabel(crumb)}
          </span>
        ) : (
          <ButtonLink
            className="breadcrumbs__crumb"
            key={crumb}
            to={links[crumb]}
            linkContent={
              <span className="breadcrumbs__crumb-content">
                <span className="breadcrumbs__crumb-label">
                  {renderCrumbLabel(crumb)}
                </span>
                {<KiteIcon name="chevron-right" size="0.8rem" />}
              </span>
            }
            type="standalone"
          />
        );
      }),
    [crumbs, currentCrumb, links, renderCrumbLabel]
  );
  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return crumbIsHidden ? null : (
    <div className="breadcrumbs">{renderBreadcrumbs()}</div>
  );
};

export default Breadcrumbs;
