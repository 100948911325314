// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import colors from '../../styles/variables/_colors.scss';

export const colorsByFamily = Object.entries(colors).reduce(
  (acc: { [family: string]: { [key: string]: string } }, currColor) => {
    const [label, value] = currColor;
    const labelSplit = label.split('-');
    const family =
      labelSplit
        .shift()
        ?.replace(/([A-Z])/g, ' $1')
        .trim() || '';
    const newLabel = '$' + labelSplit.join('-');
    if (family && !acc[family]) {
      acc[family] = { [newLabel]: value as string };
    } else if (acc[family]) {
      acc[family] = { ...acc[family], [newLabel]: value as string };
    }
    return acc;
  },
  {}
);
