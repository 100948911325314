import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';

// Components
import { KiteSelect } from '@kite/react-kite';
import { RadioToggle } from 'components';

// Utils
import { generateClassnames, NONE, dropDownConfig } from 'utils';

// Hooks
import {
  useDebounceInputSave,
  useDisabledCheck,
  useQueryDataContext,
  useUpdateFocus,
} from 'hooks';

// Types
import {
  IOnSelectionChangeParams,
  IProduct,
  IProductFamily,
  ISelectionController,
  TDisableConfigFamily,
} from 'types';

interface ICustomDropdown extends ISelectionController {
  productFamily: IProductFamily;
  customValues?: IProduct[];
  // If being used in DiscretionWrapper, this should be false
  showRadioToggle?: boolean;
}

export const CustomDropdown = ({
  productFamily,
  customValues,
  onChange,
  showRadioToggle,
}: ICustomDropdown) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { handleDisabledCheck } = useDisabledCheck();
  const { updateFocus } = useUpdateFocus();
  const { currentSelections, scenarioId: currentScenarioId } =
    useQueryDataContext();
  const { saveSelection, debounceSave } = useDebounceInputSave({
    onChange,
    debounceTime: 10,
  });

  const selection = useMemo(() => {
    return currentSelections.find(
      (selection) => selection.familyId === productFamily.id
    );
  }, [productFamily.id, currentSelections]);

  const { productId = NONE, filter = productFamily.filterOptions?.[0] } =
    selection || {};

  const [inputValue, setInputValue] = useState({
    scenarioId: currentScenarioId,
    value: productId,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const { pageClass, componentClass } = generateClassnames(productFamily);

  const isDisabled = useMemo(() => {
    return handleDisabledCheck({
      family: productFamily.name as TDisableConfigFamily,
      selections: currentSelections,
    });
  }, [handleDisabledCheck, productFamily.name, currentSelections]);

  const filteredDropdown = useMemo(() => {
    return (
      dropDownConfig[productFamily.name]?.dependentOption.reduce(
        (products: IProduct[], dependentFamily) => {
          const family = currentSelections.find(
            (p) => dependentFamily[p.familyName]
          );
          if (!family) return products;
          const selectedOption = dependentFamily[family.familyName].find((d) =>
            d.selectedOption.includes(family.name)
          );
          const dropdowns = productFamily.products.filter((product) =>
            selectedOption?.displayOption.includes(product.name)
          );
          return dropdowns;
        },
        []
      ) || null
    );
  }, [productFamily, currentSelections]);

  // =============================================
  // Render Methods
  // =============================================
  const dropdownOptions = useMemo(() => {
    const dropdownValues =
      customValues ?? filteredDropdown ?? productFamily.products;

    return [
      !filteredDropdown?.length ? (
        <option key={`${productFamily.name} ${NONE}`} value={NONE}>
          {NONE}
        </option>
      ) : (
        ''
      ),
      ...dropdownValues.map((product) => (
        <option
          key={`${product.productFamily?.name} ${product.name}`}
          value={product.id}
          data-testid={product.name}
        >
          {product.name}
        </option>
      )),
    ];
  }, [customValues, productFamily, filteredDropdown]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSave = useCallback(
    (params: {
      scenarioId: string;
      selectionParams: IOnSelectionChangeParams;
      isDebounce?: boolean;
    }) => {
      const { scenarioId, selectionParams, isDebounce } = params;
      if (scenarioId === currentScenarioId) {
        isDebounce
          ? debounceSave({ ...selectionParams, scenarioId })
          : saveSelection(selectionParams);
        const { productId } = selectionParams;
        setInputValue({
          scenarioId,
          value: productId,
        });
      } else {
        debounceSave.flush();
      }
    },
    [currentScenarioId, debounceSave, saveSelection]
  );

  const handleSelect = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { value: newProductId } = e.target;

      onSave({
        scenarioId: currentScenarioId,
        selectionParams: {
          selectionId: selection?.id,
          productId: newProductId,
          selectionValues: {
            quantity: 1,
            filter,
          },
        },
        isDebounce: true,
      });
    },
    [onSave, currentScenarioId, selection, filter]
  );

  const handleRadioSelect = useCallback(
    (option: string) => {
      onSave({
        scenarioId: currentScenarioId,
        selectionParams: {
          selectionId: selection?.id,
          productId,
          selectionValues: {
            quantity: 1,
            filter: option,
          },
        },
        isDebounce: true,
      });
    },
    [onSave, currentScenarioId, selection, productId]
  );

  useEffect(() => {
    if (
      // Reset input state on scenario change
      inputValue.scenarioId !== currentScenarioId ||
      // Reset input state if data doesn't match and no mutation pending
      (inputValue.value !== productId && !debounceSave.isPending())
    ) {
      setInputValue({
        scenarioId: currentScenarioId,
        value: productId,
      });
    }
  }, [currentScenarioId, debounceSave, inputValue, productId]);

  // =============================================
  // Return
  // =============================================
  return (
    <>
      <KiteSelect
        key={productFamily.name}
        className={classNames({
          [`${pageClass}__select`]: true,
          [`${pageClass}__select--${componentClass}`]: true,
        })}
        id={productFamily.name}
        name={productFamily.name}
        label={productFamily.name}
        value={inputValue.value}
        onChange={handleSelect}
        tooltip={productFamily.description.replace(/;/g, `\n`)}
        maxWidth="700px"
        inputProps={{
          'data-testid': productFamily.name,
          onFocus: () => updateFocus([productFamily.name]),
        }}
        disabled={isDisabled}
      >
        {dropdownOptions}
      </KiteSelect>

      {productFamily.filterOptions && showRadioToggle && (
        <RadioToggle
          options={productFamily.filterOptions}
          onChange={handleRadioSelect}
          name="Filter option"
          isDisabled={!selection}
          selectionValue={filter}
          isMutatePending={debounceSave.isPending()}
        />
      )}
    </>
  );
};
