// External Packages
import React, { useCallback, useMemo } from 'react';
import { View } from '@react-pdf/renderer';

// Components
import { PdfProductRow } from './PdfProductRow';
import { PdfProductSubRow } from './PdfProductSubRow';

// Utils
import {
  generateTextComponents,
  generateViewComponent,
  inFootprintIswId,
  outOfFootprintIswId,
  tableHeaders,
} from 'utils';

// Types
import {
  TTermLengthMonths,
  TEipTermLengthMonths,
  IProductFamily,
  ILocationSelection,
  TableValues,
  IGenerateProductTotals,
  IScenario,
  TNavigationCategory,
  IProfile,
  IPromo,
} from 'types';

// Styles
import { styles } from './PdfDocumentStyles';

interface IPdfProductTable {
  currentScenario?: IScenario;
  filteredSelections: ILocationSelection[];
  allProductFamilies: IProductFamily[];
  allProfiles: IProfile[];
  category: TNavigationCategory;
  termMonth: TTermLengthMonths;
  eipTermMonth: TEipTermLengthMonths | null;
  allPromos: IPromo[];
  locationId: string;
  locationSelections: ILocationSelection[];
  generateProductTotals: ({
    selections,
    locationId,
    category,
    subCategory,
    format,
  }: IGenerateProductTotals) => TableValues<number | string>;
}

export const PdfProductTable = ({
  currentScenario,
  filteredSelections,
  allProductFamilies,
  allProfiles,
  allPromos,
  category,
  termMonth,
  eipTermMonth,
  generateProductTotals,
  locationId,
  locationSelections,
}: IPdfProductTable) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const headers = useMemo(() => {
    return tableHeaders.map((head) =>
      generateViewComponent(head, 'tableCellHeader', 'table headers')
    );
  }, []);

  const categoryText = useMemo(() => {
    if (category === 'SBB 1-19 Pub/Priv') {
      const sbbLocation = filteredSelections.find(
        (s) => s.familyName === 'Location'
      );
      return `SBB 1-19 ${sbbLocation?.name || ''}`;
    }
    return category;
  }, [category, filteredSelections]);

  const selectionsByProfile = useMemo(() => {
    return filteredSelections.reduce(
      (acc: { [key: string]: ILocationSelection[] }, selection) => {
        if (!selection.profileId) {
          if (acc.all) {
            acc.all.push(selection);
          } else {
            acc.all = [selection];
          }
        } else {
          const { profileId } = selection;
          if (acc[profileId]) {
            acc[profileId].push(selection);
          } else {
            acc[profileId] = [selection];
          }
        }
        return acc;
      },
      {}
    );
  }, [filteredSelections]);

  // =============================================
  // Render Methods
  // =============================================
  const generateProductRows = useCallback(() => {
    const sortedSelections = Object.values(selectionsByProfile).flat();

    return sortedSelections.reduce((acc: JSX.Element[], selection, i) => {
      const prodFamily = allProductFamilies.find(
        (f) => f.id === selection.familyId
      );
      if (!prodFamily) return acc;

      const { profileId } = selection;
      const profileSelections = profileId
        ? selectionsByProfile[profileId]
        : selectionsByProfile.all;

      const appliedPromos =
        currentScenario?.promos?.reduce((acc: IPromo[], sp) => {
          const promo = allPromos.find((p) => sp.promoId === p.id);
          if (promo) {
            if (promo.isLocationSpecific) {
              sp.locationId === locationId && acc.push(promo);
            } else {
              acc.push(promo);
            }
          }
          return acc;
        }, []) || [];

      if (
        prodFamily?.subcategory &&
        selection.productId !== inFootprintIswId &&
        selection.productId !== outOfFootprintIswId
      ) {
        // Check whether current product belongs in the same section
        // as the previous or in a new section
        const isInPreviousProductFamily =
          selection.familyId === sortedSelections[i - 1]?.familyId;

        if (isInPreviousProductFamily) return acc;
        acc.push(
          <PdfProductSubRow
            key={`product sub row ${selection.name} ${i}`}
            productFamily={prodFamily}
            filteredSelections={profileSelections.filter(
              (f) =>
                f.productId !== inFootprintIswId &&
                f.productId !== outOfFootprintIswId
            )}
            currentTerm={currentScenario?.term || '12'}
            currentEipTerm={currentScenario?.eipTerm || '12'}
            generateProductTotals={generateProductTotals}
            appliedPromos={appliedPromos}
            locationSelections={locationSelections}
          />
        );
      } else {
        acc.push(
          <PdfProductRow
            key={`product row ${selection.name} ${i}`}
            selection={selection}
            productFamily={prodFamily}
            currentTerm={currentScenario?.term || '12'}
            currentEipTerm={currentScenario?.eipTerm || '12'}
            generateProductTotals={generateProductTotals}
            appliedPromos={appliedPromos}
            locationSelections={locationSelections}
          />
        );
      }

      const isLastProfileProduct =
        profileSelections[profileSelections.length - 1].id === selection.id;

      if (profileId && isLastProfileProduct) {
        const profile = allProfiles.find(
          (p) => p.id === selection.profileId
        ) as IProfile;

        const productCells = Object.entries(
          generateProductTotals({
            term: currentScenario?.term,
            eipTerm: currentScenario?.eipTerm,
            selections: profileSelections,
          })
        ).map(([key, value]) => {
          return generateViewComponent(
            value as string,
            'profilePriceCell',
            key
          );
        });

        const { name: profileName, quantity } = profile;
        const profileTitle = `${profileName} (${quantity} included) Subtotal`;

        acc.push(
          <View key={profile.id} style={styles.productRow}>
            <View style={styles.profileCellHeader}>
              {generateTextComponents([profileTitle], 'productLabel')}
            </View>
            {productCells}
          </View>
        );
      }
      return acc;
    }, []);
  }, [
    selectionsByProfile,
    allProductFamilies,
    currentScenario,
    generateProductTotals,
    allPromos,
    allProfiles,
    locationId,
    locationSelections,
  ]);

  // =============================================
  // Early Return
  // =============================================

  if (!filteredSelections || !filteredSelections.length) return null;
  // const eipTerm = filteredSelections.find(s => s.eipTerm)?.eipTerm;
  // =============================================
  // Return
  // =============================================
  return (
    <View style={styles.productWrapper}>
      <View style={styles.productRow}>
        <View style={styles.productCellHeader}>
          {generateTextComponents([categoryText], 'productLabel')}
          {generateTextComponents(
            [`(Term: ${termMonth} months)`],
            'productTerm'
          )}

          {filteredSelections.some(
            (s) => s.familyName === 'RC Phones / Devices'
          )
            ? eipTermMonth === 'Purchase'
              ? generateTextComponents(
                  ['(Outright Purchase)'],
                  'eipProductTerm'
                )
              : eipTermMonth
              ? generateTextComponents(
                  [`(EIP: ${eipTermMonth} months)`],
                  'eipProductTerm'
                )
              : null
            : null}

          {filteredSelections.some(
            (s) =>
              s.productId === inFootprintIswId ||
              s.productId === outOfFootprintIswId
          )
            ? eipTermMonth === 'One-time Payment'
              ? generateTextComponents(['(One-time Payment)'], 'eipProductTerm')
              : eipTermMonth
              ? generateTextComponents(
                  [`(IP: ${eipTermMonth} months)`],
                  'eipProductTerm'
                )
              : null
            : null}
        </View>

        {headers}
      </View>

      {generateProductRows()}
    </View>
  );
};
