// Packages
import React, { useCallback, useMemo } from 'react';

// Redux

// Components
import {
  KiteAlert,
  KiteCard,
  KiteIcon,
  KiteLink,
  KiteTable,
} from '@kite/react-kite';

// Hooks
import { useParams } from 'react-router-dom';
import {
  useQueryData,
  useGetFailedProducts,
  useGetEstimateDetails,
  useGetEstimateScenarios,
} from 'hooks';

// Utils
import { env } from 'utils';

// Types

// Styles
import './SalesforceResultsPage.scss';

/** Displays a list of products that failed to post to salesforce */

const SalesforceResultsPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId } = useParams();

  useGetEstimateDetails(estimateId);
  useGetEstimateScenarios({
    params: { estimateId },
  });

  const { currentEstimate } = useQueryData();

  const { data: failedProducts } = useGetFailedProducts({
    params: { opportunityId: currentEstimate?.sfOpportunityId || '' },
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const salesforceLink = useMemo(() => {
    const { sfUrl } = env;
    const redirectUrl = `${sfUrl}/${currentEstimate?.sfOpportunityId}`;
    return (
      <KiteLink
        className="update-salesforce-card__link"
        href={redirectUrl}
        type="standalone"
        newTab
      >
        View Opportunity in Salesforce
        <KiteIcon className="update-salesforce-card__icon" name="linkout" />
      </KiteLink>
    );
  }, [currentEstimate]);

  const alertMessage = useMemo(() => {
    if (failedProducts?.length) {
      return (
        <KiteAlert
          description="The Salesforce update was successful. However, there was a problem updating the following items. Please manually add these items via Enterprise UI."
          level="page"
          type="alert"
        />
      );
    }
    return (
      <KiteAlert
        description="All of your items were successfully entered into Salesforce. If you would like to add more items, please enter them manually via Enterprise UI."
        level="page"
        type="confirm"
      />
    );
  }, [failedProducts]);

  const failureList = useMemo(() => {
    if (!failedProducts || !failedProducts.length) return null;

    type TTableData = {
      [key: string]: {
        quantity: number;
        serviceCode: string;
        productName: string;
      }[];
    };

    return failedProducts.reduce((acc: TTableData, product) => {
      const {
        sfLocationId,
        productName,
        productFamily,
        quantity,
        serviceCode,
      } = product;

      if (!acc[sfLocationId]) {
        acc[sfLocationId] = [];
      }

      acc[sfLocationId].push({
        quantity,
        serviceCode: serviceCode ?? 'N/A',
        productName: `${productFamily}: ${productName}`,
      });
      return acc;
    }, {});
  }, [failedProducts]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderFailureTables = useCallback(() => {
    if (!failureList) return null;

    return Object.entries(failureList).map(([sfLocationId, tableData]) => {
      const location = currentEstimate?.locations.find(
        (l) => l.sfLocationId === sfLocationId
      );

      return (
        <div key={`${sfLocationId} table`}>
          <h2>{location?.name || ''}</h2>
          <KiteTable
            columns={[
              { key: 'quantity', label: 'Quantity', width: '20%' },
              { key: 'serviceCode', label: 'Service Code', width: '20%' },
              { key: 'productName', label: 'Product Name' },
            ]}
            tableData={tableData}
            useStripes
          />
        </div>
      );
    });
  }, [failureList, currentEstimate]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="salesforce-results-page">
      <h1>Salesforce Results</h1>
      <KiteCard>
        {alertMessage}
        {salesforceLink}
        {renderFailureTables()}
      </KiteCard>
    </div>
  );
};

export default SalesforceResultsPage;
