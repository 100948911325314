// Packages
import React, { useMemo } from 'react';
import classNames from 'classnames';

// Hooks
import { useGenerateProductTotals, useQueryData } from 'hooks';

// Utils

// Types
import { TProductCategory, TProductList } from 'types';

// Styles
import './PricingTableHead.scss';

export interface IPricingTableHeadProps {
  category: TProductCategory;
  subCategory?: TProductList;
  profileId?: string;
}

const PricingTableHead = ({
  category,
  subCategory,
  profileId = '',
}: IPricingTableHeadProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { allProfiles, currentSelections } = useQueryData();
  const { generateProductTotals } = useGenerateProductTotals();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const filteredSelections = useMemo(() => {
    return currentSelections.filter((s) => {
      if (profileId) {
        return s.profileId === profileId;
      }
      return s.familyCategory === category && !s.profileId;
    });
  }, [currentSelections, category, profileId]);

  const tableHeaderText = useMemo(() => {
    if (profileId) {
      const profile = allProfiles.find((p) => p.id === profileId);
      if (!profile) {
        return null;
      }
      return `${profile.name} x ${profile.quantity}`;
    } else if (subCategory) {
      return subCategory;
    } else if (category === 'SBB 1-19 Pub/Priv') {
      const location = filteredSelections.find(
        (p) => p.familyName === 'Location'
      );
      return `SBB 1-19 ${location?.name}`;
    } else if (category === 'UC Products') {
      return 'UC Addons';
    } else if (category === 'RingCentral Products') {
      return 'RC Addons';
    }
    return category;
  }, [filteredSelections, allProfiles, profileId, category, subCategory]);

  // =============================================
  // Render Methods
  // =============================================
  const outputTotals = useMemo(() => {
    if (subCategory) {
      return generateProductTotals({
        selections: filteredSelections,
        category,
        subCategory,
      });
    } else {
      return generateProductTotals({
        selections: filteredSelections,
        category,
      });
    }
  }, [filteredSelections, category, subCategory, generateProductTotals]);

  // =============================================
  // Return
  // =============================================
  return (
    <tr
      className={classNames({
        'pricing-table-head': !subCategory,
        'pricing-table-head__pricing-row': subCategory,
      })}
      data-testid={`${category} ${subCategory ?? ''}`}
    >
      <td>{tableHeaderText}</td>
      <td>{outputTotals.rateMrc}</td>
      <td>{outputTotals.rateNrc}</td>
      <td>{outputTotals.solutionMrc}</td>
      <td>{outputTotals.solutionNrc}</td>
      <td>
        {tableHeaderText === 'RC Addons' ? (
          <i>EIP Notes</i>
        ) : tableHeaderText === 'UC Addons' ? (
          <i>IP Notes</i>
        ) : (
          '-'
        )}
      </td>
    </tr>
  );
};

export default PricingTableHead;
