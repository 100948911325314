import setChannelPartnersHeader from '../setChannelPartnersHeader';
import { setAuthToken, removeAuthToken } from '../setAuthToken';
import { env } from './env';

export const postChannelPartnersLogin = async (
  email: string,
  password: string
) => {
  const url = `${env.apiUrl}/channel-partner/login`;

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    // credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (res.status !== 200) {
    return { error: 'User not found' };
  }

  const data = await res.json();

  setChannelPartnersHeader();
  setAuthToken(data.accessToken);
  return data;
};

export const getPartnerlinkToken = async () => {
  const url = `${env.apiUrl}/channel-partner/token`;

  const res = await fetch(url, {
    method: 'GET',
    // credentials: 'include',
  });

  if (res.status !== 200) {
    return { error: 'Login' };
  }

  const data = await res.json();

  setChannelPartnersHeader();
  setAuthToken(data.accessToken);
  return data;
};

export const deleteLogout = async () => {
  try {
    const url = `${env.apiUrl}/channel-partner/token`;

    const res = await fetch(url, {
      method: 'DELETE',
      // credentials: 'include',
      headers: {
        'Content-Type': 'applicaiton/json',
      },
    });

    if (res.status !== 200) {
      return false;
    }

    removeAuthToken();
    return true;
  } catch (error) {
    return false;
  }
};
