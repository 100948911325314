import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { IProfile } from 'types';
import { profileRequest, queryKeys, sandboxData } from 'utils';

export const usePostProfile = (config?: {
  onSuccess?: (profileId: string) => void;
}) => {
  const { onSuccess } = config || {};
  const queryClient = useQueryClient();

  const { estimateId } = useParams();

  const queryKey = queryKeys().all.profiles;

  const isSandbox = estimateId === 'sandbox';

  const sandboxHandler = useCallback(async (profile: Partial<IProfile>) => {
    const newProfile = { ...profile, createdAt: new Date() };
    sandboxData.profiles = [
      ...sandboxData.profiles.filter((p) => p.id !== profile.id),
      newProfile,
    ] as IProfile[];
    return newProfile;
  }, []);

  const {
    mutate: postProfile,
    isLoading: postProfileLoading,
    error: postProfileError,
    data: postProfileRes,
  } = useMutation(
    isSandbox
      ? sandboxHandler
      : (profile: Partial<IProfile>) =>
          profileRequest({
            method: 'POST',
            data: profile,
          }),
    {
      onMutate: async (profile: Partial<IProfile>) => {
        await queryClient.cancelQueries(queryKey);
        const previousProfiles: Partial<IProfile>[] | undefined =
          queryClient.getQueryData(queryKey);
        const newProfile = { ...profile, createdAt: new Date() };

        queryClient.setQueryData(
          queryKey,
          previousProfiles
            ? [
                ...previousProfiles.filter((p) => p.id !== profile.id),
                newProfile,
              ]
            : [newProfile]
        );

        return { previousProfiles };
      },
      onError: async (err, _, context: any) => {
        console.log(err);
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Reset query data to pre-mutation if mutation error
        queryClient.setQueryData(queryKey, context.previousProfiles);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onSuccess: (res: IProfile) => {
        onSuccess && onSuccess(res.id);
        res &&
          queryClient.invalidateQueries(
            queryKeys({ scenarioId: res.scenarioId }).filter.profiles
          );
      },
    }
  );

  return {
    postProfile,
    postProfileLoading,
    postProfileError,
    postProfileRes,
  };
};
