import { useCallback } from 'react';
import { useAnalytics } from './useAnalytics';

export const useTrackNavClick = () => {
  const { trackSelectAction } = useAnalytics();

  const trackNavClick = useCallback(
    (actionName: string) => () => {
      trackSelectAction(actionName, { opType: 'navigationClick' });
    },
    [trackSelectAction]
  );

  return { trackNavClick };
};
