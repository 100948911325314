// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteAlert, KiteCard } from '@kite/react-kite';
import { ImportModal } from 'components';

// Hooks
import {
  useRenderFields,
  useUCQuestionsTotal,
  useQueryData,
  useScrollToTop,
  useAnalytics,
  useTrackOpenState,
} from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { renderFormIcon } from 'utils';

// Types
import { IForm, ISubmission } from 'types';

// Styles
import './UCQForm.scss';

export interface IUCQFormProps {
  /** Current form data */
  form: IForm;
  /** Submission data for current form */
  formSubmissions: ISubmission[];
  /** Change handler for field focus, updates help text in layout */
  onFocusChange: (helpString: string) => void;

  allSubmissions?: ISubmission[];
}

/** Renders currently selected form within UCQuestions page*/

const UCQForm = ({
  form,
  onFocusChange,
  formSubmissions,
  allSubmissions,
}: IUCQFormProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const { trackOpenState } = useTrackOpenState();
  const { name, fields, id } = form;
  const { currentEstimate } = useQueryData();

  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importLocationId, setImportLocationId] = useState('');

  const { estimateId = '', locationId = '', formName } = useParams();

  const {
    fieldComponents,
    currentFocusId,
    requiredFieldIds,
    requiredSubmissions,
    updateSubmissionsError,
    onImport,
  } = useRenderFields({ fields, submissions: formSubmissions, formId: id });

  const hasIncomplete = requiredSubmissions.length !== requiredFieldIds.length;

  const importSubmissions = useMemo(
    () =>
      allSubmissions?.filter(
        (s) => s.estimateId === estimateId && s.locationId === importLocationId
      ),
    [estimateId, allSubmissions, importLocationId]
  );

  /*
  removed this code as part of performance enhancement for submissions api calls
  const { data: importSubmissions } = useGetSubmissions({
    params: { estimateId, locationId: importLocationId },
  });*/

  const formImportSubmissions = useMemo(
    () => importSubmissions?.filter((s) => s.formId === id) || [],
    [id, importSubmissions]
  );

  const { completePercent: importCompletePercent } = useUCQuestionsTotal({
    submissions: formImportSubmissions,
    formId: id,
  });

  const ref = useRef<HTMLFormElement>(null);
  useScrollToTop({ ref, refNestLevel: 0, form });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const otherLocations = useMemo(
    () => (currentEstimate?.locations || []).filter((l) => l.id !== locationId),
    [currentEstimate, locationId]
  );

  const showImportBtn =
    formName !== 'network-draft' &&
    locationId !== 'general' &&
    !!otherLocations.length;

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    // could add manual save here if needed
    e.preventDefault();
    // saveSubmissions(submissionsToSave);
  }, []);

  const toggleImportModal = useCallback(() => {
    setImportModalOpen(!importModalOpen);
    if (importModalOpen && otherLocations.length) {
      setImportLocationId(otherLocations[0].id);
    }
    trackOpenState({
      name: 'UC Questions Import Modal',
      isOpen: importModalOpen,
    });
  }, [importModalOpen, otherLocations, trackOpenState]);

  const handleImportLocationSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setImportLocationId(value);
    },
    []
  );

  const handleImport = useCallback(() => {
    onImport(formImportSubmissions);
    toggleImportModal();
    trackSelectAction(
      `UC Questions Import Submissions from locationId: ${importLocationId}`,
      {
        opType: 'buttonClick',
      }
    );
  }, [
    formImportSubmissions,
    onImport,
    toggleImportModal,
    trackSelectAction,
    importLocationId,
  ]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    // Render help text in parent (UCQLayout) component
    const helpHtml =
      fields.find((f) => f.id === currentFocusId)?.helpText || '';
    onFocusChange(helpHtml);
  }, [currentFocusId, fields, onFocusChange]);

  useEffect(() => {
    if (
      otherLocations.length &&
      (!importLocationId || importLocationId === locationId)
    ) {
      setImportLocationId(otherLocations[0].id);
    }
  }, [locationId, importLocationId, otherLocations]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="ucq-form">
      <div className="ucq-form__header-container">
        <div className="ucq-form__header">
          {renderFormIcon(form.name, '24px')}
          <h2>{name}</h2>
          <span className="ucq-form__header-complete">{`${requiredSubmissions.length}/${requiredFieldIds.length}`}</span>
        </div>
        <div className="ucq-form__header-btns">
          {hasIncomplete && (
            <div className="ucq-field__incomplete">
              Incomplete Answer:{' '}
              <div className="ucq-field__incomplete-marker"></div>
            </div>
          )}
          {showImportBtn && (
            <ImportModal
              canShow={importModalOpen}
              importLocationId={importLocationId}
              importType="submission"
              locations={otherLocations}
              onImport={handleImport}
              onLocationSelect={handleImportLocationSelect}
              onToggle={toggleImportModal}
              currentForm={form}
              completePercent={importCompletePercent}
            />
          )}
        </div>
      </div>
      {updateSubmissionsError && (
        <KiteAlert
          type="alert"
          title="Save Error"
          description="Something went wrong saving your answers. Any changes will not be saved. Please refresh and try again later."
        />
      )}
      <form
        ref={ref}
        onSubmit={handleSubmit}
        className={classNames({
          'ucq-form__fields': true,
          'ucq-form__fields--flow-chart': name === 'Network Draft',
        })}
      >
        {fieldComponents}
      </form>
    </KiteCard>
  );
};

export default UCQForm;
