import { IEstimate, ILocation, INewLocation, ISelectableLocation } from 'types';
import { parseSfAddressData } from './parseSfAddressData';

interface ICreateEstimateLocations {
  sfLocations: ISelectableLocation[];
  estimateData: IEstimate;
  userId: string;
}

export const createEstimateLocations = ({
  sfLocations,
  estimateData,
  userId,
}: ICreateEstimateLocations) => {
  return sfLocations.reduce(
    (locations: (INewLocation | ILocation)[], l, index) => {
      const existingLocation = estimateData.locations.find(
        (el) => el.sfLocationId === l.serviceLocationId
      );
      const { street, city, state, postalCode } = parseSfAddressData(
        l.shippingAddress
      );
      if (existingLocation) {
        const location: ILocation = {
          ...existingLocation,
          address1: street,
          city,
          state,
          zipcode: postalCode,
          sfBillingSystem: l.billingSystem,
          sfServiceAreaNumber: l.serviceAreaNumber,
          sfLocationId: l.serviceLocationId,
        };
        locations.push(location);
      } else {
        const newLocation: INewLocation = {
          '#id': `location__${index}`,
          isEzPass: false,
          createdBy: userId,
          name: l.serviceLocationName,
          address1: street,
          address2: '',
          city,
          state,
          zipcode: postalCode,
          isAutobuild: true, // default
          sfLocationId: l.serviceLocationId,
          sfBillingSystem: l.billingSystem,
          sfServiceAreaNumber: l.serviceAreaNumber,
        };
        locations.push(newLocation);
      }
      return locations;
    },
    []
  );
};
