import { useQueryData } from 'hooks';
import { useCallback } from 'react';
import { ILocationSelection, IOnSelectionChangeParams } from 'types';
import { useDebouncedCallback } from 'use-debounce/lib';
import { NONE } from 'utils';
import { useSelections } from './useSelections';

interface IUseDebounceInputParams {
  onChange?: (params: IOnSelectionChangeParams) => void;
  debounceTime?: number;
}

interface IDebounceSaveParams extends IOnSelectionChangeParams {
  scenarioId: string;
}

export const useDebounceInputSave = (params: IUseDebounceInputParams = {}) => {
  const { onChange, debounceTime = 350 } = params;

  const { applySelection } = useSelections();
  const { scenarioId: currentScenarioId, currentSelections } = useQueryData();

  const saveSelection = useCallback(
    ({
      selectionId,
      productId,
      selectionValues,
      opType,
    }: IOnSelectionChangeParams) => {
      const currentSelection = currentSelections.find(
        (s) => s.id === selectionId
      );

      if (currentSelection) {
        const updatedSelection: Partial<ILocationSelection> = {
          ...selectionValues,
          productId,
        };

        const updatedKeys = Object.keys(updatedSelection) as [
          keyof ILocationSelection
        ];

        const isSameSelection = updatedKeys.every(
          (key) => currentSelection[key] === updatedSelection[key]
        );

        // Do nothing if selection values are same as updated selection
        if (isSameSelection) {
          return;
        }
      }

      // Do nothing if no selection & new value is NONE
      if (!currentSelection && productId === NONE) {
        return;
      }

      // If custom on change, use that (i.e. DiscretionWrapper)
      // Else, call applySelection
      if (onChange) {
        onChange({
          selectionId,
          productId,
          selectionValues,
          opType,
        });
      } else {
        applySelection({
          selectionId,
          productId,
          selectionValues,
          opType,
        });
      }
    },
    [applySelection, currentSelections, onChange]
  );

  const handleDebounce = useCallback(
    (params: IDebounceSaveParams) => {
      const { scenarioId } = params;
      if (scenarioId === currentScenarioId) {
        // Only save if still on same scenario from when debounce was triggered
        saveSelection(params);
      }
    },
    [currentScenarioId, saveSelection]
  );

  const debounceSave = useDebouncedCallback(handleDebounce, debounceTime);

  return {
    debounceSave,
    saveSelection,
  };
};
