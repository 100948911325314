import { IProduct } from 'types';
import { productRequest, queryKeys } from 'utils';
import { IRequestConfig } from 'types';
import { useGenerateQuery } from '../useGenerateQuery';

export const useGetProducts = (
  requestConfig: IRequestConfig<IProduct> = {}
) => {
  const { params } = requestConfig;
  return useGenerateQuery<IProduct[]>({
    queryKey: params
      ? queryKeys(params).filter.products
      : queryKeys().all.products,
    apiCall: productRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
