import { IScenarioPromo, ISubmission } from 'types';
import { ILocation, ILocationBase, INewLocation } from './Location';
import { ISalesforceLocation, TOpportunityStatus } from './Salesforce';
import { INewScenario, IScenario } from './Scenario';

export type TSalesTeamRole = 'Owner' | 'AE' | 'SE' | 'SS' | 'MSS' | 'PM' | '';

export const SalesTeamRoles: TSalesTeamRole[] = [
  'Owner',
  'AE',
  'SE',
  'SS',
  'MSS',
  'PM',
  '',
];

export const defaultEstimateData = {
  opportunityId: '',
  estimateName: '',
  customerName: '',
};

export type TEstimateFields = typeof defaultEstimateData;

export type TTransportType = 'Fiber' | 'DOCSIS';

interface IEstimateBase {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  customerName: string;
  createdBy: string;
  status: TOpportunityStatus;
  sfOpportunityId: string;
  archived: boolean;
  lastRefreshed?: string;
  salesTeam: {
    [pid: string]: TSalesTeamRole;
  };
  pushedToSf?: boolean;
  webexSpaceUrl?: string;
  transportType?: TTransportType;
  pushedScenarioId?: string | null;
  serviceLocationRegion?: string | null;
  submissions?: ISubmission[];
}
export interface ISelection {
  id?: string;
  name?: string;
  quantity?: number;
  familyName?: string;
  // Include other properties of a selection here
}
export interface IEstimate extends IEstimateBase {
  scenarios: IScenario[];
  locations: ILocation[];
  scenarioPromos: IScenarioPromo[];
  selections?: ISelection[];
}

export interface INewEstimate
  extends Omit<IEstimateBase, 'id' | 'createdAt' | 'updatedAt'> {
  scenarios: INewScenario[];
  locations: INewLocation[];
}

export interface IEstimateUpdate extends IEstimateBase {
  locations: (ILocationBase | INewLocation | ILocation)[];
  scenarios: (IScenario | INewScenario)[];
}

export type IEstimateInfo = {
  oppId: string;
  estimateName: string;
  customerName: string;
};

export interface ISelectableLocation extends ISalesforceLocation {
  selected: boolean;
  disabled: boolean;
}
