// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteButton, KiteCard, KiteIcon } from '@kite/react-kite';

// Hooks
import { useGenerateProductTotals, useQueryDataContext } from 'hooks';

// Utils
import { formatPrice, imageUrl } from 'utils';
import mockImage from '../../images/mock-uc-product.jpg';

// Types
import { IProfile, ILocationSelection } from 'types';

// Styles
import './UCProductCard.scss';

export interface IUCProductCardProps {
  /** Profile information */
  profile: IProfile;
  /** Data containing details for the current product */
  selections: ILocationSelection[];
  /** Handler for editing a profile */
  onEditProfile: (profile: IProfile) => void;
  /** Handler for deleting a profile */
  onDeleteProfile: (profile: IProfile) => void;
}

/** Card for displaying UC Profiles */

const UCProductCard = ({
  profile,
  selections,
  onEditProfile,
  onDeleteProfile,
}: IUCProductCardProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { allProducts, allProfileCategories } = useQueryDataContext();
  const { generateProductTotals } = useGenerateProductTotals();

  const getImageUri = useCallback(() => {
    const categoryId = allProfileCategories.find(
      (profile) => profile.name === 'voipHardware'
    )?.id;
    const locationPhone = selections.find(
      (p) => p.profileCategoryId === categoryId
    );
    const phoneProduct = allProducts.find((p) => p.id === locationPhone?.id);
    const imageURI = !phoneProduct?.imgUrl
      ? mockImage
      : `${imageUrl}${phoneProduct.imgUrl}`;

    return imageURI;
  }, [allProducts, allProfileCategories, selections]);

  const [cardImage, setCardImage] = useState(getImageUri());

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const productImage = useMemo(() => {
    return (
      <img
        src={cardImage}
        alt={`${profile.name} phone`}
        onError={() => setCardImage(mockImage)}
      />
    );
  }, [profile, cardImage]);

  const profileCost = useMemo(() => {
    const { solutionMrc } = generateProductTotals({
      selections,
      format: false,
    });
    return formatPrice((solutionMrc as number) / profile.quantity);
  }, [selections, profile, generateProductTotals]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleEditClick = useCallback(() => {
    onEditProfile(profile);
  }, [profile, onEditProfile]);

  const handleDeleteProfileClick = useCallback(() => {
    onDeleteProfile(profile);
  }, [profile, onDeleteProfile]);

  // =============================================
  // Render Methods
  // =============================================
  const renderProductList = useCallback(() => {
    const voipHardwareProduct = selections.find(
      (product) =>
        allProfileCategories.find((pfc) => pfc.id === product.profileCategoryId)
          ?.name === 'voipHardware'
    );
    const otherProducts = selections.filter(
      (product) =>
        product.profileCategoryId !== voipHardwareProduct?.profileCategoryId
    );
    const list = otherProducts.map((f) => <li key={f.name}>{f.name}</li>);

    return (
      <div className="uc-product-card__info">
        <h5>{voipHardwareProduct?.name}</h5>
        <ul>{list}</ul>
      </div>
    );
  }, [allProfileCategories, selections]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setCardImage(getImageUri());
  }, [selections, allProducts, allProfileCategories, getImageUri]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="uc-product-card">
      <div className="uc-product-card__upper">
        <h4>{profile.name}</h4>
        <KiteButton
          type="standalone-link"
          aria-label="edit product"
          onClick={handleEditClick}
        >
          <KiteIcon name="edit" />
        </KiteButton>

        <KiteButton
          type="standalone-link"
          aria-label="delete product"
          onClick={handleDeleteProfileClick}
        >
          <KiteIcon name="trash" />
        </KiteButton>
      </div>

      <div className="uc-product-card__lower">
        {productImage}
        {renderProductList()}

        <div className="uc-product-card__lower-pricing">
          <span>Amount: {profile.quantity}</span>
          <span className="uc-product-card__lower-cost">
            {profileCost} Each
          </span>
        </div>
      </div>
    </KiteCard>
  );
};

export default UCProductCard;
