// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Redux

// Components
import { KiteAlert, KiteButton, KiteIcon } from '@kite/react-kite';
import { UCQPrecheck } from '..';

// Hooks
import { useGetSubmissions, useUploadToS3 } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { deleteFileFromS3, isValidJsonString } from 'utils';

// Types
import { IPostFileToS3Response, IUCQInputProps } from 'types';

// Styles
import '../UCQFieldStyles.scss';

const defaultFile: IPostFileToS3Response = {
  fileName: '',
  url: '',
  isDefault: true,
};

/** Custom input for 'image' or 'file' inputTypes on UCQ Field */
const UCQUpload = ({
  fieldInput,
  onFieldChange,
  uploadType,
}: IUCQInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [isUploading, setIsUploading] = useState(false);
  const { value, id, isPrecheck, inputName, repeatable } = fieldInput;
  const { estimateId = '', locationId } = useParams();

  const { data: submissions } = useGetSubmissions({ params: { estimateId } });

  const initValues: IPostFileToS3Response[] = useMemo(() => {
    if (typeof value === 'string') {
      return isValidJsonString(value) && Array.isArray(JSON.parse(value))
        ? JSON.parse(value)
        : [defaultFile];
    }
    return [defaultFile];
  }, [value]);

  const [files, setFiles] = useState(initValues);
  const [err, setErr] = useState(false);
  const [precheckValue, setPrecheckValue] = useState(
    value === 'No' ? 'No' : 'Yes'
  );

  const onSuccess = useCallback(
    (newFileData: IPostFileToS3Response) => {
      setErr(false);
      const newFiles = [...files.filter((f) => !f.isDefault), newFileData];
      onFieldChange(id, JSON.stringify(newFiles), () => setIsUploading(false));
    },
    [files, id, onFieldChange]
  );

  const onError = useCallback(() => {
    setErr(true);
    setIsUploading(false);
  }, []);

  const { uploadToS3 } = useUploadToS3({
    onSuccess,
    onError,
  });

  const uploadRef = useRef<HTMLInputElement | null>(null);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const isVisible = isPrecheck ? precheckValue === 'Yes' : true;

  const fileTypes = uploadType === 'tco' ? 'application/pdf' : 'image/*';

  const getFileKey = useCallback((fileData?: IPostFileToS3Response) => {
    if (fileData) {
      const { url } = fileData;
      return url?.replace(/.+\.com\//, '');
    }
  }, []);

  const isDirty = useMemo(() => {
    const actualFiles = files.filter((f) => !f.isDefault);
    const actualInit = initValues.filter((f) => !f.isDefault);

    if (actualFiles.length !== actualInit.length) {
      return true;
    }

    return actualFiles.some((f, i) =>
      Object.entries(f).some(([key, value]) => {
        const initObj = actualInit[i];
        if (initObj) {
          return initObj[key as keyof IPostFileToS3Response] !== value;
        }
        return true;
      })
    );
  }, [files, initValues]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onPrecheckChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPrecheckValue(e.target.value);
      if (e.target.value === 'No') {
        onFieldChange(id, 'No');
      } else {
        onFieldChange(id, null);
      }
    },
    [id, onFieldChange]
  );

  const onFileUpload = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFile = e.target.files?.item(0);

      if (newFile) {
        setIsUploading(true);
        // if has fileKey will replace file in s3, else will create new
        const fileKey = getFileKey(files[index]);
        uploadToS3({ file: newFile, fileKey });
      }
    },
    [files, getFileKey, uploadToS3]
  );

  const onFileDelete = useCallback(
    (index: number, mutable: boolean) => async () => {
      const fileKey = getFileKey(files[index]);

      if (fileKey && mutable) {
        const otherUploads = new Set(
          submissions?.reduce((acc: string[], s) => {
            if (
              s.locationId &&
              s.locationId !== locationId &&
              s.fieldId === id &&
              s.answer &&
              isValidJsonString(s.answer) &&
              Array.isArray(JSON.parse(s.answer))
            ) {
              const otherFiles: IPostFileToS3Response[] = JSON.parse(s.answer);
              const otherFileKeys =
                otherFiles.reduce((keys: string[], f) => {
                  const key = getFileKey(f);
                  if (key) {
                    keys.push(key);
                  }
                  return keys;
                }, []) || [];

              acc.push(...otherFileKeys);
            }
            return acc;
          }, [])
        );

        //check if any other locations are using the file before deleting from s3
        if (!otherUploads.has(fileKey)) {
          await deleteFileFromS3({ fileKey });
        }
      }

      const newFiles = files.filter((f, i) => i !== index && !f.isDefault);

      if (!newFiles.length) {
        mutable ? onFieldChange(id, null) : setFiles([defaultFile]);
      } else {
        mutable
          ? onFieldChange(id, JSON.stringify(newFiles))
          : setFiles(newFiles);
      }
    },
    [files, getFileKey, id, locationId, onFieldChange, submissions]
  );

  const handleInputClick = useCallback(() => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  }, []);

  const handleRepeat = useCallback(() => {
    setFiles([...files.filter((f) => !f.isDefault), defaultFile]);
  }, [files]);

  // =============================================
  // Render Methods
  // =============================================
  const renderUploadButton = useCallback(
    (file: IPostFileToS3Response, index: number) => {
      const mutable = !file.isDefault;
      const showDeleteBtn =
        files.length > 1 || (files.length === 1 && !file.isDefault);

      return !submissions ? null : (
        <div className="ucq-field__upload-btns">
          <KiteButton
            minWidth="180px"
            maxWidth="180px"
            onClick={handleInputClick}
            loading={file.isDefault && isUploading}
            disabled={!file.isDefault}
            type={uploadType === 'tco' ? 'outline' : 'primary'}
          >
            <KiteIcon
              name={uploadType === 'tco' ? 'folder' : 'image-preview'}
              margin="0 .25rem 0 0"
            />
            {uploadType === 'tco' ? 'TCO Document' : 'Upload Image'}
          </KiteButton>
          {showDeleteBtn && (
            <KiteButton
              onClick={onFileDelete(index, mutable)}
              maxWidth="min-content"
              minWidth="min-content"
              type="standalone-link"
              disabled={isUploading}
            >
              <KiteIcon name="trash" />
            </KiteButton>
          )}
          {file.isDefault ? (
            <em>No {uploadType === 'tco' ? 'document' : 'image'} attached</em>
          ) : (
            <em>{file.fileName}</em>
          )}
        </div>
      );
    },
    [
      files,
      handleInputClick,
      onFileDelete,
      submissions,
      isUploading,
      uploadType,
    ]
  );

  const renderPreview = useCallback((url: string, name: string) => {
    if (url && name) {
      const fileType = url.split('.').at(-1);

      return fileType === 'pdf' ? (
        <embed type="application/pdf" src={url} title={name} />
      ) : (
        <img
          src={url}
          alt={name}
          onError={(e) => (e.currentTarget.style.display = 'none')}
        />
      );
    }
    return null;
  }, []);

  const repeatButton = useMemo(() => {
    if (!repeatable || uploadType === 'tco' || files.some((f) => f.isDefault)) {
      return null;
    }
    return (
      <KiteButton
        onClick={handleRepeat}
        maxWidth="min-content"
        minWidth="min-content"
        type="outline"
        leftIcon="plus"
      >
        Additional Image
      </KiteButton>
    );
  }, [files, handleRepeat, uploadType, repeatable]);

  const uploadInputs = useMemo(() => {
    const inputs = files.map((file, i) => {
      return (
        <div key={`${id}${i}`}>
          <input
            type="file"
            id={`${id}${i}`}
            name={`${inputName} ${i + 1}`}
            accept={fileTypes}
            ref={(el) => {
              if (file.isDefault) {
                uploadRef.current = el;
              }
            }}
            onChange={onFileUpload(i)}
            style={{ display: 'none' }}
          />
          {renderUploadButton(file, i)}
          {renderPreview(file.url, file.fileName)}
        </div>
      );
    });

    return (
      <div className="ucq-field__upload">
        {inputs}
        {repeatButton}
        {err && (
          <KiteAlert
            type="alert"
            title="Upload Error"
            description="Something went wrong uploading your image. Any changes will not be saved. Please try again later."
          />
        )}
      </div>
    );
  }, [
    files,
    repeatButton,
    id,
    inputName,
    fileTypes,
    onFileUpload,
    renderUploadButton,
    renderPreview,
    err,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    // Keep data synced w/ state
    if (isDirty) {
      setFiles(initValues);
    }
  }, [initValues, isDirty]);

  useEffect(() => {
    setPrecheckValue(value === 'No' ? 'No' : 'Yes');
  }, [value]);

  // =============================================
  // Early Return (no precheck)
  // =============================================
  if (!isPrecheck) {
    return uploadInputs;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ucq-field__precheck-container">
      <UCQPrecheck
        fieldInput={fieldInput}
        precheckValue={precheckValue}
        onChange={onPrecheckChange}
      />
      {isVisible && uploadInputs}
    </div>
  );
};

export default UCQUpload;
