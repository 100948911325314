import { ISalesTeamMember } from 'types';

import { avatar1, avatar2 } from '../../images/mock-avatars';

export const mockSalesTeam: ISalesTeamMember[] = [
  {
    imageURI: avatar1,
    firstName: 'Gray',
    lastName: 'Smith',
    title: 'Account Executive - UX',
    email: 'gray-example-email@charter.com',
  },
  {
    imageURI: avatar2,
    firstName: 'Tiff',
    lastName: 'Powell',
    title: 'Mgr. Sales Engineering III',
    email: 'Tiff-example-email@charter.com',
  },
  {
    imageURI: avatar2,
    firstName: 'Tiff',
    lastName: 'Powell',
    title: 'Mgr. Sales Engineering III',
    email: 'Tiff-example-email@charter.com',
  },
];
