// Packages
import React, { useCallback } from 'react';

// Redux

// Components
import { SmartExpansionPanel, SortableTable } from '@kite/react-kite-plus';
import { AutobuildPill, RateCenterValidationTable, UcLinks } from 'components';

// Hooks
import { useDeletePhoneNumber } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { formatAddress } from 'utils';

// Types
import { ILocation, ISubmission } from 'types';

// Styles
import './EstimateLocationPanel.scss';

export interface IEstimateLocationPanelProps {
  location: ILocation;
  toggleAddTnModal: (locationId: string) => void;
  toggleRateCenterModal: (locationId: string) => void;
  allSubmissions?: ISubmission[];
}

/** A card that displays location data on estimate detail page */

const EstimateLocationPanel = ({
  location,
  toggleAddTnModal,
  toggleRateCenterModal,
  allSubmissions,
}: IEstimateLocationPanelProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId = '' } = useParams();
  const { deletePhoneNumber } = useDeletePhoneNumber({
    estimateId,
    locationId: location.id,
  });

  // =============================================
  // Interaction Handlers
  // =============================================
  const onDeletePhoneNumber = useCallback(
    (numberId: string) => {
      deletePhoneNumber(numberId);
    },
    [deletePhoneNumber]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderUcSection = useCallback(() => {
    return (
      <section key="uc navigation links section">
        <h3>UC Questions</h3>
        <UcLinks locationId={location.id} allSubmissions={allSubmissions} />
      </section>
    );
  }, [location.id, allSubmissions]);

  const renderLocationSection = useCallback(() => {
    interface ITableData {
      name: string;
      serviceAddress: string;
      serviceLocationId: string;
      serviceAreaNumber: string;
      billingSystem: string;
      fiberStatus: string;
    }

    const tableData: ITableData[] = [
      {
        name: location.name,
        serviceAddress: formatAddress({
          address1: location.address1,
          address2: location.address2,
          city: location.city,
          state: location.state,
          zipcode: location.zipcode,
        }),
        serviceLocationId: location.sfLocationId,
        serviceAreaNumber: `serviceAreaNumber${
          location.sfServiceAreaNumber || 'N/A'
        }`,
        billingSystem: `billingSystem${location.sfBillingSystem || 'N/A'}`,
        fiberStatus: location.isAutobuild ? 'Autobuild' : 'Non-Autobuild',
      },
    ];

    const columns = [
      { sortKey: 'name', label: 'Name', sortEnabled: false },
      {
        sortKey: 'serviceAddress',
        label: 'Service Address',
        sortEnabled: false,
      },
      {
        sortKey: 'serviceLocationId',
        label: 'Service Location ID',
        sortEnabled: false,
      },
      {
        sortKey: 'serviceAreaNumber',
        label: 'Service Area Number',
        render: (item: ITableData) => {
          const { serviceAreaNumber } = item;
          return serviceAreaNumber.split('serviceAreaNumber').at(-1) || '';
        },
        sortEnabled: false,
      },
      {
        sortKey: 'billingSystem',
        label: 'Billing System',
        render: (item: ITableData) => {
          const { billingSystem } = item;
          return billingSystem.split('billingSystem').at(-1) || '';
        },
        sortEnabled: false,
      },
      {
        sortKey: 'fiberStatus',
        label: 'Fiber Building Status',
        sortEnabled: false,
      },
    ];

    return (
      <section key="location section">
        <h3>Location</h3>
        <SortableTable tableData={tableData} columns={columns} />
      </section>
    );
  }, [location]);

  // =============================================
  // Return
  // =============================================
  return (
    <SmartExpansionPanel
      title={location.name}
      showTitle={false}
      titleColumns={[
        { content: location.name, size: 0.5, heading: true },
        {
          content: <AutobuildPill isAutobuild={location.isAutobuild} />,
          size: 2,
        },
      ]}
    >
      <section className="estimate-location-card__content-container">
        <div className="estimate-location-card__content">
          {renderUcSection()}
          {renderLocationSection()}
          <RateCenterValidationTable
            className="estimate-location-card__phone-number-section"
            location={location}
            onDeletePhoneNumber={onDeletePhoneNumber}
            toggleAddTnModal={toggleAddTnModal}
            toggleRateCenterModal={toggleRateCenterModal}
          />
        </div>
      </section>
    </SmartExpansionPanel>
  );
};

export default EstimateLocationPanel;
