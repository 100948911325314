// External Packages
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import classnames from 'classnames';

// Components
import { Toast } from '@kite/react-kite-plus';
import { AdminProductTable } from 'components';
import { NavLink, useParams } from 'react-router-dom';

// Hooks
import { useAnalytics, useQueryData } from 'hooks';

// Types
import { IProductFamily, TToast } from 'types';

// Utils
import { nameToUrlParam } from 'utils';

// Styles
import './ProductPricing.scss';

// =============================================
// Interfaces
// =============================================
const ProductPricing = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { allProductFamilies } = useQueryData();
  const { productCategory, productName } = useParams();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const [toast, setToast] = useState<TToast>(null);

  const getNameLabel = useCallback(() => {
    return (
      allProductFamilies.find((pf) => {
        return nameToUrlParam(pf.category) === productCategory;
      })?.category || ''
    );
  }, [allProductFamilies, productCategory]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleToast = useCallback(
    (config: { title: string; type: string; text: string }) => {
      const { title, type, text } = config;
      if (title && type) {
        setToast({
          text,
          type,
          title,
        });
      } else {
        setToast(null);
      }
    },
    [setToast]
  );

  const getPath = useCallback(
    (targetName: string) => {
      const path = `/admin/product-pricing/${productCategory}/${nameToUrlParam(
        targetName
      )}`;
      return path;
    },
    [productCategory]
  );

  // =============================================
  // Render Methods
  // =============================================
  const productSelectButtons = useMemo(() => {
    const filteredProductFamilies = allProductFamilies?.filter(
      (pf) =>
        pf.name !== 'Location' &&
        nameToUrlParam(pf.category) === productCategory &&
        pf.published
    );

    return filteredProductFamilies.map((productFamily: IProductFamily) => (
      <NavLink
        to={getPath(productFamily.name)}
        key={`${productFamily.name} table button`}
        className={classnames({
          'product-pricing__select': true,
          'product-pricing__select--active':
            productName === nameToUrlParam(productFamily.name),
        })}
      >
        {productFamily.name}
      </NavLink>
    ));
  }, [allProductFamilies, productCategory, productName, getPath]);

  const productTable = useMemo(() => {
    const productFamily = allProductFamilies?.find(
      (p) =>
        nameToUrlParam(p.category) === productCategory &&
        nameToUrlParam(p.name) === productName
    );
    if (!productFamily) return;

    return (
      <AdminProductTable
        productFamily={productFamily}
        handleToast={handleToast}
      />
    );
  }, [allProductFamilies, productName, productCategory, handleToast]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('Product Pricing'), [trackPageView]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="product-pricing">
      {/* toast uniqueKey MUST be "text" or throws error/doesn't render */}
      <Toast newToast={toast} uniqueKey="text" autoClose={3000} />
      <div className="product-pricing__wrapper">
        <div className="product-pricing__navigation-wrapper">
          <h2>{getNameLabel()}</h2>
          <ul>{productSelectButtons}</ul>
        </div>

        <div className="product-pricing__table-wrapper">{productTable}</div>
      </div>
    </div>
  );
};

export default ProductPricing;
