import { useCallback, useMemo, useRef } from 'react';
import { TProductList, ILocationSelection, ISubmission, IProduct } from 'types';
import {
  deepCopy,
  dependencyConfig,
  dropDownConfig,
  getSeatsRateCard,
  insightsMap,
  sdWanMap,
  NONE,
  ultraHighSpeeds,
} from 'utils';
import {
  useGenerateSelection,
  useGetSubmissions,
  useQueryData,
  useUpdateFocus,
} from 'hooks';
import { useParams } from 'react-router-dom';

export const useDependencyCheck = (): ((
  selections: ILocationSelection[]
) => ILocationSelection[]) => {
  const {
    allProductFamilies,
    allProducts,
    locationId,
    currentSelections,
    currentEstimate,
  } = useQueryData();

  const { updateFocus } = useUpdateFocus();
  const { bundleId } = useParams();

  const submissionParams = useMemo(() => {
    if (currentEstimate?.id || locationId) {
      return {
        estimateId: currentEstimate?.id,
      };
    }
  }, [currentEstimate, locationId]);

  const { data: currentSubmissions } = useGetSubmissions({
    params: submissionParams,
  });

  const submissionRef = useRef<ISubmission[] | undefined>(currentSubmissions);
  submissionRef.current = currentSubmissions;

  const matchGroup = useCallback(
    (selection: ILocationSelection) => {
      if (bundleId) {
        return selection.bundleId === bundleId;
      } else {
        return selection.locationId === locationId;
      }
    },
    [bundleId, locationId]
  );

  const generateSelection = useGenerateSelection();

  const isNewSelection = useCallback(
    (selectionId: ILocationSelection['id']) => {
      return selectionId.includes('newSelection');
    },
    []
  );

  const canChange = useCallback(
    (selection: ILocationSelection, index: number) => {
      const { id } = selection;
      if (bundleId && index > -1 && matchGroup(selection)) {
        return true;
      }
      return index > -1 && !isNewSelection(id) && matchGroup(selection);
    },
    [bundleId, isNewSelection, matchGroup]
  );

  const filterFamilies = useCallback(
    (selections: ILocationSelection[], families: TProductList[]) => {
      return selections.filter((s) => {
        return families.includes(s.familyName) && matchGroup(s);
      });
    },
    [matchGroup]
  );

  const filterProducts = useCallback(
    (selections: ILocationSelection[], products: string[]) => {
      return selections.filter((s) => {
        return products.includes(s.name) && matchGroup(s);
      });
    },
    [matchGroup]
  );

  const reduceSelectionsByKeyAndLocation = useCallback(
    (selections: ILocationSelection[], key: keyof ILocationSelection) => {
      return selections.reduce(
        (acc: ILocationSelection[keyof ILocationSelection][], s) => {
          if (!locationId || matchGroup(s)) {
            const value = s[key];
            acc.push(value);
          }
          return acc;
        },
        []
      );
    },
    [locationId, matchGroup]
  );

  // Find index within all selections that matches product and location id
  const getIndex = useCallback(
    (config: { selection: ILocationSelection; acc: ILocationSelection[] }) => {
      const { selection, acc } = config;
      return acc.findIndex(
        (s) =>
          matchGroup(selection) &&
          s.productId === selection.productId &&
          s.id === selection.id
      );
    },
    [matchGroup]
  );

  const handleDropdown = (
    familyName: TProductList,
    selection: ILocationSelection[]
  ) => {
    const displayOption =
      dropDownConfig[familyName]?.dependentOption.map((dependentFamily) => {
        const family = selection.find((p) => dependentFamily[p.familyName]);
        if (!family) return [];
        const displayOption = dependentFamily[family.familyName].find((d) =>
          d.selectedOption.includes(family.name)
        )?.displayOption;
        return displayOption;
      }) || null;
    return displayOption ? { ...displayOption }[0] : null;
  };

  const isSelectionToBeRemoved = (
    selections: ILocationSelection[],
    selection: ILocationSelection
  ) => {
    if (selection.familyName === 'MNE Device Management') {
      const dmSelections = selections.filter(
        (s) =>
          s.locationId === selection.locationId &&
          s.familyName === 'MNE Device Management' &&
          s.productId !== NONE
      );
      if (dmSelections.length) {
        return false;
      }
    } else if (selection.familyName === 'ENE Device Management') {
      const dmSelections = selections.filter(
        (s) =>
          s.locationId === selection.locationId &&
          s.familyName === 'ENE Device Management' &&
          s.productId !== NONE
      );
      if (dmSelections.length) {
        return false;
      }
    } else if (selection.familyName === 'MNE WiFi') {
      if (selection.name === 'Outdoor Antenna') {
        const offerSelections = selections.filter(
          (s) =>
            s.locationId === selection.locationId &&
            s.familyName === 'MNE WiFi' &&
            (s.name === 'General Performance Outdoor' ||
              s.name === 'High Performance Outdoor') &&
            s.productId !== NONE
        );
        if (offerSelections.length) {
          return false;
        }
      }
      if (selection.name === 'Indoor Antenna') {
        const offerSelections = selections.filter(
          (s) =>
            s.locationId === selection.locationId &&
            s.familyName === 'MNE WiFi' &&
            s.name === 'High Performance Indoor with external antenna' &&
            s.productId !== NONE
        );
        if (offerSelections.length) {
          return false;
        }
      }
    }
    return true;
  };

  const mneWifiAntennaSelection = useCallback(
    (
      selection: ILocationSelection,
      onAcc: ILocationSelection[],
      product: IProduct
    ) => {
      if (
        selection.name === 'High Performance Indoor with external antenna' &&
        product.name === 'Indoor Antenna'
      ) {
        const newSelection = generateSelection({
          productId: product.id,
        });

        onAcc.push(newSelection);
        return true;
      } else if (
        (selection.name === 'General Performance Outdoor' ||
          selection.name === 'High Performance Outdoor') &&
        product.name === 'Outdoor Antenna'
      ) {
        const newSelection = generateSelection({
          productId: product.id,
        });
        onAcc.push(newSelection);
        return true;
      }
      return (
        product.name === 'Outdoor Antenna' || product.name === 'Indoor Antenna'
      );
    },
    [generateSelection]
  );

  const handleDependencyCheck = useCallback(
    (selections: ILocationSelection[]): ILocationSelection[] => {
      const getSelectionType = (selection: ILocationSelection) =>
        selection.productId === NONE ? 'Off' : 'On';

      // Basic on/off check
      const onOffSelections = selections.reduce(
        (acc: ILocationSelection[], selection) => {
          if (!matchGroup(selection)) {
            return acc;
          }

          const familyNames = reduceSelectionsByKeyAndLocation(
            acc,
            'familyName'
          );

          const type = getSelectionType(selection);
          const dependencies = dependencyConfig[selection.familyName];

          if (dependencies) {
            const { Add = [] } = dependencies;

            const selectionsToRemove = isSelectionToBeRemoved(acc, selection)
              ? filterFamilies(acc, dependencies[type])
              : [];

            selectionsToRemove.forEach((s) => {
              const index = getIndex({ selection: s, acc });
              if (canChange(s, index)) {
                acc.splice(index, 1, { ...s, productId: NONE });
              }
            });

            if (type === 'On' && Add.length) {
              const selectionsToAdd = Add.reduce(
                (onAcc: ILocationSelection[], familyName) => {
                  if (!familyNames.includes(familyName)) {
                    const productFamily = allProductFamilies.find(
                      (pf) => pf.name === familyName
                    );
                    if (productFamily) {
                      const product = productFamily.products[0];
                      const newSelection = generateSelection({
                        productId: product.id,
                      });
                      onAcc.push(newSelection);
                    }
                  }
                  return onAcc;
                },
                []
              );
              selectionsToAdd.length && acc.push(...selectionsToAdd);

              const category = selection.familyCategory;

              if (category === 'MNE') {
                const eneSelections = acc.filter(
                  (s) => s.familyCategory === 'ENE'
                );
                eneSelections.forEach((s) => {
                  const index = getIndex({ selection: s, acc });
                  if (canChange(s, index)) {
                    acc.splice(index, 1, { ...s, productId: NONE });
                  }
                });
              }

              if (category === 'ENE') {
                const mneSelections = acc.filter(
                  (s) => s.familyCategory === 'MNE'
                );
                mneSelections.forEach((s) => {
                  const index = getIndex({ selection: s, acc });
                  if (canChange(s, index)) {
                    acc.splice(index, 1, { ...s, productId: NONE });
                  }
                });
              }
            }
          }
          return acc;
        },
        deepCopy(selections)
      );

      // Check high speeds deps
      const highSpeedSelections = onOffSelections.reduce(
        (acc: ILocationSelection[], selection) => {
          if (!matchGroup(selection)) {
            return acc;
          }

          const dependencies = dependencyConfig[selection.familyName];

          if (dependencies) {
            const { HighSpeed = [] } = dependencies;
            const isHighSpeed = ultraHighSpeeds.includes(selection.name);
            if (isHighSpeed) {
              const selectionsToRemove = filterFamilies(selections, HighSpeed);
              selectionsToRemove.forEach((s) => {
                const index = getIndex({ selection: s, acc });
                if (canChange(s, index)) {
                  acc.splice(index, 1, { ...s, productId: NONE });
                }
              });
            }
          }

          return acc;
        },
        deepCopy(onOffSelections)
      );

      // Update which addons are configured based on location selections
      const updateSelections = highSpeedSelections.reduce(
        (acc: ILocationSelection[], selection) => {
          if (!matchGroup(selection)) {
            return acc;
          }

          const dependencies = dependencyConfig[selection.familyName];
          const type = getSelectionType(selection);

          if (dependencies && type === 'On') {
            const { Update = [] } = dependencies;
            if (selection.familyName === 'Location') {
              Update.forEach((familyName) => {
                const productList = allProductFamilies.find(
                  (f) => f.name === familyName
                )?.products;

                const currentSelections = filterFamilies(acc, [familyName]);

                currentSelections.forEach((s) => {
                  const productInfo = productList?.find(
                    (p) => p.id === s.productId
                  );
                  if (!productInfo?.notes.includes(selection.name)) {
                    const index = getIndex({ selection: s, acc });
                    if (canChange(s, index)) {
                      acc.splice(index, 1, { ...s, productId: NONE });
                    }
                  }
                });
              });
            } else {
              // Check whether the location has the dependent products listed in the
              // update array and updates their values to match selected value
              // const productsToFocus: TProductList[] = [];
              Update.forEach((familyName: TProductList) => {
                if (!dependencies[type].includes(familyName)) {
                  // Family and product listed in update array
                  const dependentFamily = allProductFamilies.find(
                    (pf) => pf.name === familyName
                  );

                  const dependentSelection = acc.find(
                    (s) =>
                      s.familyId === dependentFamily?.id &&
                      matchGroup(selection) &&
                      s.locationId === selection.locationId
                  );

                  const skipUpdate =
                    !dependentFamily ||
                    !dependentSelection ||
                    dependentSelection.name === sdWanMap(selection.name) ||
                    dependentSelection.name === insightsMap(selection.name) ||
                    handleDropdown(familyName as TProductList, acc)?.includes(
                      dependentSelection.name
                    ) ||
                    dependentSelection.name === selection.name;

                  if (skipUpdate) return;

                  // Check whether product value is a valid option for dependent product
                  const updatedProduct = dependentFamily.products.find(
                    (p) =>
                      p.name === selection.name ||
                      p.name === insightsMap(selection.name) ||
                      handleDropdown(familyName as TProductList, acc)?.includes(
                        p.name
                      ) ||
                      p.name === sdWanMap(selection.name)
                  );

                  const index = getIndex({
                    selection: dependentSelection,
                    acc,
                  });

                  if (
                    !updatedProduct &&
                    index > -1 &&
                    matchGroup(dependentSelection)
                  ) {
                    acc.splice(index, 1, {
                      ...dependentSelection,
                      productId: NONE,
                    });
                  } else if (
                    dependentSelection.productId !== NONE &&
                    matchGroup(dependentSelection) &&
                    updatedProduct &&
                    index > -1
                  ) {
                    const newSelection = generateSelection({
                      productId: updatedProduct.id,
                      selectionId: dependentSelection.id,
                    });

                    acc.splice(index, 1, newSelection);
                  }
                }
              });
            }
          }

          return acc;
        },
        deepCopy(highSpeedSelections)
      );

      // Check custom speeds deps
      const customSpeedSelections = updateSelections.reduce(
        (acc: ILocationSelection[], selection) => {
          if (!matchGroup(selection)) {
            return acc;
          }

          const dependencies = dependencyConfig[selection.familyName];

          if (dependencies) {
            const { CustomSpeeds } = dependencies;
            if (CustomSpeeds) {
              Object.entries(CustomSpeeds).forEach(([family, productNames]) => {
                if (!productNames.includes(selection.name)) {
                  const selectionToRemove = acc.find(
                    (p) => p.familyName === family
                  );
                  if (selectionToRemove) {
                    const index = getIndex({
                      selection: selectionToRemove,
                      acc,
                    });

                    if (canChange(selectionToRemove, index)) {
                      acc.splice(index, 1, {
                        ...selectionToRemove,
                        productId: NONE,
                      });
                    }
                  }
                }
              });
            }
          }

          return acc;
        },
        deepCopy(updateSelections)
      );

      // Check additional fee deps
      const productLevelDepsSelections = customSpeedSelections.reduce(
        (acc: ILocationSelection[], selection) => {
          if (!matchGroup(selection)) {
            return acc;
          }

          const productNames = reduceSelectionsByKeyAndLocation(acc, 'name');

          const dependencies = dependencyConfig[selection.familyName];

          if (dependencies) {
            const { ProductLevelDeps } = dependencies;
            if (ProductLevelDeps) {
              const type = getSelectionType(selection);
              Object.entries(ProductLevelDeps).forEach(
                ([family, productDependencies]) => {
                  const { Add, Update, Remove } = productDependencies;

                  if (type === 'On' && Add.length) {
                    const selectionsToAdd = Add.reduce(
                      (onAcc: ILocationSelection[], name) => {
                        if (!productNames.includes(name)) {
                          const product = allProducts.find(
                            (p) => p.name === name
                          );

                          const selectedAntenna = mneWifiAntennaSelection(
                            selection,
                            onAcc,
                            product!
                          );

                          if (selectedAntenna) {
                            return onAcc;
                          } else if (product) {
                            const newSelection = generateSelection({
                              productId: product.id,
                            });
                            onAcc.push(newSelection);
                          }
                        }
                        return onAcc;
                      },
                      []
                    );
                    selectionsToAdd.length && acc.push(...selectionsToAdd);
                  }
                  if (type === 'Off' && Remove.length) {
                    const selectionsToRemove = filterProducts(
                      acc,
                      Remove
                    ).filter((p) => isSelectionToBeRemoved(acc, p));

                    selectionsToRemove.forEach((s) => {
                      const index = getIndex({ selection: s, acc });
                      if (canChange(s, index)) {
                        acc.splice(index, 1, { ...s, productId: NONE });
                      }
                    });
                  }
                  if (Update.length) {
                    Update.forEach((productName) => {
                      if (
                        productName === 'Per Phone Shipping Fee' ||
                        productName === 'Per Phone Activation Fee'
                      ) {
                        const dependentSelection = acc.find(
                          (s) =>
                            s.name === productName &&
                            matchGroup(selection) &&
                            s.locationId === selection.locationId
                        );

                        if (dependentSelection) {
                          const currentSelections = filterFamilies(acc, [
                            selection.familyName,
                          ]);

                          const newQuantity = currentSelections.reduce(
                            (acc, product) => {
                              if (
                                product.id !==
                                'a7887fdd-32e6-4a2d-be15-7a2f315683a1'
                              ) {
                                acc += product.quantity;
                              }

                              return acc;
                            },
                            0
                          );

                          const index = getIndex({
                            selection: dependentSelection,
                            acc,
                          });

                          if (dependentSelection.quantity !== newQuantity) {
                            dependentSelection.quantity = newQuantity;
                            const newSelection = generateSelection({
                              productId: dependentSelection.productId,
                              selectionId: dependentSelection.id,
                              selectionValues: dependentSelection,
                            });

                            acc.splice(index, 1, newSelection);
                          }
                        }
                      } else if (
                        (selection.name ===
                          'High Performance Indoor with external antenna' &&
                          productName === 'Indoor Antenna') ||
                        ((selection.name === 'General Performance Outdoor' ||
                          selection.name === 'High Performance Outdoor') &&
                          productName === 'Outdoor Antenna')
                      ) {
                        const dependentSelection = acc.find(
                          (s) =>
                            s.name === productName &&
                            matchGroup(selection) &&
                            s.locationId === selection.locationId
                        );

                        if (dependentSelection) {
                          const currentSelections =
                            productName === 'Indoor Antenna'
                              ? filterProducts(acc, [
                                  'High Performance Indoor with external antenna',
                                ])
                              : filterProducts(acc, [
                                  'General Performance Outdoor',
                                  'High Performance Outdoor',
                                ]);

                          const newQuantity = currentSelections.reduce(
                            (acc, product) => {
                              acc += product.quantity;
                              return acc;
                            },
                            0
                          );

                          const index = getIndex({
                            selection: dependentSelection,
                            acc,
                          });

                          if (dependentSelection.quantity !== newQuantity) {
                            dependentSelection.quantity = newQuantity;
                            const newSelection = generateSelection({
                              productId: dependentSelection.productId,
                              selectionId: dependentSelection.id,
                              selectionValues: dependentSelection,
                            });

                            acc.splice(index, 1, newSelection);
                          }
                        }
                      }
                    });
                  }
                }
              );
            }
          }

          return acc;
        },
        deepCopy(customSpeedSelections)
      );

      // UC Product Check
      const { rateCard } = getSeatsRateCard(productLevelDepsSelections);

      const seatsCheck = productLevelDepsSelections.map((s) => {
        if (s.familyName === 'UC Connect w/ Webex') {
          return { ...s, rateCard };
        }
        return s;
      });

      // Compare previous values to updated values and update focus
      const prevLocationSelections = new Set(
        currentSelections.map((s) => JSON.stringify(s))
      );

      const updatedLocationSelections = seatsCheck.filter(
        (s) => s.locationId === locationId && s.productId !== NONE
      );

      const diff = updatedLocationSelections.filter((s) => {
        if (!currentSelections.length) {
          return false;
        }
        const stringifiedSelection = JSON.stringify(s);
        return !prevLocationSelections.has(stringifiedSelection);
      });

      updateFocus(diff.map((d) => d.familyName));

      // Return fully processed dependency check
      return seatsCheck;
    },
    [
      mneWifiAntennaSelection,
      currentSelections,
      updateFocus,
      locationId,
      matchGroup,
      reduceSelectionsByKeyAndLocation,
      filterFamilies,
      getIndex,
      canChange,
      allProductFamilies,
      generateSelection,
      allProducts,
      filterProducts,
    ]
  );

  return handleDependencyCheck;
};
