// Packages
import React, { useCallback } from 'react';

// Redux

// Components
import { KiteButton } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './YesNoSwitch.scss';

export interface IYesNoSwitchProps {
  handleSelect: (isAccurate: boolean) => void;
  value?: boolean | null;
}

/** Yes/No switch which utilized kite buttons */

const YesNoSwitch = ({ handleSelect, value = null }: IYesNoSwitchProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSwitchClick = useCallback(
    (value: boolean) => () => {
      handleSelect(value);
    },
    [handleSelect]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className={'yes-no-switch'}>
      <KiteButton
        className={`yes-no-switch__yes${
          value ? ' yes-no-switch__yes--active' : ''
        }`}
        disabled={value === true}
        onClick={onSwitchClick(true)}
      >
        Y
      </KiteButton>
      <KiteButton
        className={`yes-no-switch__no${
          value === false ? ' yes-no-switch__no--active' : ''
        }`}
        disabled={value === false}
        onClick={onSwitchClick(false)}
      >
        N
      </KiteButton>
    </div>
  );
};

export default YesNoSwitch;
