// External Packages
import React, { useCallback, useMemo } from 'react';
import { View } from '@react-pdf/renderer';

// Components
import { PdfPageWrapper } from '../PdfComponents';

// Utils
import { generateTextComponents, generateViewComponent } from 'utils';

// Types
import { ILocationSelection, IServiceCodes } from 'types';

// Styles
import { styles } from '../PdfComponents';

type TProductCodes = {
  [key: string]: IServiceCodes[];
};

interface IOfferCodesPdf {
  customerName: string;
  quoteName: string;
  locationName: string;
  locationProducts: ILocationSelection[];
  serviceCodes: IServiceCodes[];
}

export const OfferCodesPdf = ({
  customerName,
  quoteName,
  locationName,
  locationProducts,
  serviceCodes,
}: IOfferCodesPdf) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const columnHeaders = useMemo(() => ['National', 'Regional', 'Quantity'], []);

  const { locationType, sbbProducts } = useMemo(() => {
    const locationType = locationProducts.find(
      (p) => p.familyName === 'Location'
    )?.name;
    const sbbProducts = locationProducts.filter(
      (p) =>
        p.familyName !== 'Location' && p.familyCategory === 'SBB 1-19 Pub/Priv'
    );
    return { locationType, sbbProducts };
  }, [locationProducts]);

  const productCodes = useMemo(() => {
    if (!locationType) return {};

    const codes = serviceCodes.reduce((acc: TProductCodes, code) => {
      if (code.type === 'Both' || locationType.includes(code.type)) {
        sbbProducts.forEach((product) => {
          const productName = product.name.includes('Ultra +')
            ? product.name.replace('Ultra +', 'Deluxe + Sports + Latino +')
            : product.name;

          if (
            product.productId === code.productId ||
            productName.includes(code.name) ||
            code.name.includes(productName)
          ) {
            if (!acc[code.category]) {
              acc[code.category] = [];
            }
            acc[code.category].push(code);
          }
        });
      }

      return acc;
    }, {});

    return codes;
  }, [serviceCodes, sbbProducts, locationType]);

  // =============================================
  // Render Methods
  // =============================================
  const generateTableHeadRow = useCallback(
    (category: string) => {
      return (
        <View style={styles.productRow}>
          <View style={styles.offerCodeCellHeader}>
            {generateTextComponents([category], 'productLabel')}
          </View>
          {columnHeaders.map((head) =>
            generateViewComponent(head, 'tableCellHeader', 'table headers')
          )}
        </View>
      );
    },
    [columnHeaders]
  );

  const generateTables = useCallback(() => {
    return Object.entries(productCodes).map(([category, codes]) => {
      const productRow = codes.map((code, i) => {
        let quantity = '1';
        if (category.includes('Equipment')) {
          const equipment = sbbProducts.find((p) => p.name === 'Equipment');
          quantity = equipment?.quantity.toString() || quantity;
        }
        const codeName =
          locationType?.includes('Mixed') && code.type !== 'Both'
            ? `${code.name} (${code.type})`
            : code.name;

        return (
          <View
            key={`${category} offer codes row ${i}`}
            style={styles.productRow}
          >
            {generateViewComponent(codeName, 'offerCodeCell', codeName)}
            {generateViewComponent(
              code.nationalCode,
              'tableCell',
              `${category} national code`
            )}
            {generateViewComponent(
              code.regionalCode,
              'tableCell',
              `${category} regional code`
            )}
            {generateViewComponent(
              quantity,
              'tableCell',
              `${category} quantity`
            )}
          </View>
        );
      });

      return (
        <View
          key={`${category} table`}
          style={styles.productWrapper}
          wrap={false}
        >
          {generateTableHeadRow(category)}
          {productRow}
        </View>
      );
    });
  }, [productCodes, sbbProducts, locationType, generateTableHeadRow]);

  // =============================================
  // Return
  // =============================================
  return (
    <PdfPageWrapper
      customerName={customerName}
      quoteName={quoteName}
      locationName={locationName}
    >
      {generateTables()}
    </PdfPageWrapper>
  );
};
