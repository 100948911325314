import React from 'react';
import classnames from 'classnames';

// Components
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import { KiteIcon } from '@kite/react-kite';

// Styles
import './NavLink.scss';

export interface INavLinkProps {
  /** The route to be passed to react-router-dom */
  to: string;
  /** The name of the KiteIcon you wish to display to the left of the link text */
  iconName: string;
  /** The link text */
  text: string;
  /** Whether to display the active state of the link */
  active: boolean;
  /** Whether the link is disabled */
  disabled?: boolean;
  /** Whether the navlink page has an error */
  hasError?: boolean;
}

const NavLink = ({
  iconName,
  text,
  active,
  to,
  disabled,
  hasError,
}: INavLinkProps) => {
  const { pathname } = useLocation();
  return (
    <div
      className={classnames({
        'nav-link': true,
        active,
        disabled,
      })}
    >
      <RouterNavLink to={disabled ? pathname : to}>
        <KiteIcon
          name={iconName}
          size="24px"
          color={active ? '#0a0a0a' : '#63748A'}
          margin="0 13px 0 0"
        />
        <span className="nav-link__link-text">{text}</span>
      </RouterNavLink>

      {hasError && (
        <KiteIcon
          name="caution-circle-f"
          className="nav-link__error-icon"
          size="24px"
          margin="0 8px 0 0"
          color="#feb533"
        />
      )}
    </div>
  );
};

export default NavLink;
