// External Packages
import React, { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Components
import { EstimateNavLink } from '..';
import { TermInput } from 'components';

// Utils
import { capitalizeEachWord, NavigationIconMap } from 'utils';

// Hooks
import { useErrorCheck, useQueryData } from 'hooks';

// Types
import { TNavigationCategory } from 'types';

// Styles
import './LeftNav.scss';

// Types
type NavGroups = 'global' | 'network' | 'voice' | 'video';

export interface ILeftNavProps {
  /** Determines if navigation is being used on home page */
  homeView?: boolean;
}

/** Data for nav items, will be rendered into EstimateNavLinks */
interface INavData {
  /** Link destination */
  to: string;
  /** Icon to be used */
  iconName: string;
  /** Text for link */
  text: TNavigationCategory;
  /** Determines if current link is active page */
  active: boolean;
  /** Determines if link should be rendered */
  enabled: boolean;
  /** Determines if icon is Kite font icon */
  isFontIcon?: boolean;
  /** Determines if link has a switch button */
  hasSwitch?: boolean;
  /** Additional nav links to display under link, i.e. UC => products, ... */
  children?: JSX.Element[];
  /** Determine which link is first for styling purposes (borderRadius) */
  isFirst?: boolean;
  /** Determines whether error icon should be displayed */
  hasError?: boolean;
}

/** Nav component for Estimate Builder, with switches to toggle product categories on/off */
const LeftNav = ({ homeView = true }: ILeftNavProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { pathname } = useLocation();
  const { bundleId, estimateId } = useParams();
  const { isPartnerLinkUser, currentSelections, currentScenarioPromos } =
    useQueryData();

  const { checkAllErrors } = useErrorCheck(currentSelections);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const hasPromos = currentScenarioPromos?.length;

  const navErrors = useMemo(() => {
    return checkAllErrors();
  }, [checkAllErrors]);

  const navData = useMemo(() => {
    const getRoute = (page: string) => {
      if (!homeView) {
        return `../bundles/editor/${page}/${bundleId}`;
      }

      return `${estimateId}/${page}`;
    };

    const checkIsActive = (page: string) => pathname.includes(page);

    const data: { [Property in NavGroups]: INavData[] } = {
      global: [
        {
          to: getRoute('promos'),
          iconName: hasPromos
            ? NavigationIconMap.PromosActive
            : NavigationIconMap.Promos,
          text: 'Promos',
          active: checkIsActive('promos'),
          enabled: homeView,
          isFirst: true,
          hasSwitch: false,
        },
        {
          to: getRoute('bundles'),
          iconName: NavigationIconMap.Bundles,
          text: 'Bundles',
          active: checkIsActive('bundles'),
          enabled: homeView,
        },
      ],
      network: [
        {
          to: getRoute('data'),
          iconName: NavigationIconMap.Data,
          text: 'Data',
          active: checkIsActive('data'),
          enabled: true,
          hasSwitch: homeView,
          hasError: navErrors.Data,
        },
        {
          to: getRoute('mne'),
          iconName: NavigationIconMap.MNE,
          text: 'MNE',
          active: checkIsActive('mne'),
          enabled: true,
          hasSwitch: homeView,
          hasError: navErrors.MNE,
        },
        {
          to: getRoute('ene'),
          iconName: NavigationIconMap.ENE,
          text: 'ENE',
          active: checkIsActive('ene'),
          enabled: true,
          hasSwitch: homeView,
        },
      ],
      voice: [
        {
          to: getRoute('uc-addons'),
          iconName: NavigationIconMap['UC Addons'],
          text: 'UC Addons',
          active: checkIsActive('uc-addons'),
          enabled: true,
          hasSwitch: homeView,
        },
        {
          to: getRoute('uc-profiles'),
          iconName: NavigationIconMap['UC Profiles'],
          text: 'UC Profiles',
          active: checkIsActive('uc-profiles'),
          enabled: homeView,
          hasSwitch: homeView,
        },
        {
          to: getRoute('trunks'),
          iconName: NavigationIconMap.Trunks,
          text: 'Trunks',
          active: checkIsActive('trunks'),
          enabled: true,
          hasSwitch: homeView,
        },
      ],
      video: [
        {
          to: getRoute('sbb'),
          iconName: NavigationIconMap['SBB 1-19 Pub/Priv'],
          text: 'SBB 1-19 Pub/Priv',
          active: checkIsActive('sbb'),
          enabled: !isPartnerLinkUser,
          hasSwitch: homeView,
          hasError: navErrors['SBB 1-19 Pub/Priv'],
        },
      ],
    };

    return data;
  }, [
    hasPromos,
    homeView,
    navErrors,
    isPartnerLinkUser,
    estimateId,
    bundleId,
    pathname,
  ]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderNavLinks = useCallback(
    (navData: { [Property in NavGroups]: INavData[] }) => {
      const navLinks = Object.entries(navData).map(([group, links]) => {
        let label: JSX.Element | null = (
          <h3 className="pqe-left-nav__section-title">
            {capitalizeEachWord(group)}
          </h3>
        );

        if (group === 'global' || (group === 'video' && isPartnerLinkUser)) {
          label = null;
        }

        return (
          <div key={group}>
            {label}
            <div className="pqe-left-nav__link-container">
              {links.map((link) =>
                link.enabled ? (
                  <EstimateNavLink key={link.text} {...link} />
                ) : null
              )}
            </div>
          </div>
        );
      });
      return navLinks;
    },
    [isPartnerLinkUser]
  );

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div data-testid="left-nav" className="pqe-left-nav">
      <TermInput />

      <div className="pqe-left-nav__link-wrapper">
        {renderNavLinks(navData)}
      </div>
    </div>
  );
};

export default LeftNav;
