import { useMutation, useQueryClient } from 'react-query';
import { IBundle, ILocationSelection, IUpdateBundle } from 'types';
import { bundleRequest, convertSelectionForApi, queryKeys } from 'utils';

export const useUpdateBundle = (
  onSuccess?: (bundle: IBundle) => void,
  onError?: () => void
) => {
  const queryClient = useQueryClient();
  const allBundlesKey = queryKeys().all.bundles;
  const featuredBundlesKey = queryKeys({ featured: true, published: true })
    .filter.bundles;
  const moreBundlesKey = queryKeys({ featured: false, published: true }).filter
    .bundles;
  const productFamilyKey = queryKeys().all.productFamilies;

  const { mutate: updateBundle, isLoading: updateBundleLoading } = useMutation(
    (updatedBundle: Partial<IBundle | IUpdateBundle>) =>
      bundleRequest({
        endpoint: `bundles/${updatedBundle.id}`,
        method: 'PUT',
        data: {
          ...updatedBundle,
          selections:
            updatedBundle.selections?.map((s) =>
              convertSelectionForApi(s as ILocationSelection)
            ) || [],
        },
      }),
    {
      onMutate: async (updatedBundle: Partial<IBundle | IUpdateBundle>) => {
        await queryClient.cancelQueries(allBundlesKey);
        const prevBundles =
          queryClient.getQueryData<IBundle[]>(allBundlesKey) || [];
        const newBundles = [
          ...prevBundles.filter((b) => b.id !== updatedBundle.id),
          updatedBundle,
        ];
        queryClient.setQueryData(allBundlesKey, newBundles);
        return { prevBundles };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(allBundlesKey);
        queryClient.setQueryData(allBundlesKey, context?.prevBundles);
        if (onError) {
          onError();
        }
      },
      onSuccess: (res: IBundle) => {
        if (onSuccess) {
          onSuccess(res);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(productFamilyKey);
        queryClient.invalidateQueries(allBundlesKey);
        queryClient.invalidateQueries(featuredBundlesKey);
        queryClient.invalidateQueries(moreBundlesKey);
      },
    }
  );

  return { updateBundle, updateBundleLoading };
};
