// Packages
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';

// Redux

// Components
import { EstimateDataForm, EstimateLocationsTable } from 'components';
import { KiteAlert, KiteButton, KiteModal } from '@kite/react-kite';

// Hooks
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useAnalytics,
  useGetEstimateDetails,
  useGetSfInfo,
  useLocalStorage,
  usePostEstimate,
  useQueryData,
  useUpdateEstimate,
  useTrackNavClick,
} from 'hooks';

// Utils
import {
  checkFormErrors,
  createEstimate,
  createEstimateLocations,
  getSfLocations,
  getSfOppById,
  initSalesforceLogin,
  env,
} from 'utils';

// Types
import { AxiosError } from 'axios';
import {
  IEstimateUpdate,
  ISaleforceLocalStorage,
  ISalesforceLocation,
  ISalesforceOpportunity,
  ISelectableLocation,
  TEstimateFields,
  defaultEstimateData,
} from 'types';

// Styles
import './EstimateFormPage.scss';

const EstimateFormPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackNavClick } = useTrackNavClick();
  const { trackSelectAction } = useAnalytics();

  const [tableData, setTableData] = useState<
    ISelectableLocation[] | undefined | null
  >(undefined);
  const [localOppFields, setLocalOppFields] =
    useLocalStorage<TEstimateFields | null>('opFields', null);
  const [sfLocalStorage] = useLocalStorage<ISaleforceLocalStorage | ''>(
    'jid',
    ''
  );
  const [estimateFields, setEstimateFields] = useState(defaultEstimateData);
  const [estimateFieldsErrors, setEstimateFieldsErrors] =
    useState(defaultEstimateData);
  const [locationError, setLocationError] = useState('');

  const { trackPageView } = useAnalytics();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { opportunityId = '', estimateId = '' } = useParams();
  const { userId } = useQueryData();

  const { estimateData } = useGetEstimateDetails(estimateId);
  const { postEstimate, postEstimateLoading } = usePostEstimate();
  const { updateEstimate, updateEstimateLoading } = useUpdateEstimate({
    onEditScenarioSuccess: () => navigate(`/dashboard/${estimateId}`),
  });
  const [isOpenSf, setIsOpenSf] = useState(false);
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const setEstimateFieldsOnSuccess = useCallback(
    (res: ISalesforceOpportunity) => {
      setEstimateFields((prev) => ({
        ...prev,
        estimateName: res.name,
      }));

      if (localOppFields?.opportunityId !== opportunityId) {
        setLocalOppFields({
          opportunityId: res.id,
          estimateName: res.name,
          customerName: '',
        });
      }
    },
    [opportunityId, localOppFields, setEstimateFields, setLocalOppFields]
  );

  const clearStorageOnError = useCallback(() => {
    // set table data to null so that err shows, since locations won't call if getOpp fails
    setTableData(null);
    setLocalOppFields({
      opportunityId,
      estimateName: '',
      customerName: '',
    });
    setLocationError('Something went wrong retrieving opportunity data');
  }, [opportunityId, setLocalOppFields]);

  const onLocationSuccess = useCallback(
    (res: ISalesforceLocation[]) => {
      if (
        res.some(
          (r) =>
            r.serviceLocationRegion && r.serviceLocationRegion !== 'National'
        )
      ) {
        setIsOpenSf(true);
        return;
      }
      const locations = res?.map((location) => {
        let selected = false;
        let disabled = false;
        if (estimateData) {
          const existingLocation = estimateData.locations.find(
            (l) => l.sfLocationId === location.serviceLocationId
          );
          // selected by default if the location exists on the estimate
          selected = !!existingLocation;
          // disabled if the location is configured in a scenario
          disabled = !!estimateData.scenarios.find((s) => {
            return s.locations.find((l) => l.id === existingLocation?.id);
          });
        }
        return {
          ...location,
          selected,
          disabled,
        };
      });

      setTableData(locations);
    },
    [estimateData]
  );

  const onLocationError = useCallback(
    (err: AxiosError) => {
      setTableData(null);
      setLocationError(err.message);
    },
    [setTableData]
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setEstimateFields((prev) => ({
        ...prev,
        [name]: value,
      }));
      trackSelectAction(`Estimate Form: Update ${name}`, {
        opType: 'inputFieldUpdate',
      });
    },
    [setEstimateFields, trackSelectAction]
  );

  const handleErrorCheck = useCallback(() => {
    const { hasErrors, errorObject } = checkFormErrors(estimateFields, [
      'estimateName',
      'customerName',
    ]);
    setEstimateFieldsErrors((prev) => ({ ...prev, ...errorObject }));
    return hasErrors;
  }, [estimateFields]);

  // =============================================
  // Opportunity API Hooks
  // =============================================
  const { data: sfOppData } = useGetSfInfo<ISalesforceOpportunity>({
    opportunityId,
    apiCall: getSfOppById,
    enabled: !!opportunityId,
    onSuccess: setEstimateFieldsOnSuccess,
    onError: clearStorageOnError,
  });

  useGetSfInfo<ISalesforceLocation[]>({
    billingId: sfOppData?.billingAccountId,
    apiCall: getSfLocations,
    enabled: !!sfOppData?.billingAccountId && !tableData,
    onSuccess: onLocationSuccess,
    onError: onLocationError,
  });

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleEstimateSubmit = useCallback(() => {
    const { opportunityId, estimateName, customerName } = estimateFields;
    let analyticsAction = 'Create New Estimate';

    const estimateLocations =
      tableData?.filter((location) => location.selected) || [];

    if (estimateData && sfOppData) {
      // if existing estimate, update locations with selected values
      const locations = createEstimateLocations({
        sfLocations: estimateLocations,
        estimateData,
        userId,
      });
      const updatedEstimate: IEstimateUpdate = {
        ...estimateData,
        name: estimateName,
        customerName,
        status: sfOppData.stageName,
        lastRefreshed: dayjs().toISOString(),
        locations,
        serviceLocationRegion: isOpenSf ? 'Regional' : 'National',
        salesTeam: {
          ...estimateData.salesTeam,
          [sfOppData.ownerPID]: 'Owner',
        },
      };

      analyticsAction = `Estimate Updated, id: ${estimateData.id}`;
      updateEstimate(updatedEstimate);
    } else {
      // if new estimate, create and post
      const estimateObj = createEstimate({
        oppId: opportunityId,
        estimateName,
        customerName,
        oppStatus: sfOppData?.stageName as ISalesforceOpportunity['stageName'],
        userId,
        serviceLocationRegion: isOpenSf ? 'Regional' : 'National',
        ownerPID: sfOppData?.ownerPID as ISalesforceOpportunity['ownerPID'],
        salesforceLocations: estimateLocations,
      });
      postEstimate(estimateObj);
    }
    trackSelectAction(`Estimate Form: ${analyticsAction}`, {
      opType: 'buttonClick',
    });
    setTableData(null);
  }, [
    estimateData,
    userId,
    sfOppData,
    tableData,
    estimateFields,
    postEstimate,
    updateEstimate,
    trackSelectAction,
    isOpenSf,
  ]);

  const onSubmit = useCallback(async () => {
    const { opportunityId, estimateName, customerName } = estimateFields;

    // check for empty fields
    if (handleErrorCheck()) return;

    // check that at least one location is selected
    if (!tableData?.find((location) => location.selected)) {
      return setLocationError(
        'Please select at least one location before submitting.'
      );
    }

    if (!sfLocalStorage) {
      // if there's no token stored in local storage, set field values in local store
      // and then redirect to salesforce for login
      setLocalOppFields({
        opportunityId,
        estimateName,
        customerName,
      });

      await initSalesforceLogin({ state: { location: pathname } });
    } else {
      setLocalOppFields(null);
      handleEstimateSubmit();
    }
  }, [
    tableData,
    estimateFields,
    pathname,
    sfLocalStorage,
    setLocationError,
    setLocalOppFields,
    handleErrorCheck,
    handleEstimateSubmit,
  ]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('EstimateFormPage'), [trackPageView]);

  useEffect(() => {
    setEstimateFields((prev) => {
      if (estimateData) {
        return {
          opportunityId,
          estimateName: estimateData.name,
          customerName: estimateData.customerName,
        };
      }
      return {
        ...prev,
        opportunityId,
      };
    });
  }, [opportunityId, estimateData]);

  useEffect(() => {
    // clear error messages when a field is updated
    setEstimateFieldsErrors(defaultEstimateData);
  }, [estimateFields]);

  // =============================================
  // Return
  // =============================================

  if (isOpenSf)
    return (
      <KiteModal
        modalId="sandBoxModal"
        className="sandbox-modal"
        canShow={isOpenSf}
        showCloseButton={false}
      >
        <KiteAlert
          type="alert"
          description="SPT is not available for Regional Opportunties. Please continue in Salesforce"
        />
        <section>
          <table className="estimate-detail-page__detail-table">
            <tbody>
              <span className="estimate-detail-page__detail-title">
                Salesforce
              </span>
              <tr>
                <td>Opportunity ID:</td>
                <td>
                  {React.createElement(
                    'a',
                    {
                      href: `${env.sfUrl}/${opportunityId}`,
                      target: '_blank',
                      rel: 'noopener noreferrer',
                      onClick: trackNavClick(
                        'Navigate to Salesforce Opportunity'
                      ),
                    },
                    opportunityId
                  )}
                </td>
                <div className="sptdashboard">
                  <td>SPT Dashboard:</td>
                  <td>
                    {React.createElement(
                      'a',
                      {
                        href: '/dashbaord',
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        onClick: trackNavClick(
                          'Navigate to Salesforce Opportunity'
                        ),
                      },
                      'Dashboard'
                    )}
                  </td>
                </div>
              </tr>
            </tbody>
          </table>
        </section>
      </KiteModal>
    );

  return (
    <div className="estimate-form-page">
      <div className="estimate-form-page__header">
        <h1>
          {estimateData ? 'Update Existing Estimate' : 'Create a New Estimate'}
        </h1>
      </div>
      <form className="estimate-form-page__contents">
        <EstimateDataForm
          estimateData={estimateFields}
          errorMessages={estimateFieldsErrors}
          handleChange={handleInputChange}
        />
        <EstimateLocationsTable
          locationError={locationError}
          setLocationError={setLocationError}
          tableData={tableData}
          setTableData={setTableData}
        />
      </form>
      <div className="estimate-form-page__submit">
        <KiteButton
          className="estimate-form-page__submit-button"
          size="xl"
          loading={postEstimateLoading || updateEstimateLoading}
          onClick={onSubmit}
          disabled={postEstimateLoading || !tableData}
        >
          {estimateData ? 'Update Estimate' : 'Create New Estimate'}
        </KiteButton>
      </div>
    </div>
  );
};

export default EstimateFormPage;
