import { IEnv } from './env';

export const getReportsUsers = (env: IEnv): string => {
  const envUrlMap = {
    test: 'test',
    develop: process.env.REACT_APP_REPORTS_USERS_DEV,
    local: process.env.REACT_APP_REPORTS_USERS_DEV,
    stage: process.env.REACT_APP_REPORTS_USERS_STAGE,
    prod: process.env.REACT_APP_REPORTS_USERS_PROD,
  };

  return envUrlMap[env] || '';
};
