import { IRequestConfig, IProfileCategory } from 'types';
import { profileCategoriesRequest, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetProfileCategories = (
  requestConfig: IRequestConfig<IProfileCategory> = {}
) => {
  return useGenerateQuery<IProfileCategory[]>({
    queryKey: queryKeys().all.profileCategories,
    apiCall: profileCategoriesRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
