// Packages
import React, { useCallback, useEffect, useRef } from 'react';

// Redux

// Components
import { EipInput, QuantityCard } from 'components';

// Hooks
import { useAnalytics, useQueryDataContext, useScrollToTop } from 'hooks';

// Utils

// Types

// Styles
import './UcAddonsPage.scss';
import { professionalServicesId } from 'utils';

const UcAddonsPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { allProductFamilies } = useQueryDataContext();
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1 });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const generateComponents = useCallback(() => {
    const ucProductFamilies = allProductFamilies.filter(
      (f) => f.category === 'UC Products'
    );

    return ucProductFamilies.map((family) => {
      return (
        <div>
          <QuantityCard
            key={`${family.name} quantity card`}
            productFamily={family}
            expandable={true}
          />
          {family.id === professionalServicesId && (
            <>
              <div>
                <EipInput type="UC" />
              </div>
            </>
          )}
        </div>
      );
    });
  }, [allProductFamilies]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('UcAddonsPage'), [trackPageView]);

  // =============================================
  // Early Return
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} className="uc-addons-page">
      <h3>UC Addons Configuration Options</h3>
      {generateComponents()}
    </div>
  );
};

export default UcAddonsPage;
