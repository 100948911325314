// Packages
import React, { MutableRefObject } from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks

// Utils

// Types
import { ISmeAnswer } from 'types';

// Styles
import './SmeApprovalBadge.scss';

export interface ISmeApprovalBadgeProps {
  approvalNeeded: boolean;
  smeInputAnswers?: MutableRefObject<ISmeAnswer[] | null>;
}

/** SME approval badge updates display dependng of approvalNeeded input */

const SmeApprovalBadge = ({
  approvalNeeded,
  smeInputAnswers,
}: ISmeApprovalBadgeProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================

  const smeStatus = smeInputAnswers?.current?.find((smeInput) =>
    smeInput.inputName.includes('SME approval')
  )?.answer;
  const omniStatus = smeInputAnswers?.current?.find((smeInput) =>
    smeInput.inputName.includes('Omni Channel or Hoteling')
  )?.answer;
  const smeSelection = (smeStatus: string | undefined) => {
    switch (smeStatus) {
      case 'Not Required':
        approvalNeeded = false;
        return 'No SME approval needed';
      case 'Approved':
        approvalNeeded = false;
        return 'SME approved';
      case 'In Progress':
        approvalNeeded = true;
        return 'SME approval in progress';
      case 'Denied':
        approvalNeeded = true;
        return 'SME not approved';
    }
  };

  const generateReminder = () => {
    if (omniStatus) {
      if (smeStatus) {
        return smeSelection(smeStatus);
      } else {
        switch (omniStatus) {
          case 'Yes':
            approvalNeeded = true;
            return 'SME approval needed';
          case 'No':
            approvalNeeded = false;
            return 'No SME approval needed';
        }
      }
    } else {
      if (smeStatus) {
        return smeSelection(smeStatus);
      } else {
        approvalNeeded = false;
        return 'No SME approval needed';
      }
    }
  };

  const reminder = generateReminder();

  return (
    <div
      className={classNames({
        'sme-approval-badge': true,
        'sme-approval-badge--approval-needed': approvalNeeded,
        'sme-approval-badge--no-approval-needed': !approvalNeeded,
      })}
    >
      <KiteIcon
        name={approvalNeeded ? 'caution-alert' : 'like-f'}
        margin="0 11px 0 0"
      />
      {approvalNeeded
        ? reminder
        : reminder === 'SME approved'
        ? reminder
        : 'No SME approval needed'}
    </div>
  );
};

export default SmeApprovalBadge;
