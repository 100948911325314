import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from './store';

export const partnerLinkUserSlice = createSlice<
  boolean,
  SliceCaseReducers<boolean>
>({
  name: 'partnerLinkUser',
  initialState: false,
  reducers: {
    updatePartnerLinkUser: (_state, action) => {
      return action.payload;
    },
  },
});

export const { updatePartnerLinkUser } = partnerLinkUserSlice.actions;

export const partnerLinkUser = (state: RootState): boolean =>
  state.partnerLinkUser;

export default partnerLinkUserSlice.reducer;
