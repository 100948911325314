// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteInput } from '@kite/react-kite';

// Hooks
import { useParams } from 'react-router-dom';

// Utils
import { isValidJsonString } from 'utils';

// Types
import { IUCQInputProps } from 'types';

// Styles
import '../UCQFieldStyles.scss';

interface ITimeValue {
  start: string;
  end: string;
}

export interface IUCQDateInputProps extends IUCQInputProps {
  dateType: 'date' | 'time';
}
/** Custom input for 'date' inputType on UCQ Field */
const UCQDate = ({
  fieldInput,
  onFieldChange,
  dateType,
}: IUCQDateInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { id, inputName, value } = fieldInput;
  const [date, setDate] = useState(value || '');

  const { locationId } = useParams();

  const blankTimeRange = useMemo(() => ({ start: '', end: '' }), []);

  const timeValue: ITimeValue = useMemo(() => {
    if (dateType === 'time' && value && isValidJsonString(value)) {
      return JSON.parse(value);
    }
    return blankTimeRange;
  }, [blankTimeRange, dateType, value]);

  const [time, setTime] = useState(timeValue);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;
      if (dateType === 'date') {
        setDate(value);
        onFieldChange(id, value);
      } else {
        const newTime = { ...time, [name]: value };
        setTime(newTime);
        if (newTime.start && newTime.end) {
          onFieldChange(id, JSON.stringify(newTime));
        } else if (!newTime.start && !newTime.end) {
          onFieldChange(id, null);
        }
      }
    },
    [dateType, id, onFieldChange, time]
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderDate = useCallback(
    () => (
      <KiteInput
        name={inputName}
        id={id}
        maxWidth="none"
        value={date}
        onChange={onChange}
        inputProps={{ type: dateType }}
      />
    ),
    [date, dateType, id, inputName, onChange]
  );

  const renderTime = useCallback(
    () => (
      <div className="ucq-field__time-range">
        <span className="ucq-field__subtext">From</span>
        <KiteInput
          name="start"
          id={`start-${id}`}
          maxWidth="none"
          value={time.start}
          onChange={onChange}
          inputProps={{ type: dateType }}
        />
        <span className="ucq-field__subtext">To</span>
        <KiteInput
          name="end"
          id={`end-${id}`}
          maxWidth="none"
          value={time.end}
          onChange={onChange}
          inputProps={{ type: dateType }}
        />
      </div>
    ),
    [dateType, id, onChange, time.end, time.start]
  );

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (dateType === 'date' && date !== value) {
      setDate(value || '');
    }
  }, [date, dateType, value]);

  useEffect(() => {
    setTime(timeValue);
  }, [locationId, timeValue]);

  // =============================================
  // Return
  // =============================================
  return dateType === 'date' ? renderDate() : renderTime();
};

export default UCQDate;
