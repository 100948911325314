// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import dayjs from 'dayjs';

// Components
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { KiteButton, KiteLoader } from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';
import { PageInputs, AutobuildPill, SandboxModal } from 'components';

// Utils
import { isEstimateAutobuild, env, queryKeys, sandboxData } from 'utils';

// Hooks
import {
  useAnalytics,
  useLocalStorage,
  useGetEstimates,
  usePagination,
  useGetEstimateBySfId,
  useQueryData,
  useTrackNavClick,
} from 'hooks';
import { useQueryClient } from 'react-query';

// Types
import { IFilterButtonProps, IEstimate, TFilterValue } from 'types';

// Styles
import './EstimateDashboard.scss';

const EstimateDashboard = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [query, setQuery] = useState(''); // query is search input value
  const [searchTerm, setSearchTerm] = useState(''); // search term is debounced query value sent to api call
  const [filterValue, setFilterValue] = useState<TFilterValue>();
  const [sandboxModalIsOpen, setSandboxModalIsOpen] = useState(false);
  const [dataLength, setDataLength] = useState(0);

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { trackPageView, trackSelectAction } = useAnalytics();
  const { trackNavClick } = useTrackNavClick();
  const [isIgnoreSandbox, setIsIgnoreSandbox] = useLocalStorage(
    'ignoreSandboxWarning',
    false
  );

  const {
    updateLocationId,
    updateScenarioId,
    scenarioId,
    scenarioTabLocationIds,
    userId,
  } = useQueryData();

  const pageLimit = 10;
  const { paginationComponent, paginateData } = usePagination(
    pageLimit,
    dataLength
  );
  const debounceSearchTerm = useDebouncedCallback(
    (searchValue: string) => setSearchTerm(searchValue),
    1200
  );

  // API Calls
  // Used for storing total / all estimates
  const { data: allEstimatesData, isLoading: allEstimatesLoading } =
    useGetEstimates({ params: { archived: false } });

  // Used for filtering by searchTerm
  const { data: searchedEstimates, isLoading: searchLoading } = useGetEstimates(
    {
      params: {
        searchTerm,
        archived: false,
      },
    }
  );

  //sets display access for reports page

  const allowUser = env.reportsUsers;

  /**
   *  If filter === 'Unqualified Opportunity', return estimates that match the filterValue
   *  If filter === 'Qualified Opportunity', return estimates that do _not_ match **status: 'Unqualified Opportunity'**
   *  *All estimates that are not ***status: 'Unqualified Opportunity'*** fall under the umbrella of being "Qualified"* */
  const filterEstimates = useCallback(
    (estimates?: IEstimate[], filter?: TFilterValue) => {
      if (!estimates) {
        return [];
      }

      if (filter === 'Unqualified Opportunity') {
        return estimates.filter((e) => e.status === filter);
      }

      if (filter === 'Qualified Opportunity') {
        return estimates.filter((e) => e.status !== 'Unqualified Opportunity');
      }

      return estimates;
    },
    []
  );

  // Final estimates to display on page
  // Paginated based on limit + currentPage + searchTerm + filterValue
  const estimatesToDisplay = useMemo(
    () => paginateData(filterEstimates(searchedEstimates, filterValue)),
    [filterEstimates, filterValue, paginateData, searchedEstimates]
  );

  // Get opportunity id from search param and check if an estimate exists with that oppId
  const opportunityId = useMemo(
    () => search.split('?opportunity_id=')[1],
    [search]
  );

  const { data: estimateByOppId, isLoading: estimateByOppIdLoading } =
    useGetEstimateBySfId({
      params: { opportunityId },
    });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const onFilter = useCallback(
    (newFilter: TFilterValue) => {
      if (newFilter && filterValue === newFilter) {
        // remove filter if click again while it is applied
        setFilterValue(undefined);
      } else {
        setFilterValue(newFilter);
      }

      const analyticName = filterValue || 'All Estimates';
      trackSelectAction(`Dashboard Filter: ${analyticName}`, {
        opType: 'filterApplied',
      });
    },
    [filterValue, trackSelectAction]
  );

  const linkState = useMemo(
    () => ({
      fromDomain: pathname,
    }),
    [pathname]
  );

  const filterOptions: IFilterButtonProps[] = useMemo(() => {
    if (!allEstimatesData) {
      return [];
    }

    return [
      {
        onFilter,
        filterValue: undefined,
        text: 'Total Estimates',
        total: filterEstimates(allEstimatesData).length,
        isActive: !filterValue,
      },
      {
        onFilter,
        filterValue: 'Unqualified Opportunity',
        text: 'Unqualified',
        total: filterEstimates(allEstimatesData, 'Unqualified Opportunity')
          .length,
        isActive: filterValue === 'Unqualified Opportunity',
      },
      {
        onFilter,
        filterValue: 'Qualified Opportunity',
        text: 'Qualified',
        total: filterEstimates(allEstimatesData, 'Qualified Opportunity')
          .length,
        isActive: filterValue === 'Qualified Opportunity',
      },
    ];
  }, [allEstimatesData, onFilter, filterEstimates, filterValue]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSearch = useCallback(
    (value: string) => {
      // Query is value for input
      setQuery(value);

      // If no value immediately set searchTerm
      if (!value) {
        debounceSearchTerm.cancel();
        setSearchTerm(value);
        return;
      }

      // If value, debounce search term to reduce api calls
      debounceSearchTerm(value);

      trackSelectAction('Dashboard Search', { opType: 'inputFieldUpdate' });
    },
    [debounceSearchTerm, trackSelectAction]
  );

  const onSubmit = useCallback(
    (value: string) => {
      // Immediately submit searchTerm if user hits enter
      debounceSearchTerm.cancel();
      setSearchTerm(value);
    },
    [debounceSearchTerm]
  );

  const handleModalToggle = useCallback(
    (toggleValue: boolean) => () => setSandboxModalIsOpen(toggleValue),
    []
  );

  const handleSandboxNav = useCallback(() => {
    if (!isIgnoreSandbox) {
      handleModalToggle(true)();
    } else {
      updateLocationId(sandboxData.estimate.locations[0].id);
      updateScenarioId(sandboxData.estimate.scenarios[0].id);
      navigate('/estimate-builder/sandbox');
    }

    trackSelectAction('Open Sandbox', { opType: 'navigationClick' });
  }, [
    handleModalToggle,
    isIgnoreSandbox,
    navigate,
    updateLocationId,
    updateScenarioId,
    trackSelectAction,
  ]);

  const handleSandboxSubmit = useCallback(
    (ignoreValue: boolean) => {
      setIsIgnoreSandbox(ignoreValue);
      setSandboxModalIsOpen(false);
      updateLocationId(sandboxData.estimate.locations[0].id);
      updateScenarioId(sandboxData.estimate.scenarios[0].id);
      navigate('/estimate-builder/sandbox');
    },
    [setIsIgnoreSandbox, updateLocationId, updateScenarioId, navigate]
  );
  const handleReportsSubmit = useCallback(() => {
    navigate('/reports');
  }, [navigate]);
  /** Since redux scenarioId/locationId is reset when visiting dashboard, get any data that's
   *  already been fetched for estimate being selected and update the scenarioId/locationId if it exists */
  const handleEstimateBuilderNavClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const estimateId = e.currentTarget.href.split('/').at(-1);
      const queryKey = queryKeys({ estimateId }).details.estimate;
      const estimateDetails = queryClient.getQueryData<IEstimate>(queryKey);
      if (estimateDetails && estimateDetails.scenarios.length && !scenarioId) {
        const newScenarioId = estimateDetails.scenarios[0].id;
        const newLocationId = scenarioTabLocationIds[newScenarioId];
        updateScenarioId(estimateDetails.scenarios[0].id);
        newLocationId && updateLocationId(newLocationId);
      }
      trackNavClick('Navigate to Estimate Builder')();
    },
    [
      queryClient,
      scenarioId,
      scenarioTabLocationIds,
      trackNavClick,
      updateLocationId,
      updateScenarioId,
    ]
  );

  // =============================================
  // Render Methods
  // =============================================
  const generateTableData = useCallback(
    (estimates: IEstimate[]) => {
      interface IEstTableData {
        estimate: [sortableName: string, name: string, id: string];
        customer: string;
        ucQuestions: [id: string];
        estimateBuilder: string;
        status: IEstimate['status'];
        opptyId: string;
        lastRefreshed: string | undefined;
      }

      const tableData: IEstTableData[] = estimates.map((est) => {
        const sortableName = est.name.toLowerCase();
        return {
          estimate: [sortableName, est.name, est.id],
          customer: est.customerName,
          ucQuestions: [est.id],
          estimateBuilder: `/estimate-builder/${est.id}`,
          status: est.status,
          opptyId: est.sfOpportunityId,
          lastRefreshed: est.lastRefreshed,
        };
      });

      const columns = [
        {
          label: 'Estimate Name',
          sortKey: 'estimate',
          size: 2,
          render: (item: IEstTableData) => {
            /*The sorting function used by the SortableTable wasn't recognizing the estimate name as a string (since we're passing it an array of strings), and so wasn't sorting correctly.
            To fix this, we added a sortableName to index 0 of the array, but didn't want to use that sortable name in the display to our users.
            Using array destructuring, we skip the first index with a comma.
          */
            const [, name, id] = item.estimate;
            return (
              <NavLink
                className="estimate-dashboard__name-link"
                to={`/dashboard/${id}`}
                state={linkState}
                onClick={trackNavClick('Navigate to Estimate Details')}
              >
                {name}
                <AutobuildPill
                  isAutobuild={isEstimateAutobuild(
                    allEstimatesData?.find((e) => e.id === id)
                  )}
                  iconOnly
                />
              </NavLink>
            );
          },
        },
        {
          label: 'Customer',
          sortKey: 'customer',
        },
        {
          label: 'UC Questions',
          sortKey: 'ucQuestions',
          render: (item: IEstTableData) => {
            const [estId] = item.ucQuestions;
            return (
              <div className="estimate-dashboard__uc-table-column">
                <NavLink
                  to={`/uc-questions/${estId}/general/general-discovery`}
                  state={linkState}
                  onClick={trackNavClick('Navigate to UC Questions')}
                >
                  <span>View UC Questions</span>
                </NavLink>
              </div>
            );
          },
        },
        {
          label: 'Estimate Builder',
          sortKey: 'estimateBuilder',
          sortEnabled: false,
          render: (item: IEstTableData) => (
            <NavLink
              to={item.estimateBuilder}
              state={linkState}
              onClick={handleEstimateBuilderNavClick}
            >
              Open Estimate Builder
            </NavLink>
          ),
        },
        {
          label: 'Oppty ID',
          sortKey: 'opptyId',
          render: (item: IEstTableData) => (
            <a
              href={`${env.sfUrl}/${item.opptyId}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackNavClick('Navigate to Salesforce Opportunity')}
            >
              {item.opptyId}
            </a>
          ),
        },
        {
          label: 'Status',
          sortKey: 'status',
          render: (item: IEstTableData) =>
            item.status === 'Unqualified Opportunity'
              ? 'Unqualified'
              : 'Qualified',
        },
        {
          label: 'Last Refreshed',
          sortKey: 'lastRefreshed',
          render: (item: IEstTableData) =>
            item.lastRefreshed
              ? dayjs(item.lastRefreshed).format('MMM D, YYYY h:mm A')
              : 'N/A',
        },
      ];
      return { tableData, columns };
    },
    [allEstimatesData, handleEstimateBuilderNavClick, linkState, trackNavClick]
  );

  const renderTable = useCallback(() => {
    if (searchLoading) {
      return <KiteLoader />;
    }

    if (!estimatesToDisplay?.length) {
      return <p>Sorry, there are no results matching your query</p>;
    }

    const { tableData, columns } = generateTableData(estimatesToDisplay);

    return (
      <div className="estimate-dashboard__table">
        <h2>Estimates</h2>
        <SortableTable tableData={tableData} columns={columns} stripedRows />
        {paginationComponent}
      </div>
    );
  }, [
    estimatesToDisplay,
    generateTableData,
    paginationComponent,
    searchLoading,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('EstimateDashboard'), [trackPageView]);

  useEffect(() => {
    if (!searchTerm && !filterValue) {
      setDataLength(filterEstimates(allEstimatesData).length);
    } else {
      setDataLength(filterEstimates(searchedEstimates, filterValue).length);
    }
  }, [
    allEstimatesData,
    filterEstimates,
    filterValue,
    searchTerm,
    searchedEstimates,
  ]);

  useEffect(() => {
    if (estimateByOppId) {
      navigate(`/dashboard/${estimateByOppId.id}${search}`);
    } else if (!estimateByOppIdLoading && opportunityId) {
      navigate(`/dashboard/edit-estimate/${opportunityId}`);
    }
  }, [
    estimateByOppId,
    opportunityId,
    estimateByOppIdLoading,
    search,
    navigate,
  ]);

  useEffect(() => {
    // Reset redux location/scenario id on dashboard mount
    // This is to prevent any errors for incorrect ids when switching between estimates or sandbox mode
    updateLocationId('');
    updateScenarioId('');
  }, [updateLocationId, updateScenarioId]);

  // =============================================
  // Early Return
  // =============================================
  if (allEstimatesLoading || estimateByOppIdLoading) {
    return <KiteLoader />;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <>
      <main className="estimate-dashboard">
        <header>
          <h1>Dashboard</h1>
          <KiteButton onClick={handleSandboxNav} type="outline">
            Open Sandbox
          </KiteButton>
          {allowUser.includes(userId) && (
            <KiteButton onClick={handleReportsSubmit} type="outline">
              Reports
            </KiteButton>
          )}
        </header>
        <PageInputs
          search={{ query, onSearch, onSubmit, placeholder: 'Search' }}
          filters={filterOptions}
        />
        {renderTable()}
      </main>
      {!isIgnoreSandbox && (
        <SandboxModal
          isOpen={sandboxModalIsOpen}
          onClose={handleModalToggle(false)}
          onSubmit={handleSandboxSubmit}
        />
      )}
    </>
  );
};

export default EstimateDashboard;
