// External Packages
import React, { useMemo } from 'react';

// Components
import { View, Text } from '@react-pdf/renderer';
import {
  PdfLocationTableHead,
  PdfPageWrapper,
  PdfTotalsTable,
  PdfLocationRows,
} from '../PdfComponents';

// Utils
import { generateOverviewDisclaimers } from 'utils';

// Types
import {
  TTermLengthMonths,
  TEipTermLengthMonths,
  TableValues,
  IScenario,
  IEstimate,
  IGenerateProductTotals,
  IPromo,
} from 'types';

// Styles
import { styles } from '../PdfComponents';

interface ILocationSummaryPdf {
  quoteName: string;
  customerName: string;
  termMonth: TTermLengthMonths;
  eipTermMonth: TEipTermLengthMonths;
  currentEstimate?: IEstimate;
  currentScenario?: IScenario;
  allPromos: IPromo[];

  generateProductTotals: ({
    selections,
    locationId,
    category,
    subCategory,
    format,
  }: IGenerateProductTotals) => TableValues<number | string>;
}

export const LocationSummaryPdf = ({
  quoteName,
  customerName,
  termMonth,
  eipTermMonth,
  currentEstimate,
  currentScenario,
  generateProductTotals,
  allPromos,
}: ILocationSummaryPdf) => {
  const includeRcPhonesDevices = useMemo(() => {
    return (
      currentScenario?.selections?.some(
        (s) => s.familyName === 'RC Phones / Devices'
      ) || false
    );
  }, [currentScenario]);
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const overViewDisclaimers = useMemo(() => {
    const locationSelections = currentScenario?.selections || [];
    return generateOverviewDisclaimers({
      locationSelections,
      scenarioPromos: currentScenario?.promos || [],
      promos: allPromos,
    });
  }, [currentScenario, allPromos]);

  const scenarioLocations = useMemo(() => {
    const scenarioLocationIds =
      currentScenario?.locations.map((l) => l.id) || [];
    return (
      currentEstimate?.locations.filter((l) =>
        scenarioLocationIds.includes(l.id)
      ) || []
    );
  }, [currentEstimate, currentScenario]);
  const eipTermsByLocation = useMemo(() => {
    return scenarioLocations.reduce((acc, location) => {
      // Find the selection that corresponds to this location
      const selectionForLocation = currentScenario?.selections.find(
        (selection) => selection.locationId === location.id && selection.eipTerm
      );

      // Only assign an EIP term if it's explicitly set in the selection
      if (selectionForLocation && selectionForLocation.eipTerm) {
        acc[location.name] = selectionForLocation.eipTerm;
      }

      return acc;
    }, {} as { [locationName: string]: TEipTermLengthMonths | undefined });
  }, [scenarioLocations, currentScenario]);

  const summaryText = useMemo(() => {
    const locations = currentScenario?.locations || [];
    const locationText = locations.length > 1 ? 'Locations' : 'Location';

    return `Summary: ${locations.length} ${locationText}`;
  }, [currentScenario]);

  const scenarioTotals = useMemo(() => {
    return generateProductTotals({
      term: currentScenario?.term,
      eipTerm: currentScenario?.eipTerm,
    }) as TableValues<string>;
  }, [currentScenario, generateProductTotals]);

  const promoIdsByLocationName = useMemo(() => {
    const scenarioPromos = currentScenario?.promos || [];
    const locationIds = currentScenario?.locations.map((l) => l.id) || [];
    const locations =
      currentEstimate?.locations.filter((l) => locationIds.includes(l.id)) ||
      [];

    const locationSpecificPromoIds = scenarioPromos.reduce(
      (acc: string[], sp) => {
        const promo = allPromos.find((p) => sp.promoId === p.id);
        if (promo && promo.isLocationSpecific) {
          acc.push(promo.id);
        }
        return acc;
      },
      []
    );

    const promoLocations = locations.reduce(
      (acc: { [locationName: string]: string[] }, location) => {
        acc[location.name] = [];
        scenarioPromos.forEach((sp) => {
          if (
            locationSpecificPromoIds.includes(sp.promoId) &&
            sp.locationId === location.id
          ) {
            acc[location.name].push(sp.promoId);
          } else if (!locationSpecificPromoIds.includes(sp.promoId)) {
            acc[location.name].push(sp.promoId);
          }
        });
        return acc;
      },
      {}
    );

    return promoLocations;
  }, [currentScenario, currentEstimate, allPromos]);

  // =============================================
  // Return
  // =============================================
  return (
    <PdfPageWrapper customerName={customerName} quoteName={quoteName}>
      <View style={styles.overviewSummaryBoldWrapper}>
        <Text style={styles.overviewSummaryBold}>{summaryText}</Text>
      </View>

      <View style={styles.productWrapper} wrap={false}>
        <PdfLocationTableHead
          headerText="Locations"
          termMonth={termMonth}
          eipTermMonth={eipTermMonth}
          includeRcPhonesDevices={includeRcPhonesDevices}
        />
        <PdfLocationRows
          estimateLocations={scenarioLocations}
          currentTerm={currentScenario?.term || '12'}
          generateProductTotals={generateProductTotals}
          promoIdsByLocationName={promoIdsByLocationName}
        />
      </View>

      <PdfTotalsTable tableTotals={scenarioTotals} />

      {overViewDisclaimers}
    </PdfPageWrapper>
  );
};
