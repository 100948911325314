export const paramsSerializer = (params: any) => {
  const paramArr = Object.entries(params).reduce(
    (acc: string[], [key, value]) => {
      if (typeof value === 'string') {
        value = value.replace(' ', '%20');
      }
      if (value !== undefined) {
        acc.push(`${key}=${value}`);
      }
      return acc;
    },
    []
  );

  return paramArr.join('&');
};
