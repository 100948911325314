// External Packages
import React from 'react';
import { FeedbackForm } from '@kite/react-kite-plus';

// Hooks
import { useAnalytics } from 'hooks';

// Styles
import './Feedback.scss';

interface IFeedbackProps {
  isOpen: boolean;
  onHide: () => void;
}

const Feedback = ({ isOpen, onHide }: IFeedbackProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { analytics } = useAnalytics();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const categories = [
    { label: 'Give Feedback', value: 'give-feedback' },
    { label: 'Suggest an Improvement', value: 'suggest-improvement' },
    {
      label: 'Report an Issue: Error',
      value: 'issue-error',
    },
    { label: 'Report an Issue: Navigation', value: 'issue-navigation' },
    { label: 'Report an Issue: Pricing', value: 'issue-pricing' },
    { label: 'Other', value: 'other' },
  ];

  // =============================================
  // Return
  // =============================================
  return (
    <div className="feedback">
      <FeedbackForm
        categories={categories}
        categoryRequired={false}
        interactionType="prompted"
        displayModal={isOpen}
        onHide={onHide}
        onSubmit={() => console.log('Feedback sent to quantum')}
        ratingType="satisfaction"
        selectLabel="What is your review based on? (optional)"
        websiteName="Solution Pricing Tool"
        analytics={analytics}
      />
    </div>
  );
};

export default Feedback;
