import { ILocationSelection, TUcProductRateCards } from 'types';
import { ucSeatsProductFamilies } from 'utils/defaultsAndConstants';

export const getSeatsRateCard = (currentProducts: ILocationSelection[]) => {
  const seats = currentProducts.reduce((seats, product) => {
    if (ucSeatsProductFamilies.includes(product.familyName)) {
      seats += product.quantity;
    }
    return seats;
  }, 0);

  let rateCard: TUcProductRateCards = '1-10 Seats';

  if (seats > 49) {
    rateCard = '50-99 Seats';
  } else if (seats > 10) {
    rateCard = '11-49 Seats';
  }

  return { seats, rateCard };
};
