// Packages
import React, { useCallback, useRef, useState } from 'react';

// Redux

// Components
import { KiteCard } from '@kite/react-kite';
import { ExpandButton, AutobuildPill } from 'components';

// Utils
import { capitalizeEachWord, convertCamelCase } from 'utils';

// Hooks

// Types
import { ILocation } from 'types';

// Styles
import './EstimateLocationCard.scss';

export interface IEstimateLocationCardProps {
  className?: string;
  location: ILocation;
  /** Location Salesforce details */
  sfDetails?: { [key: string]: string };
}

/** A card displaying location info with rate center data. Edit actions won't be needed until phase 2 but basic structure is set up to handle it. */

const EstimateLocationCard = ({
  className = '',
  sfDetails = {},
  location,
}: IEstimateLocationCardProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const [isOpen, setIsOpen] = useState(false);

  const listRef = useRef<HTMLUListElement>(null);
  const listHeight = listRef?.current?.clientHeight || 0;

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const contentHeight = isOpen ? listHeight : 0;

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  // =============================================
  // Render Methods
  // =============================================
  const renderHeader = useCallback(() => {
    const { name, address1, address2, city, state, zipcode } = location;
    const csz = [city, state, zipcode].filter(Boolean).join(', ');

    const locationContent = (
      <div className="pqe-location-card__header-address">
        {name && <h1>{name}</h1>}
        {address1 && <span>{address1}</span>}
        {address2 && <span>{address2}</span>}
        {!!csz.length && <span>{csz}</span>}
      </div>
    );
    return (
      <div className="pqe-location-card__header">
        {locationContent}
        <AutobuildPill isAutobuild={location.isAutobuild} />
      </div>
    );
  }, [location]);

  const renderSfDetails = useCallback(() => {
    const data = Object.entries(sfDetails);
    if (!data.length) return null;

    return (
      <ul ref={listRef}>
        {data.map(([key, value]) => (
          <li key={key}>
            <span>{convertCamelCase(key)}:</span>
            <span className="pqe-location-card__content-value">
              {capitalizeEachWord(value)}
            </span>
          </li>
        ))}
      </ul>
    );
  }, [sfDetails, listRef]);

  // =============================================
  // Early Return
  // =============================================
  if (!location) {
    return null;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className={`pqe-location-card ${className}`}>
      {renderHeader()}

      <div
        className="pqe-location-card__content"
        style={{ height: contentHeight }}
      >
        {renderSfDetails()}
      </div>

      {false && (
        // temporary hide until expansion info is populated
        <ExpandButton
          isOpen={isOpen}
          onClick={toggleOpen}
          ariaLabel="expand location card"
        />
      )}
    </KiteCard>
  );
};

export default EstimateLocationCard;
