// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteSelect, KiteInput } from '@kite/react-kite';
import {
  LocationAddressInput,
  RadioToggle,
  FieldValidationHeader,
} from 'components';

// Hooks
import { useAnalytics, useQueryData, useUpdateLocation } from 'hooks';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce/lib';

// Utils
import { stateIsoCodes, blankAddress, addressInputDetails } from 'utils';

// Types
import { IDetailedAddress } from 'types';

// Styles
import './ShipToForm.scss';

/** Renders "Ship Equipment To" form for Field Validation tab with google places search and custom address inputs */

const ShipToForm = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const { currentLocation } = useQueryData();
  const { estimateId } = useParams();
  const shipToValue = useMemo(
    () => currentLocation?.shipTo || blankAddress,
    [currentLocation]
  );
  const [shippingAddress, setShippingAddress] = useState({
    locationId: currentLocation?.id,
    shipTo: shipToValue,
  });
  const [isCustomAddress, setIsCustomAddress] = useState('No');
  const { updateLocation } = useUpdateLocation(estimateId);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const saveShipping = useCallback(
    (shipTo: IDetailedAddress) => {
      if (!currentLocation) {
        return;
      }

      if (currentLocation.id === shippingAddress.locationId) {
        updateLocation({
          ...currentLocation,
          shipTo: { ...shipToValue, ...shipTo },
        });
        trackSelectAction(
          `Ship To Form Updated, LocationId: ${currentLocation.id}`
        );
      }
    },
    [
      currentLocation,
      shipToValue,
      shippingAddress.locationId,
      trackSelectAction,
      updateLocation,
    ]
  );

  const debounceSaveShipping = useDebouncedCallback(saveShipping, 500);

  const handleCustomAddressChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newShipTo = {
        ...shippingAddress.shipTo,
        [e.target.name as keyof IDetailedAddress]: e.target.value,
      };
      setShippingAddress({
        locationId: shippingAddress.locationId,
        shipTo: newShipTo,
      });
      debounceSaveShipping(newShipTo);
    },
    [debounceSaveShipping, shippingAddress]
  );

  const handleSearchAddressSelect = useCallback(
    (address: IDetailedAddress) => {
      const { shipTo } = shippingAddress;
      const newShipTo = { ...shipTo, ...address };
      debounceSaveShipping.cancel();
      saveShipping(newShipTo);
    },
    [debounceSaveShipping, saveShipping, shippingAddress]
  );

  const handleIsCustomAddressToggle = useCallback(
    (option: string) => {
      debounceSaveShipping.cancel();
      setIsCustomAddress(option);
    },
    [debounceSaveShipping]
  );

  const clearAddress = useCallback(() => {
    debounceSaveShipping.cancel();
    saveShipping({
      ...blankAddress,
      name: shippingAddress.shipTo.name,
    });
  }, [debounceSaveShipping, saveShipping, shippingAddress.shipTo.name]);

  // =============================================
  // Render Methods
  // =============================================
  const customAddressForm = useMemo(() => {
    const inputs = addressInputDetails.map(({ value, label }) => {
      if (value === 'name') {
        return null;
      }

      const className = `ship-to-form__custom-address--${value}`;
      const controlValue = shippingAddress.shipTo[value];

      if (label === 'State') {
        const stateOptions = stateIsoCodes.map((code) => {
          return (
            <option value={code} key={`US-${code}`}>
              {code}
            </option>
          );
        });
        return (
          <KiteSelect
            key={value}
            id={value}
            name={value}
            label={label}
            className={className}
            value={controlValue}
            placeholder="Select State"
            onChange={handleCustomAddressChange}
          >
            {stateOptions}
          </KiteSelect>
        );
      }

      return (
        <KiteInput
          key={value}
          name={value}
          label={label}
          className={className}
          placeholder={`Enter ${label}`}
          onChange={handleCustomAddressChange}
          value={controlValue}
        />
      );
    });
    return <div className="ship-to-form__custom-address">{inputs}</div>;
  }, [handleCustomAddressChange, shippingAddress.shipTo]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    // Ensure that data is in sync with inputs
    if (!debounceSaveShipping.isPending()) {
      setShippingAddress({
        locationId: currentLocation?.id,
        shipTo: shipToValue,
      });
    }
  }, [currentLocation, debounceSaveShipping, shipToValue]);

  useEffect(() => {
    if (currentLocation?.id !== shippingAddress.locationId) {
      debounceSaveShipping.cancel();
      setShippingAddress({
        locationId: currentLocation?.id,
        shipTo: shipToValue,
      });
    }
  }, [
    currentLocation,
    debounceSaveShipping,
    shipToValue,
    shippingAddress.locationId,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ship-to-form">
      <FieldValidationHeader label="Ship Equipment To" iconName="map-point" />
      <div className="ship-to-form__shipping-input">
        <RadioToggle
          name="Use custom shipping address"
          label="Enter a custom shipping address?"
          options={['No', 'Yes']}
          onChange={handleIsCustomAddressToggle}
          selectionValue={isCustomAddress}
          isMutatePending={true}
        />
        <KiteInput
          name="name"
          label="Recipient Name"
          placeholder="Enter Recipient"
          onChange={handleCustomAddressChange}
          value={shippingAddress.shipTo.name}
        />
        {isCustomAddress === 'No' ? (
          <LocationAddressInput
            label="Address Search"
            address={shipToValue}
            onAddressSelect={handleSearchAddressSelect}
            addressError={''}
            clearAddress={clearAddress}
          />
        ) : (
          customAddressForm
        )}
      </div>
    </div>
  );
};

export default ShipToForm;
