// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { YesNoSwitch } from 'components';
import { KiteInput } from '@kite/react-kite';

// Hooks
import { useParams } from 'react-router-dom';
import { useUpdateDistros } from 'hooks';
import { useDebouncedCallback } from 'use-debounce/lib';

// Utils
import { sortDistros } from 'utils';

// Types
import { IDistro } from 'types';
import { TFieldValidationValues } from 'hooks/useRenderReviewUcFields';

// Styles
import './UcReviewNetworkDraftTable.scss';

export interface IUcReviewNetworkDraftTableProps {
  distros: IDistro[];
}

interface IDisplayDistro extends IDistro {
  building: string;
  from: string;
  toNodes: string;
}

/** Displays Distro data in read only page field validation tab */

const UcReviewNetworkDraftTable = ({
  distros,
}: IUcReviewNetworkDraftTableProps) => {
  const initialDistroValidationValues = useMemo(() => {
    return distros.reduce((acc: TFieldValidationValues, distro) => {
      acc[distro.id] = {
        accurate: distro.fieldOpsAccurate,
        comment: distro.fieldOpsComments || '',
      };
      return acc;
    }, {});
  }, [distros]);

  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [distroValidationValues, setDistroValidationValues] =
    useState<TFieldValidationValues>(initialDistroValidationValues);

  const { estimateId = '' } = useParams();

  const { updateDistros } = useUpdateDistros({
    estimateId,
  });

  const debounceSave = useDebouncedCallback(
    (distros: IDistro[]) => updateDistros(distros),
    350
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  // Checks if any distro validation values have changed
  const isDirty = useMemo(
    () =>
      Object.entries(initialDistroValidationValues).some(([id, values]) => {
        const { accurate: currentFieldOpsAccurate, comment: currentComment } =
          distroValidationValues[id];

        return (
          values.accurate !== currentFieldOpsAccurate ||
          values.comment !== currentComment
        );
      }),
    [initialDistroValidationValues, distroValidationValues]
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSelect = useCallback(
    (distroId: string) => (selection: boolean) => {
      const distroToUpdate = distros.find(({ id }) => id === distroId);
      if (!distroToUpdate) return;
      const updatedSwitchValues = {
        ...distroValidationValues,
        [distroToUpdate.id]: {
          ...distroValidationValues[distroToUpdate.id],
          accurate: selection,
        },
      };
      setDistroValidationValues(updatedSwitchValues);
      const distroToSave = { ...distroToUpdate, fieldOpsAccurate: selection };
      debounceSave([distroToSave]);
    },
    [distros, distroValidationValues, debounceSave]
  );

  const handleInputChange = useCallback(
    (value: string, id: string) => {
      const distroToUpdate = distros.find((distro) => id === distro.id);
      if (!distroToUpdate) return;
      const updatedSwitchValues = {
        ...distroValidationValues,
        [distroToUpdate.id]: {
          ...distroValidationValues[distroToUpdate.id],
          comment: value,
        },
      };
      setDistroValidationValues(updatedSwitchValues);
      const distroToSave = {
        ...distroToUpdate,
        fieldOpsComments: value,
      };

      debounceSave([distroToSave]);
    },
    [distros, distroValidationValues, debounceSave]
  );

  // =============================================
  // Render Methods
  // =============================================
  const formatData = useMemo(() => {
    return sortDistros(distros).reduce((acc: IDisplayDistro[], distro) => {
      if (distro.nodeType === 'bdg') return acc;

      let displayDistro = { ...distro, building: '', from: '', toNodes: '' };

      // Get parent for bulding column
      const parent = distros.find(
        ({ nodeId }) => nodeId === distro.parentNodeId
      );

      // Find where this node is from
      const fromNode = distros.find(({ toNodeIds }) =>
        toNodeIds?.includes(distro.nodeId)
      );

      // Format to nodes
      const toNodes = distro.toNodeIds
        ?.map(
          (nodeId) => distros.find((distro) => distro.nodeId === nodeId)?.name
        )
        .filter((toNode) => toNode && toNode.length > 0)
        .join(', ');

      displayDistro = {
        ...displayDistro,
        building: parent?.name || parent?.nodeId || 'N/A',
        from: fromNode?.name || parent?.name || 'N/A',
        toNodes: toNodes || 'N/A',
      };
      acc.push(displayDistro);
      return acc;
    }, []);
  }, [distros]);

  const renderField = (value?: string | number | null) => {
    if (typeof value === 'string') {
      return value.length > 0 ? value : 'N/A';
    } else if (typeof value === 'number') return value;
    else {
      return 'N/A';
    }
  };

  const renderYesNo = useCallback(
    (distro: IDisplayDistro) => {
      return (
        <YesNoSwitch
          handleSelect={handleSelect(distro.id)}
          value={distroValidationValues[distro.id]?.accurate}
        />
      );
    },
    [handleSelect, distroValidationValues]
  );

  const renderHeaders = useMemo(() => {
    return (
      <thead>
        <tr>
          <th>BLDNG</th>
          <th>Name</th>
          <th>From</th>
          <th>Interconnect Type</th>
          <th>Interconnect Type (MDF to IDF)</th>
          <th>To</th>
          <th>Connector Type</th>
          <th># of Switch Ports</th>
          <th># of Analog Phones</th>
          <th>Distance (in feet)</th>
          <th>Interconnect Responsibility</th>
          <th>Conduit</th>
          <th>Notes</th>
          <th>Correct</th>
        </tr>
      </thead>
    );
  }, []);

  const renderRows = useMemo(() => {
    return (
      <tbody>
        {formatData.map((rowData) => {
          return (
            <React.Fragment key={renderField(rowData.id)}>
              <tr>
                <td>{renderField(rowData.building)}</td>
                <td>{renderField(rowData.name)}</td>
                <td>{renderField(rowData.from)}</td>
                <td>{renderField(rowData.interconnectType)}</td>
                <td>{renderField(rowData.interconnectTypeIdf)}</td>
                <td>{renderField(rowData.toNodes)}</td>
                <td>{renderField(rowData.connectorType)}</td>
                <td>{renderField(rowData.switchPorts)}</td>
                <td>{renderField(rowData.phones)}</td>
                <td>{renderField(rowData.distance)}</td>
                <td>{renderField(rowData.interconnectResponsibility)}</td>
                <td>{renderField(rowData.conduit)}</td>
                <td>{renderField(rowData.notes)}</td>
                <td>{renderYesNo(rowData)}</td>
              </tr>
              {distroValidationValues[rowData.id]?.accurate === false && (
                <tr className="uc-review-network-draft-table__validation-row">
                  <td colSpan={10}>
                    <KiteInput
                      maxWidth="100%"
                      margin="0"
                      placeholder="Enter changes or discrepancies"
                      onChange={(e) =>
                        handleInputChange(e.target.value, rowData.id)
                      }
                      value={distroValidationValues[rowData.id]?.comment || ''}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    );
  }, [formatData, handleInputChange, renderYesNo, distroValidationValues]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (isDirty && !debounceSave.isPending()) {
      setDistroValidationValues(initialDistroValidationValues);
    }
  }, [debounceSave, initialDistroValidationValues, isDirty]);

  // =============================================
  // Return
  // =============================================
  return (
    <table className="uc-review-network-draft-table">
      {renderHeaders}
      {renderRows}
    </table>
  );
};

export default UcReviewNetworkDraftTable;
