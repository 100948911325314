import { searchActiveDirectory } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';
import { IUseGetActiveDirectoryRes } from './useGetActiveDirectory';

interface IUseSearchActiveDirectoryParams {
  search?: string;
  onError?: () => void;
}

export type TActiveDirectorySearchRes = Omit<
  IUseGetActiveDirectoryRes,
  'avatar'
>[];

export const useSearchActiveDirectory = (
  requestConfig: IUseSearchActiveDirectoryParams
) => {
  const { search, onError } = requestConfig;
  return useGenerateQuery<TActiveDirectorySearchRes>({
    queryKey: ['activeDirectorySearch', search],
    apiCall: searchActiveDirectory,
    requestConfig: { params: { search } },
    opts: {
      enabled: !!search && search.length > 2,
      retry: 0,
      refetchOnWindowFocus: false,
      onError: () => onError && onError(),
    },
  });
};
