// Packages
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks
import { useQueryData } from 'hooks';

// Utils
import { UcQuestionIconsMap } from 'utils';

// Types
import { ISalesTeamMember, ISubmissionHistory } from 'types';

// Styles
import './UCQHistoryRow.scss';

export interface IUCQHistoryRowProps {
  historyData?: ISubmissionHistory | null;
  teamMember?: ISalesTeamMember;
  onClick?: () => void;
}

/** Row that displays a change in the UCQHistory drawer */

const UCQHistoryRow = ({
  historyData,
  teamMember,
  onClick,
}: IUCQHistoryRowProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [imgError, setImgError] = useState(false);

  const { currentEstimate } = useQueryData();

  const {
    editedBy: pid,
    formName = '',
    fieldOrder,
    createdAt = '',
    locationId,
  } = historyData || ({} as ISubmissionHistory);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const locationName = useMemo(
    () =>
      currentEstimate?.locations.find((l) => l.id === locationId)?.name ||
      'General',
    [locationId, currentEstimate]
  );

  const { firstName, lastName } = teamMember || {
    firstName: pid,
    lastName: '',
  };

  const displayName = `${firstName} ${
    lastName.length ? lastName.split('')[0] + '.' : ''
  }`;

  const formatCreatedAt = dayjs(createdAt).format('hh:mm A');

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const avatarImage = useMemo(() => {
    if (!teamMember?.imageURI || imgError)
      return (
        <KiteIcon
          name="account"
          className="ucq-history-row__avatar--generic"
          size="30px"
        />
      );
    return (
      <img
        src={teamMember.imageURI}
        alt={`${teamMember.fullName} avatar`}
        className="ucq-history-row__avatar"
        onError={() => setImgError(true)}
      />
    );
  }, [teamMember, imgError]);

  const rowContent = useMemo(
    () => (
      <div className="ucq-history-row__content">
        <div className="ucq-history-row__title">
          {avatarImage} <span>{displayName}</span>{' '}
          <span
            className="ucq-history-row__title--location"
            title={locationName}
          >
            {locationName}
          </span>
        </div>
        <div className="ucq-history-row__sub-title">
          <KiteIcon
            className="ucq-history-row__sub-title__icon"
            name={UcQuestionIconsMap[formName]}
          />
          <span>{formName}</span>
          <span className="ucq-history-row__sub-title--question-change">
            {fieldOrder === 100
              ? 'Notes changed'
              : `Question ${fieldOrder} changed`}
          </span>
          <span className="ucq-history-row__sub-title--time">
            {formatCreatedAt}
          </span>
        </div>
      </div>
    ),
    [
      avatarImage,
      locationName,
      displayName,
      fieldOrder,
      formName,
      formatCreatedAt,
    ]
  );

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ucq-history-row">
      {onClick ? <button onClick={onClick}>{rowContent}</button> : rowContent}
    </div>
  );
};

export default UCQHistoryRow;
