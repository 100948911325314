// Packages
import React, { useCallback, useEffect, useState } from 'react';

// Redux

// Components
import { KiteRadio } from '@kite/react-kite';

// Hooks

// Utils

// Types
import { ILocationSelection } from 'types';

// Styles
import './RadioToggle.scss';

export interface IRadioToggleProps {
  // Options to choose between
  options: [string, string];
  // Change handler when option is selected
  onChange: (option: string) => void;
  // Name for input group
  name: string;
  // Current selection property being affected, used to keep local state in sync w/ API data
  selectionValue: ILocationSelection[keyof ILocationSelection];
  // Determines if there are pending location selection changes occuring elsewhere
  isMutatePending: boolean;
  // Disables the radio inputs, i.e. productId === NONE
  isDisabled?: boolean;
  // Optional label
  label?: string;
  // Optional class name
  className?: string;
}

/** Custom radio to toggle between location selection options, i.e. filter: intrastate || interstate. Option must be string value. */

const RadioToggle = ({
  options,
  onChange,
  name,
  selectionValue,
  isMutatePending,
  label,
  isDisabled = false,
  className,
}: IRadioToggleProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [inputValue, setInputValue] = useState(selectionValue || options[0]);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
      onChange(value);
    },
    [onChange]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  // // Keeps state in sync w/ data
  useEffect(() => {
    if (
      typeof selectionValue === 'string' &&
      selectionValue !== inputValue &&
      !isMutatePending
    ) {
      setInputValue(selectionValue);
    } else if (!selectionValue) {
      setInputValue(options[0]);
    }
  }, [inputValue, isMutatePending, options, selectionValue]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteRadio
      name={name}
      className={`radio-toggle ${className}`}
      groupLabel={label}
      onChange={handleChange}
      buttonOrientation="row"
      disabled={isDisabled}
      radioButtons={options.map((option) => ({
        id: option,
        label: option,
        checked: option === inputValue,
      }))}
    />
  );
};

export default RadioToggle;
