import React from 'react';
import { Text, View } from '@react-pdf/renderer';

// Types
import { ILocationSelection, IPromo, IScenarioPromo } from 'types';

// Styles
import { styles } from 'components/pdf/PdfComponents';

interface IDisplayDisclaimers {
  locationSelections: ILocationSelection[];
  scenarioPromos: IScenarioPromo[];
  promos: IPromo[];
  locationId: string;
}

type TOverviewDisplayDisclaimers = Omit<IDisplayDisclaimers, 'locationId'>;

const romanNumerals = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
  'XIII',
];

export const generateDisclaimers = ({
  locationSelections,
  scenarioPromos,
  promos,
  locationId,
}: IDisplayDisclaimers) => {
  const disclaimerSpeeds = [
    '2G',
    '5G',
    '10G',
    '20G',
    '30G',
    '40G',
    '50G',
    '60G',
    '70G',
    '80G',
    '90G',
    '100G',
  ];

  const allDisclaimers = {
    defaults: [
      'The Solution Pricing Tool output is not a guarantee of service or rate availability. All other business and product rules must be followed in conjunction with this pricing.',
      'Products and Features not explicitly called out in the pricing above are not included. Any additional products or features must be configured via normal business processes.',
    ],
    multiGigDfi: ['Multi-Gig DFI requires DRT review'],
    multiGigEthernet: ['Multi-Gig Ethernet requires DRT review'],
    ucVoice: [
      'Phones, other seat types, and other options are not included in the Solution Pricing Tool. These other options must be added in addition to these seats, in the opportunity.',
      'UC Seat Prices can be extended to other locations associated with this deal.',
      'UC Autobuild rules are still applicable',
    ],
    wireless: [
      'Wireless Internet wirelessth unlimited data does not have an overage charge or data-pooling and speeds are reduced to 600Kbps when data usage exceeds 70GB within the billing cycle.', // unlimited messaging
      "Wireless Internet with usage-based plans allow for data-pooling and, in addition to monthly recurring charges associated with client's selected monthly data plan as detailed above, overage may apply and is charged one month in arrears at a rate of $15 per GB for data usage in excess of the plan's allowance as outlined on the Wireless Internet Access service attachment.", // other value messaging
    ],
    wirelessInternetOffers: [
      'If Customer purchases a Wireless Internet Service plan with a monthly Data Allowance, plans with the same monthly Data Allowance will participate in the same Data Pool. In addition to monthly recurring charges associated with a Wireless Internet Service plan with monthly Data Allowance, overage charges may apply if Customer’s data usage in a given calendar month exceeds the monthly Data Allowance/Data Pool, as applicable. Overage charges are applied in arrears at a rate of $8.00 per GB of data usage in excess of the plan’s monthly Data Allowance/Data Pool, as applicable. Wireless Internet Service plans with unlimited data do not have overage charges or data-pooling. See the Wireless Internet Access Service attachment of the Service Agreement for additional information.',
    ],
    mne: [
      'MNE provides routing, security, and SD-WAN Functionality. MNE can support a maximum of 2x WAN Uplinks. MNE requires an Internet connection at each location. Output from the Solution Pricing Tool does not guarantee availability of any component or configuration, all BAU processes must still be followed.',
    ],
    businessInternet: [
      'Business Internet pricing is assumed to be Acquisition. For Renewal opportunities please use BAU processes to gather pricing.',
      'When creating an opportunity, select regular SBPP MTM offers in Salesforce.',
      'When creating an opportunity, select regular Vertical/MSA termed offers in Salesforce.',
      'Ensure that SBPP rules are followed for side grades and upgrades.',
      'The vertical/MSA offer allows you to term your customer to and of the following terms: MTM, 1yr, 2yr, 3yr, 4yr, 5yr, or 7yr at the same rate.',
    ],
    pqe: ['Please ensure a copy of this page is added in the PQE form.'],
    sbbMusicChoice: [
      `A client with the Music Choice programming add-in is not permitted to:

          (i) charge a cover charge or admission fee to any Service Location(s) at the time the TV Service is being displayed or are to be displayed; or

          (ii) permit dancing, skating or other similar forms of entertainment or physical activity in conjunction with the performance of the TV Service`,
    ],
    sbbProducts: [
      'Equipment pricing is calculated at $8 MRC per outlet plus NRC at either $100 for 1-4 outlets or $25 per outlet for solutions that have 5+ outlets.',
    ],
    insideWiring: [
      'If selected, Installment Plan is presented as an NRC on this form and Service Order.  Invoice will apply monthly charges for selected period.',
    ],
  };

  const appliedPromos = scenarioPromos.reduce((acc: IPromo[], sp) => {
    const promo = promos.find((p) => p.id === sp.promoId);
    if (promo) {
      if (promo.isLocationSpecific) {
        sp.locationId === locationId && acc.push(promo);
      } else {
        acc.push(promo);
      }
    }
    return acc;
  }, []);

  const disclaimers = allDisclaimers.defaults;

  const hasInandOutFootprint = locationSelections.some(
    (s) =>
      s.name === 'Inside Wiring Service (In Footprint Option)' ||
      s.name === 'Inside Wiring Service (Out of Footprint Option)'
  );

  const familyNames = locationSelections.map(
    (selection) => selection.familyName
  );

  const dfiSelection = locationSelections.find(
    (s) => s.familyName === 'DFI Speed'
  );
  const ethernetSelection = locationSelections.find(
    (s) => s.familyName === 'Ethernet Speed'
  );

  if (dfiSelection && disclaimerSpeeds.includes(dfiSelection.name)) {
    disclaimers.push(...allDisclaimers.multiGigDfi);
  }

  if (ethernetSelection && disclaimerSpeeds.includes(ethernetSelection.name)) {
    disclaimers.push(...allDisclaimers.multiGigEthernet);
  }

  if (familyNames.includes('UC Connect w/ Webex')) {
    disclaimers.push(...allDisclaimers.ucVoice);
  }

  if (familyNames.includes('Wireless Internet')) {
    const wirelessSelection = locationSelections.find(
      (s) => s.name === 'Wireless Internet'
    );
    if (wirelessSelection && wirelessSelection.name === 'Unlimited Plan') {
      disclaimers.push(allDisclaimers.wireless[0]);
    } else {
      disclaimers.push(allDisclaimers.wireless[1]);
    }
  }

  if (familyNames.includes('Wireless Internet Service Plan')) {
    disclaimers.push(...allDisclaimers.wirelessInternetOffers);
  }

  if (familyNames.includes('MNE Network Speed')) {
    disclaimers.push(...allDisclaimers.mne);
  }

  if (familyNames.includes('Business Internet')) {
    disclaimers.push(allDisclaimers.businessInternet[0]);

    const businessInternetSelection = locationSelections.find(
      (s) => s.familyName === 'Business Internet'
    );

    if (businessInternetSelection?.name.includes('Term')) {
      disclaimers.push(allDisclaimers.businessInternet[0]);
      disclaimers.push(allDisclaimers.businessInternet[1]);
      disclaimers.push(allDisclaimers.businessInternet[3]);
    } else if (businessInternetSelection?.name.includes('M to M')) {
      disclaimers.push(allDisclaimers.businessInternet[0]);
      disclaimers.push(allDisclaimers.businessInternet[2]);
      disclaimers.push(allDisclaimers.businessInternet[3]);
      disclaimers.push(allDisclaimers.businessInternet[4]);
    }
  }

  disclaimers.push(...allDisclaimers.pqe);

  if (familyNames.includes('Equipment')) {
    disclaimers.push(...allDisclaimers.sbbProducts);
  }

  if (familyNames.includes('Add-Ons')) {
    const musicChoice = locationSelections.find(
      (selection) => selection.name === 'Music Choice'
    );
    musicChoice && disclaimers.push(...allDisclaimers.sbbMusicChoice);
  }
  if (hasInandOutFootprint && familyNames.includes('Professional Services')) {
    disclaimers.push(...allDisclaimers.insideWiring);
  }

  const disclaimerText = disclaimers.map((disclaimer, i) => (
    <Text key={`disclaimer ${i}`} style={styles.disclaimer}>
      {`${romanNumerals[i]}. ${disclaimer}`}
    </Text>
  ));

  const promoText = (
    <Text style={styles.promoText}>
      {`* = Promotion(s) Applied: ${appliedPromos
        .map((p) => p.name)
        .join(', ')}`}
    </Text>
  );

  return (
    <View style={styles.disclaimerWrapper}>
      {disclaimerText}
      {!!appliedPromos.length && promoText}
    </View>
  );
};

export const generateOverviewDisclaimers = ({
  locationSelections,
  promos,
  scenarioPromos,
}: TOverviewDisplayDisclaimers) => {
  const allDisclaimers = {
    defaults: [
      'The Solution Pricing Tool output is not a guarantee of service or rate availability. All other business and product rules must be followed in conjunction with this pricing.',
      'Products and Features not explicitly called out in the pricing above are not included. Any additional products or features must be configured via normal business processes.',
    ],
    uc: [
      'Phones, other seat types, and other options are not included in the Solution Pricing Tool. These other options must be added in addition to these seats, in the opportunity.',
      'UC Seat Prices can be extended to other locations associated with this deal.',
      'UC Autobuild rules are still applicable',
      'Please ensure a copy of this page is added in the PQE form.',
    ],
    cloud: [
      'Cloud Connect requires at least 1 additional Ethernet location.',
      'Cloud Connect connection types and available data center locations vary by Cloud Service Provider',
    ],
    cloudWi: [
      'WI allows usage up to the stated Data Plan limit. Usage fees beyond this data limit will apply. Refer to the Enterprise Rate Card and documentation for additional fee’s and product rules.',
    ],
    wi: [
      'Wireless Internet with usage-based plans allow for data-pooling and, in addition to monthly recurring charges associated with client’s selected monthly data plan, overage may apply and is charged one month in arrears at a rate of $15 per GB for data usage in excess of the plan’s allowance as outlined on the Wireless Internet Access service attachment. Wireless Internet with unlimited data does not have an overage change or data-pooling and speeds are reduced to 600Kbps when data usage exceeds 150GB within the billing cycle.',
    ],
    wio: [
      'If Customer purchases a Wireless Internet Service plan with a monthly Data Allowance, plans with the same monthly Data Allowance will participate in the same Data Pool. In addition to monthly recurring charges associated with a Wireless Internet Service plan with monthly Data Allowance, overage charges may apply if Customer’s data usage in a given calendar month exceeds the monthly Data Allowance/Data Pool, as applicable. Overage charges are applied in arrears at a rate of $8.00 per GB of data usage in excess of the plan’s monthly Data Allowance/Data Pool, as applicable. Wireless Internet Service plans with unlimited data do not have overage charges or data-pooling. See the Wireless Internet Access Service attachment of the Service Agreement for additional information.',
    ],
    insideWiring: [
      'If selected, Installment Plan is presented as an NRC on this form and Service Order.  Invoice will apply monthly charges for selected period.',
    ],
  };

  const appliedPromos = scenarioPromos.reduce((acc: IPromo[], sp) => {
    const promo = promos.find((p) => p.id === sp.promoId);
    if (promo) {
      acc.push(promo);
    }
    return acc;
  }, []);

  const disclaimers = allDisclaimers.defaults;

  const familyNames = locationSelections.map(
    (selection) => selection.familyName
  );

  if (familyNames.includes('UC Connect w/ Webex')) {
    disclaimers.push(...allDisclaimers.uc);
  }
  const hasInandOutFootprint = locationSelections.some(
    (s) =>
      s.name === 'Inside Wiring Service (In Footprint Option)' ||
      s.name === 'Inside Wiring Service (Out of Footprint Option)'
  );

  const hasCloudProducts =
    familyNames.includes('Cloud Connect Single') ||
    familyNames.includes('Cloud Connect Dual');

  if (hasCloudProducts) {
    disclaimers.push(...allDisclaimers.cloud);

    if (familyNames.includes('Wireless Internet')) {
      disclaimers.push(...allDisclaimers.cloudWi);
    }

    if (familyNames.includes('Wireless Internet Service Plan')) {
      disclaimers.push(...allDisclaimers.wio);
    }
  }

  if (familyNames.includes('Wireless Internet') && !hasCloudProducts) {
    disclaimers.push(...allDisclaimers.wi);
  }

  if (
    familyNames.includes('Wireless Internet Service Plan') &&
    !hasCloudProducts
  ) {
    disclaimers.push(...allDisclaimers.wio);
  }
  if (hasInandOutFootprint && familyNames.includes('Professional Services')) {
    disclaimers.push(...allDisclaimers.insideWiring);
  }

  const disclaimerText = disclaimers.map((disclaimer, i) => (
    <Text key={`disclaimer ${i}`} style={styles.disclaimer}>
      {`${romanNumerals[i]}. ${disclaimer}`}
    </Text>
  ));

  const promoText = (
    <Text style={styles.promoText}>{`* = Promotion(s) Applied: ${appliedPromos
      .map((p) => p.name)
      .join(', ')}`}</Text>
  );

  return (
    <View style={styles.disclaimerWrapper}>
      {disclaimerText}
      {!!appliedPromos.length && promoText}
    </View>
  );
};
