import axios from 'axios';
import { IRequestConfig, IRequestParams, ISfFailedProduct } from 'types';
import { paramsSerializer } from 'utils/format/paramSerializer';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IFailedProductsRequestParams extends IRequestParams {
  opportunityId: string;
}

export interface IFailedProductsRequest
  extends IRequestConfig<ISfFailedProduct[]> {
  params?: IFailedProductsRequestParams;
}

export const failedProductsRequest = async (
  config?: IFailedProductsRequest
) => {
  const {
    endpoint = 'failed-products',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with failed products ${method}`,
      })
    );
};
