// External Packages
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

// Components
import { KiteInput } from '@kite/react-kite';

// Styles
import './DiscretionaryInput.scss';

// =============================================
// Interfaces
// =============================================
interface IProps {
  onChange: (value: string) => void;
  value: number | null;
  errorMes: string;
  min?: string;
  max?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  label: string;
}

// =============================================
// Component
// =============================================
const DiscretionaryInput = ({
  onChange,
  value,
  min,
  max,
  onFocus,
  onBlur,
  errorMes,
  label,
}: IProps) => {
  const [inputValue, setInputValue] = useState('0');

  // discretionary wrapper should allow decimal points
  const handleKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const invalidKeys = ['e', '+', '-'];
      if (invalidKeys.includes(e.key)) {
        e.preventDefault();
      }
    },
    []
  );

  useEffect(() => {
    if (value) {
      setInputValue(value.toString());
    }
  }, [inputValue, value]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  // =============================================
  // Return
  // =============================================
  return (
    <div className="discretion">
      <p className="discretion__description">{label}</p>
      <div
        className={classnames({
          'discretion__price-input-wrapper': true,
          'discretion__price-input-wrapper--error': errorMes,
        })}
      >
        <input
          className="discretion__slider"
          id="myRange"
          type="range"
          min={min}
          max={max}
          onChange={handleChange}
          value={inputValue}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <KiteInput
          className={classnames({ 'discretion__input--danger': errorMes })}
          type="number"
          min={min}
          max={max}
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeydown}
          inputProps={{
            onFocus,
            onBlur,
          }}
        />
      </div>
      {errorMes && <p className="kite-form-control-feedback">{errorMes}</p>}
    </div>
  );
};

export default DiscretionaryInput;
