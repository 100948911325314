import { TProductList } from 'types';

export const originalProfileInfoData = {
  id: '',
  profileName: '',
  quantity: '',
};

export const originalProfileProductData = {
  voipHardware: '',
  package: '',
  insideWiring: '',
  recordingLicense: '',
  additionalSoftware: '',
  unityAddonLicense: '',
};

export const ucSeatsProductFamilies: TProductList[] = [
  'UC Connect w/ Webex',
  'Additional Seats',
  'Hosted Call Center Seats',
];

export const rcSeatsProductFamilies: TProductList[] = ['RC Seats'];
