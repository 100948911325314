import { useMemo } from 'react';
import { ISubmission } from 'types';
import { useUCQuestionsTotal } from './useUCQuestionsTotal';

const useLocationCompletePercent = ({
  submissions,
  locationId,
}: {
  submissions: ISubmission[];
  locationId: string;
}) => {
  // Required subs / fields for the location are taken from useUCQuestionsTotal
  const {
    requiredSubmissions,
    requiredLocationFieldIds,
    requiredEstimateFieldIds,
  } = useUCQuestionsTotal({ submissions });

  // If general, use estimate required field ids, else get selected location required field ids
  const requiredIds = useMemo(
    () =>
      locationId === 'general'
        ? requiredEstimateFieldIds
        : requiredLocationFieldIds[locationId] || [],
    [locationId, requiredEstimateFieldIds, requiredLocationFieldIds]
  );

  // Complete % should reflect subs / all fields for currently selected location / general
  const completePercent = Math.round(
    (requiredSubmissions.length / requiredIds.length) * 100
  );

  return { completePercent, requiredIds, requiredSubmissions };
};

export default useLocationCompletePercent;
