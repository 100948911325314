import axios from 'axios';
import { IRequestConfig, IProductFamily, IRequestParams } from 'types';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IProductFamilyParams extends IRequestParams {
  category?: IProductFamily['category'];
}

export interface IProductFamilyRequest
  extends IRequestConfig<IProductFamily | IProductFamily[]> {
  params?: IProductFamilyParams;
}

export const productFamilyRequest = async (config?: IProductFamilyRequest) => {
  const {
    endpoint = 'product-families',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with product family ${method}`,
      })
    );
};
