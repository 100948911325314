import { useCallback, useRef } from 'react';
import { v4 } from 'uuid';

// Hooks
import {
  useAnalytics,
  useUpdateScenarioProducts,
  useDependencyCheck,
  useGenerateSelection,
  useQueryData,
  useDeleteProfiles,
  useUpdateBundleProducts,
  useUpdateProfiles,
  useDeleteScenarioPromos,
  usePostScenarioPromo,
} from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import {
  findDuplicates,
  NONE,
  scenarioRequest,
  eipTermMap,
  rcPhonesAndDevicesId,
  rcIswId,
  inFootprintIswId,
  outOfFootprintIswId,
  iswEipTermMap,
} from 'utils';

// Types
import {
  IBundle,
  ILocationSelection,
  IProductFamily,
  IProfile,
  IPromo,
  IScenario,
  IScenarioPromo,
  ISelectionValues,
  TEipTermLengthMonths,
  TOnSelectionChange,
  TTermLengthMonths,
} from 'types';

export interface IUseSelections {
  applySelection: TOnSelectionChange;
  onBundleSelect: (bundle: IBundle) => void;
  clearSelections: (category?: IProductFamily['category']) => void;
  togglePromo: (promo: IPromo) => void;
  updateScenarioTerm: (term: TTermLengthMonths) => void;
  updateLocationSelectionEipTerm: (eipTerm: TEipTermLengthMonths) => void;
  applySelections: (
    selections: ILocationSelection[],
    newScenarioId?: string
  ) => void;
  removeSelections: (selectionsToRemove: ILocationSelection[]) => void;
  addProductSelections: (
    products: {
      productId: string;
      quantity?: number;
      profileId?: string;
      profileCategoryId?: string;
    }[]
  ) => void;
  clearProfiles: (profiles: IProfile[]) => void;
  clearBundle: (bundle: IBundle) => void;
  deleteProfileLoading: boolean;
  updateScenarioProductsLoading: boolean;
  generateSelection: (config: {
    productId: string;
    selectionValues?: ISelectionValues | undefined;
    selectionId?: string | undefined;
    isDelete?: boolean | undefined;
  }) => ILocationSelection;
  updateProfilesLoading: boolean;
  applyProfilesAndSelections: (params: {
    profiles: Partial<IProfile>[] | IProfile[];
    selections: ILocationSelection[];
    newScenarioId?: string;
  }) => void;
  deleteScenarioPromosLoading: boolean;
  postScenarioPromoLoading: boolean;
  removeDisabledPromos: () => void;
}

export const useSelections = (): IUseSelections => {
  const { bundleId, estimateId } = useParams();
  const dependencyCheck = useDependencyCheck();
  const {
    currentScenario,
    currentSelections,
    currentBundle,
    currentScenarioPromos,
    scenarioId,
    locationId,
    allPromos,
  } = useQueryData();
  const { updateScenarioProducts, updateScenarioProductsLoading } =
    useUpdateScenarioProducts();
  const { deleteProfiles, deleteProfileLoading } = useDeleteProfiles();
  const { updateBundleProducts } = useUpdateBundleProducts({ canSave: false });
  const { trackSelectAction } = useAnalytics();
  const generateSelection = useGenerateSelection();
  const { deleteScenarioPromos, deleteScenarioPromosLoading } =
    useDeleteScenarioPromos();
  const { postScenarioPromo, postScenarioPromoLoading } =
    usePostScenarioPromo();

  /**
   * Combine current location selections w/ all scenario selections
   * Only pass in currentScenario if updating an estimate
   * Only pass in currentBundle if updating a bundle config (admin)
   */
  const mergeAllSelections = useCallback(
    (config: {
      newSelections: ILocationSelection[];
      currentScenario?: IScenario;
      currentBundle?: IBundle;
    }) => {
      const { currentScenario, newSelections, currentBundle } = config;

      const oldSelections =
        currentScenario?.selections || currentBundle?.selections || [];

      if (!oldSelections.length) {
        return dependencyCheck(newSelections);
      }

      const newSelectionIds = new Set(newSelections?.map((s) => s.id));

      // Combine new and old selections, overriding any old selection ids with the new selection
      const mergedSelections = oldSelections.reduce(
        (acc: ILocationSelection[], oldSelection) => {
          if (!newSelectionIds.has(oldSelection.id)) {
            acc.push(oldSelection);
          }
          return acc;
        },
        newSelections
      );

      // All product ids for non-profile selections by location
      const productIdsByLocation = mergedSelections.reduce(
        (acc: { [locationId: string]: string[] }, s) => {
          if (!s.profileId) {
            const { productId, locationId = '' } = s;
            if (!acc[locationId]) {
              acc[locationId] = [productId];
            } else {
              acc[locationId].push(productId);
            }
          }
          return acc;
        },
        {}
      );

      // Duplicate product ids (by location) for non-profile selections
      const duplicateProductIds = new Set(
        Object.values(productIdsByLocation).reduce(
          (acc: string[], productIds) => {
            const duplicates = findDuplicates(productIds);
            acc.push(...duplicates);
            return acc;
          },
          []
        )
      );

      // In some edge cases the selection change handler will create a duplicate, this prevents that
      // Only Profiles should be able to have duplicate product id selections
      if (duplicateProductIds.size) {
        const duplicateSelectionIdsToRemove = mergedSelections.reduce(
          (acc: string[], selection) => {
            if (
              duplicateProductIds.has(selection.productId) &&
              !selection.id.includes('newSelection')
            ) {
              acc.push(selection.id);
            }
            return acc;
          },
          []
        );

        return dependencyCheck(
          mergedSelections.filter(
            (s) => !duplicateSelectionIdsToRemove.includes(s.id)
          )
        );
      }

      return dependencyCheck(mergedSelections);
    },
    [dependencyCheck]
  );

  /** Clear all selections or clear by product family category */
  const clearSelections = useCallback(
    (category?: IProductFamily['category']) => {
      const newSelections = currentSelections.reduce(
        (acc: ILocationSelection[], selection) => {
          if (!category) {
            acc.push({ ...selection, productId: NONE });
          }

          if (
            category &&
            selection.familyCategory === category &&
            !selection.profileId
          ) {
            acc.push({ ...selection, productId: NONE });
          }

          return acc;
        },
        []
      );

      updateScenarioProducts({
        ...currentScenario,
        selections: mergeAllSelections({ newSelections, currentScenario }),
      });
    },
    [
      currentSelections,
      currentScenario,
      mergeAllSelections,
      updateScenarioProducts,
    ]
  );

  /** Apply multiple selections */
  const applySelections = useCallback(
    async (selections: ILocationSelection[], newScenarioId?: string) => {
      const newSelections = selections.map((s) => ({
        ...s,
        id: !s.id ? `newSelection${v4()}` : s.id,
      }));

      if (!newScenarioId) {
        updateScenarioProducts({
          ...currentScenario,
          selections: mergeAllSelections({
            currentScenario,
            newSelections,
          }),
        });
      } else {
        const newScenario = await scenarioRequest({
          endpoint: `scenarios/${newScenarioId}`,
        });

        if (newScenario) {
          updateScenarioProducts({
            ...newScenario,
            selections: mergeAllSelections({
              currentScenario: { ...newScenario },
              newSelections,
            }),
          });
        }
      }
    },
    [currentScenario, mergeAllSelections, updateScenarioProducts]
  );

  /** Apply a single selection */
  const applySelection: TOnSelectionChange = useCallback(
    ({ selectionId, productId, selectionValues, opType }) => {
      const newSelection = generateSelection({
        productId,
        selectionId,
        selectionValues,
      });

      if (!bundleId) {
        updateScenarioProducts({
          ...currentScenario,
          selections: mergeAllSelections({
            newSelections: [newSelection],
            currentScenario,
          }),
        });

        if (newSelection.familyName) {
          trackSelectAction(newSelection.familyName, { opType });
        }
      } else if (bundleId) {
        updateBundleProducts(
          mergeAllSelections({ currentBundle, newSelections: [newSelection] })
        );
      }
    },
    [
      generateSelection,
      bundleId,
      updateScenarioProducts,
      currentScenario,
      mergeAllSelections,
      trackSelectAction,
      updateBundleProducts,
      currentBundle,
    ]
  );

  /* Apply selections from a bundle */
  const onBundleSelect = useCallback(
    (bundle: IBundle) => {
      const { selections: bundleSelections } = bundle;
      const selections = bundleSelections.map(
        // need to fix bundle typing, doesn't match BE
        (s: IBundle['selections'][number]) => {
          const selectionId = currentSelections.find(
            (cs) => cs.productId === s.productId || cs.familyId === s.familyId
          )?.id;

          const newSelection = generateSelection({
            selectionId,
            productId: s.productId,

            selectionValues: {
              rateCard: s.rateCard,
              quantity: s.quantity,
              profileId: s.profileId,
              discretionValue12: s.discretionValue12,
              discretionValue24: s.discretionValue24,
              discretionValue36: s.discretionValue36,
              discretionValue48: s.discretionValue48,
              discretionValue60: s.discretionValue60,
              discretionValue84: s.discretionValue84,
            },
          });

          return newSelection as ILocationSelection;
        }
      );

      const selectionIds = new Set(selections.map((s) => s.id));

      const newSelections = [
        ...selections,
        ...currentSelections.filter((s) => !selectionIds.has(s.id)),
      ];

      updateScenarioProducts({
        ...currentScenario,
        selections: mergeAllSelections({
          newSelections,
          currentScenario,
        }),
      });
    },
    [
      currentSelections,
      updateScenarioProducts,
      currentScenario,
      mergeAllSelections,
      generateSelection,
    ]
  );

  const clearBundle = useCallback(
    (bundle: IBundle) => {
      const { selections: bundleSelections } = bundle;
      const newSelections = bundleSelections.map((s) => {
        const selectionId = currentSelections.find(
          (cs) => cs.productId === s.productId || cs.familyId === s.familyId
        )?.id;
        const newSelection = generateSelection({
          selectionId,
          productId: NONE,
          selectionValues: {
            rateCard: s.rateCard,
            quantity: s.quantity,
            profileId: s.profileId,
            discretionValue36: s.discretionValue36,
            discretionValue48: s.discretionValue48,
            discretionValue60: s.discretionValue60,
          },
        });
        return newSelection as ILocationSelection;
      });
      updateScenarioProducts({
        ...currentScenario,
        selections: mergeAllSelections({
          newSelections,
          currentScenario,
        }),
      });
    },
    [
      currentScenario,
      currentSelections,
      generateSelection,
      mergeAllSelections,
      updateScenarioProducts,
    ]
  );

  /** Remove an array of selections */
  const removeSelections = useCallback(
    (selectionsToRemove: ILocationSelection[]) => {
      const idsToRemove = new Set(selectionsToRemove.map((s) => s.id));
      updateScenarioProducts({
        ...currentScenario,
        selections: dependencyCheck(
          currentScenario?.selections.map((s) => {
            if (idsToRemove.has(s.id)) {
              return { ...s, productId: NONE };
            }
            return s;
          }) || []
        ),
      });
    },
    [currentScenario, dependencyCheck, updateScenarioProducts]
  );

  /** Add/update selections based on productId/profileId/categoryId */
  const addProductSelections = useCallback(
    (
      products: {
        productId: string;
        quantity?: number;
        profileId?: string;
        profileCategoryId?: string;
      }[]
    ) => {
      const newSelections = products.map((p) => {
        const { productId, quantity = 1, profileId, profileCategoryId } = p;

        const selectionId = currentScenario?.selections?.find((s) => {
          if (s.profileCategoryId || s.profileId) {
            return (
              s.profileCategoryId === profileCategoryId &&
              s.profileId === profileId
            );
          }
          return false;
        })?.id;

        return generateSelection({
          selectionId,
          productId,
          selectionValues: {
            quantity,
            profileId,
            profileCategoryId,
          },
        });
      });

      applySelections(newSelections);
    },
    [applySelections, currentScenario, generateSelection]
  );

  /** Toggle promo on/off for current scenario */
  const togglePromo = useCallback(
    (promo: IPromo) => {
      if (!currentScenario) {
        return;
      }

      const appliedPromo = currentScenarioPromos?.find(
        (sp) => sp.promoId === promo.id
      );

      if (appliedPromo) {
        if (appliedPromo.id) {
          deleteScenarioPromos([appliedPromo.id]);
        }
      } else if (estimateId) {
        const newScenarioPromo: {
          promoId: string;
          scenarioId: string;
          estimateId: string;
          locationId?: string | null;
        } = {
          promoId: promo.id,
          scenarioId,
          estimateId,
        };
        if (promo.isLocationSpecific) {
          newScenarioPromo.locationId = locationId;
        }
        postScenarioPromo(newScenarioPromo);
      }
    },
    [
      currentScenario,
      currentScenarioPromos,
      deleteScenarioPromos,
      estimateId,
      locationId,
      postScenarioPromo,
      scenarioId,
    ]
  );

  /** Update eip term for current scenario - current location products */
  const updateLocationSelectionEipTerm = useCallback(
    (eipTerm: TEipTermLengthMonths) => {
      const selections = currentScenario?.selections.map((s) => {
        if (
          s.locationId === locationId &&
          (s.familyId === rcPhonesAndDevicesId ||
            s.productId === rcIswId ||
            s.productId === inFootprintIswId ||
            s.productId === outOfFootprintIswId)
        ) {
          return { ...s, eipTerm: eipTerm };
        }
        return s;
      });
      if (selections) {
        updateScenarioProducts({
          ...currentScenario,
          selections: selections,
        });
      }
    },
    [currentScenario, updateScenarioProducts, locationId]
  );

  /** Update term for current scenario */
  const updateScenarioTerm = useCallback(
    (term: TTermLengthMonths) => {
      const promosToKeep =
        currentScenario?.promos?.reduce((acc: IScenarioPromo[], sp) => {
          const promo = allPromos.find((p) => sp.promoId === p.id);
          if (promo) {
            if (
              !promo?.requiredTerm ||
              parseInt(promo.requiredTerm) <= parseInt(term)
            ) {
              acc.push(sp);
            }
          }
          return acc;
        }, []) || [];

      const selections = currentScenario?.selections.map((s) => {
        if (s.familyId === rcPhonesAndDevicesId || s.productId === rcIswId) {
          const maxEipTerm = eipTermMap(term);
          if (
            s.eipTerm !== 'Purchase' &&
            s.eipTerm !== undefined &&
            s.eipTerm !== null &&
            s.eipTerm > maxEipTerm
          ) {
            s.eipTerm = maxEipTerm;
            return s;
          }
        } else if (
          s.productId === inFootprintIswId ||
          s.productId === outOfFootprintIswId
        ) {
          const maxEipTerm = iswEipTermMap(term);
          if (
            s.eipTerm !== 'One-time Payment' &&
            s.eipTerm !== undefined &&
            s.eipTerm !== null &&
            s.eipTerm > maxEipTerm
          ) {
            s.eipTerm = maxEipTerm;
            return s;
          }
        }
        return s;
      });

      updateScenarioProducts({
        ...currentScenario,
        promos: promosToKeep,
        term,
        selections: selections,
      });
      trackSelectAction('SelectTerm');
    },
    [currentScenario, updateScenarioProducts, trackSelectAction, allPromos]
  );

  const removeDisabledPromos = useCallback(() => {
    const promosToKeep =
      currentScenario?.promos?.reduce((acc: IScenarioPromo[], sp) => {
        const promo = allPromos.find((p) => sp.promoId === p.id);
        if (promo) {
          acc.push(sp);
        }
        return acc;
      }, []) || [];

    if (
      currentScenario?.promos?.length !== promosToKeep.length ||
      !promosToKeep.every((cp) =>
        currentScenario?.promos?.some((pk) => cp.promoId === pk.promoId)
      )
    ) {
      updateScenarioProducts({
        ...currentScenario,
        promos: promosToKeep,
      });
      return true;
    }
    return false;
  }, [currentScenario, updateScenarioProducts, allPromos]);

  const clearProfiles = useCallback(
    (profiles: IProfile[]) => {
      const ids = profiles.map((p) => p.id);
      deleteProfiles(ids);
    },
    [deleteProfiles]
  );

  // Add profile logic (must add profile then onSuccess add any selections)
  const selections = useRef<ILocationSelection[] | null>([]);
  const profileScenarioId = useRef<string | null>(null);

  const onProfileAddSuccess = useCallback(
    async (profilesRes: IProfile[]) => {
      const profileSelections =
        estimateId === 'sandbox'
          ? selections.current?.filter((s) => s.profileId) || []
          : profilesRes.reduce((acc: ILocationSelection[], profile, i) => {
              // profileSelections will have indexed profileId ('0', '1', '2') to find matching profile,
              // because profiles have been deleted & will have new profileId.
              // Response from mutation will be used to fill in correct new profileId
              const mappedProfileSelections =
                selections.current?.reduce((acc: ILocationSelection[], s) => {
                  if (s.profileId === i.toString()) {
                    acc.push({ ...s, profileId: profile.id });
                  }
                  return acc;
                }, []) || [];
              acc.push(...mappedProfileSelections);
              return acc;
            }, []);

      const profileSelectionIds = new Set(
        profileSelections.map((s) => s.profileId)
      );

      const newSelections = [
        ...profileSelections,
        ...(selections.current?.filter(
          (s) => !s.profileId && !profileSelectionIds.has(s.id)
        ) || []),
      ];

      if (profileScenarioId.current) {
        await applySelections(newSelections, profileScenarioId.current);
      } else {
        applySelections(newSelections);
      }
    },
    [applySelections, estimateId, profileScenarioId, selections]
  );

  const { updateProfiles, updateProfilesLoading } = useUpdateProfiles({
    onSuccess: onProfileAddSuccess,
  });

  const convertProfilesAndSelectionsForApi = useCallback(
    (params: {
      profiles: Partial<IProfile>[];
      selections: ILocationSelection[];
    }) => {
      const { profiles, selections } = params;

      if (estimateId === 'sandbox') {
        return { profilesToAdd: profiles, selectionsToAdd: selections };
      }

      const { addedSelections, removedSelections } = selections.reduce(
        (
          acc: {
            addedSelections: ILocationSelection[];
            removedSelections: ILocationSelection[];
          },
          s
        ) => {
          if (s.productId === NONE) {
            acc.removedSelections.push(s);
          } else {
            acc.addedSelections.push(s);
          }
          return acc;
        },
        { addedSelections: [], removedSelections: [] }
      );

      const { nonProfileSelections, profileSelections } =
        addedSelections.reduce(
          (
            acc: {
              nonProfileSelections: ILocationSelection[];
              profileSelections: ILocationSelection[];
            },
            s
          ) => {
            if (s.profileId) {
              acc.profileSelections.push(s);
            } else {
              acc.nonProfileSelections.push(s);
            }
            return acc;
          },
          { nonProfileSelections: [], profileSelections: [] }
        );

      const { apiProfiles, apiSelections } = profiles.reduce(
        (
          acc: {
            apiSelections: ILocationSelection[];
            apiProfiles: Partial<IProfile>[];
          },
          profile,
          i
        ) => {
          // Remove id and convert profileId to stringified index value.
          // This id will be used to match selection w/ correct profile
          const indexedSelections = profileSelections.reduce(
            (acc: ILocationSelection[], s) => {
              if (s.profileId === profile.id) {
                acc.push({
                  ...s,
                  profileId: i.toString(),
                });
              }
              return acc;
            },
            []
          );

          const storedProfile = {
            ...profile,
            id: undefined,
          };

          acc.apiSelections.push(...indexedSelections);
          acc.apiProfiles.push(storedProfile);
          return acc;
        },
        {
          apiSelections: [...removedSelections, ...nonProfileSelections],
          apiProfiles: [],
        }
      );

      return { profilesToAdd: apiProfiles, selectionsToAdd: apiSelections };
    },
    [estimateId]
  );

  /** Handles application of selections containing profileIds by creating profiles first,
   * and then applying selections to avoid database relational errors */
  const applyProfilesAndSelections = useCallback(
    (params: {
      profiles: Partial<IProfile>[];
      selections: ILocationSelection[];
      newScenarioId?: string;
    }) => {
      const { profilesToAdd, selectionsToAdd } =
        convertProfilesAndSelectionsForApi({
          profiles: params.profiles,
          selections: params.selections,
        });
      selections.current = selectionsToAdd;
      if (params.newScenarioId) {
        profileScenarioId.current = params.newScenarioId;
      }
      updateProfiles(profilesToAdd);
    },
    [convertProfilesAndSelectionsForApi, updateProfiles]
  );

  return {
    applySelection,
    onBundleSelect,
    clearSelections,
    togglePromo,
    updateScenarioTerm,
    applySelections,
    removeSelections,
    addProductSelections,
    clearProfiles,
    clearBundle,
    deleteProfileLoading,
    updateScenarioProductsLoading,
    generateSelection,
    updateProfilesLoading,
    applyProfilesAndSelections,
    deleteScenarioPromosLoading,
    postScenarioPromoLoading,
    removeDisabledPromos,
    updateLocationSelectionEipTerm,
  };
};
