import { IProductFamily } from 'types';

export const mockProductFamilies: IProductFamily[] = [
  {
    name: 'DFI Speed',
    type: 'enum',
    hasDiscretion: true,
    category: 'Data',
    subcategory: false,
    order: 1100,
    description:
      'UHSD speeds (20Gb+) require DRT review. MSS, MRS, and DDoS are ICB when combined with UHSD speeds and are not supported in SPT.',
    published: true,
    rateNrc: 250,
    discountNrc: 250,
    id: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
    products: [
      {
        id: '4751bebb-963c-4a43-81cd-1b283b0753e2',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '20M',
        notes: [],
        order: 1000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '8a121c4b-1954-4617-af41-070744909f7a',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '30M',
        notes: [],
        order: 2000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'f8f0ee63-f08d-473b-bb77-0efad597aeec',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '50M',
        notes: [],
        order: 3000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'd58ea67d-61d4-4ff1-a2ee-147ec27d206d',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '100M',
        notes: [],
        order: 4000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'c700bba0-7e7c-4a64-9d78-f04b290ffd6f',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '200M',
        notes: [],
        order: 5000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'eb5730af-738f-4ee1-9a22-8de94e78f64f',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '500M',
        notes: [],
        order: 6000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'ab645693-f10a-4797-8d6a-e98d937d0e32',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '1G',
        notes: [],
        order: 7000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'e0f762ad-c41b-449f-b0d0-7053e88c04a8',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '2G',
        notes: [],
        order: 8000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '8e0b6de9-92c2-4bb6-8ea9-528506bd106b',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '5G',
        notes: [],
        order: 9000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '383936ea-d0c7-4b4f-88ba-2bc47fd15cae',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '10G',
        notes: [],
        order: 10000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'd4779db4-e2aa-40b0-90eb-0c97e3896920',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '20G',
        notes: [],
        order: 11000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '3609fbd0-af77-47c1-8d2a-897056270605',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '30G',
        notes: [],
        order: 12000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'b702197d-7ebc-43da-923b-579f46fea6b0',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '40G',
        notes: [],
        order: 13000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '333e15b2-2a53-433e-9ada-c30f22c9ac19',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '50G',
        notes: [],
        order: 14000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '865a04de-2ac9-4f93-b06c-bcc390082c18',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '60G',
        notes: [],
        order: 15000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '8abfe2ec-f55f-4940-9204-032dfb6639d8',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '70G',
        notes: [],
        order: 16000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'a327e87e-3cc9-4826-8ffa-c182603aa686',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '80G',
        notes: [],
        order: 17000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'eca64ca2-f893-490b-96cd-47bf4fc66df7',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '90G',
        notes: [],
        order: 18000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'a629d500-9d50-4217-95fc-7fdfaac2d99d',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '100G',
        notes: [],
        order: 19000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
    ],
    createdAt: '2022-05-20T21:45:26.104Z',
    updatedAt: '2022-05-20T21:45:26.104Z',
  },
  {
    name: 'MNE Network Speed',
    type: 'enum',
    hasDiscretion: false,
    category: 'MNE',
    subcategory: false,
    order: 1100,
    description:
      'Provides routing, security, & SD-WAN functionality; Maximum of 2 WAN Uplinks; Requires an Internet connection at each location',
    published: true,
    rateNrc: 250,
    discountNrc: 0,
    id: '492a55d9-71ba-46b5-af56-e36c3dee663a',
    products: [
      {
        id: '21f483a0-361d-4592-ab8b-394f1070ae41',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '492a55d9-71ba-46b5-af56-e36c3dee663a',
        name: '20 Mbps',
        notes: [],
        order: 1000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'f59721b2-af54-493b-a911-a5b1081b9e5b',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '492a55d9-71ba-46b5-af56-e36c3dee663a',
        name: '30 Mbps',
        notes: [],
        order: 2000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '4be77cc5-55ed-44cf-9ed8-e4aa57863e90',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '492a55d9-71ba-46b5-af56-e36c3dee663a',
        name: '50 Mbps',
        notes: [],
        order: 3000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '5c399ef1-dc0f-4b0f-977b-f9ea3deba9d8',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '492a55d9-71ba-46b5-af56-e36c3dee663a',
        name: '100 Mbps',
        notes: [],
        order: 4000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '2244a7e0-d80d-4289-8638-0f67275dc865',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '492a55d9-71ba-46b5-af56-e36c3dee663a',
        name: '200 Mbps',
        notes: [],
        order: 5000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '976795c4-92aa-479c-b329-a669b3479dd4',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '492a55d9-71ba-46b5-af56-e36c3dee663a',
        name: '500 Mbps',
        notes: [],
        order: 6000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '8273fd5a-a02c-4740-bdcb-296fe662a71c',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '492a55d9-71ba-46b5-af56-e36c3dee663a',
        name: '1 Gbps',
        notes: [],
        order: 7000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
    ],
    createdAt: '2022-05-20T21:45:26.104Z',
    updatedAt: '2022-05-20T21:45:26.104Z',
  },
  {
    name: 'DFI Static IP',
    type: 'enum',
    hasDiscretion: false,
    category: 'Data',
    subcategory: false,
    order: 1200,
    description: '',
    published: true,
    rateNrc: 0,
    discountNrc: 0,
    id: 'fdf7290f-83e8-43d2-9632-da687858fbf0',
    products: [
      {
        id: 'c49eb90c-def9-4f5d-8cdf-055441c57e75',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: 'fdf7290f-83e8-43d2-9632-da687858fbf0',
        name: '1',
        notes: [],
        order: 1000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'a67ff729-19fc-46ac-9cdb-4d382e563f74',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: 'fdf7290f-83e8-43d2-9632-da687858fbf0',
        name: '5',
        notes: [],
        order: 2000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '727f53b5-8548-407c-8dcd-23bd03a18ea7',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: 'fdf7290f-83e8-43d2-9632-da687858fbf0',
        name: '13',
        notes: [],
        order: 3000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '0a4b7956-205a-4559-821e-9fdc17b1cba5',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: 'fdf7290f-83e8-43d2-9632-da687858fbf0',
        name: '29',
        notes: [],
        order: 4000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
    ],
    createdAt: '2022-05-20T21:45:26.104Z',
    updatedAt: '2022-05-20T21:45:26.104Z',
  },
  {
    name: 'MNE WiFi',
    type: 'number',
    hasDiscretion: false,
    category: 'MNE',
    subcategory: true,
    order: 2000,
    description: '',
    published: true,
    rateNrc: 150,
    discountNrc: 0,
    id: '3f884a0e-3b94-4678-8c03-b7a52756e441',
    products: [
      {
        id: '1bdabfe4-3c5d-48fc-8d5c-75c5fb20fd7e',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '3f884a0e-3b94-4678-8c03-b7a52756e441',
        name: 'General Purpose',
        notes: [
          'Standard performance AP',
          'Integrated enterprise security and guest access',
        ],
        order: 1000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '0097500d-234d-4881-969b-4d6d102454b9',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '3f884a0e-3b94-4678-8c03-b7a52756e441',
        name: 'High Performance',
        notes: [
          'High Performance AP w/ Multi-Gig capability',
          'Integrated enterprise security and guest access',
        ],
        order: 2000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'cfd4484d-fe75-458f-b14a-1f9972df4bb4',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '3f884a0e-3b94-4678-8c03-b7a52756e441',
        name: 'General Outdoor',
        notes: [
          'Outdoor standard performance AP Integrated enterprise security and guest access',
        ],
        order: 3000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '71891589-0cfe-44f3-a41b-3aff9e2e5d69',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '3f884a0e-3b94-4678-8c03-b7a52756e441',
        name: 'High Outdoor',
        notes: [
          'Outdoor high performance AP w/ Multi-Gig capability',
          'Integrated enterprise security and guest access',
        ],
        order: 4000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
    ],
    createdAt: '2022-05-20T21:45:26.104Z',
    updatedAt: '2022-05-20T21:45:26.104Z',
  },
  {
    name: 'Add-Ons',
    type: 'boolean',
    hasDiscretion: false,
    category: 'SBB 1-19 Pub/Priv',
    subcategory: true,
    order: 3000,
    description: '',
    published: true,
    rateNrc: 0,
    discountNrc: 0,
    id: '0aa186df-389b-4634-a2b2-397655dbafad',
    products: [
      {
        id: '80fc1dab-fff5-4180-8689-8f2d0e8102b9',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'HBO Multiplex',
        notes: ['Private'],
        order: 1000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '34a36e89-ee4c-46d4-b860-951574169786',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'Cinemax Multiplex',
        notes: ['Private'],
        order: 2000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'bdc4a803-cb48-4796-ac52-f684f2a5c8a0',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'Showtime Multiplex',
        notes: ['Private'],
        order: 3000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'e8b4e626-a6c1-4ef1-9d68-6c47c822afd5',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'STARZ Multiplex',
        notes: ['Private'],
        order: 4000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'b6cddb04-1b17-4f88-9c0b-fca53e7e32c9',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'TMC',
        notes: ['Private'],
        order: 5000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '1bc4d75b-c1d0-465b-b0b2-0fa9814fa5ed',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'Encore',
        notes: ['Private'],
        order: 6000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '62e4428f-c942-4219-9723-c235c0b46fca',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'EPIX',
        notes: ['Private'],
        order: 7000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'cf3c7bf9-a9a8-48f1-b64c-4a3c37bd9dbf',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'TV Japan',
        notes: ['Public', 'Private'],
        order: 8000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '77b111f4-33c2-4651-babc-123acfecdcd6',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'TV5 Monde',
        notes: ['Public', 'Private'],
        order: 9000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'e8152e8f-04be-480c-ab8c-e2516a9377fa',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'Channel One Russia',
        notes: ['Public', 'Private'],
        order: 10000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '4084ba87-be88-4ad4-b2ad-1a4666b1b861',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'TV Globo',
        notes: ['Public', 'Private'],
        order: 11000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '57983d49-0d29-4ee0-a96c-0ee9c30c8398',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '0aa186df-389b-4634-a2b2-397655dbafad',
        name: 'Music Choice',
        notes: ['Public', 'Private'],
        order: 12000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
    ],
    createdAt: '2022-05-20T21:45:26.104Z',
    updatedAt: '2022-05-20T21:45:26.104Z',
  },
  {
    name: 'Location',
    type: 'radio',
    hasDiscretion: false,
    category: 'SBB 1-19 Pub/Priv',
    subcategory: false,
    order: 1000,
    description: '',
    published: true,
    rateNrc: 0,
    discountNrc: 0,
    id: '7a4e14ec-6007-445b-9787-f56f9f39df02',
    products: [
      {
        id: '90b4f85f-e1d0-4856-a6ec-e107eddc4662',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '7a4e14ec-6007-445b-9787-f56f9f39df02',
        name: 'Public',
        notes: [],
        order: 1000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: '7be3f63c-70aa-4ee1-acf2-d95af23c05df',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '7a4e14ec-6007-445b-9787-f56f9f39df02',
        name: 'Private',
        notes: [],
        order: 2000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
      {
        id: 'b5afe805-a2d9-4658-b8ce-448b05720ddc',
        createdAt: '2022-05-20T21:45:26.107Z',
        updatedAt: '2022-05-20T21:45:26.107Z',
        productFamilyId: '7a4e14ec-6007-445b-9787-f56f9f39df02',
        name: 'Mixed Public / Private',
        notes: [],
        order: 3000,
        updatedBy: 'P3059592',
        imgUrl: null,
        prices: [],
      },
    ],
    createdAt: '2022-05-20T21:45:26.104Z',
    updatedAt: '2022-05-20T21:45:26.104Z',
  },
];
