import axios from 'axios';
import { IEstimateRequest } from 'types';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

export const estimateBySfId = async (config?: IEstimateRequest) => {
  const { endpoint = 'estimates/sf', method = 'GET', params } = config || {};
  const url = `${env.apiUrl}/${endpoint}/${params?.opportunityId}`;

  return axios({
    url,
    method,
    validateStatus: function (status) {
      return status <= 404; // Reject only if the status code is greater than 400
    },
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong retrieving estimate from Salesforce with opportunity Id: ${params?.opportunityId} `,
      })
    );
};
