import axios from 'axios';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IDeleteFromS3Params {
  fileKey: string;
}

export const deleteFileFromS3 = async ({ fileKey }: IDeleteFromS3Params) => {
  return axios({
    method: 'DELETE',
    url: `${env.apiUrl}/files`,
    data: {
      objectKey: fileKey,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((error) =>
      throwQueryError({
        error,
        message: 'Something went wrong deleting the file from S3',
      })
    );
};
