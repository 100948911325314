import { useMutation, useQueryClient } from 'react-query';
import { IBundle } from 'types';
import { bundleRequest, queryKeys } from 'utils';

export const useDeleteBundle = () => {
  const queryClient = useQueryClient();
  const allBundlesKey = queryKeys().all.bundles;
  const { mutate: deleteBundle } = useMutation(
    (bundleId: string) =>
      bundleRequest({
        method: 'DELETE',
        endpoint: `bundles/${bundleId}`,
      }),
    {
      onMutate: async (bundleId: string) => {
        await queryClient.cancelQueries(allBundlesKey);
        const prevBundles =
          queryClient.getQueryData<IBundle[]>(allBundlesKey) || [];
        const newBundles = prevBundles.filter((b) => b.id !== bundleId);
        queryClient.setQueryData(allBundlesKey, newBundles);
        return { prevBundles };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(allBundlesKey);
        queryClient.setQueryData(allBundlesKey, context?.prevBundles);
      },
      onSettled: () => {
        queryClient.invalidateQueries(allBundlesKey);
      },
    }
  );

  return {
    deleteBundle,
  };
};
