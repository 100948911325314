// Packages
import React, { useCallback, useState } from 'react';

// Redux

// Components
import { UCQHistoryDrawer, UCQReminders, ProgressBar } from 'components';
import { KiteButton } from '@kite/react-kite';

// Hooks
import {
  useGetEstimateDetails,
  useGetFields,
  useGetLocationById,
  useGetSubmissions,
  useLocationCompletePercent,
  useUcqApproval,
} from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { UCQFORMS } from 'utils';

// Types

// Styles
import './UcReviewLocationHeader.scss';

export interface IUcReviewLocationHeaderProps {
  opptyName: string;
  locationName: string;
  hasBadges?: boolean;
  hasHistory?: boolean;
  hasProgress?: boolean;
}

/** Location header on the read only page  */

const UcReviewLocationHeader = ({
  opptyName,
  locationName,
  hasBadges,
  hasHistory,
  hasProgress,
}: IUcReviewLocationHeaderProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId, locationId = '' } = useParams();

  const { estimateData } = useGetEstimateDetails(estimateId);

  const { data: locationData } = useGetLocationById(locationId);

  const { completePercent } = useLocationCompletePercent({
    submissions: locationData?.submissions || [],
    locationId,
  });

  // Need consistent allSubmissions to handle reminders badge logic for SME
  const { data: allSubmissions } = useGetSubmissions({
    params: { estimateId },
  });

  const { data: allFields } = useGetFields({ params: { type: UCQFORMS } });

  const { requiresSmeApproval, smeInputAnswers, requiresSparcPreContract } =
    useUcqApproval({
      submissions: allSubmissions,
      fields: allFields,
    });

  const [historyDrawerIsOpen, setHistoryDrawerIsOpen] = useState(false);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleHistoryDrawerIsOpen = useCallback(() => {
    setHistoryDrawerIsOpen(!historyDrawerIsOpen);
  }, [historyDrawerIsOpen]);
  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="uc-review-location-header">
      <div className="uc-review-location-header__top">
        <p className="uc-review-location-header__top__oppty-name">
          {opptyName}
        </p>
        {hasHistory && (
          <KiteButton onClick={toggleHistoryDrawerIsOpen} type="outline">
            History
          </KiteButton>
        )}
      </div>
      <div className="uc-review-location-header__lower">
        <h1 className="uc-review-location-header__lower__title">
          {locationName}
        </h1>
        {hasProgress && (
          <ProgressBar
            percentage={completePercent || 0}
            title={'UC Question Progress'}
          />
        )}
        {hasBadges && (
          <UCQReminders
            requiresSmeApproval={requiresSmeApproval}
            smeInputAnswers={smeInputAnswers}
            showHeader={false}
            estimate={estimateData}
            requiresSparcPreContract={requiresSparcPreContract}
          />
        )}
      </div>
      {historyDrawerIsOpen && (
        <UCQHistoryDrawer
          isOpen={historyDrawerIsOpen}
          onClose={toggleHistoryDrawerIsOpen}
        />
      )}
    </div>
  );
};

export default UcReviewLocationHeader;
