// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

// Components
import { LabeledSwitch } from '@kite/react-kite-plus';
import { CustomDropdown } from 'components';

// Utils
import { generateClassnames, NONE } from 'utils';

// Hooks
import { useDebounceInputSave, useQueryDataContext } from 'hooks';

// Types
import {
  TBusInternetType,
  IProductFamily,
  IProduct,
  ISelectionController,
} from 'types';

// Styles
import './DropdownSwitch.scss';

interface IDropdownSwitchProps extends ISelectionController {
  productFamily: IProductFamily;
}

const DropdownSwitch = ({ productFamily, onChange }: IDropdownSwitchProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    allProducts,
    currentSelections,
    scenarioId: currentScenarioId,
  } = useQueryDataContext();
  const { saveSelection, debounceSave } = useDebounceInputSave({ onChange });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const { pageClass, componentClass } = generateClassnames(productFamily);

  const switchSelection = useMemo(() => {
    return currentSelections.find(
      (selection) => selection.familyId === productFamily.id
    );
  }, [productFamily.id, currentSelections]);

  const selectionValue: TBusInternetType = useMemo(() => {
    if (switchSelection?.name.includes('M to M')) {
      return 'M to M';
    }
    return 'Term';
  }, [switchSelection]);

  const [inputValue, setInputValue] = useState<{
    scenarioId: string;
    value: TBusInternetType;
  }>({
    scenarioId: currentScenarioId,
    value: selectionValue,
  });

  const customValues = useMemo(() => {
    return productFamily.products.reduce((products: IProduct[], product) => {
      if (product.name.includes(selectionValue)) {
        products.push({
          ...product,
          name: product.name.replace(` ${selectionValue}`, ''),
        });
      }

      return products;
    }, []);
  }, [productFamily.products, selectionValue]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSave = useCallback(
    (params: {
      scenarioId: string;
      value: TBusInternetType;
      isDebounce?: boolean;
    }) => {
      const { scenarioId, value, isDebounce } = params;
      const product = allProducts.find((p) => p.name === value);
      if (product) {
        isDebounce
          ? debounceSave({
              selectionId: switchSelection?.id,
              productId: product?.id || NONE,
              selectionValues: { quantity: 1 },
              opType: 'toggleFlip',
              scenarioId,
            })
          : saveSelection({
              selectionId: switchSelection?.id,
              productId: product?.id || NONE,
              selectionValues: { quantity: 1 },
              opType: 'toggleFlip',
            });
      }
    },
    [allProducts, debounceSave, saveSelection, switchSelection]
  );

  const handleLabeledSwitchToggle = useCallback(() => {
    const newValue = inputValue.value === 'Term' ? 'M to M' : 'Term';
    const newProductName = switchSelection?.name.replace(
      selectionValue,
      newValue
    ) as TBusInternetType;

    onSave({
      scenarioId: currentScenarioId,
      value: newProductName,
      isDebounce: true,
    });
    if (newValue) {
      setInputValue({ scenarioId: currentScenarioId, value: newValue });
    }
  }, [
    inputValue.value,
    switchSelection,
    selectionValue,
    onSave,
    currentScenarioId,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (
      inputValue.scenarioId !== currentScenarioId ||
      (inputValue.value !== selectionValue && !debounceSave.isPending())
    ) {
      setInputValue({ scenarioId: currentScenarioId, value: selectionValue });
    }
  }, [currentScenarioId, debounceSave, inputValue, selectionValue]);

  useEffect(() => {
    if (!switchSelection) {
      setInputValue({ scenarioId: currentScenarioId, value: 'Term' });
    }
  }, [currentScenarioId, switchSelection]);

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={classNames({
        'dropdown-switch': true,
        [`${pageClass}__dropdown-switch`]: true,
        [`${pageClass}__dropdown-switch--${componentClass}`]: true,
      })}
    >
      <div className="dropdown-switch__container">
        <CustomDropdown
          productFamily={productFamily}
          customValues={customValues}
        />

        <LabeledSwitch
          title="Business Internet Rate Type"
          className={classNames({
            'dropdown-switch__labeled-switch': true,
            [`${pageClass}__labeled-switch`]: true,
            [`${pageClass}__labeled-switch--${componentClass}`]: true,
          })}
          options={['Term', 'M to M']}
          checked={inputValue.value === 'M to M'}
          onChange={handleLabeledSwitchToggle}
          disabled={!switchSelection}
          width="300px"
        />
      </div>
    </div>
  );
};

export default DropdownSwitch;
