import axios from 'axios';
import {
  IRequestConfig,
  IEstimate,
  IEstimateUpdate,
  IRequestParams,
  TTermLengthMonths,
  INewEstimate,
} from 'types';
import { paramsSerializer } from 'utils';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IReportParams extends IRequestParams {
  isSandbox?: boolean;
  userId?: string;
  status?: IEstimate['status'];
  termMonth?: TTermLengthMonths;
  opportunityId?: string;
  archived?: boolean;
  fromDate?: string;
  toDate?: string;
}

export interface IReportRequest
  extends IRequestConfig<IEstimate | INewEstimate | IEstimateUpdate> {
  params?: IReportParams;
}

export const reportsRequest = async (config?: IReportRequest) => {
  const { endpoint = 'reports', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) => {
      if (error.response.status === 409) {
        throw new Error(
          JSON.stringify({
            status: error.response.status,
            id: error.response.data.id,
          })
        );
      }
      throwQueryError({
        error,
        message: `Something went wrong with reports ${method}`,
      });
    });
};
