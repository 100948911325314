// External Packages
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

// Components
import { KiteButton, KiteCard, KiteInput } from '@kite/react-kite';
import { CustomAddressForm, LocationAddressInput } from 'components';

// Utils
import { blankAddress, removeEmojis } from 'utils';

// Hooks
import { useQueryData } from 'hooks';

// Types
import { ILocationBase } from 'types';

// Styles
import './LocationForm.scss';

interface ILocationFormProps {
  locationData: ILocationBase;
  onSaveLocation: (location: ILocationBase) => void;
  onCancelSave: () => void;
}

// =============================================
// Component
// =============================================
const LocationForm = ({
  locationData,
  onSaveLocation,
  onCancelSave,
}: ILocationFormProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const { currentEstimate } = useQueryData();

  const [locationName, setLocationName] = useState(locationData.name);
  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const [nameError, setNameError] = useState('');

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const defaultAddress = useMemo(() => {
    return {
      address1: locationData.address1,
      address2: locationData.address2,
      city: locationData.city,
      state: locationData.state,
      zipcode: locationData.zipcode,
    };
  }, [locationData]);

  const [detailedAddress, setDetailedAddress] = useState(defaultAddress);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLocationName(e.target.value);
      setNameError('');
    },
    [setLocationName, setNameError]
  );

  const checkNameError = useCallback(() => {
    const locationWithSameName = currentEstimate?.locations.find((l) => {
      return l.name === locationName && l.id !== locationData.id;
    });
    const nameWithoutEmojis = removeEmojis(locationName);
    let errorMessage = '';

    if (locationWithSameName) {
      errorMessage = 'Location names must be unique.';
    } else if (nameWithoutEmojis !== locationName) {
      errorMessage = 'Location name can not have emojis';
    } else if (!locationName.trim()) {
      errorMessage = 'Location name can not be blank';
    }
    setNameError(errorMessage);

    if (errorMessage) return true;
    return false;
  }, [currentEstimate, locationName, locationData]);

  const handleSave = useCallback(() => {
    const nameErrorCheck = checkNameError();

    if (!nameErrorCheck) {
      onSaveLocation({
        ...locationData,
        ...detailedAddress,
        name: locationName,
      });
    }
    setAddressModalOpen(false);
  }, [
    detailedAddress,
    locationName,
    locationData,
    checkNameError,
    onSaveLocation,
  ]);

  const toggleAddressModal = useCallback(() => {
    setAddressModalOpen(!addressModalOpen);
  }, [addressModalOpen]);

  const clearAddress = useCallback(() => {
    setDetailedAddress(blankAddress);
  }, []);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="location-form">
      <form action="">
        <KiteInput
          id="location name"
          className="location-form__input"
          label="Location Name"
          value={locationName}
          onChange={handleNameChange}
          maxWidth="none"
          errorMessage={nameError}
        />

        <LocationAddressInput
          address={detailedAddress}
          onAddressSelect={setDetailedAddress}
          addressError={''}
          clearAddress={clearAddress}
        />

        <div className="location-form__custom-address">
          <p>Or</p>
          <KiteButton
            type="standalone-link"
            onClick={toggleAddressModal}
            margin="0px"
            minWidth="20px"
          >
            Enter Custom Address
          </KiteButton>
        </div>

        <div className="location-form__button-container">
          <KiteButton
            className="location-form__cancel-button"
            size="small"
            type="outline"
            onClick={onCancelSave}
          >
            Cancel
          </KiteButton>
          <KiteButton type={'primary'} size="small" onClick={handleSave}>
            Save
          </KiteButton>
        </div>
      </form>

      <CustomAddressForm
        locationName={locationName}
        nameError={nameError}
        checkNameError={checkNameError}
        handleNameChange={handleNameChange}
        detailedAddress={detailedAddress}
        setDetailedAddress={setDetailedAddress}
        addressModalOpen={addressModalOpen}
        setAddressModalOpen={setAddressModalOpen}
        onSave={handleSave}
      />
    </KiteCard>
  );
};

export default LocationForm;
