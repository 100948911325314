// Packages
import React from 'react';

// Redux

// Components

// Hooks

// Utils

// Types
import { IUCQDesignFlowNode } from 'types';

// Styles
import './NodeLabel.scss';

export interface INodeLabelProps {
  /** Text to be displayed within label */
  label: string;
  /** Type of label, used to determine label color */
  type: NonNullable<IUCQDesignFlowNode['nodeType']> | 'demarc';
  /** Optional className prop for additional styling */
  className?: string;
}

/** A color-coded label for a distro's node id (bdg, mdf, or idf node types) or other information (isDemarc) */

const NodeLabel = ({ label, type, className = '' }: INodeLabelProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return <span className={`node-label--${type} ${className}`}>{label}</span>;
};

export default NodeLabel;
