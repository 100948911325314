// External Packages
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ReactFlowProvider } from 'react-flow-renderer';
import { ClearCacheProvider } from 'react-clear-cache';
import { datadogRum } from '@datadog/browser-rum';

// Components
import App from './App';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

// React Query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Utils
import AnalyticsProvider from './utils/analytics/AnalyticsContext';
import * as serviceWorker from './serviceWorker';
import worker from 'utils/testing/worker';
import configs from 'configs/env-configs.json';
import { env } from 'utils';

// Styles
import './index.css';

//Initialize DataDog Rum
datadogRum.init({
  applicationId: '88c76a71-44ae-493e-a200-0a4cc8cf4976',
  clientToken: 'pubd587851e6ba47c99d60ad689b3cdda1c',
  site: 'datadoghq.com',
  service: 'spt',
  env: env.environment,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask',
});

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const queryClient = new QueryClient();

if (configs['mock-worker']) {
  worker.start({
    onUnhandledRequest: 'warn',
    serviceWorker: {
      url: './mockServiceWorker.js',
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ClearCacheProvider duration={5000}>
      <QueryClientProvider client={queryClient}>
        <AnalyticsProvider>
          <Provider store={store}>
            <ReactFlowProvider>
              <Router>
                <App />
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              </Router>
            </ReactFlowProvider>
          </Provider>
        </AnalyticsProvider>
      </QueryClientProvider>
    </ClearCacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
