import {
  IDetailedAddress,
  IDistro,
  TSbbAddonNotesStars,
  TSbbLocationOptions,
  TTermLengthMonths,
} from 'types';

export const NONE = 'None';
export const imageUrl =
  'https://cdn.spectrumflow.net/cwe/solution-pricing-tool/product-images';
export const UCQFORMS = 'SE Only, AE - MSS - SE, SME Only';
export const CPWFORMS = 'CPW Quote';

export const ultraHighSpeeds = [
  '20G',
  '30G',
  '40G',
  '50G',
  '60G',
  '70G',
  '80G',
  '90G',
  '100G',
];

export const invalidNumInputChars = ['+', '-', '.', 'e'];

export const businessIntSpeedAlert =
  'The following zip codes have the following speeds available: 06488 (100x10M only), 92339 (100x10/300x20M), 96713 (100x10M only).';
export const businessIntTermAlert =
  'Business Internet pricing remains the same regardless of Term length.';

export const sbbAddonNotesConfiguration: {
  [Property in TSbbLocationOptions]: { [key: string]: TSbbAddonNotesStars };
} = {
  Public: {
    'Music Choice': '**',
  },
  Private: {
    'Music Choice': '**',
  },
  'Mixed Public / Private': {
    'HBO Multiplex': '*',
    'Cinemax Multiplex': '*',
    'Showtime Multiplex': '*',
    'STARZ Multiplex': '*',
    TMC: '*',
    Encore: '*',
    EPIX: '*',
    'Music Choice': '**',
  },
};

export const sbbAddonNotes: { [Property in TSbbAddonNotesStars]: string[] } = {
  '*': ['* Premium channels will not be available on Public outlets.'],
  '**': [
    '** A client with the Music Choice programming add-in is not permitted to:',
    '(i) charge a cover charge or admission fee to any Service Location(s) at the time the TV Service is being displayed or are to be displayed; or',
    '(ii) permit dancing, skating or other similar forms of entertainment or physical activity in conjunction with the performance of the TV Service',
  ],
};

export const sbbStepperValues = [
  {
    label: 'Location',
    status: 'initial',
    kiteIcon: 'home-f',
  },
  {
    label: 'Package',
    status: 'initial',
    kiteIcon: 'person',
  },
  {
    label: 'Add-Ons',
    status: 'initial',
    kiteIcon: 'plus',
  },
  {
    label: 'Equipment',
    status: 'initial',
    kiteIcon: 'model',
  },
];

export const networkEdgeStepperValues = {
  MNE: [
    {
      label: 'Network',
      status: 'initial',
      kiteIcon: 'internet',
    },
    {
      label: 'WiFi',
      status: 'initial',
      kiteIcon: 'wifi',
    },
    {
      label: 'Cameras',
      status: 'initial',
      kiteIcon: 'camera',
    },
    {
      label: 'Switches',
      status: 'initial',
      kiteIcon: 'outlet',
    },
    {
      label: 'Remote Workers',
      status: 'initial',
      kiteIcon: 'person',
    },
    {
      label: 'Sensors',
      status: 'initial',
      kiteIcon: 'person',
    },
    {
      label: 'Device Management',
      status: 'initial',
      kiteIcon: 'internet',
    },
  ],
  ENE: [
    {
      label: 'Network',
      status: 'initial',
      kiteIcon: 'internet',
    },
    {
      label: 'Switches',
      status: 'initial',
      kiteIcon: 'outlet',
    },
    {
      label: 'WiFi',
      status: 'initial',
      kiteIcon: 'wifi',
    },
    {
      label: 'Device Management',
      status: 'initial',
      kiteIcon: 'internet',
    },
  ],
};

// Installation Charge - New Site product for UC profiles
export const installProductId = 'dc9a80aa-1e19-45dd-a3b8-e01ef07c5e5a';

export const connectorTypes: NonNullable<IDistro['connectorType']>[] = [
  'RJ-45',
  'FC',
  'LC',
  'SC',
  'ST',
];

export const interconnectTypes: NonNullable<IDistro['interconnectType']>[] = [
  '100 AMP',
  '25 AMP',
  '50 AMP',
  'CAT3',
  'CAT5e',
  'CAT6',
  'MM Fiber',
  'SM Fiber',
  'Spectrum Provided Interconnect',
  'Single Building',
];

export const interconnectResponsibilityTypes: NonNullable<
  IDistro['interconnectResponsibility']
>[] = ['Customer Provided', 'Spectrum Provided'];

export const conduitTypes: NonNullable<IDistro['conduit']>[] = ['Yes', 'No'];

export const addressInputDetails: {
  value: keyof IDetailedAddress;
  label: string;
}[] = [
  {
    value: 'address1',
    label: 'Address Line 1',
  },
  {
    value: 'address2',
    label: 'Address Line 2 (optional)',
  },
  {
    value: 'city',
    label: 'City',
  },
  {
    value: 'state',
    label: 'State',
  },
  {
    value: 'zipcode',
    label: 'Zipcode',
  },
];

export const blankAddress: IDetailedAddress = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  name: '',
};

export const insightsMap = (mneSelections: string | undefined) => {
  switch (mneSelections) {
    case '20 Mbps':
    case '30 Mbps':
    case '50 Mbps':
    case '100 Mbps':
    case '200 Mbps':
      return 'Managed Network Insights - S';
    case '500 Mbps':
    case '1 Gbps':
      return 'Managed Network Insights - M';
    default:
      return '';
  }
};

export const sdWanMap = (mneSelections: string | undefined) => {
  switch (mneSelections) {
    case '20 Mbps':
      return 'SD-WAN Plus 20Mbps';
    case '30 Mbps':
      return 'SD-WAN Plus 30Mbps';
    case '50 Mbps':
      return 'SD-WAN Plus 50Mbps';
    case '100 Mbps':
      return 'SD-WAN Plus 100Mbps';
    case '200 Mbps':
      return 'SD-WAN Plus 200Mbps';
    case '500 Mbps':
      return 'SD-WAN Plus 500Mbps';
    case '1 Gbps':
      return 'SD-WAN Plus 1Gbps';
    default:
      return '';
  }
};

export const display$0Price = [
  'Per Phone Shipping Fee',
  'Per Phone Activation Fee',
];

export const rcProductFamilies = [
  'RC Seats',
  'RC Add Ons',
  'RC Phones / Devices',
  'RC Additional NRC',
];

export const rcAdditionalNRCProducts = [
  'On Site Professional Install',
  'Inside Wiring',
];

export const rcHelpText = [
  'Prices quoted are valid for 90 days and are subject to change and do not include any taxes, fees, and/or surcharges which may apply. THIS QUOTE SHALL NOT CONSTITUTE A BINDING CONTRACT BETWEEN SPECTRUM ENTERPRISE AND CUSTOMER.  THE PURCHASE OF SPECTRUM ENTERPRISE SERVICES SHALL BE GOVERNED BY THE TERMS AND CONDITIONS OF A SEPARATE SERVICE AGREEMENT TO BE ENTERED INTO BY SPECTRUM ENTERPRISE AND CUSTOMER.',
  ' If total number of devices (phones+BYOD) exceeds total number of seats (Std.+Prem.+Ltd Ext), additional devices cannot be activated.',
  ' Equipment may only be returned within 30 days of install;  restocking fees may apply.',
  ' Standard / Premium Seats come with unlimited usage that covers outbound calling in US-50, Puerto Rico, and Canada.',
  ' Charges for  Toll Free Usage, International Usage, SMS/MMS Usage, and TCR Fees are available at https://enterprise.spectrum.com/services/voice/unified-communications/unified-communications-with-ringcentral.html',
  ' Must have both MNE / ENE Edge Service & Mgd Switches to be eligible for Inside Wiring or Onsite Prof. Install.  Additional charges may apply.',
  ' If selected, Equipment Installment Plan is presented as an NRC on this form and Service Order.  Invoice will apply monthly charges for period.',
];

export const eipTermMap = (term: TTermLengthMonths) => {
  switch (term) {
    case '12':
      return '12';
    case '24':
      return '24';
    case '36':
    case '48':
    case '60':
    case '84':
      return '36';
    default:
      return 'Purchase';
  }
};

export const iswEipTermMap = (term: TTermLengthMonths) => {
  switch (term) {
    case '12':
      return '12';
    case '24':
      return '24';
    case '36':
      return '36';
    case '48':
      return '48';
    case '60':
      return '60';
    case '84':
      return '84';
    default:
      return 'One-time Payment';
  }
};

export const EstimateBuilderOffNetRateCard: { [quantity in number]: number } = {
  1: 1500,
  2: 1500,
  3: 1500,
  4: 1800,
  5: 2250,
  6: 2700,
  7: 2800,
  8: 3200,
  9: 3600,
  10: 4000,
  11: 4400,
  12: 4800,
};

export const offNetRateCard: { [quantity in number]: number } = {
  1: 1500,
  2: 1500,
  3: 1500,
  4: 3000,
  5: 3000,
  6: 3000,
  7: 3500,
  8: 3600,
  9: 4050,
  10: 4500,
  11: 4950,
  12: 5400,
  13: 5850,
  14: 6300,
  15: 6750,
  16: 7200,
  17: 7200,
  18: 7200,
  19: 7600,
  20: 8000,
  21: 8400,
  22: 8800,
  23: 9200,
  24: 9600,
  25: 10000,
  26: 10400,
  27: 10800,
  28: 11200,
  29: 11600,
  30: 12000,
};

// RC EIP and UC ISW IP
export const rcIswId = '3b27fb28-2012-4d29-974a-07455faba58b';
export const rcPhonesAndDevicesId = 'a75524a5-f60a-4753-8e39-aa91e17558e8';
export const professionalServicesId = 'c3f4f93f-d1bf-4424-95c2-fcba6cf9c65c';
export const outOfFootprintIswId = 'fdafdad6-72c3-447b-923a-8f7b5e2efc72';
export const inFootprintIswId = '91cf1d90-3989-48ee-85b7-10c91da31953';
