import { RootState } from './store';
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

export const isAdminSlice = createSlice<boolean, SliceCaseReducers<boolean>>({
  name: 'isAdmin',
  initialState: false,
  reducers: {
    storeIsAdmin: (_, action) => {
      return action.payload;
    },
  },
});

// Actions
export const { storeIsAdmin } = isAdminSlice.actions;

// State
// export const isAdmin = (state: RootState): boolean => state.isAdmin;
export const isAdmin = (state: RootState): boolean => state.isAdmin;

// Reducer
export default isAdminSlice.reducer;
