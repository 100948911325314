import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateProductFocus } from 'redux/productFocusSlice';

import { TProductFocus } from 'types';

export const useUpdateFocus = () => {
  const dispatch = useDispatch();

  const updateFocus = useCallback(
    (productFocus: TProductFocus) => {
      dispatch(updateProductFocus(productFocus));
    },
    [dispatch]
  );

  return { updateFocus };
};
