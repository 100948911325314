import { useState, useEffect } from 'react';

const getHeight = (element: Element | null) => {
  if (!element) return window.innerHeight;

  return element.clientHeight;
};

export const useCurrentHeight = (element?: Element | null) => {
  const [currentHeight, setCurrentHeight] = useState(0);

  useEffect(() => {
    const onResize = () => {
      setCurrentHeight(getHeight(element || null));
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [element]);

  return currentHeight;
};
