import { IField, IForm, IRule, ISubmission, ISubmissionHistory } from 'types';
import { IApiResponse } from './types';

import { mockFormList } from 'utils/mocks/mockForm';
import { mockEstimates, mockSubmissionHistory } from 'utils/mocks';

export const forms: IApiResponse<IForm> = {
  get: {
    200: {
      message: 'success',
      total: Object.keys(mockFormList).length,
      data: Object.values(mockFormList),
    },
  },
  getOne: {},
  post: {},
  put: {},
};

export const fields: IApiResponse<IField> = {
  get: {
    200: {
      message: 'success',
      total: Object.values(mockFormList)[0].fields.length,
      data: Object.values(mockFormList)[0].fields,
    },
  },
  getOne: {},
  post: {},
  put: {},
};

export const submissions: IApiResponse<ISubmission> = {
  get: {
    200: {
      message: 'success',
      total: mockEstimates.testOne.submissions?.length || 0,
      data: mockEstimates.testOne.submissions || [],
    },
  },
  getOne: {},
  post: {},
  put: {},
};

export const submissionHistories: IApiResponse<ISubmissionHistory> = {
  get: {
    200: {
      message: 'success',
      total: 1,
      data: mockSubmissionHistory,
    },
  },
  getOne: {},
  post: {},
  put: {},
};

export const rules: IApiResponse<IRule> = {
  get: {
    200: {
      message: 'success',
      total: [].length,
      data: [],
    },
  },
  getOne: {},
  post: {},
  put: {},
};
