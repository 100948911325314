// Packages
import React, { useEffect, useMemo } from 'react';

// Redux

// Components
import { KiteAlert, KiteLoader } from '@kite/react-kite';

// Hooks
import {
  useAnalytics,
  useGetEstimateBySfId,
  useGetLocationBySfId,
} from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';

// Utils

// Types

// Styles
import './SfFieldValRedirect.scss';

const SfFieldValRedirect = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();

  const navigate = useNavigate();

  const { search } = useLocation();

  const { opportunityId, sfLocationId } = useMemo(() => {
    return search.split('&').reduce(
      (acc, str) => {
        if (str.includes('op_id')) {
          acc.opportunityId = str.split('=')[1];
        } else if (str.includes('service_location_id')) {
          acc.sfLocationId = str.split('=')[1];
        }

        return acc;
      },
      { opportunityId: '', sfLocationId: '' }
    );
  }, [search]);

  const {
    data: estimateData,
    isLoading: estimateIsLoading,
    isSuccess: estimateDataIsSucess,
  } = useGetEstimateBySfId({
    params: { opportunityId },
  });

  const {
    data: locationData,
    isLoading: locationIsLoading,
    isSuccess: locationDataIsSucess,
  } = useGetLocationBySfId({
    params: {
      sfLocationId,
      estimateId: estimateData?.id,
    },
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const currentLocation = useMemo(() => {
    if (locationDataIsSucess && locationData) {
      return locationData;
    } else {
      return estimateData?.locations[0];
    }
  }, [estimateData, locationData, locationDataIsSucess]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderContent = useMemo(() => {
    if (estimateIsLoading || locationIsLoading) {
      return <KiteLoader />;
    } else if (!estimateData && estimateDataIsSucess) {
      return (
        <KiteAlert
          type="alert"
          description="Estimate not found. Please make sure you've created an estimate in Solution Pricing Tool for this Opportunity by clicking the Solution Pricing Tool link on the Opportunity page."
        />
      );
    } else if (!currentLocation && locationDataIsSucess) {
      return (
        <KiteAlert type="alert" description="Location could not be found." />
      );
    }
  }, [
    estimateData,
    estimateDataIsSucess,
    estimateIsLoading,
    currentLocation,
    locationDataIsSucess,
    locationIsLoading,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('SfFieldValRedirect'), [trackPageView]);

  useEffect(() => {
    if (estimateData && currentLocation) {
      navigate(`/review-uc/${estimateData.id}/${currentLocation.id}`);
    }
  }, [navigate, estimateData, currentLocation]);

  // =============================================
  // Early Return
  // =============================================

  // =============================================
  // Return
  // =============================================
  return <div className="sf-field-val-redirect">{renderContent}</div>;
};

export default SfFieldValRedirect;
