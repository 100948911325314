// External Packages
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

// Components
import {
  KiteModal,
  KiteButton,
  KiteInput,
  KiteRadio,
  KiteAlert,
} from '@kite/react-kite';
import PdfDownloads from '../PdfDownloads';

// Hooks
import { useErrorCheck, useQueryData } from 'hooks';

// Types

// Styles
import './PdfModal.scss';

export interface IPdfModalProps {
  pdfModalIsOpen: boolean;
  setPdfModalIsOpen: (x: boolean) => void;
  pdfMulti: boolean;
  setPdfMulti: (value: boolean) => void;
}

// =============================================
// Component
// =============================================
const PdfModal = ({
  pdfModalIsOpen,
  setPdfModalIsOpen,
  pdfMulti,
  setPdfMulti,
}: IPdfModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const defaultQuoteName = 'Spectrum Enterprise Solution Price';
  const [quoteName, setQuoteName] = useState<string>(defaultQuoteName);
  const [pdfGenerated, setPdfGenerated] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const { currentSelections, currentScenario } = useQueryData();
  const { checkAllErrors } = useErrorCheck(currentSelections);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handlePdfTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === 'All Locations') {
      setPdfMulti(true);
    } else {
      setPdfMulti(false);
    }
  };

  const handleQuoteNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuoteName(e.target.value);
    setPdfGenerated(false);
  };

  const handleCancel = () => {
    setQuoteName('Spectrum Enterprise Solution Price');
    setPdfGenerated(false);
    setPdfModalIsOpen(false);
  };

  const handlePdfValidation = useCallback(() => {
    let errors: { [key: string]: boolean } = {};
    if (pdfMulti) {
      errors = checkAllErrors(currentScenario?.selections);
    } else {
      errors = checkAllErrors();
    }

    const hasError = Object.values(errors).some(Boolean);

    if (hasError) {
      setShowError(true);
      return true;
    } else {
      setShowError(false);
      return false;
    }
  }, [pdfMulti, currentScenario, checkAllErrors]);

  // =============================================
  // Render Methods
  // =============================================
  const displayButtons = useMemo(() => {
    if (!pdfGenerated) {
      return (
        <KiteButton
          className="pdf-modal__cancel-button"
          size="small"
          onClick={() => {
            const hasErrors = handlePdfValidation();
            !hasErrors && setPdfGenerated(true);
          }}
        >
          Generate PDF
        </KiteButton>
      );
    } else {
      return (
        <PdfDownloads
          setPdfModalIsOpen={setPdfModalIsOpen}
          quoteName={quoteName}
          pdfMulti={pdfMulti}
          setPdfGenerated={setPdfGenerated}
        />
      );
    }
  }, [
    pdfGenerated,
    pdfMulti,
    quoteName,
    setPdfGenerated,
    setPdfModalIsOpen,
    handlePdfValidation,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    handlePdfValidation();
  }, [handlePdfValidation]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="pdf-modal"
      canShow={pdfModalIsOpen}
      onHide={handleCancel}
    >
      <h2 className="pdf-modal__title kite-h2">Download PDF</h2>
      <h5 className="pdf-modal__message kite-h5">
        ** Prices are confidential and for internal use only **
      </h5>
      <KiteInput
        id="quoteName"
        name="quoteName"
        label="Document Title/Name"
        value={quoteName}
        className="pdf-modal__input"
        onChange={handleQuoteNameChange}
        maxWidth="unset"
      />
      {showError && (
        <KiteAlert
          className="pdf-modal__error"
          description="Please resolve all errors to generate PDF."
          level="page"
          margin="0 0 10px 0"
          type="alert"
        />
      )}
      <KiteRadio
        radioButtons={[
          {
            label: 'All Locations',
            checked: pdfMulti,
          },
          {
            label: 'Current Location Only',
            checked: !pdfMulti,
          },
        ]}
        name="pdf modal"
        onChange={handlePdfTypeChange}
      />
      <div className="pdf-modal__button-container">
        <KiteButton
          className="pdf-modal__cancel-button"
          type="outline"
          size="small"
          onClick={handleCancel}
        >
          Cancel
        </KiteButton>
        {displayButtons}
      </div>
    </KiteModal>
  );
};

export default PdfModal;
