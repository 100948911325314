// Packages
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteButton, KiteCard, KiteIcon } from '@kite/react-kite';

// Utils

// Types

// Styles
import './HelpBox.scss';

export interface IHelpBoxProps {
  /** An array of strings that will be transformed into bullet points within the box */
  helpInfo: string[];
  /** Optional class name to be passed into component */
  className?: string;
  /** Changes the banner color to gray if present */
  darkBanner?: boolean;
  /** Determines if information should be expanded */
  isExpanded: boolean;
  /** Callback to handle toggling expansion */
  onToggleExpand: () => void;
}

/** A help dialog displaying information relating to the current page/table */

const HelpBox = ({
  className = '',
  helpInfo,
  darkBanner,
  isExpanded,
  onToggleExpand,
}: IHelpBoxProps) => {
  // =============================================
  // State/Hooks
  // =============================================
  const [height, setHeight] = useState(0);
  const [refHeight, setRefHeight] = useState(0);

  // =============================================
  // Render Methods
  // =============================================
  const renderHelpList = useCallback(() => {
    const listItems = helpInfo.map((h) => (
      <li key={h}>
        <span>{h}</span>
      </li>
    ));
    const list = (
      <ul
        id="help-information"
        ref={(element: HTMLUListElement) => {
          if (element) {
            setRefHeight(element.getBoundingClientRect().height);
          }
        }}
      >
        {listItems}
      </ul>
    );
    return list;
  }, [helpInfo]);

  const banner = useMemo(() => {
    const expandIconClass = isExpanded
      ? 'chevron chevron--expanded'
      : 'chevron';

    return (
      <KiteButton
        className={classNames({
          'help-box__banner': true,
          'help-box__banner--expanded': isExpanded,
          'help-box__banner--dark': darkBanner,
        })}
        onClick={onToggleExpand}
        minWidth="auto"
        maxWidth="none"
        type="standalone-link"
        aria-expanded={isExpanded}
        aria-controls="help-information"
        aria-label="show help information"
      >
        <KiteIcon name="question-circle-f" size="22px" /> Help
        <KiteIcon className={expandIconClass} name="chevron-right" />
      </KiteButton>
    );
  }, [isExpanded, darkBanner, onToggleExpand]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (isExpanded) {
      setHeight(refHeight);
    } else {
      setHeight(0);
    }
  }, [refHeight, isExpanded]);

  return (
    <KiteCard className={`help-box ${className}`}>
      {banner}
      <div
        className="help-box__content"
        style={{
          height,
        }}
        role="button"
        tabIndex={0}
      >
        {renderHelpList()}
      </div>
    </KiteCard>
  );
};

export default HelpBox;
