import React, { useCallback, useEffect, useRef } from 'react';

// Components
import { KiteAlert, KiteCard } from '@kite/react-kite';
import {
  CustomDropdown,
  CustomSwitch,
  DiscretionWrapper,
  DropdownSwitch,
} from 'components';

// Utils
import { informationAlerts } from 'utils';

// Hooks
import { useAnalytics, useQueryDataContext, useScrollToTop } from 'hooks';

// Types
import { IProductFamily } from 'types';

// Styles
import './DataPage.scss';

// Component
const DataPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { allProductFamilies, currentSelections } = useQueryDataContext();
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1 });

  // =============================================
  // Render Methods
  // =============================================
  const createDropdown = useCallback((productFamily: IProductFamily) => {
    if (productFamily.hasDiscretion) {
      return (
        <DiscretionWrapper
          key={`${productFamily.name} discretionary wrapper`}
          productFamily={productFamily}
        />
      );
    }

    return (
      <CustomDropdown
        key={`${productFamily.name} select`}
        productFamily={productFamily}
      />
    );
  }, []);

  const generateComponents = useCallback(() => {
    const dataProductFamilies = allProductFamilies?.filter(
      (family) => family.category === 'Data'
    );

    const components = dataProductFamilies.reduce(
      (acc: { [orderKey: string]: JSX.Element[] }, pf) => {
        const orderKey = pf.order.toString()[0];

        if (!acc[orderKey]) {
          acc[orderKey] = [];
        }

        const alert = informationAlerts(pf.name, currentSelections);
        if (alert) {
          acc[orderKey].push(
            <KiteAlert
              className="data-page__alert"
              key={`${pf.name} alert`}
              title={alert.title}
              level={alert.level}
              description={alert.description}
              type={alert.type}
            />
          );
        }

        switch (pf.type) {
          case 'enum':
            acc[orderKey].push(createDropdown(pf));
            break;
          case 'enum-switch':
            acc[orderKey].push(
              <DropdownSwitch
                key={`${pf.name} dropdown switch`}
                productFamily={pf}
              />
            );
            break;
          default:
            acc[orderKey].push(
              <CustomSwitch key={`${pf.name} switch`} productFamily={pf} />
            );
            break;
        }
        return acc;
      },
      {}
    );

    return Object.entries(components)
      .sort(([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB))
      .map(([_, components], i) => (
        <KiteCard key={`data card ${i}`} className="data-page__card">
          {components}
        </KiteCard>
      ));
  }, [allProductFamilies, createDropdown, currentSelections]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('Data'), [trackPageView]);

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} data-testid="DataPage" className="data-page">
      <h3>Data Configuration Options</h3>
      {generateComponents()}
    </div>
  );
};

export default DataPage;
