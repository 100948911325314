// Packages
import React from 'react';

// Redux

// Components
import { KiteButton } from '@kite/react-kite';
import { PhoneNumberTable } from 'components';

// Hooks

// Utils

// Types
import { ILocation } from 'types';

// Styles
import './RateCenterValidationTable.scss';

export interface IRateCenterValidationTableProps {
  location: ILocation;
  className?: string;
  toggleAddTnModal?: (locationId: string) => void;
  onDeletePhoneNumber?: (numberId: string) => void;
  toggleRateCenterModal?: (locationId: string) => void;
}

/** Displays rate center validation table in the estimate location card and uc review page */

const RateCenterValidationTable = ({
  location,
  className = '',
  toggleAddTnModal,
  onDeletePhoneNumber,
  toggleRateCenterModal,
}: IRateCenterValidationTableProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className={`rate-center-validation-table ${className}`}>
      <div className="rate-center-validation-table__phone-title-container">
        <h3>Rate Center Validation</h3>
        <div className="rate-center-validation-table__phone-title-container__btn-group">
          {toggleAddTnModal && (
            <KiteButton
              onClick={() => toggleAddTnModal(location.id)}
              type="outline"
              size="medium"
            >
              Add TNs
            </KiteButton>
          )}
          {toggleRateCenterModal && (
            <KiteButton
              onClick={() => toggleRateCenterModal(location.id)}
              type="outline"
              size="medium"
            >
              Search Rate Center
            </KiteButton>
          )}
        </div>
      </div>
      {!location.phoneNumbers?.length ? (
        <span>No phone numbers found for this location.</span>
      ) : (
        <PhoneNumberTable
          phoneNumbers={location.phoneNumbers}
          onDeletePhoneNumber={onDeletePhoneNumber}
          showNpaNxx
        />
      )}
    </div>
  );
};

export default RateCenterValidationTable;
