import { IBundle } from 'types';

export const mockBundles: { [key: string]: IBundle } = {
  '2ddf5f38-843a-43b4-8fe6-adf0c8a2e9f9': {
    name: 'MNE Security & Performance 50Mb',
    description: '',
    published: true,
    featured: true,
    id: '2ddf5f38-843a-43b4-8fe6-adf0c8a2e9f9',
    createdAt: '2022-04-15T16:57:12.103Z',
    updatedAt: '2022-04-15T16:57:12.103Z',
    selections: [],
  },
  '8775eb23-a73a-42a0-80f4-6d94316347c4': {
    name: 'MNE Internet with Backup',
    description: '',
    published: true,
    featured: true,
    id: '8775eb23-a73a-42a0-80f4-6d94316347c4',
    createdAt: '2022-04-15T16:57:12.103Z',
    updatedAt: '2022-04-15T16:57:12.103Z',
    selections: [],
  },
  '2e6d0a25-f859-45cf-a5a8-09fd347120ee': {
    name: 'MNE Internet + Wifi with Backup',
    description: '',
    published: true,
    featured: true,
    id: '2e6d0a25-f859-45cf-a5a8-09fd347120ee',
    createdAt: '2022-04-15T16:57:12.103Z',
    updatedAt: '2022-04-15T16:57:12.103Z',
    selections: [],
  },
  '8ea90b23-f7e3-4566-8b60-ed524b3832ec': {
    name: 'Office of the Future',
    description: '',
    published: true,
    featured: false,
    id: '8ea90b23-f7e3-4566-8b60-ed524b3832ec',
    createdAt: '2022-04-15T16:57:12.103Z',
    updatedAt: '2022-04-15T16:57:12.103Z',
    selections: [],
  },
  '100d9fc2-ad0d-4f53-acb6-2c4ef7ef9cea': {
    name: 'MNE Security & Performance 20Mb',
    description: '',
    published: true,
    featured: false,
    id: '100d9fc2-ad0d-4f53-acb6-2c4ef7ef9cea',
    createdAt: '2022-04-15T16:57:12.103Z',
    updatedAt: '2022-04-15T16:57:12.103Z',
    selections: [],
  },
  '7f370159-1323-4ed2-ad5a-6d04dec5d3e4': {
    name: 'Demo Bundle',
    description: '',
    published: false,
    featured: false,
    id: '7f370159-1323-4ed2-ad5a-6d04dec5d3e4',
    createdAt: '2022-04-18T16:57:15.086Z',
    updatedAt: '2022-04-18T16:57:15.086Z',
    selections: [],
  },
  '8874057f-f8e2-43e3-9419-52b62fdbfe9e': {
    name: 'Demo Bundle',
    description: '',
    published: false,
    featured: false,
    id: '8874057f-f8e2-43e3-9419-52b62fdbfe9e',
    createdAt: '2022-04-18T16:57:50.826Z',
    updatedAt: '2022-04-18T16:57:50.826Z',
    selections: [],
  },
  '64223c24-ea6a-4b0a-92a8-86ebd75741d0': {
    name: 'Demo Bundle',
    description: '',
    published: false,
    featured: false,
    id: '64223c24-ea6a-4b0a-92a8-86ebd75741d0',
    createdAt: '2022-04-18T16:58:14.092Z',
    updatedAt: '2022-04-18T16:58:14.092Z',
    selections: [],
  },
  'ed332da6-4d88-4b44-8fe1-0a87213796b6': {
    name: 'Demo Bundle',
    description: '',
    published: false,
    featured: false,
    id: 'ed332da6-4d88-4b44-8fe1-0a87213796b6',
    createdAt: '2022-04-18T17:06:41.264Z',
    updatedAt: '2022-04-18T17:06:41.264Z',
    selections: [],
  },
  'b2d89b45-167a-4622-8590-82b0c2476831': {
    name: 'Demo Bundle',
    description: '',
    published: false,
    featured: false,
    id: 'b2d89b45-167a-4622-8590-82b0c2476831',
    createdAt: '2022-04-18T17:07:14.569Z',
    updatedAt: '2022-04-18T17:07:14.569Z',
    selections: [],
  },
  '76060858-5ddb-4175-a047-bb437217e9c9': {
    name: 'Demo Bundle',
    description: '',
    published: false,
    featured: false,
    id: '76060858-5ddb-4175-a047-bb437217e9c9',
    createdAt: '2022-04-18T17:11:02.437Z',
    updatedAt: '2022-04-18T17:11:02.437Z',
    selections: [],
  },
};
