import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IDistro } from 'types';
import { distrosRequest, queryKeys } from 'utils';

interface IUpdateDistro {
  estimateId: string;
}

export const useUpdateDistros = ({ estimateId }: IUpdateDistro) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => queryKeys({ estimateId }).details.distros,
    [estimateId]
  );

  // Match & update any pre-existing submissions for optimistic changes
  const updateOldDistros = useCallback(
    (oldDistros: Partial<IDistro>[], newDistros: Partial<IDistro>[]) => {
      const oldDistroIds = oldDistros.map((oldDistro) => oldDistro.id);
      const updatedDistros = oldDistros.map((oldDistro) => {
        const { id } = oldDistro;
        const match = newDistros.find((newDistro) => newDistro.id === id);
        if (match) {
          return {
            ...oldDistro,
            ...match,
          };
        }
        return oldDistro;
      });
      const filteredNewDistros = newDistros.filter(
        (newDistro) => !oldDistroIds.includes(newDistro.id)
      );
      return [...updatedDistros, ...filteredNewDistros];
    },
    []
  );

  const {
    mutate: updateDistros,
    isLoading: updateDistrosLoading,
    isSuccess: updateDistrosSuccess,
    isError: updateDistrosError,
  } = useMutation(
    (distros: Partial<IDistro>[]) =>
      distrosRequest({
        endpoint: `distros/${estimateId}`,
        method: 'PUT',
        data: distros,
      }),
    {
      onMutate: async (newDistros: Partial<IDistro>[]) => {
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Get previous data in case of mutation error (see onError below)
        const previousDistros: Partial<IDistro>[] | undefined =
          queryClient.getQueryData(queryKey);
        // Optimistically set data
        queryClient.setQueryData(
          queryKey,
          (oldDistros: Partial<IDistro>[] | undefined) =>
            oldDistros ? updateOldDistros(oldDistros, newDistros) : newDistros
        );

        return { previousDistros };
      },
      onError: async (err: unknown, _: Partial<IDistro>[], context: any) => {
        console.log(err);
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Reset query data to pre-mutation if mutation error
        queryClient.setQueryData(queryKey, context.previousDistros);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    updateDistros,
    updateDistrosLoading,
    updateDistrosSuccess,
    updateDistrosError,
  };
};
