import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useParams } from 'react-router-dom';

import { ILocationSelection, INewSelection, IScenario } from 'types';

import {
  convertSelectionForApi,
  NONE,
  queryKeys,
  scenarioRequest,
  sortLocationSelections,
  sandboxData,
} from 'utils';
import { useQueryData } from 'hooks';

export const useUpdateScenarioProducts = () => {
  const { allProducts, scenarioId, locationId } = useQueryData();
  const queryClient = useQueryClient();
  const { estimateId } = useParams();

  const scenarioKey = queryKeys({ estimateId }).filter.estimateScenarios;
  // const productFamilyKey = queryKeys().all.productFamilies;
  const profilesKey = queryKeys().all.profiles;
  const estimateKey = queryKeys({ estimateId }).details.estimate;

  const isSandbox =
    locationId?.includes('sandbox') || scenarioId?.includes('sandbox');

  const optimisticUpdate = useCallback(
    (newScenario: Partial<IScenario>, oldScenarios: IScenario[]) => {
      const filteredSelections =
        newScenario.selections?.reduce((acc: ILocationSelection[], s) => {
          if (s.productId !== NONE) {
            if (estimateId === 'sandbox') {
              acc.push({ ...s, id: s.id.replace('newSelection', '') });
            } else {
              acc.push(s);
            }
          }
          return acc;
        }, []) || [];

      const updatedScenario = {
        ...newScenario,
        // Selections are being sorted here because during optimistic update,
        // the products are not sorted. The actual mutation response from BE will be sorted.
        selections: sortLocationSelections({
          selections: filteredSelections,
          products: allProducts,
        }),
      };

      const updatedScenarios: IScenario[] = [
        ...oldScenarios.filter((s) => s.id !== newScenario.id),
        updatedScenario as IScenario,
      ];

      return updatedScenarios;
    },
    [allProducts, estimateId]
  );

  const sandboxHandler = useCallback(
    async (scenario: Partial<IScenario>) => {
      const updatedScenarios = optimisticUpdate(
        scenario,
        sandboxData.estimate.scenarios
      );
      sandboxData.estimate.scenarios = updatedScenarios;
      return sandboxData.estimate.scenarios;
    },
    [optimisticUpdate]
  );

  const {
    mutate: updateScenarioProducts,
    isLoading: updateScenarioProductsLoading,
    isSuccess: updateScenarioProductsSuccess,
    isError: updateScenarioProductsError,
  } = useMutation(
    isSandbox
      ? sandboxHandler
      : (scenario: Partial<IScenario>) => {
          // Sending fieldOpsAcurrate as undefined rather than null to avoid it being defaulted to false
          const newSelections =
            scenario.selections?.reduce((acc: INewSelection[], s) => {
              if (s.productId !== NONE) {
                acc.push(
                  convertSelectionForApi({
                    ...s,
                    fieldOpsAccurate: s.fieldOpsAccurate ?? undefined,
                  })
                );
              }
              return acc;
            }, []) || [];

          return scenarioRequest({
            method: 'PUT',
            data: {
              ...scenario,
              selections: newSelections,
            },
          });
        },
    {
      onMutate: async (newScenario: Partial<IScenario>) => {
        await queryClient.cancelQueries(scenarioKey);

        const oldScenarios: IScenario[] =
          queryClient.getQueryData(scenarioKey) || [];

        const updatedScenarios = optimisticUpdate(newScenario, oldScenarios);

        queryClient.setQueryData(scenarioKey, updatedScenarios);

        return { oldScenarios };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(scenarioKey);
        queryClient.setQueryData(scenarioKey, context?.oldScenarios);
      },
      onSettled: () => {
        queryClient.invalidateQueries(scenarioKey);
        queryClient.invalidateQueries(estimateKey);
        // queryClient.invalidateQueries(productFamilyKey);
        queryClient.invalidateQueries(profilesKey);
      },
    }
  );

  return {
    updateScenarioProducts,
    updateScenarioProductsLoading,
    updateScenarioProductsSuccess,
    updateScenarioProductsError,
  };
};
