// Packages
import React from 'react';

// Components
import { KiteButton, KiteIcon } from '@kite/react-kite';

// Utils

// Types

// Styles
import './ExpandButton.scss';

export interface IExpandButtonProps {
  className?: string;
  /** Determines if card is open */
  isOpen: boolean;
  /** Callback to happen on click, should toggle card isOpen state */
  onClick: () => void;
  /** Accessibility label for button, i.e. 'expand <component> button' */
  ariaLabel: string;
}

/** Button used to expand cards, used in EstimateLocationCard & BundleInfoCard */

const ExpandButton = ({
  isOpen = false,
  onClick,
  ariaLabel,
}: IExpandButtonProps) => {
  // =============================================
  // State/Refs
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const expandIconName = isOpen ? 'more-up-f' : 'more-down-f';

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteButton
      className="expand-button"
      type="standalone-link"
      onClick={onClick}
      aria-label={ariaLabel}
      aria-expanded={isOpen}
    >
      <KiteIcon name={expandIconName} size="25px" />
    </KiteButton>
  );
};

export default ExpandButton;
