import axios from 'axios';
import { IRequestConfig, IRule, IRequestParams } from 'types';
import { paramsSerializer } from 'utils/format/paramSerializer';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IRulesRequestParams extends IRequestParams {
  location?: number;
}

export interface IRulesRequest extends IRequestConfig<IRule> {
  params?: IRulesRequestParams;
}

export const rulesRequest = async (config?: IRulesRequest) => {
  const { endpoint = 'rules', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with rules ${method}`,
      })
    );
};
