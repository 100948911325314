import { useQueryData } from 'hooks/useQueryData';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { IScenario, IScenarioPromo } from 'types';
import { scenarioPromosRequest, queryKeys, sandboxData } from 'utils';
import { v4 } from 'uuid';

interface INewScenarioPromo {
  id?: string;
  promoId: string;
  scenarioId: string;
  estimateId: string;
  locationId?: string | null;
}

export const usePostScenarioPromo = () => {
  const queryClient = useQueryClient();
  const { estimateId } = useParams();
  const { currentScenario } = useQueryData();
  const scenarioKey = queryKeys({ estimateId }).filter.estimateScenarios;

  const isSandbox = estimateId === 'sandbox';

  const optimisticUpdate = useCallback(
    (newPromo: INewScenarioPromo, oldScenarios: IScenario[]): any => {
      return oldScenarios.map((s) => {
        if (s.id === currentScenario?.id) {
          const { promos = [] } = s;
          return { ...s, promos: [...promos, newPromo] };
        }
        return s;
      });
    },
    [currentScenario]
  );

  const sandboxHandler = useCallback(
    async (newPromo: INewScenarioPromo) => {
      const updatedScenarios = optimisticUpdate(
        newPromo,
        sandboxData.estimate.scenarios
      );
      newPromo.id = `sandboxPromo${v4()}`;
      sandboxData.estimate.scenarios = updatedScenarios;
      sandboxData.estimate.scenarioPromos.push(newPromo as IScenarioPromo);
      return sandboxData.estimate.scenarios;
    },
    [optimisticUpdate]
  );

  const {
    mutate: postScenarioPromo,
    isLoading: postScenarioPromoLoading,
    error: postScenarioPromoError,
    data: postScenarioPromoRes,
  } = useMutation(
    isSandbox
      ? sandboxHandler
      : (body: INewScenarioPromo) =>
          scenarioPromosRequest({
            method: 'POST',
            data: body,
          }),
    {
      onMutate: async (body: INewScenarioPromo) => {
        if (!currentScenario) {
          return;
        }
        await queryClient.cancelQueries(scenarioKey);

        const oldScenarios: IScenario[] =
          queryClient.getQueryData(scenarioKey) || [];

        const updatedScenarios = optimisticUpdate(body, oldScenarios);

        queryClient.setQueryData(scenarioKey, updatedScenarios);

        return { oldScenarios };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(scenarioKey);
        queryClient.setQueryData(scenarioKey, context?.oldScenarios);
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  return {
    postScenarioPromo,
    postScenarioPromoLoading,
    postScenarioPromoError,
    postScenarioPromoRes,
  };
};
