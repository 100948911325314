// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteSelect } from '@kite/react-kite';

// Hooks
import { useQueryData, useUpdateEstimate } from 'hooks';
import { useDebouncedCallback } from 'use-debounce/lib';

// Utils

// Types
import { TTransportType } from 'types';

// Styles
import './TransportTypeSelect.scss';

export interface ITransportTypeSelectProps {
  // Optional label for select element
  label?: string;
}
/** Dropdown that changes the transportType of the current estimate */

const TransportTypeSelect = ({ label }: ITransportTypeSelectProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { currentEstimate } = useQueryData();
  const currentTransportType: TTransportType | '' =
    currentEstimate?.transportType || '';
  const [transportType, setTransportType] = useState(currentTransportType);
  const { updateEstimate } = useUpdateEstimate();
  const debounceUpdate = useDebouncedCallback(updateEstimate, 300);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      const newTransportType = value as TTransportType;
      setTransportType(newTransportType);
      if (currentEstimate) {
        debounceUpdate({ ...currentEstimate, transportType: newTransportType });
      }
    },
    [currentEstimate, debounceUpdate]
  );
  // =============================================
  // Render Methods
  // =============================================
  const options = useMemo(() => {
    const types: TTransportType[] = ['Fiber', 'DOCSIS'];
    return types.map((type) => (
      <option key={type} value={type}>
        {type}
      </option>
    ));
  }, []);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (transportType !== currentTransportType && !debounceUpdate.isPending()) {
      setTransportType(currentTransportType);
    }
  }, [currentTransportType, debounceUpdate, transportType]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="transport-type-select">
      <KiteSelect
        id="transportType"
        name="transportType"
        value={transportType}
        onChange={handleSelect}
        label={label}
      >
        {options}
      </KiteSelect>
    </div>
  );
};

export default TransportTypeSelect;
