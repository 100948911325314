import axios from 'axios';
import { IRequestConfig, IProfile, IRequestParams } from 'types';
import { throwQueryError, paramsSerializer } from 'utils';
import { env } from './env';

interface IProfileParams extends IRequestParams {
  scenarioId?: string;
  locationId?: string;
}

export interface IProfileRequests
  extends IRequestConfig<
    | Partial<IProfile>
    | Partial<IProfile>[]
    | IProfile
    | IProfile[]
    | IProfileParams
  > {
  params?: IProfileParams;
}
export const profileRequest = async (
  config?: IRequestConfig<
    Partial<IProfile> | Partial<IProfile>[] | IProfile | IProfile[] | string[]
  >
) => {
  const {
    endpoint = 'product-profiles',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with profile ${method}`,
      })
    );
};
