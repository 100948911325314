import axios from 'axios';
import { getSignedUrl } from './getSignedUrl';
import { throwQueryError } from './throwQueryError';

export interface IPostFileToS3Params {
  file: File;
  fileKey?: string;
}

export interface IPostFileToS3Response {
  fileName: string;
  url: string;
  isDefault?: boolean;
}

export const postFileToS3 = async ({ file, fileKey }: IPostFileToS3Params) => {
  const { name: fileName, type: fileType } = file;

  const { signedUrl, objectUrl: url } = await getSignedUrl({
    fileType,
    fileKey,
  });

  // create custom axios instance b/c AWS hates Authorization headers
  const axiosAWS = axios.create({
    headers: {
      'Content-Type': fileType,
    },
    transformRequest: (data, headers) => {
      delete headers['Authorization'];
      return data;
    },
  });

  return axiosAWS({
    method: 'PUT',
    url: signedUrl,
    data: file,
  })
    .then(() => {
      return { fileName, url } as IPostFileToS3Response;
    })
    .catch((error) =>
      throwQueryError({
        error,
        message: 'Something went wrong uploading the file to S3',
      })
    );
};
