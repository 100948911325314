import { IEstimate } from 'types';
import { IApiResponse } from './types';

import { mockEstimates } from 'utils/mocks';

export const estimates: IApiResponse<IEstimate> = {
  get: {
    200: {
      message: 'success',
      total: Object.keys(mockEstimates).length,
      data: Object.values(mockEstimates),
    },
  },
  getOne: {
    200: {
      message: 'success',
      total: 1,
      data: mockEstimates.testOne,
    },
  },
  post: {},
  put: {
    200: {
      message: 'success',
      total: 1,
      data: mockEstimates.testOne,
    },
  },
};
