import { ISalesforceOpportunity } from 'types';

export const mockOpportunity: ISalesforceOpportunity = {
  id: '',
  billingAccountId: '',
  opportunityNumber: '',
  opportunityOwner: '',
  stageName: 'Unqualified Opportunity',
  createdByName: '',
  ownerPID: '',
  ownerEmail: '',
  lastModifiedByName: '',
  name: '',
};
