import { IProfile, IProfileCategory } from 'types';

export const mockProductProfiles: IProfile[] = [
  {
    id: '',
    createdAt: '',
    updatedAt: '',
    name: 'Mock Profile',
    quantity: 3,
    locationId: '',
    scenarioId: '',
  },
];

export const mockProductProfileCategories: IProfileCategory[] = [
  {
    id: '50c46721-1097-4277-b027-ab99d6889371',
    name: 'voipHardware',
    createdAt: '',
    updatedAt: '',
  },
  {
    id: '18564e8a-4a86-4614-9355-689753bd44c7',
    name: 'package',
    createdAt: '',
    updatedAt: '',
  },
  {
    id: '05bd6b48-26d0-4e87-90ff-68b1e9f53576',
    name: 'recordingLicense',
    createdAt: '',
    updatedAt: '',
  },
  {
    id: '6750d5af-432b-4f40-a149-5f81b6cfc0a9',
    name: 'additionalSoftware',
    createdAt: '',
    updatedAt: '',
  },
  {
    id: 'fe095d23-b325-4e45-987b-d2a2029e21dd',
    name: 'unityAddonLicense',
    createdAt: '',
    updatedAt: '',
  },
];
