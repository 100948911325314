import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useAnalytics } from './useAnalytics';

interface AnaylticsRequestConfig extends AxiosRequestConfig {
  metaData: { startTime: number };
}

export const useApiAnalytics = () => {
  const { trackApiCall } = useAnalytics();

  const convertParams = useCallback((params: unknown) => {
    if (typeof params !== 'string') {
      if (Array.isArray(params)) {
        return params.toString();
      }
      if (params && typeof params === 'object') {
        return Object.entries(params)
          .map(([k, v]) => `${k}: ${v}`)
          .join(', ');
      }
    }
    if (typeof params === 'string') {
      return params;
    }
  }, []);

  const startApiAnalytics = useCallback(() => {
    axios.interceptors.request.use(
      (config) => {
        return {
          ...config,
          metaData: { startTime: new Date().getTime() },
        };
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (res: AxiosResponse) => {
        const {
          url,
          method,
          params,
          metaData: { startTime },
        } = res.config as AnaylticsRequestConfig;
        if (url && method) {
          const { message } = res.data;
          const { status, statusText } = res;
          const queryParams = convertParams(params);
          const endTime = new Date().getTime();
          const time = endTime - startTime;
          trackApiCall({
            url,
            method,
            isSuccess: statusText === 'OK',
            status,
            time,
            queryParams,
            message,
          });
        }
        return res;
      },
      (err: AxiosError) => {
        const {
          url,
          method,
          params,
          metaData: { startTime },
        } = err.config as AnaylticsRequestConfig;
        if (url && method) {
          const { response, message } = err;
          const status = response?.status;
          const queryParams = convertParams(params);
          const endTime = new Date().getTime();
          const time = endTime - startTime;
          trackApiCall({
            url,
            method,
            isSuccess: false,
            status,
            time,
            queryParams,
            message,
          });
        }
        return Promise.reject(err);
      }
    );
  }, [convertParams, trackApiCall]);

  return startApiAnalytics;
};
