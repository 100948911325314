import { Node } from 'react-flow-renderer';
import { IUCQDesignFlowNode } from 'types';

export const sortFlowNodes = (nodes: Node<IUCQDesignFlowNode>[]) => {
  return nodes.sort((a, b) => {
    const idToNum = (id: string) => parseInt(id.slice(1));
    const isIdf = (node: Node<IUCQDesignFlowNode>) => node.type === 'idf';
    const isBdg = (node: Node<IUCQDesignFlowNode>) => node.type === 'bdg';
    if (isBdg(a) && isBdg(b)) {
      const aId = idToNum(a.id);
      const bId = idToNum(b.id);
      return aId < bId ? -1 : aId > bId ? 1 : 0;
    }

    if (isBdg(a) && !isBdg(b)) {
      return -1;
    }

    if (!isBdg(a) && isBdg(b)) {
      return 1;
    }

    if (!isIdf(a) && isIdf(b)) {
      return -1;
    }

    if (isIdf(a) && !isIdf(b)) {
      return 1;
    }

    const isBothIdf = isIdf(a) && isIdf(b);
    const aNum = idToNum(a.id);
    const bNum = idToNum(b.id);

    if (isBothIdf && aNum < bNum) {
      return -1;
    }

    if (isBothIdf && aNum > bNum) {
      return 1;
    }

    return 0;
  });
};
