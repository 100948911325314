import { IDistro } from 'types';

export const getHighestNodeId = (
  nodeType: IDistro['nodeType'],
  distros: Partial<IDistro>[]
) => {
  const nodeIds = distros
    .filter((d) => d.nodeType === nodeType)
    .map((d) => parseInt(d.nodeId?.slice(1) || '0'));

  if (!nodeIds.length) {
    return 0;
  }

  return Math.max(...nodeIds);
};
