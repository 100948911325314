// Packages
import React from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './CpwCostApprovalBadge.scss';

export interface ICpwCostApprovalBadgeProps {
  approved: boolean;
}

/** Badge on the read only page CPW tab that displays approval status */

const CpwCostApprovalBadge = ({ approved }: ICpwCostApprovalBadgeProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={classNames({
        'cpw-cost-approval-badge': true,
        'cpw-cost-approval-badge--approved': approved,
      })}
    >
      <KiteIcon
        name={approved ? 'like-f' : 'caution-alert'}
        margin="0 11px 0 0"
      />
      {approved
        ? 'Cost less than or equal to approved IRR'
        : 'Cost greater than approved IRR'}
    </div>
  );
};

export default CpwCostApprovalBadge;
