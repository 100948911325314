import { useMutation, useQueryClient } from 'react-query';
import { IDistro } from 'types';
import { distrosRequest, queryKeys } from 'utils';

interface IDeleteDistro {
  estimateId: string;
}

export const useDeleteDistros = ({ estimateId }: IDeleteDistro) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys({ estimateId }).details.distros;

  const { mutate: deleteDistros, isLoading: deleteDistroLoading } = useMutation(
    (distroIds: string[]) =>
      distrosRequest({
        method: 'DELETE',
        data: distroIds,
      }),
    {
      onMutate: async (distroIds: string[]) => {
        await queryClient.cancelQueries(queryKey);
        const prevDistros = queryClient.getQueryData<IDistro[]>(queryKey);
        queryClient.setQueryData(
          queryKey,
          prevDistros?.filter((d) => !distroIds.includes(d.id)) || []
        );
        return { prevDistros };
      },
      onError: async (err, _, context) => {
        await queryClient.cancelQueries(queryKey);
        queryClient.setQueryData(queryKey, context?.prevDistros);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    deleteDistros,
    deleteDistroLoading,
  };
};
