import { useMutation, useQueryClient } from 'react-query';
import { IBundle, ILocationSelection, IUpdateBundle } from 'types';
import { bundleRequest, convertSelectionForApi, queryKeys } from 'utils';

export const usePostBundle = (
  onSuccess?: (bundle: IBundle) => void,
  onError?: () => void
) => {
  const queryClient = useQueryClient();
  const allBundlesKey = queryKeys().all.bundles;
  const featuredBundlesKey = queryKeys({ featured: true, published: true })
    .filter.bundles;
  const moreBundlesKey = queryKeys({ featured: false, published: true }).filter
    .bundles;
  const productFamilyKey = queryKeys().all.productFamilies;

  const {
    mutate: postBundle,
    isLoading: postBundleLoading,
    error: postBundleError,
    data: postBundleRes,
  } = useMutation(
    (newBundle: Partial<IUpdateBundle>) =>
      bundleRequest({
        method: 'POST',
        endpoint: 'bundles',
        data: {
          ...newBundle,
          selections:
            newBundle.selections?.map((s) => ({
              ...convertSelectionForApi(s as ILocationSelection),
              bundleId: undefined,
            })) || [],
        },
      }),
    {
      onMutate: async (newBundle: Partial<IUpdateBundle>) => {
        await queryClient.cancelQueries(allBundlesKey);
        const prevBundles =
          queryClient.getQueryData<IBundle[]>(allBundlesKey) || [];
        const newBundles = [
          ...prevBundles.filter((b) => b.id !== newBundle.id),
          newBundle,
        ];
        queryClient.setQueryData(allBundlesKey, newBundles);
        return { prevBundles };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(allBundlesKey);
        queryClient.setQueryData(allBundlesKey, context?.prevBundles);
        if (onError) {
          onError();
        }
      },
      onSuccess: (res: IBundle) => {
        if (onSuccess) {
          onSuccess(res);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(productFamilyKey);
        queryClient.invalidateQueries(allBundlesKey);
        queryClient.invalidateQueries(featuredBundlesKey);
        queryClient.invalidateQueries(moreBundlesKey);
      },
    }
  );

  return {
    postBundle,
    postBundleLoading,
    postBundleError,
    postBundleRes,
  };
};
