// Packages
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteInput, KiteSelect } from '@kite/react-kite';

// Hooks

// Utils
import { handleNumInputKeydown } from 'utils';

// Types

// Styles
import './LabelledInput.scss';

export interface ILabelledInputProps {
  // Label that acts as placeholder + top right info
  label: string;
  // Initial value of component
  initValue?: string | null;
  // Input type to be rendered
  type: 'text' | 'number' | 'select';
  // Change handler callback
  onChange: (value: string) => void;
  // Options for select input
  options?: string[];
  // Max value for number input
  max?: number;
  // Error message to display
  error?: string;
}

/** Renders a text, number, or select input with a custom label placeholder that moves to top right corner when an input value is entered */

const LabelledInput = ({
  label,
  type,
  initValue = '',
  onChange,
  options,
  max,
  error,
}: ILabelledInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [value, setValue] = useState(initValue || '');

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const isActive = !!value;

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value } = e.target;
      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  const onKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (type === 'number') {
        handleNumInputKeydown(e);
      }
    },
    [type]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderInput = useCallback(() => {
    if (type === 'select') {
      const opts =
        options?.map((o) => (
          <option key={o} value={o}>
            {o}
          </option>
        )) || [];
      return (
        <KiteSelect
          id={label}
          name={label}
          value={value}
          onChange={handleChange}
          maxWidth="none"
          margin="0"
        >
          {[<option key="default" value="" />, ...opts]}
        </KiteSelect>
      );
    }
    return (
      <KiteInput
        value={value}
        onChange={handleChange}
        onKeyDown={onKeydown}
        inputProps={{ type, min: 0, max }}
        maxWidth="none"
        margin="0"
        aria-errormessage={error}
      />
    );
  }, [error, handleChange, label, max, onKeydown, options, type, value]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (type === 'number') {
      const valueNum = parseInt(value);
      if (value && max && valueNum > max) {
        const maxString = max.toString();
        setValue(maxString);
        onChange(maxString);
      }
    }
  }, [max, onChange, type, value]);

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={classNames({
        'labelled-input': true,
        'labelled-input--active': isActive,
      })}
    >
      {renderInput()}
      <span
        className={classNames({
          'labelled-input__label': true,
          'labelled-input__label--active': isActive,
          'labelled-input__label--error': !!error,
        })}
      >
        {error ? error : label}
      </span>
    </div>
  );
};

export default LabelledInput;
