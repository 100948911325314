import { useMutation, useQueryClient } from 'react-query';
import { ILocation } from 'types';
import { fieldOpsCostRequest, queryKeys } from 'utils';

export const useDeleteFieldOpsCost = (locationId: string) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys({ locationId }).details.location;

  const {
    mutate: deleteFieldOpsCost,
    isLoading: deleteFieldOpsCostLoading,
    error: deleteFieldOpsCostError,
    data: deleteFieldOpsCostRes,
  } = useMutation(
    (fieldOpsCostIds: string[]) =>
      fieldOpsCostRequest({
        method: 'DELETE',
        data: fieldOpsCostIds,
      }),
    {
      onMutate: async (deletedFieldOpsCostIds: string[]) => {
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Get previous data in case of mutation error (see onError below)
        const previousLocation: Partial<ILocation> | undefined =
          queryClient.getQueryData(queryKey);
        const prevCosts = previousLocation?.fieldOpsCosts || [];
        const updatedCosts = prevCosts.filter(
          (cost) => !deletedFieldOpsCostIds.includes(cost.id)
        );

        // Optimistically set data
        queryClient.setQueryData(queryKey, {
          ...previousLocation,
          fieldOpsCosts: updatedCosts,
        });

        return { previousLocation };
      },
      onError: async (err, _, context) => {
        console.log(err);
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Reset query data to pre-mutation if mutation error
        queryClient.setQueryData(queryKey, context?.previousLocation);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    deleteFieldOpsCost,
    deleteFieldOpsCostLoading,
    deleteFieldOpsCostError,
    deleteFieldOpsCostRes,
  };
};
