import { IProfile } from 'types';
import { profileRequest, queryKeys, sandboxData } from 'utils';
import { useGenerateQuery } from '../useGenerateQuery';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { IProfileRequests } from 'utils/api/profileRequest';

export const useGetProfiles = (requestConfig: IProfileRequests = {}) => {
  const { estimateId } = useParams();

  const isSandbox = estimateId === 'sandbox';

  const sandboxHandler = useCallback(async () => sandboxData.profiles, []);

  return useGenerateQuery<IProfile[]>({
    queryKey: requestConfig.params
      ? queryKeys(requestConfig.params).filter.profiles
      : queryKeys().all.profiles,
    apiCall: isSandbox ? sandboxHandler : profileRequest,
    requestConfig,
    opts: { enabled: requestConfig.params?.scenarioId !== '' },
  });
};
