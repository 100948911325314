import axios from 'axios';
import { env } from './env';
import { paramsSerializer } from 'utils';
import { throwQueryError } from './throwQueryError';

export const getActiveDirectory = async (config: { pid: string }) => {
  if (env.apiUrl === 'test') return;
  if (env.apiUrl.includes('localhost')) {
    throw new Error('If running on dev, you cannot access AD data. Sorry 😥');
  }

  return axios({
    url: `${env.apiUrl}/active-directory/${config.pid}`,
    paramsSerializer,
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        // Return mock response w/ pid if user not found in AD
        return {
          id: config.pid,
          email: '',
          first_name: config.pid,
          last_name: '',
          enabled: true,
          pid: config.pid,
          title: '',
          department: '',
          employee_type: '',
          avatar: '',
        };
      }
      throwQueryError({
        error,
        message: `Something went wrong with Active Directory request`,
      });
    });
};
