import { useMutation, useQueryClient } from 'react-query';
import { IFieldOpsCost, ILocation } from 'types';
import { fieldOpsCostRequest, queryKeys } from 'utils';

export const useUpdateFieldOpsCosts = (locationId: string) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys({ locationId }).details.location;

  const {
    mutate: updateFieldOpsCost,
    isLoading: updateFieldOpsCostLoading,
    error: updateFieldOpsCostError,
    data: updateFieldOpsCostRes,
  } = useMutation(
    (fieldOpsCosts: Partial<IFieldOpsCost>[]) =>
      fieldOpsCostRequest({
        method: 'PUT',
        data: fieldOpsCosts,
      }),
    {
      onMutate: async (newFieldOpsCosts: Partial<IFieldOpsCost>[]) => {
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Get previous data in case of mutation error (see onError below)
        const previousLocation: Partial<ILocation> | undefined =
          queryClient.getQueryData(queryKey);

        const prevCosts = previousLocation?.fieldOpsCosts || [];
        const newCostIds = new Set(prevCosts.map((cost) => cost.id));
        const updatedCosts = prevCosts.map((cost) => {
          if (newCostIds.has(cost.id)) {
            const newCost = newFieldOpsCosts.find(
              (newCost) => newCost.id === cost.id
            );
            if (newCost) return newCost;
          }
          return cost;
        });

        // Optimistically set data
        queryClient.setQueryData(queryKey, {
          ...previousLocation,
          fieldOpsCosts: updatedCosts,
        });

        return { previousLocation };
      },
      onError: async (err, _, context) => {
        console.log(err);
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Reset query data to pre-mutation if mutation error
        queryClient.setQueryData(queryKey, context?.previousLocation);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    updateFieldOpsCost,
    updateFieldOpsCostLoading,
    updateFieldOpsCostError,
    updateFieldOpsCostRes,
  };
};
