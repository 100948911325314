import { IRequestConfig, IServiceCodes } from 'types';
import { getServiceCodeData, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetServiceCode = (
  requestConfig: IRequestConfig<IServiceCodes> = {}
) => {
  return useGenerateQuery<IServiceCodes[]>({
    queryKey: queryKeys().all.serviceCodes,
    apiCall: getServiceCodeData,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
