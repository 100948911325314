// External Packages
import React, { useCallback, useMemo } from 'react';
import { View, Text } from '@react-pdf/renderer';

// Utils
import {
  generateTextComponents,
  generateViewComponent,
  formatPrice,
  display$0Price,
  _3MonthsPromoCheck,
} from 'utils';

// Types
import {
  ILocationSelection,
  IProductFamily,
  IGenerateProductTotals,
  TableValues,
  TTermLengthMonths,
  TEipTermLengthMonths,
  IPromo,
} from 'types';

// Styles
import { styles } from './PdfDocumentStyles';

interface IPdfProductSubRow {
  productFamily: IProductFamily;
  filteredSelections: ILocationSelection[];
  currentTerm: TTermLengthMonths;
  currentEipTerm: TEipTermLengthMonths;
  appliedPromos: IPromo[];
  locationSelections: ILocationSelection[];
  generateProductTotals: ({
    selections,
    locationId,
    category,
    subCategory,
    format,
  }: IGenerateProductTotals) => TableValues<number | string>;
}

export const PdfProductSubRow = ({
  productFamily,
  filteredSelections,
  currentTerm,
  currentEipTerm,
  generateProductTotals,
  appliedPromos,
  locationSelections,
}: IPdfProductSubRow) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const familySelections = filteredSelections.filter(
    (s) => s.familyId === productFamily.id
  );

  const isPromoProduct = useCallback(
    (promo: IPromo, selection: ILocationSelection) => {
      if (promo.id === 'd4cf26a3-5785-427a-905a-7705dc00df6b') {
        return _3MonthsPromoCheck({
          promo,
          currentSelections: locationSelections,
          selection,
        });
      }
      const { includedFamilyIds, includedProductIds, excludedProductIds } =
        promo;

      if (excludedProductIds.includes(selection.productId)) {
        return false;
      }
      if (includedFamilyIds.includes(productFamily!.id)) {
        // Promo only applies to UC Connect w/ Webex if
        // there are at least 5 seats configured
        if (selection.familyId === 'c886b29b-20f4-4244-9c29-2ccf415ce44b') {
          return !(selection.quantity < 5);
        }
        return true;
      }
      if (includedProductIds.includes(selection.productId)) {
        return true;
      }
    },
    [productFamily, locationSelections]
  );
  const { rowValues, styleHeight } = useMemo(() => {
    let styleHeight = 0;
    const products = familySelections.map((selection) => {
      const { name, quantity, eipTerm } = selection;
      const { solutionMrc, rateNrc } = generateProductTotals({
        term: currentTerm,
        eipTerm: currentEipTerm,
        selections: [selection],
        format: false,
      }) as TableValues<number>;
      const promoStar = appliedPromos.find((promo) =>
        isPromoProduct(promo, selection)
      )
        ? ' *'
        : '';

      let perEachCost;
      let additionalCost = '';

      if (eipTerm) {
        perEachCost = formatPrice(rateNrc / quantity);

        if (['12', '24', '36'].includes(eipTerm)) {
          const dividedCost = rateNrc / parseInt(eipTerm, 10);
          additionalCost = `\n${name} EIP Rate - (${formatPrice(
            dividedCost
          )}/Month)`;
          styleHeight += 1;
        }
      } else {
        perEachCost = display$0Price.includes(name)
          ? '$0'
          : formatPrice(solutionMrc / quantity);
      }

      let cellText = `${quantity} x ${name}`;

      if (eipTerm) {
        cellText = `${quantity} x ${name} (${perEachCost} ea) ${additionalCost}`;
      } else if (productFamily.name === 'Add-Ons') {
        cellText = `${name} (${perEachCost})`;
      } else if (productFamily.type === 'number') {
        cellText =
          perEachCost === '-'
            ? `${quantity} x ${name}`
            : `${quantity} x ${name} (${perEachCost} ea)`;
      }

      if (cellText.length > 45 && !cellText.includes('EIP')) {
        cellText = cellText.replace('($', '\n($');
        styleHeight += 1;
      }
      styleHeight += 1;
      return cellText + promoStar;
    });
    const outputValues = generateProductTotals({
      term: currentTerm,
      eipTerm: currentEipTerm,
      selections: familySelections,
    });

    const rowValues = {
      products,
      ...outputValues,
    };

    return { rowValues, styleHeight };
  }, [
    familySelections,
    productFamily,
    currentTerm,
    currentEipTerm,
    generateProductTotals,
    appliedPromos,
    isPromoProduct,
  ]);

  // =============================================
  // Render Methods
  // =============================================
  const tableData = useMemo(() => {
    const productCell = (
      <View style={styles[`productCellStep${styleHeight}`]}>
        <Text>{productFamily.name}</Text>
        {generateTextComponents(rowValues.products, 'productCellSubtext')}
      </View>
    );

    const priceCells = Object.entries(rowValues).reduce(
      (acc: JSX.Element[], [key, value], i) => {
        if (key !== 'products') {
          acc.push(
            generateViewComponent(
              value as string,
              `tableCellStep${styleHeight}`,
              `${productFamily.name} ${i}`
            )
          );
        }
        return acc;
      },
      []
    );

    return (
      <View
        key={`${productFamily.name} row`}
        style={styles.productRow}
        wrap={false}
      >
        {productCell}
        <View style={styles.productRow}>{priceCells}</View>
      </View>
    );
  }, [rowValues, styleHeight, productFamily.name]);

  // =============================================
  // Return
  // =============================================
  return <View wrap={false}>{tableData}</View>;
};
