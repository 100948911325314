import { useQueries, UseQueryOptions, UseQueryResult } from 'react-query';
import { IUseGetActiveDirectoryRes } from 'types';
import { getActiveDirectory, queryKeys } from 'utils';

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

const useQueriesTyped = <TQueries extends readonly UseQueryOptions[]>(
  queries: [...TQueries]
): {
  [ArrayElement in keyof TQueries]: UseQueryResult<
    TQueries[ArrayElement] extends { select: infer TSelect }
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        TSelect extends (data: any) => any
        ? ReturnType<TSelect>
        : never
      : Awaited<
          ReturnType<
            NonNullable<
              Extract<TQueries[ArrayElement], UseQueryOptions>['queryFn']
            >
          >
        >
  >;
} => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQueries(
    queries as UseQueryOptions<unknown, unknown, unknown>[]
  ) as any;
};

export const useGetTeamMembersData = (config: {
  pids: string[];
  enabled?: boolean;
}) => {
  const { pids, enabled = true } = config;
  const queries = pids.reduce((acc: UseQueryOptions[], pid) => {
    const queryData: UseQueryOptions = {
      queryKey: queryKeys({ pid }).details.activeDirectory,
      queryFn: () => getActiveDirectory({ pid }),
      enabled: enabled && !!pid,
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    };
    acc.push(queryData);
    return acc;
  }, []);
  return useQueriesTyped(
    queries
  ) as UseQueryResult<IUseGetActiveDirectoryRes>[];
};
