import React, { useCallback, useMemo } from 'react';
import {
  ValidationYesNo,
  SubmissionYesNo,
  FieldValidationHeader,
} from 'components';
import {
  IField,
  ILocation,
  ISubmission,
  IProduct,
  IRuleMap,
  IPostFileToS3Response,
} from 'types';
import { isValidJsonString, capitalizeFirstLetter } from 'utils';
import {
  useGetEstimateDetails,
  useGetEstimateScenarios,
  useUpdateLocation,
  useUpdateSubmissions,
} from './apiHooks';
import { useParams } from 'react-router-dom';
import { useQueryData } from './useQueryData';
import { useSelections } from './useSelections';
import { useAnalytics } from './useAnalytics';

interface IUseRenderReviewUcFieldsProps {
  location: ILocation;
  submissions?: ISubmission[];
  isValidation?: boolean;
  ruleMap?: IRuleMap;
  ruleKeys?: string[];
}
interface IReviewUcFormFields {
  gridFields: { header?: string; elements: JSX.Element[] }[];
  fullWidthFields: JSX.Element[];
}

export type TFieldValidationValues = {
  [id: string]: { accurate?: boolean | null; comment?: string | null };
};

export const useRenderReviewUcFields = ({
  location,
  submissions = [],
  isValidation = false,
  ruleMap = {},
  ruleKeys = [],
}: IUseRenderReviewUcFieldsProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId = '', locationId = '' } = useParams();
  const { trackSelectAction } = useAnalytics();
  const { allProducts, updateScenarioId } = useQueryData();
  const { estimateData } = useGetEstimateDetails(estimateId);
  const { applySelections } = useSelections();
  const { updateSubmissions } = useUpdateSubmissions({
    estimateId,
    locationId,
  });
  const { updateLocation } = useUpdateLocation(estimateId);
  // Make sure we can access currentScenario
  useGetEstimateScenarios({
    params: { estimateId },
  });

  // =============================================
  // Helpers
  // =============================================
  // Prevents an "undefined" value from being passed as isAccurate
  // Prevents "false" from defaulting to "null"
  const parseAccurateValue = useCallback((value?: boolean | null) => {
    if (typeof value === 'boolean') {
      return value;
    }
    return null;
  }, []);

  /** Uses rule map to determine if submission should be displayed based on any parent rules */
  const canShow = useCallback(
    (fieldId: string) => {
      const isRule = ruleKeys.includes(fieldId);
      return isRule ? ruleMap[fieldId] : true;
    },
    [ruleKeys, ruleMap]
  );

  // Get all initial validation fields for submissions and location validation
  const submissionValidationValues = useMemo(() => {
    // get submission validation values
    const values = submissions?.reduce((acc: TFieldValidationValues, sub) => {
      const { fieldId, id, fieldOpsAccurate, fieldOpsComments } = sub;

      if (canShow(fieldId)) {
        acc[id] = {
          accurate: parseAccurateValue(fieldOpsAccurate),
          comment: fieldOpsComments || '',
        };
      }
      return acc;
    }, {});
    // get location validation values for both contact and general
    if (isValidation) {
      values['locationContact'] = {
        accurate: parseAccurateValue(location.fieldOpsAccurateContact),
        comment: location.fieldOpsCommentsContact || '',
      };
      values['locationGeneral'] = {
        accurate: parseAccurateValue(location.fieldOpsAccurateGeneral),
        comment: location.fieldOpsCommentsGeneral || '',
      };
    }
    return values;
  }, [
    submissions,
    isValidation,
    canShow,
    parseAccurateValue,
    location.fieldOpsAccurateContact,
    location.fieldOpsCommentsContact,
    location.fieldOpsAccurateGeneral,
    location.fieldOpsCommentsGeneral,
  ]);

  // ID used for location validation section (General or Contact)
  const locationValidationId = useCallback((sectionName: string) => {
    return sectionName === 'General' ? 'locationGeneral' : 'locationContact';
  }, []);

  // All selections matching current estimate "pushedScenarioId"
  const pushedSelections = useMemo(() => {
    if (!estimateData || !estimateData.pushedScenarioId) {
      return [];
    }
    const { pushedScenarioId, scenarios } = estimateData;
    if (pushedScenarioId) {
      // Update scenarioId in redux to align "currentScenario" used in external fns
      updateScenarioId(pushedScenarioId);
    }
    const pushedScenario = scenarios.find((s) => s.id === pushedScenarioId);
    const pushedSelections =
      pushedScenario?.selections.filter((s) => s.locationId === locationId) ||
      [];
    return pushedSelections;
  }, [estimateData, updateScenarioId, locationId]);

  // =============================================
  // Interaction Handlers
  // =============================================
  // Switch handler for individual submission validation
  const handleSubmissionAccuracy = useCallback(
    (submissionId: string) => (isAccurate: boolean) => {
      const submissionToSave = submissions.reduce(
        (acc: Partial<ISubmission>[], sub) => {
          if (sub.id === submissionId) {
            acc.push({
              ...sub,
              fieldOpsAccurate: isAccurate,
            });
          }
          return acc;
        },
        []
      );
      if (submissionToSave) {
        updateSubmissions(submissionToSave);
        trackSelectAction(
          `UCQ Submission Validation: ${submissionId}, ${isAccurate}`,
          { opType: 'buttonClick' }
        );
      }
    },
    [submissions, trackSelectAction, updateSubmissions]
  );

  // Input handler for individual submission validation
  const handleSubmissionComments = useCallback(
    (submission: ISubmission) => (comment: string) => {
      const submissionToSave = submissions.reduce(
        (acc: Partial<ISubmission>[], sub) => {
          if (sub.id === submission.id) {
            acc.push({
              ...sub,
              fieldOpsComments: comment,
            });
          }
          return acc;
        },
        []
      );
      if (submissionToSave) {
        updateSubmissions(submissionToSave);
        trackSelectAction(
          `UCQ Submission Comment: ${submission.id}, ${comment}`,
          { opType: 'inputFieldUpdate' }
        );
      }

      // debounceSubmissionSave(submissionToSave);
    },
    [submissions, trackSelectAction, updateSubmissions]
  );

  // Switch handler for location submissions validation (General and Contact)
  const handleLocationAccuracy = useCallback(
    (validationType: string) => (isAccurate: boolean) => {
      const updatedFieldOpsVals = () => {
        if (validationType === 'locationGeneral') {
          return {
            fieldOpsAccurateGeneral: isAccurate,
          };
        } else if (validationType === 'locationContact') {
          return { fieldOpsAccurateContact: isAccurate };
        }
      };

      updateLocation({ ...location, ...updatedFieldOpsVals() });
      trackSelectAction(
        `Location Validation: ${validationType}, ${isAccurate}`,
        { opType: 'buttonClick' }
      );
    },
    [location, trackSelectAction, updateLocation]
  );

  // Input handler for location submissions validation (General and Contact)
  const handleLocationComments = useCallback(
    (validationType: 'locationContact' | 'locationGeneral') =>
      (comment: string) => {
        const updatedFieldOpsVals = () => {
          if (validationType === 'locationGeneral') {
            return {
              fieldOpsCommentsGeneral: comment,
            };
          } else if (validationType === 'locationContact') {
            return { fieldOpsCommentsContact: comment };
          }
        };

        if (location) {
          updateLocation({ ...location, ...updatedFieldOpsVals() });
          trackSelectAction(
            `Location Validation Comment: ${validationType}, ${comment}`,
            { opType: 'inputFieldUpdate' }
          );
        }
      },
    [location, trackSelectAction, updateLocation]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderLabel = useCallback((htmlString: string) => {
    return <span dangerouslySetInnerHTML={{ __html: htmlString }}></span>;
  }, []);

  const renderAnswer = useCallback((answer: string | null) => {
    if (!answer || answer.length === 0) return '';
    if (isValidJsonString(answer)) {
      const parsed = JSON.parse(answer);
      if (typeof parsed === 'object' && !Array.isArray(parsed))
        return (
          <li>
            {Object.entries(parsed)
              .map(([key, val]) => capitalizeFirstLetter(key) + ': ' + val)
              .join(' ')}
          </li>
        );
    }
    return <li>{answer}</li>;
  }, []);

  const renderTextQuantityField = useCallback(
    (precheckSubmission: ISubmission, label: string, id: string) => {
      const answers: string[][] =
        precheckSubmission.answer &&
        isValidJsonString(precheckSubmission.answer)
          ? JSON.parse(precheckSubmission.answer)
          : [];
      return (
        <li className="uc-review-submissions__form__fields__question" key={id}>
          {precheckSubmission.fieldOrder}. {renderLabel(label)}
          <ul>
            <li>Yes</li>
            <ul>
              {answers.map((answer: string[], i: number) => (
                <li key={i}>{answer.join(' x ')}</li>
              ))}
            </ul>
          </ul>
        </li>
      );
    },
    [renderLabel]
  );

  const renderImageField = useCallback(
    (
      imageSubmission: ISubmission,
      label: string,
      id: string,
      order: number
    ) => {
      const answer: IPostFileToS3Response | IPostFileToS3Response[] =
        imageSubmission.answer && isValidJsonString(imageSubmission.answer)
          ? JSON.parse(imageSubmission.answer)
          : {};

      const images = [];
      if (Array.isArray(answer)) {
        answer.forEach(({ fileName, url }) => {
          const element = React.createElement('img', {
            key: fileName,
            src: url,
            alt: fileName,
          });
          images.push(element);
        });
      } else {
        const { fileName, url } = answer;
        const element = React.createElement('img', {
          key: fileName,
          src: url,
          alt: fileName,
        });
        images.push(element);
      }

      return (
        <div
          className="uc-review-submissions__form__fields__question--image-wrapper"
          key={id}
        >
          {order}. {renderLabel(label)}
          <div className="uc-review-submissions__form__fields__question--image">
            {images}
          </div>
        </div>
      );
    },
    [renderLabel]
  );

  const renderFieldHeader = useCallback((header?: string) => {
    if (!header || header.length === 0) return '';
    return <h3>{header}</h3>;
  }, []);

  const renderStandardField = useCallback(
    (fieldSubmission: ISubmission, label: string, id: string) => {
      const { fieldOrder: order } = fieldSubmission;
      const fieldNumber =
        Math.trunc(order as number) === order ? `${order}. ` : '';

      return (
        <li className="uc-review-submissions__form__fields__question" key={id}>
          {fieldNumber}
          {renderLabel(label)}
          <ul>{renderAnswer(fieldSubmission.answer)}</ul>
        </li>
      );
    },
    [renderLabel, renderAnswer]
  );

  const renderNotesField = useCallback(
    (notesSubmission: ISubmission, id: string) => {
      return (
        <div className="uc-review-submissions__form__fields__notes" key={id}>
          Notes
          <div className="uc-review-submissions__form__fields__notes--content">
            {notesSubmission.answer ? notesSubmission.answer : ''}
          </div>
        </div>
      );
    },
    []
  );

  // Separate fields into groups at each header to break up page layout
  // Notes and images also break column layout so they are in their own group (fullWidthFields)
  const formatFields = useCallback(
    (fields: IField[], formSubmissions: ISubmission[]) => {
      return fields.reduce(
        (allFields, { id, label, isPrecheck, inputType, header }, i) => {
          if (canShow(id)) {
            const fieldSubmission = formSubmissions.find(
              ({ fieldId }) => fieldId === id
            );
            if (!fieldSubmission) {
              return allFields;
            } else if (inputType === 'text-area') {
              allFields.fullWidthFields.push(
                renderNotesField(fieldSubmission, id)
              );
            } else if (inputType === 'image') {
              allFields.fullWidthFields.push(
                renderImageField(
                  fieldSubmission,
                  label,
                  id,
                  fieldSubmission?.fieldOrder || 0
                )
              );
            } else if (
              isPrecheck &&
              inputType === 'text-quantity' &&
              isValidJsonString(fieldSubmission.answer || '')
            ) {
              allFields.gridFields[
                allFields.gridFields.length - 1
              ].elements.push(
                renderTextQuantityField(fieldSubmission, label, id)
              );
            } else if (header) {
              // If there is a header, create a new group of fields
              allFields.gridFields.push({
                header,
                elements: [renderStandardField(fieldSubmission, label, id)],
              });
            } else {
              // If no header, push element to the end of the last group of elements
              allFields.gridFields[
                allFields.gridFields.length - 1
              ].elements.push(renderStandardField(fieldSubmission, label, id));
            }
          }

          return allFields;
        },
        {
          gridFields: [{ elements: [] }],
          fullWidthFields: [],
        } as IReviewUcFormFields
      );
    },
    [
      canShow,
      renderNotesField,
      renderImageField,
      renderTextQuantityField,
      renderStandardField,
    ]
  );

  const renderGridFields = useCallback(
    (gridFields: { elements: JSX.Element[]; header?: string }[]) => {
      return gridFields.map((fieldGroup) => {
        if (!fieldGroup.elements.length) {
          return null;
        }
        return (
          <div key={fieldGroup.elements[0].key}>
            {renderFieldHeader(fieldGroup.header)}
            <ul className="uc-review-submissions__form__fields">
              {fieldGroup.elements}
            </ul>
          </div>
        );
      });
    },
    [renderFieldHeader]
  );

  // Renders individual submissions along with validation switch and input
  const renderValidationSubmissions = useCallback(
    (validationSubmissions: ISubmission[]) => {
      return validationSubmissions.reduce((acc: JSX.Element[], sub, i) => {
        if (canShow(sub.fieldId)) {
          acc.push(
            <SubmissionYesNo
              key={sub.id}
              submission={sub}
              onCommentChange={handleSubmissionComments(sub)}
              onAccurateSelect={handleSubmissionAccuracy(sub.id)}
              locationId={location.id}
              order={i + 1}
            />
          );
        }
        return acc;
      }, []);
    },
    [canShow, handleSubmissionComments, handleSubmissionAccuracy, location.id]
  );

  // Section in the validation tab that validates individual submissions
  const renderSubmissionValidationSection = useCallback(
    (params: { name: string; id: string; submissions: ISubmission[] }) => {
      const { name, id, submissions } = params;
      return !submissions.length ? null : (
        <div className="uc-review-validation__form" key={id}>
          <FieldValidationHeader label={name} />
          <div className="uc-review-validation__form__validation">
            <div className="uc-review-validation__form__validation__headers">
              <h3 style={{ width: '40%' }}>Question</h3>
              <h3 style={{ width: '18%' }}>Is this information accurate?</h3>
              <h3 style={{ width: '40%' }}>Changes/Discrepancies</h3>
            </div>
            <ul className="uc-review-validation__form__validation__main-list">
              {renderValidationSubmissions(submissions)}
            </ul>
          </div>
        </div>
      );
    },
    [renderValidationSubmissions]
  );

  // Yes/No switch and input at the bottom of contact and general validation sections
  const renderLocationValidation = useCallback(
    (formName: string) => {
      const validationType = locationValidationId(formName);
      const isAccurate = parseAccurateValue(
        submissionValidationValues[validationType].accurate
      );
      const comment = submissionValidationValues[validationType].comment || '';
      const onCommentChange = handleLocationComments(validationType);
      const onAccurateSelect = handleLocationAccuracy(validationType);

      return (
        <ValidationYesNo
          isAccurate={isAccurate}
          comment={comment}
          locationId={location.id}
          onAccurateSelect={onAccurateSelect}
          onCommentChange={onCommentChange}
        />
      );
    },
    [
      locationValidationId,
      parseAccurateValue,
      submissionValidationValues,
      handleLocationComments,
      handleLocationAccuracy,
      location.id,
    ]
  );

  // Renders all grid fields and full-width(notes/image) fields and validation if on the validation tab
  const renderForm = useCallback(
    (formName: string, fields: IField[], formSubmissions: ISubmission[]) => {
      if (!formSubmissions.length) {
        return null;
      }
      const allFields = formatFields(fields, formSubmissions);
      return (
        <div className="uc-review-submissions__form" key={formName}>
          <FieldValidationHeader label={formName} />
          {renderGridFields(
            allFields.gridFields.filter((gf) => gf.elements.length)
          )}
          {allFields.fullWidthFields}
          {isValidation && renderLocationValidation(formName)}
        </div>
      );
    },
    [formatFields, renderGridFields, isValidation, renderLocationValidation]
  );

  const renderProduct = useCallback(
    (product: IProduct) => {
      const { id: productId, name } = product;
      const selection = pushedSelections.find((s) => s.productId === productId);

      if (!selection) {
        return (
          <div key={productId}>
            <h3>{name}</h3>{' '}
            <div className="uc-review-submissions__form__fields">
              No selection for this product
            </div>
          </div>
        );
      }

      const { fieldOpsAccurate, fieldOpsComments } = selection || {};
      const { quantity = 'N/A' } = selection || {};
      const isAccurate = parseAccurateValue(fieldOpsAccurate);
      const comment = fieldOpsComments || '';
      const onAccurateSelect = (value: boolean) => {
        applySelections([
          {
            ...selection,
            fieldOpsAccurate: value,
          },
        ]);
        trackSelectAction(
          `Product Validated: ${value}, estimateId: ${estimateId}`,
          { opType: 'buttonClick' }
        );
      };
      const onCommentChange = (comment: string) => {
        trackSelectAction(
          `Product Validation Comment: ${comment}, estimateId: ${estimateId}`,
          { opType: 'inputFieldUpdate' }
        );

        applySelections([
          {
            ...selection,
            fieldOpsComments: comment,
          },
        ]);
      };

      return (
        <div key={productId}>
          <h3>{name}</h3>
          <div className="uc-review-submissions__form__fields">
            Quantity: {quantity}
          </div>
          <ValidationYesNo
            isAccurate={isAccurate}
            comment={comment}
            locationId={location.id}
            onAccurateSelect={onAccurateSelect}
            onCommentChange={onCommentChange}
          />
        </div>
      );
    },
    [
      pushedSelections,
      parseAccurateValue,
      location.id,
      applySelections,
      trackSelectAction,
      estimateId,
    ]
  );

  const renderProductValidation = useCallback(() => {
    const productsToValidate = allProducts.filter((p) => p.requiresValidation);
    return productsToValidate.map((p) => renderProduct(p));
  }, [allProducts, renderProduct]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return {
    renderForm,
    formatFields,
    renderGridFields,
    renderSubmissionValidationSection,
    renderProductValidation,
  };
};
