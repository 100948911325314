import { IDistro, IDistrosRequest } from 'types';
import { queryKeys, distrosRequest } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

interface IUseGetDistrosParams extends IDistrosRequest {
  onUpdateSubmission?: (res: IDistro[]) => void;
}
export const useGetDistros = (requestConfig: IUseGetDistrosParams = {}) => {
  const { params, onUpdateSubmission } = requestConfig;

  return useGenerateQuery<IDistro[]>({
    queryKey: queryKeys({ estimateId: params?.estimateId }).details.distros,
    apiCall: distrosRequest,
    requestConfig,
    opts: {
      enabled: !!params?.estimateId,
      refetchOnWindowFocus: false,
      onSuccess: (res: IDistro[]) => {
        if (onUpdateSubmission) {
          onUpdateSubmission(res);
        }
      },
    },
  });
};
