import axios from 'axios';
import { IRequestConfig, ILocationSelection, IRequestParams } from 'types';
import { env } from './env';
import { paramsSerializer } from 'utils';
import { throwQueryError } from './throwQueryError';

export interface IBundleSelectionRequest
  extends IRequestConfig<Partial<ILocationSelection>[]> {
  params?: IRequestParams;
}

export const bundleSelectionRequest = async (
  config?: IBundleSelectionRequest
) => {
  const {
    endpoint = 'bundle-selections',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with bundle selections ${method}`,
      })
    );
};
