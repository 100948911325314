// Packages
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';

// Context
import AlertProvider from 'utils/globalAlert/AlertContext';

// Components
import { Breadcrumbs, Header } from 'components';

// Hooks

// Utils

// Types

// Styles
import './MainLayout.scss';

/** Main layout wrapper including header */

const MainLayout = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { pathname } = useLocation();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <AlertProvider>
      <div
        className={classNames({
          'main-layout': true,
          'main-layout--builder': pathname.includes('estimate-builder'),
        })}
      >
        {!pathname.includes('review-uc') && (
          <>
            <Header />
            <Breadcrumbs />
          </>
        )}
        <Outlet />
      </div>
    </AlertProvider>
  );
};

export default MainLayout;
