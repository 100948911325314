// External Packages
import React, { useCallback, useMemo } from 'react';
import { View } from '@react-pdf/renderer';

// Utils
import { generateViewComponent } from 'utils';

// Types
import {
  ILocation,
  TableValues,
  IGenerateProductTotals,
  TTermLengthMonths,
} from 'types';

// Styles
import { styles } from './PdfDocumentStyles';

interface ILocationData extends TableValues<string> {
  name: string;
}

interface IPdfLocationRows {
  estimateLocations: ILocation[];
  currentTerm: TTermLengthMonths;
  promoIdsByLocationName: { [locationName: string]: string[] };
  generateProductTotals: ({
    selections,
    locationId,
    category,
    subCategory,
    format,
  }: IGenerateProductTotals) => TableValues<string | number>;
}

export const PdfLocationRows = ({
  estimateLocations,
  currentTerm,
  generateProductTotals,
  promoIdsByLocationName,
}: IPdfLocationRows) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const locationData: ILocationData[] = useMemo(() => {
    return estimateLocations.map((location) => {
      const outputTotals = generateProductTotals({
        term: currentTerm,
        locationId: location.id,
      }) as TableValues<string>;

      return {
        name: location.name,
        ...outputTotals,
      };
    });
  }, [estimateLocations, currentTerm, generateProductTotals]);

  // =============================================
  // Render Methods
  // =============================================
  const generateLocationRows = useCallback(() => {
    return locationData.map((location) => {
      const locationValues = Object.entries(location).map(([key, value]) => {
        if (key === 'name') {
          const promoIds = promoIdsByLocationName[location.name];
          const label = `${value}${promoIds?.length ? '*' : ''}`;
          return generateViewComponent(
            label,
            'productCell',
            'location address'
          );
        }
        return generateViewComponent(value, 'tableCell', key);
      });

      return (
        <View key={`location row ${location.name}`} style={styles.productRow}>
          {locationValues}
        </View>
      );
    });
  }, [locationData, promoIdsByLocationName]);

  // =============================================
  // Return
  // =============================================
  return <View wrap={false}>{generateLocationRows()}</View>;
};
