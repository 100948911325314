// External Packages
import React from 'react';
import { KiteButton, KiteCard } from '@kite/react-kite';

// Styles
import './EssoLogin.scss';

// =============================================
// Interfaces
// =============================================
export interface IEssoLoginProps {
  onEssoClick: () => void;
}

// =============================================
// Component
// =============================================
const EssoLogin = ({ onEssoClick }: IEssoLoginProps) => {
  // =============================================
  // Return
  // =============================================
  return (
    <div className="esso-login">
      <KiteCard className="esso-login__card">
        <div className="esso-login__inner">
          <h2 className="esso-login__title kite-h2">Spectrum Employees</h2>
          <KiteButton className="esso-login__button" onClick={onEssoClick}>
            Sign In with ESSO
          </KiteButton>
        </div>
      </KiteCard>
      <div className="esso-login__contact-container">
        <p className="esso-login__contact-text">
          For employee help using this site, please contact
        </p>
        <a
          className="kite-link"
          href="mailto:DL-Solution-Pricing-Tool-Support@charter.com"
        >
          DL-Solution-Pricing-Tool-Support@charter.com
        </a>
      </div>
    </div>
  );
};

export default EssoLogin;
