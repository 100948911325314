// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

// Components
import { KiteSwitch } from '@kite/react-kite';

// Utils
import { generateClassnames, insightsMap, sdWanMap, NONE } from 'utils';

// Hooks
import {
  useDebounceInputSave,
  useDisabledCheck,
  useQueryDataContext,
} from 'hooks';

// Types
import {
  IProductFamily,
  ISelectionController,
  TDisableConfigFamily,
} from 'types';

// Styles
import './CustomSwitch.scss';

interface ICustomSwitchProps extends ISelectionController {
  productFamily: IProductFamily;
}

const CustomSwitch = ({ productFamily, onChange }: ICustomSwitchProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { handleDisabledCheck } = useDisabledCheck();
  const { currentSelections, scenarioId: currentScenarioId } =
    useQueryDataContext();
  const { saveSelection, debounceSave } = useDebounceInputSave({ onChange });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const { pageClass, componentClass } = generateClassnames(productFamily);

  const isDisabled = useMemo(() => {
    return handleDisabledCheck({
      family: productFamily.name as TDisableConfigFamily,
      selections: currentSelections,
    });
  }, [currentSelections, productFamily.name, handleDisabledCheck]);

  const switchSelection = useMemo(() => {
    return currentSelections.find((s) => s.familyId === productFamily.id);
  }, [productFamily.id, currentSelections]);

  const selectionValue =
    !!switchSelection && switchSelection.productId !== NONE && !isDisabled;

  const [inputValue, setInputValue] = useState({
    scenarioId: currentScenarioId,
    value: selectionValue,
  });

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSave = useCallback(
    (params: {
      scenarioId: string;
      productId: string;
      isDebounce?: boolean;
    }) => {
      const { scenarioId, productId, isDebounce } = params;
      isDebounce
        ? debounceSave({
            selectionId: switchSelection?.id,
            productId,
            selectionValues: { quantity: 1 },
            opType: 'toggleFlip',
            scenarioId,
          })
        : saveSelection({
            selectionId: switchSelection?.id,
            productId,
            selectionValues: { quantity: 1 },
            opType: 'toggleFlip',
          });
    },
    [debounceSave, saveSelection, switchSelection]
  );

  const handleToggleSwitch = useCallback(() => {
    if (isDisabled) return;

    let productId;

    if (productFamily.type === 'boolean-single') {
      productId = productFamily.products.find((p) => p.name === 'ON')?.id;
    } else if (productFamily.type === 'boolean-many') {
      const dfiProduct = currentSelections.find(
        (p) => p.familyName === 'DFI Speed'
      );
      productId = productFamily.products.find(
        (p) => p.name === dfiProduct?.name
      )?.id;

      const mneProduct = currentSelections.find(
        (p) => p.familyName === 'MNE Network Speed'
      );
      if (!productId && productFamily.name === 'Insights Licensing') {
        productId = productFamily.products.find(
          (p) => p.name === insightsMap(mneProduct?.name)
        )?.id;
      } else if (!productId && productFamily.name === 'SD-WAN Plus Licensing') {
        productId = productFamily.products.find(
          (p) => p.name === sdWanMap(mneProduct?.name)
        )?.id;
      }
    }

    if (inputValue.value || !productId) {
      productId = NONE;
    }

    setInputValue({ scenarioId: currentScenarioId, value: !inputValue.value });

    onSave({ scenarioId: currentScenarioId, productId, isDebounce: true });
  }, [
    isDisabled,
    productFamily.type,
    productFamily.name,
    productFamily.products,
    inputValue,
    onSave,
    currentScenarioId,
    currentSelections,
  ]);

  useEffect(() => {
    if (inputValue.scenarioId !== currentScenarioId) {
      setInputValue({ scenarioId: currentScenarioId, value: selectionValue });
    }
  }, [currentScenarioId, inputValue, selectionValue]);

  useEffect(() => {
    if (inputValue.value !== selectionValue && !debounceSave.isPending()) {
      setInputValue({ scenarioId: currentScenarioId, value: selectionValue });
    }
  }, [currentScenarioId, debounceSave, inputValue.value, selectionValue]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteSwitch
      aria-disabled={isDisabled}
      className={classNames({
        'custom-switch': true,
        'custom-switch--disabled': isDisabled,
        [`${pageClass}__custom-switch`]: true,
        [`${pageClass}__custom-switch--${componentClass}`]: true,
      })}
      label={`Add ${productFamily.name}`}
      isOn={inputValue.value}
      onClick={handleToggleSwitch}
    />
  );
};

export default CustomSwitch;
