import { useMutation, useQueryClient } from 'react-query';
import { fieldOpsPictureRequest, queryKeys } from 'utils';

export const useDeleteFieldOpsPicture = (locationId: string) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys({ locationId }).details.location;

  const {
    mutate: deleteFieldOpsPicture,
    isLoading: deleteFieldOpsPictureLoading,
    error: deleteFieldOpsPictureError,
    data: deleteFieldOpsPictureRes,
  } = useMutation(
    (fieldOpsPicId: string) =>
      fieldOpsPictureRequest({
        method: 'DELETE',
        endpoint: `field-ops-pictures/${fieldOpsPicId}`,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    deleteFieldOpsPicture,
    deleteFieldOpsPictureLoading,
    deleteFieldOpsPictureError,
    deleteFieldOpsPictureRes,
  };
};
