import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

interface IGenerateQuery<T> {
  queryKey: QueryKey;
  apiCall: (config: any) => Promise<any>;
  requestConfig?: any;
  opts?: UseQueryOptions<T, AxiosError>;
}

export const useGenerateQuery = <T>({
  queryKey,
  apiCall,
  requestConfig,
  opts,
}: IGenerateQuery<T>) => {
  const queryData = useQuery<T, AxiosError>(
    queryKey,
    () => apiCall(requestConfig),
    opts
  );

  return queryData;
};
