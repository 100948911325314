import { ISubmissionHistory, ISubmissionHistoryRequest } from 'types';
import { queryKeys, submissionHistoryRequest } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetSubmissionHistory = (
  requestConfig: ISubmissionHistoryRequest = {}
) => {
  const { params } = requestConfig;
  const enabled = !!params?.estimateId;

  return useGenerateQuery<ISubmissionHistory[]>({
    queryKey: queryKeys(params).filter.submissionHistory,
    apiCall: submissionHistoryRequest,
    requestConfig,
    opts: { enabled, staleTime: Infinity },
  });
};
