import { AxiosError } from 'axios';

interface IThrowQueryError {
  error: AxiosError;
  message: string;
}

export const throwQueryError = ({ error, message }: IThrowQueryError) => {
  console.error(error.response);
  throw new Error(message);
};
