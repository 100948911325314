import React, { useEffect } from 'react';
import { Header } from 'components';

import './Unauthorized.scss';
interface UnauthorizedProps {
  trackPageView: (pageName: string) => void;
}

const Unauthorized = ({ trackPageView }: UnauthorizedProps) => {
  useEffect(() => trackPageView('Unauthorized'), [trackPageView]);

  return (
    <div className="unauthorized">
      <Header />
      <div className="unauthorized__main">
        <h1>Unauthorized</h1>
        <div>
          Our records indicate you are not authorized for access to the Bundle
          Pricing Tool.
        </div>
        <div> To request access, please contact:</div>
        <div>
          <a href="mailto:DL-Bundle-Pricing-Tool-Support@charter.com">
            DL-Bundle-Pricing-Tool-Support@charter.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
