export const TermMonths = {
  '12': null,
  '24': null,
  '36': null,
  '48': null,
  '60': null,
  '84': null,
};

export const TermYears = {
  '1': null,
  '2': null,
  '3': null,
  '4': null,
  '5': null,
  '7': null,
};

export const EipTermMonths = {
  Purchase: null,
  '12': null,
  '24': null,
  '36': null,
  '48': null,
  '60': null,
  '84': null,
  'One-time Payment': null,
};

export type TTermLengthMonths = keyof typeof TermMonths;
export type TTermLengthYears = keyof typeof TermYears;
export type TEipTermLengthMonths = keyof typeof EipTermMonths;
