import { IEnv } from './env';

export const getSfClientId = (env: IEnv): string => {
  const envUrlMap = {
    test: 'test',
    develop: process.env.REACT_APP_SF_CLIENT_ID_DEV,
    local: process.env.REACT_APP_SF_CLIENT_ID_LOCAL,
    stage: process.env.REACT_APP_SF_CLIENT_ID_STAGE,
    prod: process.env.REACT_APP_SF_CLIENT_ID_PROD,
  };

  return envUrlMap[env] || '';
};
