// Packages
import React, { useCallback, useMemo } from 'react';
import { Handle, NodeProps, Position, useNodes } from 'react-flow-renderer';

// Redux

// Components
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { NodeTotalBadge, NodeConnectorHandle, NodeLabel } from '..';

// Hooks
import { useParams } from 'react-router-dom';
import { useGetEstimateDetails } from 'hooks';

// Utils

// Types
import { IUCQDesignFlowNode } from 'types';

// Styles
import './IDFNode.scss';

/** Renders an IDF (type: 'idf') distro node within react-flow chart */

const IDFNode = ({
  id,
  data: {
    name,
    locationId,
    isDemarc,
    connectorType,
    interconnectType,
    editNode,
    addNode,
    switchPorts = 0,
    phones = 0,
    distance = 0,
    interconnectTypeIdf
  },
}: NodeProps<IUCQDesignFlowNode>) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId } = useParams();

  const { estimateData } = useGetEstimateDetails(estimateId);

  const nodes = useNodes<IUCQDesignFlowNode>();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const thisNode = useMemo(() => nodes.find((n) => n.id === id), [id, nodes]);

  const locationName = useMemo(() => {
    const location = estimateData?.locations.find((l) => l.id === locationId);
    if (location) {
      return location.name;
    }
    return '';
  }, [estimateData, locationId]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleAdd = useCallback(() => {
    if (thisNode && addNode) {
      addNode(thisNode);
    }
  }, [thisNode, addNode]);

  const handleEdit = useCallback(() => {
    if (thisNode && editNode) {
      editNode(thisNode);
    }
  }, [thisNode, editNode]);

  // =============================================
  // Render Methods
  // =============================================
  const externalBdgIds = useMemo(() => {
    if (thisNode) {
      const externalConnections = Array.from(
        new Set(
          nodes
            .filter(
              (n) =>
                thisNode?.data.toNodeIds?.includes(n.id) &&
                n.parentNode !== thisNode.parentNode
            )
            .map((n) => n.parentNode)
        )
      );

      return externalConnections.map((c) => (
        <NodeLabel
          key={c}
          className="idf-node__ext-bdg"
          label={`to ${c?.toUpperCase() || ''}`}
          type="bdg"
        />
      ));
    }

    return [];
  }, [nodes, thisNode]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className="idf-node"
      style={!externalBdgIds.length ? { paddingBottom: 10 } : {}}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ opacity: 0, pointerEvents: 'none' }}
      />
      <div className="idf-node__connector">
        Interconnect: {interconnectTypeIdf || 'N/A'}<br />
        Connector: {connectorType || 'N/A'}
      </div>
      <div className="idf-node__name">
        <span>{name || 'No Name Provided'}</span>
        <KiteButton type="standalone-link" onClick={handleEdit}>
          <KiteIcon name="edit" />
        </KiteButton>
      </div>
      <div className="idf-node__labels">
        <strong>IDF</strong>
        <NodeLabel label={id} type="idf" />
        {isDemarc && <NodeLabel label="DEMARC" type="demarc" />}
      </div>
      <div className="idf-node__location">
        {locationName || 'No Location Provided'}
      </div>
      <div className="idf-node__badges">
        <NodeTotalBadge total={switchPorts} iconName="ethernet" />
        <NodeTotalBadge total={phones} iconName="phone-office" />
      </div>
      <div className='idf-node__badges_distance'>
        <NodeTotalBadge total={distance || 0} iconName="distance" />
        {externalBdgIds}
      </div>
      {!!externalBdgIds.length && (
        <div className="idf-node__interconnect">
          {interconnectType || 'Interconnect: N/A'}
        </div>
      )}
      <NodeConnectorHandle
        onAddNode={handleAdd}
        disabled={interconnectType === 'Single Building'}
      />
    </div>
  );
};

export default IDFNode;
