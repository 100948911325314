import { IPromo, IRequestConfig } from 'types';
import { queryKeys, promosRequest } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetPromos = (requestConfig: IRequestConfig<IPromo> = {}) => {
  return useGenerateQuery<IPromo[]>({
    queryKey: queryKeys().all.promos,
    apiCall: promosRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
