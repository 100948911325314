// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Node } from 'react-flow-renderer';

// Redux

// Components
import { KiteCard, KiteInput, KiteModal, KiteSelect } from '@kite/react-kite';

// Hooks
import { useGetEstimateDetails } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import {
  connectorTypes,
  interconnectTypes,
  formatLocationAddress,
} from 'utils';

// Types
import { IUCQDesignFlowNode } from 'types';

// Styles
import './NewConnectionModal.scss';

export interface INewConnectionModalProps {
  /** Currently selected node */
  currentNode: Node<IUCQDesignFlowNode> | null;
  /** Determines if modal is displayed */
  isOpen: boolean;
  /** Callback for closing modal */
  onHide: () => void;
  /** Callback for submitting form data to create a new distro */
  onSubmit: (formData: { [key: string]: string }) => void;
}

/** A modal for creating a new IDF connection from an MDF or another IDF */

const idfForm = { sameBuilding: '', connectorType: '' };
const mdfForm = { connectorType: '' };
const idfFormNewBuilding = {
  sameBuilding: '',
  buildingName: '',
  locationId: '',
  interconnectType: '',
  connectorType: '',
};

const NewConnectionModal = ({
  currentNode,
  isOpen = true,
  onHide,
  onSubmit,
}: INewConnectionModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId } = useParams();

  const { estimateData } = useGetEstimateDetails(estimateId);

  const nodeType = currentNode?.type;

  const [formData, setFormData] = useState<{
    [key: string]: string;
  }>(nodeType === 'mdf' ? mdfForm : idfForm);

  const [errId, setErrId] = useState<string | null>(null);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const currentAddress = useMemo(() => {
    const location = estimateData?.locations.find(
      (l) => l.id === formData.locationId
    );
    if (location) {
      return formatLocationAddress(location);
    }

    return '';
  }, [estimateData, formData.locationId]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { name, value } = e.target;
      if (name === 'sameBuilding') {
        value === 'No'
          ? setFormData({
              ...idfFormNewBuilding,
              ...formData,
              sameBuilding: value,
            })
          : setFormData({
              sameBuilding: value,
              connectorType: formData.connectorType,
            });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    },
    [formData]
  );

  const formComplete = useMemo(
    () =>
      Object.entries(formData)
        .filter(([key]) => key !== 'buildingName')
        .every(([_, value]) => !!value),
    [formData]
  );

  const handleSubmit = useCallback(() => {
    if (formComplete) {
      onSubmit(formData);
      onHide();
    }

    if (!formComplete) {
      const [incompleteId] = Object.entries(formData)
        .filter(([key]) => key !== 'buildingName')
        .find(([_, value]) => !value) || [''];

      if (incompleteId) {
        setErrId(incompleteId);
      }
    }
  }, [formComplete, onSubmit, formData, onHide]);

  const generateSelectOptions = useCallback(
    (values: any[], valueKey?: string, labelKey?: string) => {
      if (valueKey && labelKey) {
        return values.map((v) => (
          <option key={v[valueKey]} value={v[valueKey]}>
            {v[labelKey]}
          </option>
        ));
      }
      return values.map((v) => (
        <option key={v} value={v}>
          {v}
        </option>
      ));
    },
    []
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderModalContent = useCallback(() => {
    if (currentNode) {
      return (
        <div className="new-connection-modal__content">
          {currentNode?.type === 'idf' && (
            <KiteSelect
              onChange={onChange}
              id="sameBuilding"
              name="sameBuilding"
              label={`Is this connection in the same building (${currentNode?.parentNode})?`}
              value={formData.sameBuilding}
              placeholder={!formData.sameBuilding ? 'Make Selection' : ''}
              errorMessage={
                errId === 'sameBuilding' ? 'Please make a selection.' : ''
              }
            >
              <option value="Yes">Yes</option>
              <option value="No">No (New Building)</option>
            </KiteSelect>
          )}
          {formData.sameBuilding === 'No' && (
            <>
              <span className="new-connection-modal__subtext">
                An IDF will automatically be applied. If this building needs an
                MDF, select “Add Building (MDF)” on the main screen.
              </span>
              <KiteInput
                id="buildingName"
                name="buildingName"
                label="Building Name (optional)"
                value={formData.buildingName}
                onChange={onChange}
              />
              <KiteSelect
                value={formData.locationId}
                onChange={onChange}
                id="locationId"
                name="locationId"
                placeholder={!formData.locationId ? 'Make Selection' : ''}
                label="Service Location"
                errorMessage={
                  errId === 'locationId'
                    ? 'Please select a service location.'
                    : ''
                }
              >
                {generateSelectOptions(
                  estimateData?.locations || [],
                  'id',
                  'name'
                )}
              </KiteSelect>
              <div className="new-connection-modal__address">
                <span>Selected Location Address</span>
                <KiteCard>
                  <span>{currentAddress}</span>
                </KiteCard>
              </div>
              <KiteSelect
                id="interconnectType"
                name="interconnectType"
                value={formData.interconnectType}
                onChange={onChange}
                label="Interconnect Type"
                placeholder={!formData.interconnectType ? 'Make Selection' : ''}
                errorMessage={
                  errId === 'interconnectType'
                    ? 'Please select an Interconnect Type.'
                    : ''
                }
              >
                {generateSelectOptions(
                  interconnectTypes.filter((ict) =>
                    ict === 'Single Building' && nodeType !== 'mdf'
                      ? false
                      : true
                  )
                )}
              </KiteSelect>
            </>
          )}
          <KiteSelect
            id="connectorType"
            name="connectorType"
            value={formData.connectorType}
            onChange={onChange}
            label="Connector Type"
            placeholder={!formData.connectorType ? 'Make Selection' : ''}
            errorMessage={
              errId === 'connectorType' ? 'Please make a selection.' : ''
            }
          >
            {generateSelectOptions(connectorTypes)}
          </KiteSelect>
        </div>
      );
    }

    return <div>add mdf</div>;
  }, [
    currentNode,
    onChange,
    formData.sameBuilding,
    formData.buildingName,
    formData.locationId,
    formData.interconnectType,
    formData.connectorType,
    errId,
    generateSelectOptions,
    estimateData,
    currentAddress,
    nodeType,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (currentNode?.type === 'mdf') {
      setFormData(mdfForm);
    } else {
      setFormData(idfForm);
    }
  }, [currentNode]);

  useEffect(() => {
    if (errId && formData[errId]) {
      setErrId(null);
    }

    if (!currentNode) {
      setErrId(null);
    }
  }, [errId, formData, currentNode]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="new-connection-modal"
      title={`New Connection (${
        currentNode?.type === 'idf' ? 'IDF' : 'MDF'
      } > IDF)`}
      canShow={isOpen}
      onHide={onHide}
      ctaAction={handleSubmit}
      ctaCopy="Add"
      secondaryCtaAction={onHide}
      secondaryCtaCopy="Cancel"
    >
      {renderModalContent()}
    </KiteModal>
  );
};

export default NewConnectionModal;
