import { queryKeys, sandboxData } from 'utils';

import { IScenario } from 'types';

import { useGenerateQuery } from '../useGenerateQuery';
import { IScenarioRequest, scenarioRequest } from 'utils/api/scenarioRequest';

export const useGetEstimateScenarios = (
  requestConfig: IScenarioRequest = {}
) => {
  const { params } = requestConfig;

  const queryKey = params
    ? queryKeys(params).filter.estimateScenarios
    : queryKeys().all.estimateScenarios;

  const isSandbox =
    params?.estimateId?.includes('sandbox') ||
    params?.locationId?.includes('sandbox');

  if (!params?.locationId) {
    delete params?.locationId;
  }
  if (!params?.estimateId) {
    delete params?.estimateId;
  }

  const { data, isLoading, isSuccess, error, isRefetching } = useGenerateQuery<
    IScenario[]
  >({
    queryKey,
    apiCall: isSandbox
      ? () => new Promise((res) => res(sandboxData.estimate.scenarios))
      : scenarioRequest,
    requestConfig,
    opts: { refetchOnWindowFocus: false },
  });

  return { data, isLoading, isSuccess, error, isRefetching };
};
