import { IScenario } from 'types';
import { mockEstimates } from 'utils/mocks';
import { IApiResponse } from './types';

export const scenarios: IApiResponse<IScenario> = {
  get: {
    200: {
      message: 'success',
      total: mockEstimates.testOne.scenarios.length,
      data: mockEstimates.testOne.scenarios,
    },
  },
  getOne: {},
  post: {},
  put: {},
};
