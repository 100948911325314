// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Components
import {
  DiscretionaryButton,
  CustomDropdown,
  DiscretionaryInput,
  RadioToggle,
} from 'components';

// Hooks
import {
  useDebounceInputSave,
  useDisabledCheck,
  useErrorCheck,
  useQueryDataContext,
  useSelections,
} from 'hooks';

// Utils
import { NONE, TermMonths } from 'utils';

// Types
import {
  IProductFamily,
  TDiscretionValue,
  TDisableConfigFamily,
  ISelectionController,
  ILocationSelection,
  IOnSelectionChangeParams,
  TTermLengthMonths,
} from 'types';

// Styles
import './DiscretionWrapper.scss';

interface IDiscretionWrapperProps extends ISelectionController {
  productFamily: IProductFamily;
}

const DiscretionWrapper = ({ productFamily }: IDiscretionWrapperProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    currentSelections,
    allProducts,
    scenarioId: currentScenarioId,
    currentTerm,
  } = useQueryDataContext();
  const { handleDisabledCheck } = useDisabledCheck();
  const { checkDiscretionError } = useErrorCheck(currentSelections);
  const { debounceSave, saveSelection } = useDebounceInputSave();
  const { applySelection } = useSelections();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const selection = useMemo((): ILocationSelection | undefined => {
    return currentSelections.find((s) => s.familyId === productFamily.id);
  }, [productFamily.id, currentSelections]);

  const {
    id: selectionId = '',
    quantity = 1,
    filter = productFamily.filterOptions?.[0],
    productId = NONE,
  } = selection || {};

  const selectedProduct = useMemo(
    () => allProducts.find((p) => p.id === productId),
    [allProducts, productId]
  );

  const isDisabled = useMemo(() => {
    return handleDisabledCheck({
      family: `${productFamily.name} Discretion` as TDisableConfigFamily,
      selections: currentSelections,
    });
  }, [handleDisabledCheck, productFamily.name, currentSelections]);

  const { listPrice, current, floorPrice } = useMemo(() => {
    const discretionTermKey =
      `discretionValue${currentTerm}` as TDiscretionValue;
    const listPrice =
      selectedProduct?.prices.find(
        (p) => p.type === 'rate' && p.term === currentTerm
      )?.price || 0;
    const floorPrice =
      selectedProduct?.prices.find(
        (p) => p.type === 'discount' && p.term === currentTerm
      )?.price || 0;

    const selectionValue = selection
      ? selection[discretionTermKey] || null
      : null;

    const current = isDisabled ? null : selectionValue;

    return { current, listPrice, floorPrice };
  }, [isDisabled, selectedProduct, selection, currentTerm]);

  const [isDiscretionOpen, setIsDiscretionOpen] = useState(
    !!current && current !== listPrice
  );

  const [discretionValue, setDiscretionValue] = useState<{
    scenarioId: string;
    value: number | null;
  }>({ scenarioId: currentScenarioId, value: current });

  const errorMessage = useMemo(() => {
    if (selection) {
      return checkDiscretionError(selection);
    }
    return '';
  }, [selection, checkDiscretionError]);

  // =============================================
  // Interaction Handlers
  // =============================================

  const saveDiscretion = useCallback(
    (params: {
      scenarioId: string;
      value: number | null;
      isDebounce?: boolean;
    }) => {
      const { scenarioId, value, isDebounce } = params;
      isDebounce
        ? debounceSave({
            selectionId,
            productId,
            selectionValues: {
              ...selection,
              quantity,
              filter,
              [`discretionValue${currentTerm}`]: value,
            },
            opType: 'inputFieldUpdate',
            scenarioId,
          })
        : saveSelection({
            selectionId,
            productId,
            selectionValues: {
              ...selection,
              quantity,
              filter,
              [`discretionValue${currentTerm}`]: value,
            },
            opType: 'inputFieldUpdate',
          });
    },
    [
      debounceSave,
      selectionId,
      productId,
      selection,
      filter,
      quantity,
      currentTerm,
      saveSelection,
    ]
  );

  const onToggleDiscretion = useCallback(() => {
    setIsDiscretionOpen(!isDiscretionOpen);

    if (isDiscretionOpen) {
      // Save discretion value as null on cancel
      saveDiscretion({
        scenarioId: currentScenarioId,
        value: null,
        isDebounce: true,
      });
      setDiscretionValue({ scenarioId: currentScenarioId, value: null });
    } else {
      setDiscretionValue({ scenarioId: currentScenarioId, value: listPrice });
    }
  }, [currentScenarioId, isDiscretionOpen, listPrice, saveDiscretion]);

  const handleDiscretionChange = useCallback(
    (value: string) => {
      const newDiscretionValue = parseFloat(value);

      if (!isNaN(newDiscretionValue) && newDiscretionValue) {
        saveDiscretion({
          scenarioId: currentScenarioId,
          value: newDiscretionValue,
          isDebounce: true,
        });
        setDiscretionValue({
          scenarioId: currentScenarioId,
          value: newDiscretionValue,
        });
      }
    },
    [currentScenarioId, saveDiscretion]
  );

  const handleSelectChange = useCallback(
    ({ productId, selectionValues }: IOnSelectionChangeParams) => {
      const allTerms = Object.keys(TermMonths) as TTermLengthMonths[];

      const newProduct = allProducts.find((p) => p.id === productId);

      const discretionValues = allTerms.reduce(
        (acc: { [key: string]: number | null }, term) => {
          const value =
            newProduct?.prices.find((p) => p.type === 'rate' && p.term === term)
              ?.price || null;
          acc[`discretionValue${term}`] = value;
          return acc;
        },
        {}
      );

      const listPrice = discretionValues[`discretionValue${currentTerm}`];
      setDiscretionValue({ scenarioId: currentScenarioId, value: listPrice });

      debounceSave.cancel();

      applySelection({
        selectionId,
        productId,
        selectionValues: {
          quantity: selectionValues?.quantity || quantity,
          filter: selectionValues?.filter || filter,
          ...discretionValues,
        },
        opType: 'inputFieldUpdate',
      });
    },
    [
      allProducts,
      currentTerm,
      debounceSave,
      applySelection,
      selectionId,
      quantity,
      filter,
      currentScenarioId,
    ]
  );

  const handleRadioSelect = useCallback(
    (option: string) => {
      if (selection) {
        debounceSave.cancel();

        applySelection({
          selectionId,
          productId,
          selectionValues: {
            ...selection,
            filter: option,
          },
        });
      }
    },
    [selection, debounceSave, applySelection, selectionId, productId]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (discretionValue.scenarioId !== currentScenarioId) {
      debounceSave.cancel();
      // Reset discretion slider on scenario change
      setDiscretionValue({
        scenarioId: currentScenarioId,
        value: current || listPrice,
      });

      // If scenario selection's discretion value doesn't exist or is same as listPrice, close discretion slider
      if (!current || current === listPrice) {
        setIsDiscretionOpen(false);
      } else {
        // Else open discretion slider
        setIsDiscretionOpen(true);
      }
    }
  }, [
    current,
    discretionValue,
    debounceSave,
    currentScenarioId,
    isDiscretionOpen,
    listPrice,
  ]);

  useEffect(() => {
    // If data doesn't match local state, set local state to match
    if (discretionValue.value !== current && !debounceSave.isPending()) {
      setDiscretionValue({
        scenarioId: currentScenarioId,
        value: current || listPrice,
      });
    }
  }, [
    current,
    currentScenarioId,
    debounceSave,
    discretionValue.value,
    listPrice,
  ]);

  useEffect(() => {
    // Close slider if disabled
    if (isDiscretionOpen && isDisabled) {
      setIsDiscretionOpen(false);
    }
  }, [isDisabled, isDiscretionOpen]);

  // Save on unmount
  useEffect(() => {
    return () => debounceSave.flush();
  }, [debounceSave]);

  // =============================================
  // Return
  // =============================================
  return (
    <>
      <DiscretionaryButton
        productName={productFamily.name}
        discretionState={isDiscretionOpen}
        isDisabled={isDisabled}
        testId={`${productFamily.name} Discretionary Button`}
        onToggleButton={onToggleDiscretion}
      >
        <CustomDropdown
          productFamily={productFamily}
          onChange={handleSelectChange}
          showRadioToggle={false}
        />
      </DiscretionaryButton>

      {!isDisabled && isDiscretionOpen && (
        <DiscretionaryInput
          label="Price (move slider or type in price in the field below)"
          onChange={handleDiscretionChange}
          value={discretionValue.value}
          min={floorPrice.toString()}
          max={(listPrice * 2).toString()}
          errorMes={current ? errorMessage : ''}
        />
      )}

      {productFamily.filterOptions && (
        <RadioToggle
          options={productFamily.filterOptions}
          onChange={handleRadioSelect}
          name="Filter option"
          selectionValue={filter}
          isDisabled={!selection}
          isMutatePending={debounceSave.isPending()}
        />
      )}
    </>
  );
};

export default DiscretionWrapper;
