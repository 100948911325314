import { IEstimate, IEstimateRequest } from 'types';
import { estimateRequest, queryKeys } from 'utils';
import { useGenerateQuery } from '../useGenerateQuery';

export const useGetEstimates = (requestConfig: IEstimateRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<IEstimate[]>({
    queryKey: params
      ? queryKeys(params).filter.estimates
      : queryKeys().all.estimates,
    apiCall: estimateRequest,
    requestConfig,
    opts: {
      staleTime: 15000,
    },
  });
};
