// External Packages
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import classnames from 'classnames';

// Components
import { LinearProgressStepper } from '@kite/react-kite-plus';
import { KiteButton, KiteCard, KiteAlert } from '@kite/react-kite';
import { CustomCheckboxes, CustomRadio, QuantityCard } from 'components';

// Hooks
import { useAnalytics, useQueryDataContext, useScrollToTop } from 'hooks';

// Utils
import { sbbStepperValues } from 'utils';

// Types
import { IProductFamily, TSbbLocationOptions } from 'types';

// Styles
import './SbbPage.scss';

const SbbPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { allProductFamilies, currentSelections } = useQueryDataContext();

  const sbbProducts = allProductFamilies.filter(
    (prod) => prod.category === 'SBB 1-19 Pub/Priv'
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const steps = useMemo(
    () => ['Location', 'Package', 'Add-Ons', 'Equipment'],
    []
  );
  const [stepNumber, setStepNumber] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1, stepNumber });

  const sbbLocationFamily = allProductFamilies.find(
    (f) => f.name === 'Location'
  );
  const sbbSelection = useMemo(() => {
    return currentSelections.find(
      (prod) => prod.familyId === sbbLocationFamily?.id
    );
  }, [currentSelections, sbbLocationFamily]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const clearErrorMessage = useCallback(() => {
    setErrorMessage('');
  }, []);

  const handleErrorCheck = useCallback(() => {
    if (stepNumber === 0 && !sbbSelection) {
      setErrorMessage(
        'SBB 1 - 19 Public/Private must have a Location Type to configure SBB 1 - 19 Public/Private products'
      );
      return false;
    } else {
      errorMessage && clearErrorMessage();
      return true;
    }
  }, [errorMessage, sbbSelection, stepNumber, clearErrorMessage]);

  const onStepClick = useCallback(
    (s: string) => {
      handleErrorCheck() && setStepNumber(steps.indexOf(s));
    },
    [steps, handleErrorCheck]
  );

  // =============================================
  // Render Methods
  // =============================================
  const generateRadioSelectors = useCallback(
    (productFamily: IProductFamily) => {
      if (!sbbLocationFamily) return;
      return (
        <KiteCard>
          <CustomRadio productFamily={productFamily} />

          {!stepNumber && (
            <p className="sbb-page__note-text">
              NOTE: Solution Pricing Tool cannot be used for Bar and Restaurant
              pricing at this time.
            </p>
          )}
        </KiteCard>
      );
    },
    [sbbLocationFamily, stepNumber]
  );

  const generateCheckboxes = useCallback(
    (productFamily: IProductFamily) => {
      if (!sbbSelection) return;

      return (
        <KiteCard>
          <CustomCheckboxes
            productFamily={productFamily}
            addonType={sbbSelection.name as TSbbLocationOptions}
          />
        </KiteCard>
      );
    },
    [sbbSelection]
  );

  const generateComponents = useCallback(() => {
    const productFamily = sbbProducts.find((prod) =>
      prod.name.includes(steps[stepNumber])
    );
    if (!productFamily) return;

    if (productFamily.type === 'radio') {
      return generateRadioSelectors(productFamily);
    } else if (productFamily.type === 'boolean') {
      return generateCheckboxes(productFamily);
    } else if (productFamily.type === 'number') {
      return (
        <QuantityCard
          key={`${productFamily.name} card`}
          productFamily={productFamily}
          minValue="1"
          maxValue="20"
        />
      );
    }
  }, [
    sbbProducts,
    steps,
    stepNumber,
    generateRadioSelectors,
    generateCheckboxes,
  ]);

  const generateNavigationButtons = useCallback(() => {
    return (
      <>
        <KiteButton
          className={classnames({
            'sbb-page__prev-button': true,
            'sbb-page__prev-button--hidden': stepNumber === 0,
          })}
          type="outline"
          onClick={() => handleErrorCheck() && setStepNumber(stepNumber - 1)}
        >
          Previous: {steps[stepNumber - 1]}
        </KiteButton>

        <KiteButton
          className={classnames({
            'sbb-page__next-button': true,
            'sbb-page__next-button--hidden': stepNumber === steps.length - 1,
          })}
          type="outline"
          onClick={() => handleErrorCheck() && setStepNumber(stepNumber + 1)}
        >
          Next: {steps[stepNumber + 1]}
        </KiteButton>
      </>
    );
  }, [stepNumber, steps, handleErrorCheck]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (!sbbSelection) {
      setStepNumber(0);
    } else {
      errorMessage && handleErrorCheck();
    }
    // eslint-disable-next-line
  }, [sbbSelection]);

  useEffect(() => trackPageView('SBB 1-19'), [trackPageView]);

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} className="sbb-page">
      <h3>SBB 1-19 Public/Private Configuration</h3>

      <KiteCard className="sbb-page__stepper-wrapper">
        <LinearProgressStepper
          leanLeft
          activeStep={steps[stepNumber]}
          onClick={onStepClick}
          steps={sbbStepperValues}
        />
      </KiteCard>

      {errorMessage && (
        <KiteAlert
          className="sbb-page__alert"
          description={errorMessage}
          level="page"
          margin="10px 0"
          onClose={clearErrorMessage}
          type="alert"
        />
      )}

      <h2 className="sbb-page__header-text">
        {stepNumber + 1}. {steps[stepNumber]}
      </h2>
      {generateComponents()}

      <div className="sbb-page__button-container">
        {generateNavigationButtons()}

        <p
          className={classnames({
            'sbb-page__done-text': true,
            'sbb-page__done-button--hidden': stepNumber < steps.length,
          })}
        >
          When you are finished with SBB 1 - 19 Public/Private make another
          selection on the left.
        </p>
      </div>
    </div>
  );
};

export default SbbPage;
