import { RootState } from './store';
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

export interface IScenarioTabLocationIds {
  [scenarioId: string]: string;
}

export const scenarioTabSlice = createSlice<
  IScenarioTabLocationIds,
  SliceCaseReducers<IScenarioTabLocationIds>
>({
  name: 'tabLocationIds',
  initialState: {},
  reducers: {
    updateTabLocationIds: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

// Actions
export const { updateTabLocationIds } = scenarioTabSlice.actions;

// State
export const tabLocationIds = (state: RootState): IScenarioTabLocationIds => {
  return state.scenarioTabs;
};

// Reducer
export default scenarioTabSlice.reducer;
