import { IForm, IFormsRequest } from 'types';
import { formsRequest, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetForms = (requestConfig: IFormsRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<IForm[]>({
    queryKey: params ? queryKeys(params).filter.forms : queryKeys().all.forms,
    apiCall: formsRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
