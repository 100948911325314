// Packages
import React from 'react';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './LocationTotalBadge.scss';

export interface ILocationTotalBadgeProps {
  /** Total amount of locations within scenario */
  locationTotal: number;
}

/** Update this description, which will appear in the Storybook documentation */

const LocationTotalBadge = ({
  locationTotal = 0,
}: ILocationTotalBadgeProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="location-total-badge">
      <KiteIcon name="office" />({locationTotal})
    </div>
  );
};

export default LocationTotalBadge;
