import {
  IProduct,
  IProductFamily,
  IProfile,
  IProfileCategory,
  IServiceCodes,
} from 'types';
import { IApiResponse } from './types';

import {
  mockProductFamilies,
  mockProductProfileCategories,
  mockProductProfiles,
  mockServiceCodes,
} from 'utils';

export const productFamilies: IApiResponse<IProductFamily> = {
  get: {
    200: {
      message: 'success',
      total: mockProductFamilies.length,
      data: mockProductFamilies,
    },
  },
  getOne: {},
  post: {},
  put: {},
};

export const products: IApiResponse<IProduct> = {
  get: {
    200: {
      message: 'success',
      total: 3,
      data: mockProductFamilies.map((f) => f.products).flat(),
    },
  },
  getOne: {},
  post: {},
  put: {},
};

export const serviceCodes: IApiResponse<IServiceCodes> = {
  get: {
    200: {
      message: 'success',
      total: mockServiceCodes.length,
      data: mockServiceCodes,
    },
  },
  getOne: {},
  post: {},
  put: {},
};

export const productProfiles: IApiResponse<IProfile> = {
  get: {
    200: {
      message: 'success',
      total: 3,
      data: mockProductProfiles,
    },
  },
  getOne: {},
  post: {},
  put: {},
};

export const productProfileCategories: IApiResponse<IProfileCategory> = {
  get: {
    200: {
      message: 'success',
      total: mockProductProfileCategories.length,
      data: mockProductProfileCategories,
    },
  },
  getOne: {},
  post: {},
  put: {},
};
