import { RootState } from './store';
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { TTermLengthMonths } from 'types';

interface ICurrentSelectionIds {
  locationId: string;
  scenarioId: string;
  bundleTerm: TTermLengthMonths;
  isDuplicate?: { id: string; isDuplicate: boolean } | undefined;
}

export const currentSelectionIdsSlice = createSlice<
  ICurrentSelectionIds,
  SliceCaseReducers<ICurrentSelectionIds>
>({
  name: 'currentSelectionIds',
  initialState: {
    locationId: '',
    scenarioId: '',
    bundleTerm: '36',
    isDuplicate: undefined,
  },
  reducers: {
    setLocationId: (state, action) => {
      state.locationId = action.payload;
    },
    setScenarioId: (state, action) => {
      state.scenarioId = action.payload;
    },
    setBundleTerm: (state, action) => {
      state.bundleTerm = action.payload;
    },
    setDuplicate: (state, action) => {
      state.isDuplicate = action.payload;
    },
  },
});

// Actions
export const { setLocationId, setScenarioId, setBundleTerm, setDuplicate } =
  currentSelectionIdsSlice.actions;

// State
export const currentSelectionIds = (state: RootState): ICurrentSelectionIds =>
  state.currentSelectionIds;

// Reducer
export default currentSelectionIdsSlice.reducer;
