// External Packages
import React from 'react';
import classNames from 'classnames';

// Components
import { KiteTooltip } from '@kite/react-kite';

// Hooks

// Utils

// Styles
import './NavSwitch.scss';

// =============================================
// Interfaces
// =============================================
export interface ISwitchProps {
  label?: string;
  testId?: string;
  isOn: boolean;
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  showText?: boolean;
  disabled?: boolean;
  onText?: string;
  offText?: string;
  tooltip?: string | React.ReactNode;
  onFocus?: () => void;
  onBlur?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
}

// =============================================
// Component
// =============================================
const NavSwitch = ({
  label = '',
  testId,
  isOn = false,
  onClick,
  className = '',
  showText = true,
  disabled = false,
  onText = 'ON',
  offText = 'OFF',
  tooltip,
  onFocus,
  onBlur,
  title,
}: ISwitchProps) => {
  // =============================================
  // Return
  // =============================================
  return (
    <button
      title={title}
      aria-label="toggle"
      aria-pressed={isOn}
      onClick={onClick}
      type="button"
      disabled={disabled}
      onFocus={onFocus}
      onMouseLeave={onBlur}
      className="nav-switch-wrapper"
      data-testid={`${testId} button`}
    >
      <div
        className={classNames({
          'nav-switch__label': true,
          'nav-switch__label--disabled': disabled,
        })}
      >
        {label}
        {tooltip && (
          <KiteTooltip ariaLabel={`${label} tooltip`}>{tooltip}</KiteTooltip>
        )}
      </div>
      <div
        data-testid={testId}
        className={classNames({
          'nav-switch': true,
          'nav-switch--on': isOn,
          'nav-switch--disabled': disabled,
          [className || '']: className,
        })}
      >
        <span className="nav-switch__inner">
          <span
            className={classNames({
              'nav-switch__track': true,
              'nav-switch__track--disabled': disabled,
            })}
          >
            <span className="nav-switch__toggle" />
            {showText && (
              <>
                <span
                  className="nav-switch__track-label nav-switch__track-label--off"
                  aria-hidden
                >
                  {offText}
                </span>
                <span
                  className="nav-switch__track-label nav-switch__track-label--on"
                  aria-hidden
                >
                  {onText}
                </span>
              </>
            )}
          </span>
        </span>
      </div>
    </button>
  );
};

export default NavSwitch;
