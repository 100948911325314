// Packages
import React, { useCallback, useRef } from 'react';

// Redux

// Components
import { KiteAlert, KiteIcon, KiteInput } from '@kite/react-kite';

// Hooks
import { useOutsideClick } from 'hooks';

// Utils

// Types
import { TActiveDirectorySearchRes } from 'types';

// Styles
import './ActiveDirSuggestionInput.scss';

export interface IActiveDirSuggestionInputProps {
  showSuggestions: boolean;
  toggleShowSuggestions: (canShow: boolean) => void;
  isLoading: boolean;
  error: string;
  suggestions?: TActiveDirectorySearchRes;
  onSelect: (teamMember: TActiveDirectorySearchRes[number]) => void;
  salesTeamPids: string[];
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  className?: string;
}

/** Renders an input that displays results from Active Directory search as list of dropdown options */

const ActiveDirSuggestionInput = ({
  suggestions,
  showSuggestions,
  toggleShowSuggestions,
  onSelect,
  onSearch,
  searchValue,
  salesTeamPids,
  isLoading,
  error,
  className = '',
}: IActiveDirSuggestionInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const arrowRef = useRef<HTMLButtonElement>(null);
  const firstSuggestion = useRef<HTMLLIElement | null>(null);
  const lastSuggestion = useRef<HTMLLIElement | null>(null);
  useOutsideClick(rootRef, () => toggleShowSuggestions(false));

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const applySuggestionRefs = useCallback(
    (i: number) => (el: HTMLLIElement | null) => {
      if (!i) {
        firstSuggestion.current = el;
      }
      if (suggestions && i === suggestions.length - 1) {
        lastSuggestion.current = el;
      }
      return { firstSuggestion, lastSuggestion };
    },
    [suggestions]
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleArrowClick = useCallback(() => {
    toggleShowSuggestions(!showSuggestions);
  }, [toggleShowSuggestions, showSuggestions]);

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Enter':
          toggleShowSuggestions(true);
          firstSuggestion.current?.focus();
          break;
        case 'ArrowDown':
          toggleShowSuggestions(true);
          firstSuggestion.current?.focus();
          break;
        case 'Esc':
          e.preventDefault();
          e.stopPropagation();
          inputRef.current?.focus();
          toggleShowSuggestions(false);
          break;
        default:
          break;
      }
    },
    [firstSuggestion, toggleShowSuggestions]
  );

  const handleArrowKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      switch (e.key) {
        case 'Enter':
          toggleShowSuggestions(true);
          firstSuggestion.current?.focus();
          break;
        case 'ArrowDown':
          toggleShowSuggestions(true);
          firstSuggestion.current?.focus();
          break;
        case 'ArrowUp':
          toggleShowSuggestions(false);
          break;
        default:
          break;
      }
    },
    [toggleShowSuggestions]
  );

  const handleSuggestionKeyDown = useCallback(
    (
      e: React.KeyboardEvent<HTMLLIElement>,
      teamMember: TActiveDirectorySearchRes[number]
    ) => {
      const { currentTarget } = e;
      const nextSuggestion = currentTarget.nextElementSibling as HTMLElement;
      const previousSuggestion =
        currentTarget.previousElementSibling as HTMLElement;

      const isFirst = currentTarget.id === String(0);
      const isLast =
        suggestions && currentTarget.id === String(suggestions.length - 1);
      const isOnly = isFirst && isLast;

      switch (e.key) {
        case 'Esc':
          e.preventDefault();
          e.stopPropagation();
          toggleShowSuggestions(false);
          inputRef.current?.focus();
          break;
        case 'Enter':
          e.preventDefault();
          onSelect(teamMember);
          toggleShowSuggestions(false);
          inputRef.current?.focus();
          break;
        case 'Tab':
          toggleShowSuggestions(false);
          arrowRef.current?.focus();
          break;
        case 'ArrowDown':
          e.preventDefault();
          if (isOnly) {
            break;
          }

          if (isLast) {
            firstSuggestion.current?.focus();
            break;
          }

          nextSuggestion.focus();
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (isOnly) {
            break;
          }
          if (isFirst) {
            lastSuggestion.current?.focus();
            break;
          }

          previousSuggestion.focus();
          break;
        default:
          break;
      }
    },
    [
      firstSuggestion,
      lastSuggestion,
      onSelect,
      suggestions,
      toggleShowSuggestions,
    ]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderDropdownResults = useCallback(() => {
    if (searchValue.length < 3) {
      return (
        <p className="active-dir-suggestion-input__results-message">
          Enter at least three characters to start search
        </p>
      );
    }

    if (!suggestions || isLoading) {
      return (
        <p className="active-dir-suggestion-input__results-message">
          Searching Active Directory...
        </p>
      );
    }

    return (
      <>
        <ul>
          {suggestions?.map((suggestion, i) => {
            const isSelected = salesTeamPids.includes(suggestion.pid);
            return (
              <li
                key={suggestion.pid}
                id={String(i)}
                onClick={() => onSelect(suggestion)}
                onKeyDown={(e) => handleSuggestionKeyDown(e, suggestion)}
                tabIndex={0}
                role="option"
                aria-selected={isSelected}
                ref={applySuggestionRefs(i)}
              >
                <div>
                  <p className="active-dir-suggestion-input__results-name">
                    {suggestion.first_name} {suggestion.last_name}
                    {isSelected && <KiteIcon name="checkmark" />}
                  </p>
                  <p>
                    ({suggestion.title} / {suggestion.department})
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
        {suggestions && (
          <div className="active-dir-suggestion-input__results-total">
            {suggestions.length
              ? `${suggestions.length} Result${
                  suggestions.length > 1 ? 's' : ''
                }`
              : 'No Matches Found'}
          </div>
        )}
      </>
    );
  }, [
    searchValue.length,
    isLoading,
    suggestions,
    salesTeamPids,
    applySuggestionRefs,
    onSelect,
    handleSuggestionKeyDown,
  ]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className={`active-dir-suggestion-input ${className}`} ref={rootRef}>
      {error && <KiteAlert description={error} />}
      <p className="active-dir-suggestion-input__label">
        Search for Team Members by PID or Name
      </p>
      <div className="active-dir-suggestion-input__input">
        <KiteInput
          value={searchValue}
          onChange={onSearch}
          onKeyDown={handleInputKeyDown}
          maxWidth="none"
          inputProps={{ ref: inputRef }}
        />
        <button
          type="button"
          aria-label="Toggle suggestion list"
          aria-expanded={showSuggestions}
          onClick={handleArrowClick}
          onKeyDown={handleArrowKeyDown}
          className="active-dir-suggestion-input__arrow"
          ref={arrowRef}
        >
          <KiteIcon name={showSuggestions ? 'chevron-down' : 'chevron-right'} />
        </button>
      </div>
      {showSuggestions && (
        <div className="active-dir-suggestion-input__results">
          {renderDropdownResults()}
        </div>
      )}
    </div>
  );
};

export default ActiveDirSuggestionInput;
