import { ILocation } from 'types';
import { IApiResponse } from './types';

import { mockLocation } from 'utils/mocks';

export const locations: IApiResponse<ILocation> = {
  get: {
    200: {
      message: 'success',
      total: Object.keys(mockLocation).length,
      data: Object.values(mockLocation),
    },
  },
  getOne: {
    200: {
      message: 'success',
      total: 1,
      data: mockLocation.location1,
    },
  },
  post: {},
  put: {},
};
