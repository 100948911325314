import { TTermLengthMonths } from 'types';

export const TablePriceType = {
  rate: 'Rate',
  discount: 'Discount',
};

export type TPriceType = keyof typeof TablePriceType;

export interface INewPrice {
  updatedBy: string;
  productId: string;
  rateCard?: string | null;
  term: TTermLengthMonths;
  type: TPriceType;
  price: number;
}

export interface IPrice extends INewPrice {
  id: string;
  createdAt: string;
  updatedAt: string;
}
