// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';

// Redux

// Components
import { KiteButton, KiteModal } from '@kite/react-kite';
import {
  ScenarioTab,
  LocationDropdown,
  ScenarioModal,
  ImportModal,
  TransportTypeSelect,
  LocationsDrawer,
} from 'components';

// Hooks
import {
  useDeleteProfiles,
  useQueryData,
  useSelections,
  useUpdateEstimate,
} from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { NONE } from 'utils';

// Types
import { IEstimateUpdate, ILocation, ILocationSelection } from 'types';

// Styles
import './EstimateBuilderControls.scss';

/** Controls for estimate builder, including the estimate builder content */

interface IEstimateBuilderControlsProps {
  setPdfModalIsOpen: (value: boolean) => void;
}

const EstimateBuilderControls = ({
  setPdfModalIsOpen,
}: IEstimateBuilderControlsProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    currentEstimate,
    estimateScenarios,
    locationId,
    currentScenario,
    currentSelections,
    updateScenarioId,
    updateLocationId,
    scenarioTabLocationIds,
    allProfiles,
    scenarioId,
  } = useQueryData();
  const { estimateId = '' } = useParams();
  const { updateEstimate } = useUpdateEstimate();
  const { applySelections, applyProfilesAndSelections } = useSelections();
  const { deleteProfiles } = useDeleteProfiles();

  const [showScenarioModal, setShowScenarioModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [importLocationId, setImportLocationId] = useState('');
  const [locDrawerIsOpen, setLocDrawerIsOpen] = useState(false);
  const [scenarioModalType, setScenarioModalType] = useState<'edit' | 'new'>(
    'edit'
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const buttonWidth = useMemo(
    () => ({ maxWidth: 'min-content', minWidth: 'min-content' }),
    []
  );

  const isSandbox = estimateId === 'sandbox';

  const locationData = useMemo(
    () =>
      // need to reduce to get full location since scenario location only includes id
      currentScenario?.locations?.reduce((acc: ILocation[], l) => {
        const estimateLocation = currentEstimate?.locations.find(
          (el) => el.id === l.id
        );
        if (estimateLocation) {
          acc.push(estimateLocation);
        }
        return acc;
      }, []) || [],
    [currentEstimate, currentScenario]
  );

  const otherLocations = useMemo(
    () => locationData.filter((l) => l.id !== locationId),
    [locationData, locationId]
  );

  const profilesToImport = useMemo(() => {
    return allProfiles.filter(
      (profile) =>
        profile.locationId === importLocationId &&
        profile.scenarioId === currentScenario?.id
    );
  }, [allProfiles, currentScenario, importLocationId]);

  const selectionsToImport = useMemo(() => {
    const getSelectionId = (selection: ILocationSelection) =>
      currentSelections.find((s) => {
        return s.productId === selection.productId && !s.profileId;
      })?.id || `newSelection${v4()}`;

    const imports =
      currentScenario?.selections.reduce((acc: ILocationSelection[], s) => {
        if (s.locationId === importLocationId) {
          acc.push({
            ...s,
            id: getSelectionId(s),
            locationId,
          });
        }
        return acc;
      }, []) || [];

    const importIds = new Set(imports.map((s) => s.id));

    const removedSelections = currentSelections.reduce(
      (acc: ILocationSelection[], s) => {
        if (!importIds.has(s.id)) {
          acc.push({ ...s, productId: NONE });
        }
        return acc;
      },
      []
    );

    return [...imports, ...removedSelections];
  }, [currentScenario, currentSelections, importLocationId, locationId]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleScenarioModal = useCallback(
    (params: { id?: string; modalType: 'edit' | 'new' }) => {
      const { id, modalType } = params;
      if (id) {
        updateScenarioId(id);
      }
      setScenarioModalType(modalType);
      setShowScenarioModal(!showScenarioModal);
    },
    [showScenarioModal, updateScenarioId]
  );

  const toggleLocationDrawer = useCallback(() => {
    setLocDrawerIsOpen(!locDrawerIsOpen);
  }, [locDrawerIsOpen, setLocDrawerIsOpen]);

  const handlePDFShare = useCallback(() => {
    setPdfModalIsOpen(true);
  }, [setPdfModalIsOpen]);

  const closeDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const toggleImportModal = useCallback(() => {
    setShowImportModal(!showImportModal);
    if (showImportModal && otherLocations.length) {
      setImportLocationId(otherLocations[0].id);
    }
  }, [otherLocations, showImportModal]);

  const handleImportLocationSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setImportLocationId(value);
    },
    []
  );

  const handleImport = useCallback(() => {
    // Handle import differently if import location contains profiles
    if (profilesToImport.length) {
      applyProfilesAndSelections({
        profiles: profilesToImport.map((p) => ({ ...p, locationId })),
        selections: selectionsToImport,
      });
    } else {
      applySelections(selectionsToImport.filter((s) => !s.profileId));
    }
    toggleImportModal();
  }, [
    applyProfilesAndSelections,
    applySelections,
    locationId,
    profilesToImport,
    selectionsToImport,
    toggleImportModal,
  ]);

  const handleDeleteScenario = useCallback(() => {
    if (!currentEstimate || !scenarioId) return;
    const updatedScenarios = estimateScenarios.filter(
      (s) => s.id !== scenarioId
    );
    const updatedEstimate: IEstimateUpdate = {
      ...currentEstimate,
      scenarios: updatedScenarios,
    };

    const profileIdsToRemove = new Set(
      currentScenario?.selections.reduce((acc: string[], s) => {
        if (s.profileId) {
          acc.push(s.profileId);
        }
        return acc;
      }, [])
    );

    updateEstimate(updatedEstimate);
    if (profileIdsToRemove.size) {
      deleteProfiles(Array.from(profileIdsToRemove));
    }

    const { id: defaultScenarioId, locations } = updatedScenarios[0];
    const [firstLocation] = locations;
    const defaultLocationId = firstLocation.id;
    updateScenarioId(defaultScenarioId);
    updateLocationId(
      scenarioTabLocationIds[defaultScenarioId] || defaultLocationId
    );

    closeDeleteModal();
  }, [
    currentEstimate,
    scenarioId,
    estimateScenarios,
    currentScenario,
    updateEstimate,
    updateScenarioId,
    updateLocationId,
    scenarioTabLocationIds,
    closeDeleteModal,
    deleteProfiles,
  ]);

  const onDeleteScenario = useCallback(() => {
    setShowScenarioModal(false);
    setShowDeleteModal(true);
  }, []);

  // =============================================
  // Render Methods
  // =============================================
  const renderScenarioTabs = useCallback(() => {
    estimateScenarios.sort((a, b) => a.order - b.order);

    if (estimateScenarios) {
      const tabs = estimateScenarios.map((s) => (
        <ScenarioTab
          key={s.id || 'new-scenario'}
          scenarioData={s}
          toggleScenarioModal={toggleScenarioModal}
        />
      ));
      return estimateScenarios.length < 3
        ? [
            ...tabs,
            <KiteButton
              key="add-tab"
              className={classNames({
                'scenario-tab': true,
                'scenario-tab--add-tab': true,
              })}
              type="standalone-link"
              maxWidth="min-content"
              minWidth="min-content"
              leftIcon="plus"
              onClick={() => toggleScenarioModal({ modalType: 'new' })}
            >
              New Scenario
            </KiteButton>,
          ]
        : tabs;
    }
  }, [estimateScenarios, toggleScenarioModal]);

  const renderLocationControls = useCallback(() => {
    if (locationData.length) {
      return (
        <div className="estimate-builder-controls__locations-controls">
          <LocationDropdown label="Current Location" />
          {!isSandbox && <TransportTypeSelect label="Transport Type" />}
          {isSandbox && (
            <KiteButton
              maxWidth="min-content"
              minWidth="min-content"
              onClick={toggleLocationDrawer}
              type="primary"
            >
              Locations
            </KiteButton>
          )}
          {estimateId !== 'sandbox' && (
            <ImportModal
              canShow={showImportModal}
              importLocationId={importLocationId}
              importType={'selection'}
              locations={otherLocations}
              onImport={handleImport}
              onLocationSelect={handleImportLocationSelect}
              onToggle={toggleImportModal}
              disabled={!otherLocations.length}
            />
          )}
        </div>
      );
    }

    return null;
  }, [
    locationData.length,
    isSandbox,
    toggleLocationDrawer,
    otherLocations,
    estimateId,
    showImportModal,
    importLocationId,
    handleImport,
    handleImportLocationSelect,
    toggleImportModal,
  ]);

  const renderPDFButton = useCallback(() => {
    return (
      <KiteButton
        className="estimate-builder-controls__pdf-btn"
        {...buttonWidth}
        type="standalone-link"
        leftIcon="share-f"
        onClick={handlePDFShare}
      >
        Download PDF
      </KiteButton>
    );
  }, [handlePDFShare, buttonWidth]);

  // =============================================
  // Effects
  // =============================
  useEffect(() => {
    if (
      otherLocations.length &&
      (!importLocationId || importLocationId === locationId)
    ) {
      setImportLocationId(otherLocations[0].id);
    }
  }, [locationId, importLocationId, otherLocations]);

  // =============================================
  // Early Return
  // =============================================
  if (!currentEstimate) return null;

  // =============================================
  // Return
  // =============================================
  return (
    <>
      <div className="estimate-builder-controls">
        <div className="estimate-builder-controls__scenarios">
          {!isSandbox && renderScenarioTabs()}
        </div>

        <div className="estimate-builder-controls__locations">
          {renderPDFButton()}
          {renderLocationControls()}
        </div>
      </div>
      <ScenarioModal
        isModalOpen={showScenarioModal}
        closeModal={() => setShowScenarioModal(false)}
        onDeleteScenario={onDeleteScenario}
        modalType={scenarioModalType}
      />

      <KiteModal
        modalId="delete-scenario"
        className="estimate-builder-controls__modal--delete"
        title="Delete Scenario"
        onHide={closeDeleteModal}
        canShow={showDeleteModal}
        ctaCopy="Delete"
        ctaAction={handleDeleteScenario}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={closeDeleteModal}
      >
        <p>
          You are about to delete this scenario which will delete all of your
          saved changes. Would you like to continue?
        </p>
      </KiteModal>
      <LocationsDrawer
        isOpen={locDrawerIsOpen}
        onClose={toggleLocationDrawer}
        isSandbox={estimateId === 'sandbox'}
      />
    </>
  );
};
export default EstimateBuilderControls;
