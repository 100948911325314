import { IDistro } from 'types';

export const sortDistros = (distros: IDistro[]) => {
  return distros.sort((a, b) => {
    const idToNum = (id: string) => parseInt(id.slice(1));
    const isIdf = (distro: IDistro) => distro.nodeType === 'idf';
    const isBdg = (distro: IDistro) => distro.nodeType === 'bdg';
    if (isBdg(a) && isBdg(b)) {
      const aId = idToNum(a.nodeId);
      const bId = idToNum(b.nodeId);
      return aId < bId ? -1 : aId > bId ? 1 : 0;
    }

    if (isBdg(a) && !isBdg(b)) {
      return -1;
    }

    if (!isBdg(a) && isBdg(b)) {
      return 1;
    }

    if (!isIdf(a) && isIdf(b)) {
      return -1;
    }

    if (isIdf(a) && !isIdf(b)) {
      return 1;
    }

    const isBothIdf = isIdf(a) && isIdf(b);
    const aNum = idToNum(a.nodeId);
    const bNum = idToNum(b.nodeId);

    if (isBothIdf && aNum < bNum) {
      return -1;
    }

    if (isBothIdf && aNum > bNum) {
      return 1;
    }

    return 0;
  });
};
