import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { INewEstimate, IEstimate } from 'types';
import { estimateRequest, isValidJsonString, queryKeys } from 'utils';

export const usePostEstimate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    mutate: postEstimate,
    isLoading: postEstimateLoading,
    error: postEstimateError,
    data: postEstimateRes,
  } = useMutation(
    (newEstimate: INewEstimate) =>
      estimateRequest({
        method: 'POST',
        endpoint: 'estimates',
        data: newEstimate,
      }),
    {
      onSuccess: (data: IEstimate) => {
        queryClient.invalidateQueries(queryKeys().all.estimates);
        navigate(`/dashboard/${data.id}`);
      },
      onError: (err) => {
        const error = err as Error;
        if (error && isValidJsonString(error.message)) {
          const { status, id } = JSON.parse(error.message);
          if (status === 409 && id) {
            queryClient.invalidateQueries(queryKeys().all.estimates);
            navigate(`/dashboard/${id}`);
            return;
          }
        }
      }
    }
  );

  return {
    postEstimate,
    postEstimateLoading,
    postEstimateError,
    postEstimateRes,
  };
};
