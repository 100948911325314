// Packages
import React from 'react';
import { usePDF } from '@react-pdf/renderer';

// Components
import { KiteButton } from '@kite/react-kite';
import { PdfWrapper } from '../PdfPages';

// Hooks
import { useAnalytics, useGenerateProductTotals, useQueryData } from 'hooks';

// Styles
import './PdfDownloads.scss';

export interface IPdfDownloadsProps {
  quoteName: string;
  setPdfModalIsOpen: (arg: boolean) => void;
  setPdfGenerated: (arg: boolean) => void;
  pdfMulti: boolean;
}

/** PDF Generation and Download Buttons */
const PdfDownloads = ({
  quoteName,
  setPdfModalIsOpen,
  setPdfGenerated,
  pdfMulti,
}: IPdfDownloadsProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const {
    allProductFamilies,
    currentTerm,
    currentEipTerm,
    currentEstimate,
    locationId,
    allServiceCodes,
    currentScenario,
    allProfiles,
    allPromos,
  } = useQueryData();

  const { generateProductTotals } = useGenerateProductTotals();

  // =============================================
  // USE PDF HOOKS (Documentation: https://react-pdf.org/advanced#on-the-fly-rendering)
  // =============================================
  const { locations = [], customerName = '' } = currentEstimate || {};
  const [multiInstance] = usePDF({
    document: (
      <PdfWrapper
        pdfMulti={true}
        quoteName={quoteName}
        customerName={customerName}
        locations={locations}
        locationId={locationId}
        currentScenario={currentScenario}
        currentEstimate={currentEstimate}
        termMonth={currentTerm}
        eipTermMonth={currentEipTerm}
        allProductFamilies={allProductFamilies}
        allProfiles={allProfiles}
        allPromos={allPromos}
        serviceCodes={allServiceCodes}
        generateProductTotals={generateProductTotals}
      />
    ),
  });

  const [singleInstance] = usePDF({
    document: (
      <PdfWrapper
        pdfMulti={false}
        quoteName={quoteName}
        customerName={customerName}
        locations={locations}
        locationId={locationId}
        currentScenario={currentScenario}
        currentEstimate={currentEstimate}
        termMonth={currentTerm}
        eipTermMonth={currentEipTerm}
        allProductFamilies={allProductFamilies}
        allProfiles={allProfiles}
        allPromos={allPromos}
        serviceCodes={allServiceCodes}
        generateProductTotals={generateProductTotals}
      />
    ),
  });

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleDownloadClick = () => {
    const link = document.createElement('a');
    const href = pdfMulti ? multiInstance.url : singleInstance.url;
    link.href = href || '';
    link.download = `${quoteName.split(' ').join('_')}.pdf`;
    link.click();
    link.remove();
    setPdfModalIsOpen(false);
    trackSelectAction('DownloadPDF', { opType: 'buttonClick' });
    setTimeout(() => setPdfGenerated(false), 5000);
  };

  const isLoading = pdfMulti ? multiInstance.loading : singleInstance.loading;
  const btnContent = pdfMulti ? 'All Locations PDF' : 'Current Location PDF';
  const ariaLabel = pdfMulti
    ? 'download all locations pdf'
    : 'download selected location pdf';

  if (multiInstance.error || singleInstance.error) {
    return <div>Something went wrong</div>;
  }

  // =============================================
  // Return
  // =============================================

  return (
    <KiteButton
      className="pdf-downloads"
      onClick={handleDownloadClick}
      leftIcon="download"
      aria-label={ariaLabel}
      loading={isLoading}
      disabled={isLoading}
    >
      {btnContent}
    </KiteButton>
  );
};

export default PdfDownloads;
