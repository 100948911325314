// External Packages
import React, { useEffect, useRef } from 'react';

// Components
import { NetworkEdgeContainer } from 'components';

// Utils

// Hooks
import { useAnalytics, useScrollToTop } from 'hooks';

// Types

// Styles
import './MnePage.scss';

const MnePage = () => {
  // =============================================
  // State/Refs
  // =============================================
  const { trackPageView } = useAnalytics();
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1 });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('MNE'), [trackPageView]);

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} data-testid="MnePage" className="mne-page">
      <h3>Managed Network Edge (MNE) Configuration</h3>
      <NetworkEdgeContainer className="mne-page" networkCategory="MNE" />
    </div>
  );
};

export default MnePage;
