// Packages
import React from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Utils

// Types

// Styles
import './AutobuildPill.scss';

export interface IAutobuildPillProps {
  /** Determines if estimate/location is autobuild enabled */
  isAutobuild: boolean;
  /** If included, will only display pill icon (no text) */
  iconOnly?: boolean;
}

/** A pill displaying whether a location or estimate has been verified for autobuild.
 * Locations are always autobuild until user configuration makes them non-autobuild.
 * Estimates are autobuild if all locations are also autobuild (1 non-autobuild location = non-autobuild estimate) */

const AutobuildPill = ({
  isAutobuild = true,
  iconOnly = false,
}: IAutobuildPillProps) => {
  const iconName = isAutobuild ? 'checkmark' : 'caution-circle';
  const pillText = isAutobuild ? 'Autobuild' : 'Non-Autobuild';

  return (
    <div
      className={classNames({
        'autobuild-pill': true,
        'autobuild-pill--icon-only': iconOnly,
        'autobuild-pill--non-autobuild': !isAutobuild,
      })}
    >
      <KiteIcon name={iconName} />
      {!iconOnly && pillText}
    </div>
  );
};

export default AutobuildPill;
