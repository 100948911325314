import { IEstimate, ILocation, IScenario } from 'types';
import { v4 } from 'uuid';

interface IGenerateDefaultProps {
  userId: string;
  estimateId?: string;
  scenarioId?: string;
  locationId?: string;
}

export const generateDefaultConfiguration = ({
  userId,
  estimateId = v4(),
  scenarioId = v4(),
  locationId = v4(),
}: IGenerateDefaultProps) => {
  const newDate = new Date(Date.now()).toISOString();

  const newLocation: ILocation = {
    id: locationId,
    createdAt: newDate,
    updatedAt: newDate,
    name: 'Location 1',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    createdBy: userId,
    estimateId: estimateId,
    isAutobuild: true,
    sfLocationId: '',
    sfBillingSystem: '',
    sfServiceAreaNumber: '',
    isEzPass: false,
  };

  const newScenario: IScenario = {
    id: scenarioId,
    name: 'Scenario 1',
    estimateId: estimateId,
    createdAt: newDate,
    updatedAt: newDate,
    createdBy: userId,
    order: 1,
    primary: true,
    term: '36',
    selections: [],
    locations: [
      {
        id: locationId,
      },
    ],
    promos: [],
  };

  const newEstimate: IEstimate = {
    id: estimateId,
    createdAt: newDate,
    updatedAt: newDate,
    name: 'Estimate 1',
    status: 'Unqualified Opportunity',
    customerName: '',
    createdBy: userId,
    sfOpportunityId: '',
    archived: false,
    lastRefreshed: '',
    salesTeam: {},
    scenarios: [newScenario],
    locations: [newLocation],
    pushedToSf: false,
    scenarioPromos: [],
  };

  return { newEstimate, newScenario, newLocation };
};
