// Packages
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';

// Redux

// Components
import {
  KiteAlert,
  KiteButton,
  KiteCard,
  KiteCheckbox,
} from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';

// Hooks
import { useAnalytics, useCurrentHeight } from 'hooks';

// Utils
// Types
import { ISelectableLocation } from 'types';

// Styles
import './EstimateLocationsTable.scss';

export interface IEstimateLocationsTableProps {
  locationError: string;
  setLocationError: (error: string) => void;
  tableData: ISelectableLocation[] | undefined | null;
  setTableData: Dispatch<
    SetStateAction<ISelectableLocation[] | undefined | null>
  >;
}

/** List of locations to add to an estimate */
const EstimateLocationsTable = ({
  locationError = '',
  setLocationError,
  tableData = undefined,
  setTableData,
}: IEstimateLocationsTableProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();

  const currentHeight = useCurrentHeight(
    document.getElementsByClassName('estimate-locations-table')[0]
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const disabledMessage = useMemo(() => {
    const disabledLocations = !!tableData?.find(
      (location) => location.disabled
    );
    if (!disabledLocations) {
      return null;
    }
    return 'Disabled locations are unable to be removed as they are configured in a scenario. To remove them from the estimate, first remove them from all scenarios.';
  }, [tableData]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleTableSelectionAll = useCallback(
    (selected: boolean) => () => {
      setTableData((prevTableData) =>
        prevTableData?.map((location) => {
          if (location.disabled) {
            return location;
          }
          return { ...location, selected };
        })
      );
    },
    [setTableData]
  );

  const handleLocationSelection = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = e.target;
      const analyticsAction = checked ? 'Select' : 'Deselect';

      if (locationError) {
        setLocationError('');
      }

      setTableData((prevTableData) => {
        const updated = prevTableData?.map((location) => {
          if (location.serviceLocationId === name) {
            return { ...location, selected: checked };
          }
          return location;
        });
        return updated;
      });
      trackSelectAction(`${analyticsAction} Estimate Location: ${name}`, {
        opType: 'radioSelect',
      });
    },
    [setTableData, locationError, setLocationError, trackSelectAction]
  );

  // sets the needed height of the table based on the current height of the page
  const tableHeight = useMemo(() => {
    if (currentHeight) {
      let height = currentHeight - 280;

      if (locationError || disabledMessage) {
        // adjust height to account for error message
        height = height - 127;
      }
      return `${height}px`;
    }
    return '0px';
  }, [locationError, currentHeight, disabledMessage]);

  // =============================================
  // Render Methods
  // =============================================
  const locationDetails = useMemo(() => {
    const columns = [
      {
        label: 'Selected',
        sortKey: 'selected',
        size: 0.25,
        render: (loc: ISelectableLocation) => (
          <div className="estimate-locations-table__table-container">
            <KiteCheckbox
              className="estimate-locations-table__table-checkbox"
              id={loc.serviceLocationId}
              label=""
              disabled={loc.disabled}
              name={loc.serviceLocationId}
              checked={loc.selected}
              onChange={handleLocationSelection}
            />
          </div>
        ),
      },
      { label: 'Name', sortKey: 'serviceLocationName' },
      {
        label: 'State',
        size: 0.25,
        sortKey: 'shippingAddress',
        render: (loc: ISelectableLocation) => {
          const locationState =
            loc.shippingAddress?.split('getState=')[1]?.substring(0, 2) || '';
          return (
            <div className="estimate-locations-table__table-container">
              {locationState}
            </div>
          );
        },
      },
      { label: 'SF Location Id', sortKey: 'serviceLocationId' },
    ];

    return (
      <SortableTable
        className="estimate-locations-table__table"
        columns={columns}
        tableData={tableData || []}
        fixedHeight={tableHeight}
        errorMessage={locationError}
        noDataMessage="No locations found for this opportunity"
        loading={!tableData}
      />
    );
  }, [tableHeight, tableData, locationError, handleLocationSelection]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="estimate-locations-table">
      <div className="estimate-locations-table__header">
        <h2>Select Locations to Include</h2>

        <div className="estimate-locations-table__header-buttons">
          <KiteButton
            size="small"
            type="outline"
            onClick={handleTableSelectionAll(false)}
          >
            Clear All
          </KiteButton>
          <KiteButton
            size="small"
            type="primary"
            onClick={handleTableSelectionAll(true)}
          >
            Select All
          </KiteButton>
        </div>
      </div>

      {disabledMessage && (
        <KiteAlert type="info" title="Note" description={disabledMessage} />
      )}

      {locationError && (
        <KiteAlert type="caution" description={locationError} />
      )}

      {locationDetails}
    </KiteCard>
  );
};

export default EstimateLocationsTable;
