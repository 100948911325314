import { IEnv } from './env';

export const getSfUrl = (env: IEnv): string => {
  const develop =
    process.env.REACT_APP_SF_URL_DEV ||
    'https://spectrum-enterprise--projectpqe.sandbox.my.salesforce.com';
  const stage =
    process.env.REACT_APP_SF_URL_STAGE ||
    'https://spectrum-enterprise--por6214.sandbox.my.salesforce.com';
  const prod =
    process.env.REACT_APP_SF_URL_PROD ||
    'https://spectrum-enterprise.my.salesforce.com';

  const envUrlMap = {
    develop,
    stage,
    prod,
    local: process.env.REACT_APP_SF_URL || develop,
    test: 'test',
  };

  return envUrlMap[env];
};
