import React, { useCallback, useMemo } from 'react';

// Components
import { CustomDropdown, CustomInput } from 'components';

// Utils

// Hooks
import { useQueryDataContext } from 'hooks';

// Types
import { IProduct, IProductFamily, ISelectionController } from 'types';

// Styles
import './DropdownInput.scss';
import { KiteAlert } from '@kite/react-kite';
import { informationAlerts } from 'utils';

interface IDropdownInput extends ISelectionController {
  productFamily: IProductFamily;
}

export const DropdownInput = ({ productFamily }: IDropdownInput) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { currentSelections } = useQueryDataContext();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const productSelection = useMemo(() => {
    return currentSelections.find(
      (selection) => selection.familyId === productFamily.id
    );
  }, [productFamily, currentSelections]);

  const product = useMemo(() => {
    return productFamily.products.find(
      (p) => p.id === productSelection?.productId
    ) as IProduct;
  }, [productFamily, productSelection]);

  const productNotes = useMemo(() => {
    const notes = productFamily.products.map((p) => p.notes).flat();
    return notes.map((note) => {
      return <li key={note}>{note}</li>;
    });
  }, [productFamily]);

  // =============================================
  // Render Methods
  // =============================================
  const renderAlert = useCallback(() => {
    const alertInfo = informationAlerts(productFamily.name, currentSelections);
    const quantity = productSelection?.quantity || 0;

    if (alertInfo && quantity) {
      return (
        <KiteAlert
          title={alertInfo.title}
          description={alertInfo.description}
          level={alertInfo.level}
          type={alertInfo.type}
        />
      );
    }
    return null;
  }, [productFamily.name, productSelection, currentSelections]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="dropdown-input">
      {renderAlert()}
      <div className="dropdown-input__wrapper">
        <CustomDropdown productFamily={productFamily} />
        <CustomInput productFamily={productFamily} product={product} />
      </div>

      <ul className="dropdown-input__description">{productNotes}</ul>
    </div>
  );
};
