import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ILocationSelection, IPrice, IProductFamily } from 'types';
import { deepCopy, productFamilyRequest, queryKeys, sandboxData } from 'utils';

export const useUpdateProductFamily = (onUpdateSuccess: () => void) => {
  const queryClient = useQueryClient();
  const allProductFamiliesKey = queryKeys().all.productFamilies;
  const allProductsKey = queryKeys().all.products;

  // Updates prices on any sandbox selections when PF updates
  const updateSandboxPrices = useCallback(
    (res: IProductFamily[]) =>
      sandboxData.estimate.scenarios.forEach((scenario, i) => {
        const { selections } = scenario;
        const newSelections = selections.reduce(
          (acc: ILocationSelection[], sel) => {
            const { familyId, productId } = sel;
            let prices = sel.prices;
            const productFamily = res.find((pf) => pf.id === familyId);

            if (productFamily) {
              const product = productFamily.products.find(
                (prod) => prod.id === productId
              );

              if (product) {
                prices = product.prices;
              }
            }

            acc.push({ ...sel, prices });
            return acc;
          },
          []
        );

        sandboxData.estimate.scenarios[i].selections = newSelections;
      }),
    []
  );

  const {
    mutate: updateProductFamily,
    isLoading: updateProductFamilyLoading,
    isSuccess: updateProductFamilySuccess,
    isError: updateProductFamilyError,
  } = useMutation(
    (productFamily: IProductFamily) =>
      productFamilyRequest({
        endpoint: 'product-families',
        method: 'PUT',
        data: [
          // PUT requires array
          {
            ...productFamily,
            products: productFamily.products.map((product) => {
              return {
                ...product,
                productFamily: undefined,
                profileCategory: undefined,
                prices: product.prices.map((p) => {
                  if (p.id.includes('new-price')) {
                    const {
                      updatedBy,
                      productId,
                      rateCard,
                      term,
                      type,
                      price,
                    } = p;
                    return {
                      updatedBy,
                      productId,
                      rateCard,
                      term,
                      type,
                      price,
                    } as IPrice;
                  }
                  return p;
                }),
              };
            }),
          },
        ],
      }),
    {
      onMutate: async (productFamily: IProductFamily) => {
        await queryClient.cancelQueries(allProductFamiliesKey);
        const prevProductFamilies =
          queryClient.getQueryData<IProductFamily[]>(allProductFamiliesKey) ||
          [];
        const pfIndex = prevProductFamilies.findIndex(
          (pf) => pf.id === productFamily.id
        );
        const pfCopy = deepCopy(prevProductFamilies);
        pfCopy.splice(pfIndex, 1, productFamily);
        queryClient.setQueryData(allProductFamiliesKey, pfCopy);
        return { prevProductFamilies };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(allProductFamiliesKey);
        queryClient.setQueryData(
          allProductFamiliesKey,
          context?.prevProductFamilies
        );
      },
      onSuccess: (res: IProductFamily[]) => {
        onUpdateSuccess();
        updateSandboxPrices(res);
      },
      onSettled: () => {
        queryClient.invalidateQueries(allProductFamiliesKey);
        queryClient.invalidateQueries(allProductsKey);
      },
    }
  );

  return {
    updateProductFamily,
    updateProductFamilyLoading,
    updateProductFamilySuccess,
    updateProductFamilyError,
  };
};
