import { isValidJsonString } from './isValidJsonString';

export const deepCopy = <T>(inputs: T): T => {
  const stringified = JSON.stringify(inputs);

  if (isValidJsonString(stringified)) {
    return JSON.parse(stringified);
  }

  return inputs;
};
