import { useQueryData } from 'hooks';
import { useCallback } from 'react';
import { ILocationSelection, TProductList, TTableCategory } from 'types';

type TConfig = { min?: number; max?: number; product?: string };

const config: Partial<{ [Property in TProductList]: TConfig }> = {
  'DFI Speed': {},
  'Ethernet Speed': {},
  'Cloud Connect Single': {},
  'Cloud Connect Dual': {},
  'MNE Remote Workers': { min: 25, max: Infinity, product: 'Connect Plus' },
};

type TGlobalErrors = {
  [Property in TTableCategory]: boolean;
};

export const useErrorCheck = (selections: ILocationSelection[]) => {
  const { currentTerm, allProductFamilies } = useQueryData();

  const checkDiscretionError = useCallback(
    (selection: ILocationSelection) => {
      if (!config[selection.familyName]) return '';
      const listPrice =
        selection?.prices?.find(
          (p) => p.type === 'rate' && p.term === currentTerm
        )?.price || 0;
      const ceiling = listPrice * 2;
      const floorPrice =
        selection?.prices?.find(
          (p) => p.type === 'discount' && p.term === currentTerm
        )?.price || 0;
      const discretion = selection?.[`discretionValue${currentTerm}`];

      if (discretion && (discretion > ceiling || discretion < floorPrice)) {
        return `Input Must Be Between: $${floorPrice} And $${ceiling}`;
      }
      return '';
    },
    [currentTerm]
  );

  const checkRangeError = useCallback((selection: ILocationSelection) => {
    const configValues = config[selection.familyName];
    if (!configValues || configValues.product !== selection.name) return '';
    const { min = 0, max = Infinity } = configValues as TConfig;

    if (selection && (selection.quantity < min || selection.quantity > max)) {
      return `Value must be at least ${min}`;
    }

    return '';
  }, []);

  const checkAllErrors = useCallback(
    (scenarioSelections?: ILocationSelection[]) => {
      const selectionsToCheck = scenarioSelections ?? selections;
      return selectionsToCheck.reduce(
        (acc: TGlobalErrors, selection) => {
          const { familyName, familyCategory } = selection;
          if (!config[familyName]) return acc;

          const family = allProductFamilies.find(
            (f) => f.id === selection.familyId
          );
          let errorMessage = '';

          if (family?.hasDiscretion) {
            errorMessage = checkDiscretionError(selection);
          } else {
            errorMessage = checkRangeError(selection);
          }

          if (errorMessage && familyCategory !== 'NE') {
            acc[familyCategory] = true;
          }
          return acc;
        },
        {
          Data: false,
          Trunks: false,
          MNE: false,
          ENE: false,
          'UC Products': false,
          'RingCentral Products': false,
          'SBB 1-19 Pub/Priv': false,
        }
      );
    },
    [selections, allProductFamilies, checkDiscretionError, checkRangeError]
  );

  return { checkAllErrors, checkDiscretionError, checkRangeError };
};
