import { useMutation } from 'react-query';
import { getRateCenterSearch } from 'utils';
import { IRateCenterSearchData } from 'types';

export const useGetRateCenterSearch = () => {
  const {
    mutateAsync: rateCenterSearch,
    isLoading: rateCenterSearchLoading,
    error: rateCenterSearchError,
    data: rateCenterSearchRes,
    reset: resetRateCenterSearch,
  } = useMutation((searchData: IRateCenterSearchData) =>
    getRateCenterSearch(searchData)
  );

  return {
    rateCenterSearch,
    rateCenterSearchLoading,
    rateCenterSearchError,
    rateCenterSearchRes,
    resetRateCenterSearch,
  };
};
