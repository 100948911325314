// Packages
import React from 'react';

// Redux

// Components
import {
  LocationDropdown,
  OutputTotals,
  PricingTable,
  ScenarioSummary,
} from 'components';

// Hooks
import { useGenerateProductTotals, useQueryData } from 'hooks';

// Utils

// Types

// Styles
import './EstimateReviewSection.scss';

/** Estimate review section including read only product table */

const EstimateReviewSection = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { generateProductTotals } = useGenerateProductTotals();
  const { locationId, currentScenario } = useQueryData();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const scenarioTotals = generateProductTotals({
    term: currentScenario?.term || '36',
  });
  const locationTotals = generateProductTotals({
    locationId,
    term: currentScenario?.term || '36',
  });

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="estimate-review-section__body">
      <ScenarioSummary />
      <div className="estimate-review-section__body-details">
        <div className="estimate-review-section__body-header">
          <h2>{`${
            currentScenario ? currentScenario.name : 'Scenario'
          } Totals`}</h2>
          <div className="estimate-builder-controls__locations-controls">
            <LocationDropdown />
          </div>
        </div>
        <OutputTotals
          scenarioTotals={scenarioTotals}
          locationTotals={locationTotals}
        />
        <PricingTable disableBanner={true} isExpanded={true} />
      </div>
    </div>
  );
};

export default EstimateReviewSection;
