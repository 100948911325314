import { NONE, removeEmojis } from 'utils';

interface IErrorState {
  [key: string]: any;
}

export const checkFormErrors = (
  state: IErrorState,
  possibleErrorFields: string[],
  inputTypes?: { [key: string]: 'number' | 'select' }
) => {
  let hasErrors = false;

  const errorObject: IErrorState = possibleErrorFields.reduce(
    (errorObject: IErrorState, input: string) => {
      if (
        inputTypes &&
        inputTypes[input] === 'select' &&
        (!state[input] || state[input] === NONE)
      ) {
        errorObject[input] = 'Please make a selection';
      } else if (
        inputTypes &&
        inputTypes[input] === 'number' &&
        !parseInt(state[input])
      ) {
        errorObject[input] = 'Minimum 1 required';
      } else if (!state[input].trim()) {
        errorObject[input] = 'This field may not be blank';
      } else if (state[input] !== removeEmojis(state[input])) {
        errorObject[input] = 'This field may not include emojis';
      } else {
        errorObject[input] = '';
      }
      errorObject[input] && (hasErrors = true);
      return errorObject;
    },
    {}
  );
  return { hasErrors, errorObject };
};
