// Packages
import React from 'react';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './NodeTotalBadge.scss';

export interface INodeTotalBadgeProps {
  className?: string;
  /** Name of icon to be displaed in badge */
  iconName: string;
  /** Total number of items (phones or switches) */
  total: number;
}

/** Displays the current number of phones or switches a distro node contains*/

const NodeTotalBadge = ({
  className = '',
  total = 0,
  iconName = 'phone-office',
}: INodeTotalBadgeProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className={`node-total-badge ${className}`}>
      <KiteIcon name={iconName} />
      {total}
    </div>
  );
};

export default NodeTotalBadge;
