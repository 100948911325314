// Packages
import React, { useCallback, useEffect, useMemo } from 'react';

// Redux

// Components
import { KiteSelect } from '@kite/react-kite';

// Hooks
import { useErrorCheck, useQueryData } from 'hooks';

// Utils
import { collatorSort } from 'utils';

// Types

// Styles
import './LocationDropdown.scss';

export interface ILocationDropdownProps {
  className?: string;
  /** Optional dropdown label */
  label?: string;
}

/** Dropdown menu containing a list of locations to select */

const LocationDropdown = ({
  className = '',
  label,
}: ILocationDropdownProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const {
    currentEstimate,
    currentScenario,
    locationId,
    currentSelections,
    updateLocationId,
    updateScenarioTabs,
  } = useQueryData();
  const { checkAllErrors } = useErrorCheck(currentSelections);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const scenarioLocationIds = useMemo(
    () => currentScenario?.locations.map((l) => l.id) || [],
    [currentScenario]
  );

  const locationOptions = useMemo(() => {
    return scenarioLocationIds.length ? (
      currentEstimate?.locations
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          return collatorSort(nameA, nameB);
        })
        .reduce((acc: JSX.Element[], loc) => {
          const locationSelections = currentScenario?.selections.filter(
            (s) => s.locationId === loc.id
          );

          const { isEzPass, name, id } = loc;
          const errors = checkAllErrors(locationSelections);

          const hasError = Object.values(errors).some(Boolean);
          const label = `${name}${isEzPass ? ' (EZ Pass)' : ''}`;

          if (scenarioLocationIds.includes(id)) {
            acc.push(
              <option className="location-dropdown__option" key={id} value={id}>
                {hasError ? `❗️ ${label}` : label}
              </option>
            );
          }
          return acc;
        }, [])
    ) : (
      <option disabled value="">
        No Locations Added
      </option>
    );
  }, [scenarioLocationIds, currentEstimate, currentScenario, checkAllErrors]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleLocationSelect = useCallback(
    (targetLocationId: string) => {
      if (currentScenario?.id) {
        updateScenarioTabs(currentScenario.id, targetLocationId);
      }
      updateLocationId(targetLocationId);
    },
    [currentScenario, updateLocationId, updateScenarioTabs]
  );

  const onLocationSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      handleLocationSelect(value);
    },
    [handleLocationSelect]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (!locationId) {
      if (currentScenario?.id) {
        updateScenarioTabs(currentScenario.id, scenarioLocationIds[0]);
      }
      updateLocationId(scenarioLocationIds[0]);
    }
  }, [
    currentScenario,
    locationId,
    scenarioLocationIds,
    updateLocationId,
    updateScenarioTabs,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteSelect
      className={`location-dropdown ${className}`}
      id="location-dropdown"
      label={label}
      name="currLocationId"
      onChange={onLocationSelect}
      value={locationId || ''}
      disabled={!currentEstimate?.locations.length}
      inputProps={{ 'aria-label': 'location select' }}
    >
      {locationOptions}
    </KiteSelect>
  );
};

export default LocationDropdown;
