export const capitalizeFirstLetter = (input: string) => {
  const firstLetter = input.charAt(0).toUpperCase();
  return firstLetter + input.slice(1).toLocaleLowerCase();
};

export const capitalizeEachWord = (words: string) => {
  if (words) {
    const output = words.split(' ').map((word) => capitalizeFirstLetter(word));
    return output.join(' ');
  }
  return '';
};
