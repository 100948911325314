import { TProductList } from 'types';

export type IDropDownConfig = {
  [Property in TProductList]: {
    dependentOption: {
      [DependentDropDown: string]: {
        //Selected option for the DependentDropDown
        selectedOption: string[];
        //Option to be displayed for Property dropdown
        displayOption: string[];
      }[];
    }[];
  };
};

export const dropDownConfig: Partial<IDropDownConfig> = {
  'Wireless Internet Device Selection': {
    dependentOption: [
      {
        'Wireless Internet Service Plan': [
          {
            selectedOption: ['Essential 5GB', 'Unlimited Select'],
            displayOption: [
              'Cradlepoint E100 - Indoor 4G Modem',
              'Cradlepoint W1850 - Indoor 5G Modem',
            ],
          },
          {
            selectedOption: ['Unlimited Premium'],
            displayOption: ['Cradlepoint W1850 - Indoor 5G Modem'],
          },
        ],
      },
    ],
  },
};
