// Packages
import React, { ChangeEvent, useCallback } from 'react';

// Components
import { KiteInput } from '@kite/react-kite';

// Hooks

// Utils
import {
  capitalizeFirstLetter,
  formatPrice,
  TermMonths,
  TermYears,
} from 'utils';

// Types
import {
  TTermLengthMonths,
  IProduct,
  TTermLengthYears,
  TNrcTableData,
} from 'types';
import { IAdminTableData } from '../AdminProductTable/AdminProductTable';

// Styles
import './EditableTable.scss';

interface IEditableTableProps {
  rateCard: string;
  tableData: IAdminTableData;
  onPricingChange: (
    productName: string,
    term: TTermLengthMonths
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  onNrcChange: (
    property: keyof TNrcTableData
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  onUniqueNrcChange: (
    id: string,
    property: keyof IProduct
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  isEditable?: boolean;
}

const EditableTable = ({
  rateCard,
  tableData,
  onPricingChange,
  onNrcChange,
  onUniqueNrcChange,
  isEditable = false,
}: IEditableTableProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { pricing, nrc, uniqueNrc } = tableData;

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const getPriceValue = useCallback((value?: number) => {
    if (!value || isNaN(value)) {
      return '';
    }
    return value;
  }, []);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const generatePriceTable = useCallback(
    (pricingData: IAdminTableData['pricing'], isEditable: boolean) => {
      const tableRows = Object.entries(pricingData).reduce(
        (acc: JSX.Element[], [productName, prices]) => {
          const productPrices = rateCard
            ? prices.filter((p) => p.rateCard === rateCard)
            : prices;
          if (!productPrices.length) return acc;

          const cellValues = Object.keys(TermMonths).map((term) => {
            const price = productPrices.find((price) => price.term === term);
            const key = `${productName} ${term}`;

            if (isEditable) {
              return (
                <td
                  key={`${productName} ${term}`}
                  className="editable-table__input"
                >
                  <KiteInput
                    margin="0"
                    type="number"
                    value={getPriceValue(price?.price)}
                    onChange={onPricingChange(
                      productName,
                      term as TTermLengthMonths
                    )}
                  />
                </td>
              );
            } else {
              return <td key={key}>{formatPrice(price?.price || '-')}</td>;
            }
          });

          acc.push(
            <tr key={`${productName} row`}>
              <th>{productName}</th>
              {cellValues}
            </tr>
          );
          return acc;
        },
        []
      );

      if (!tableRows.length) {
        return <p>No unique pricing values were found for this selection</p>;
      }

      const termYears = Object.keys(TermYears) as TTermLengthYears[];
      const tableHeads = (
        <tr>
          {[
            <th key="price table product cell">Product</th>,
            ...termYears.map((column) => (
              <th key={column}>{`${column} Years`}</th>
            )),
          ]}
        </tr>
      );

      return (
        <table className="editable-table__table">
          <thead>{tableHeads}</thead>
          <tbody className="editable-table__body">{tableRows}</tbody>
        </table>
      );
    },
    [rateCard, onPricingChange, getPriceValue]
  );

  const generateNrcTable = useCallback(
    (nrcData: IAdminTableData['nrc'], isEditable: boolean) => {
      const staticRows = Object.entries(nrcData).map(([nrcProperty, price]) => {
        return (
          <tr key={nrcProperty}>
            <th>{capitalizeFirstLetter(nrcProperty)}</th>
            <td>{formatPrice(price)}</td>
          </tr>
        );
      });

      const editableRows = [
        <tr key="list">
          <th>List</th>
          <td className="editable-table__input">
            <KiteInput
              margin="0"
              type="number"
              value={getPriceValue(nrcData.list)}
              onChange={onNrcChange('list')}
            />
          </td>
        </tr>,
        <tr key="discount">
          <th>Discount</th>
          <td className="editable-table__input">
            <KiteInput
              margin="0"
              type="number"
              value={getPriceValue(nrcData.discount)}
              onChange={onNrcChange('discount')}
            />
          </td>
        </tr>,
      ];

      return (
        <table className="editable-table__table">
          <thead>
            <tr>
              <th>NRC</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody className="editable-table__body">
            {isEditable ? editableRows : staticRows}
          </tbody>
        </table>
      );
    },
    [getPriceValue, onNrcChange]
  );

  const generateUniqueNrcTable = useCallback(
    (uniqueNrcData: IAdminTableData['uniqueNrc'], isEditable: boolean) => {
      if (uniqueNrcData.length) {
        const tableRows = uniqueNrcData.map((product) => {
          if (isEditable) {
            return (
              <tr key={product.name}>
                <th>{product.name}</th>

                <td className="editable-table__input">
                  <KiteInput
                    key={`${product.name} list`}
                    margin="0"
                    type="number"
                    value={getPriceValue(product?.rateNrc)}
                    onChange={onUniqueNrcChange(product.id, 'rateNrc')}
                  />
                </td>
                <td className="editable-table__input">
                  <KiteInput
                    key={`${product.name} list`}
                    margin="0"
                    type="number"
                    value={getPriceValue(product.rateMrc)}
                    onChange={onUniqueNrcChange(product.id, 'rateMrc')}
                  />
                </td>
              </tr>
            );
          } else {
            return (
              <tr key={product.name}>
                <th>{product.name}</th>
                <td>{formatPrice(product.rateNrc || 0)}</td>
                <td>{formatPrice(product.rateMrc || 0)}</td>
              </tr>
            );
          }
        });

        return (
          <table className="editable-table__table">
            <thead>
              <tr>
                <th>Product</th>
                <th>NRC</th>
                <th>MRC</th>
              </tr>
            </thead>
            <tbody className="editable-table__body">{tableRows}</tbody>
          </table>
        );
      }
      return null;
    },
    [onUniqueNrcChange, getPriceValue]
  );

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="editable-table">
      <h3>Term Pricing</h3>
      {generatePriceTable(pricing, isEditable)}
      <h3>Term NRC</h3>
      {generateNrcTable(nrc, isEditable)}

      {uniqueNrc.length > 0 && <h3>Month to Month / NRC Pricing</h3>}
      {generateUniqueNrcTable(uniqueNrc, isEditable)}
    </div>
  );
};

export default EditableTable;
