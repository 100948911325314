import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { IProfile } from 'types';
import { profileRequest, queryKeys, sandboxData } from 'utils';

export const useDeleteProfiles = () => {
  const queryClient = useQueryClient();
  const { estimateId } = useParams();
  const queryKey = queryKeys().all.profiles;

  const isSandbox = estimateId === 'sandbox';

  const sandboxHandler = useCallback(async (profileIds: string[]) => {
    sandboxData.profiles = sandboxData.profiles.filter(
      (p) => !profileIds.includes(p.id)
    );
    return sandboxData.profiles;
  }, []);

  const { mutate: deleteProfiles, isLoading: deleteProfileLoading } =
    useMutation(
      isSandbox
        ? sandboxHandler
        : (profileIds: string[]) =>
            profileRequest({
              method: 'DELETE',
              endpoint: 'product-profiles',
              data: profileIds,
            }),
      {
        onMutate: async (profileIds: string[]) => {
          await queryClient.cancelQueries(queryKey);
          const prevProfiles = queryClient.getQueryData<IProfile[]>(queryKey);
          const updatedProfiles =
            prevProfiles?.filter((p) => !profileIds.includes(p.id)) || [];
          queryClient.setQueryData(queryKey, updatedProfiles);
          return { prevProfiles };
        },
        onError: async (err, _, context) => {
          console.log(err);
          await queryClient.cancelQueries(queryKey);
          queryClient.setQueryData(queryKey, context?.prevProfiles);
        },
        onSettled: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }
    );

  return {
    deleteProfiles,
    deleteProfileLoading,
  };
};
