import { IBundle } from 'types';

const newDate = new Date(Date.now()).toISOString();

export const defaultBundle: IBundle = {
  id: 'new-bundle',
  name: 'Demo Bundle',
  description: '',
  createdAt: newDate,
  updatedAt: newDate,
  published: false,
  featured: false,
  selections: [],
};
