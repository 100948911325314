// Packages
import React, { useMemo, useState } from 'react';

// Components
import { KiteLoader } from '@kite/react-kite';
import {
  CustomerBar,
  EstimateBuilderContent,
  EstimateBuilderControls,
  PdfModal,
} from 'components';

// Hooks
import { useParams } from 'react-router-dom';
import {
  useGetEstimateDetails,
  useGetEstimateScenarios,
  useQueryData,
} from 'hooks';

// Utils
import { isEstimateAutobuild } from 'utils';

// Types

// Styles
import './EstimateBuilderLayout.scss';

/** Layout for estimate builder, contains functionality to fetch estimate/sandbox data */

const EstimateBuilderLayout = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { currentLocation } = useQueryData();
  const { estimateId = '' } = useParams();

  const {
    estimateData: currentEstimate,
    estimateLoading,
    error,
  } = useGetEstimateDetails(estimateId);

  // array of all scenarios in an estimate
  const { data: scenarios, isLoading: scenariosLoading } =
    useGetEstimateScenarios({
      params: { estimateId },
    });

  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);
  const [pdfMulti, setPdfMulti] = useState(true);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const isReady = useMemo(() => {
    return !!currentEstimate && !!scenarios && !!currentLocation;
  }, [currentEstimate, scenarios, currentLocation]);

  const pageLoading = useMemo(() => {
    return (estimateLoading || !estimateId || scenariosLoading) && !isReady;
  }, [estimateLoading, estimateId, scenariosLoading, isReady]);

  const isAutobuild = isEstimateAutobuild(currentEstimate);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Early Return
  // =============================================
  if (pageLoading) {
    return (
      <div className="estimate-builder-layout__loader">
        <KiteLoader loaderTitle="Loading Estimate Data" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="estimate-detail-page-error">
        <span>{error}</span>
      </div>
    );
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="estimate-builder-layout">
      <div>
        {estimateId && estimateId !== 'sandbox' && (
          <div className="estimate-builder-layout__header">
            <CustomerBar
              companyName={currentEstimate?.customerName || ''}
              opportunityName={currentEstimate?.name || ''}
              isAutobuild={isAutobuild}
            />
          </div>
        )}
        <EstimateBuilderControls setPdfModalIsOpen={setPdfModalIsOpen} />
      </div>
      <EstimateBuilderContent />
      <PdfModal
        pdfModalIsOpen={pdfModalIsOpen}
        setPdfModalIsOpen={setPdfModalIsOpen}
        pdfMulti={pdfMulti}
        setPdfMulti={setPdfMulti}
      />
    </div>
  );
};

export default EstimateBuilderLayout;
