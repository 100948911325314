import React, { createContext, ReactNode, useEffect, useRef, useState } from 'react';
import { Toast } from '@kite/react-kite-plus';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { isValidJsonString } from 'utils/tablesAndOutputs';

export const AlertContext = createContext<null>(null);

interface IAlertProviderProps {
  children: ReactNode;
}

interface IToastData {
  type: string;
  title: string;
  text: string;
}

export type TToast = IToastData | null;

const AlertProvider = ({ children }: IAlertProviderProps) => {
  const [toast, setToast] = useState<TToast>(null);
  const [autoClose, setAutoClose] = useState<number | null>(null);
  const { state } = useLocation();
  const duplicateError = useRef(false);

  const queryClient = useQueryClient();

  queryClient.getMutationCache().config = {
    // Sets global toast alert when any mutation errors,
    onError: (error: any) => {

      if (error && isValidJsonString(String(error.message))) {
        const { status = '', id = '' } = JSON.parse(error.message || '{}');
        if (status === 409 && id) {
          duplicateError.current = true;
          setAutoClose(null);
          setToast({
            type: 'alert',
            title: 'Error',
            text: 'Estimate for opportunity already exists and has been loaded.'
          });
          return;
        }
      }

      if (toast === null) {
        duplicateError.current = false;
        setAutoClose(null);
        setToast({
          type: 'alert',
          title: 'Error',
          text: 'Something went wrong saving changes.',
        });
      }
    },
    onSuccess: () => {
      if (toast && !duplicateError.current) {
        setToast(null);
      }
    },
  };

  // If being redirected from salesforce login page, display SF login success toast w/ 3 second autoclose
  useEffect(() => {
    if (state?.fromDomain === '/salesforce-login') {
      const jid = window.localStorage.getItem('jid');
      if (jid) {
        setAutoClose(3000);
        setToast({
          type: 'confirm',
          title: 'Salesforce Login',
          text: 'Success',
        });
      }
    }
  }, [state]);

  // "uniqueKey" prop throws React key error if not set to "text"
  return (
    <AlertContext.Provider value={null}>
      {children}
      <Toast newToast={toast} uniqueKey="text" autoClose={autoClose} />
    </AlertContext.Provider>
  );
};

export default AlertProvider;
