// Packages
import React from 'react';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './IswBadge.scss';

interface IIswBadgeProps {
  contractType?: 'pre' | 'post';
}

/** ISW UC Question Badge */

const IswBadge = ({ contractType = 'pre' }: IIswBadgeProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const label =
    contractType === 'pre' ? 'IRR Pre-Contract' : 'ISW Post-Contract';

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={`isw-badge ${contractType === 'pre' ? 'isw-badge--pre' : ''}`}
    >
      <KiteIcon
        name={contractType === 'pre' ? 'caution-alert' : 'like-f'}
        margin="0 11px 0 0"
      />
      {label}
    </div>
  );
};

export default IswBadge;
