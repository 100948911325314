import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IField, ISubmission } from 'types';
import { queryKeys, submissionsRequest } from 'utils';

interface IUpdateSubmission {
  estimateId: string;
  locationId: string | null;
  // Current fields being answered. Used to check if estimate autobuild status may have changed based on submission value.
  fields?: IField[];
}

export const useUpdateSubmissions = ({
  estimateId,
  locationId,
  fields,
}: IUpdateSubmission) => {
  const queryClient = useQueryClient();

  const autoBuildAnswers = useMemo(
    () =>
      new Set(
        fields?.reduce((acc: string[], field) => {
          if (field.noAutobuildOptions?.length) {
            acc.push(...field.noAutobuildOptions);
          }
          return acc;
        }, [])
      ),
    [fields]
  );

  const queryKey = useMemo(() => {
    if (estimateId && locationId) {
      return queryKeys({ estimateId, locationId }).filter.submissions;
    }
    if (estimateId && !locationId) {
      return queryKeys({ estimateId }).filter.submissions;
    }
    return queryKeys().all.submissions;
  }, [estimateId, locationId]);

  const submissionQueryKeys = [
    queryKeys({ estimateId, locationId: locationId || '' }).filter.submissions,
    queryKeys({ estimateId }).filter.submissions,
    queryKeys().all.submissions,
    queryKeys({ estimateId }).filter.submissionHistory,
  ];

  // Match & update any pre-existing submissions for optimistic changes
  const updateOldSubmissions = useCallback(
    (oldSubs: Partial<ISubmission>[], newSubs: Partial<ISubmission>[]) => {
      const oldSubIds = oldSubs.map((oldSub) => oldSub.id);
      const updatedSubs = oldSubs.map((oldSub) => {
        const { id } = oldSub;
        const match = newSubs.find((newSub) => newSub.id === id);
        if (match) {
          return {
            ...oldSub,
            answer: match.answer,
          };
        }
        return oldSub;
      });
      const filteredNewSubs = newSubs.filter(
        (newSub) => !oldSubIds.includes(newSub.id)
      );
      return [...updatedSubs, ...filteredNewSubs];
    },
    []
  );

  const {
    mutate: updateSubmissions,
    isLoading: updateSubmissionsLoading,
    isSuccess: updateSubmissionsSuccess,
    isError: updateSubmissionsError,
  } = useMutation(
    (submissions: Partial<ISubmission>[]) =>
      submissionsRequest({
        endpoint: 'submissions',
        method: 'PUT',
        data: submissions,
      }),
    {
      onMutate: async (newSubmissions: Partial<ISubmission>[]) => {
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Get previous data in case of mutation error (see onError below)
        const previousSubmissions: Partial<ISubmission>[] | undefined =
          queryClient.getQueryData(queryKey);
        // Optimistically set data
        queryClient.setQueryData(
          queryKey,
          (oldSubmissions: Partial<ISubmission>[] | undefined) =>
            oldSubmissions
              ? updateOldSubmissions(oldSubmissions, newSubmissions)
              : newSubmissions
        );

        return { previousSubmissions };
      },
      onError: async (err, _, context) => {
        console.log(err);
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Reset query data to pre-mutation if mutation error
        queryClient.setQueryData(queryKey, context?.previousSubmissions);
      },
      onSuccess: (res: ISubmission[], _, context) => {
        const isAutoBuildChange =
          !!res.find((s) => s.answer && autoBuildAnswers.has(s.answer)) ||
          !!context?.previousSubmissions?.find(
            (s) => s.answer && autoBuildAnswers.has(s.answer)
          );

        if (isAutoBuildChange) {
          queryClient.invalidateQueries(queryKeys().all.estimates);
          queryClient.invalidateQueries(
            queryKeys({ estimateId }).details.estimate
          );
        }
      },
      onSettled: () => {
        submissionQueryKeys.forEach((key) =>
          queryClient.invalidateQueries(key)
        );
      },
    }
  );

  return {
    updateSubmissions,
    updateSubmissionsLoading,
    updateSubmissionsSuccess,
    updateSubmissionsError,
  };
};
