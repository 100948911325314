import { ILocation } from 'types';
import { locationRequest, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetLocationById = (locationId: string) => {
  const requestConfig = {
    method: 'GET',
    endpoint: `locations/${locationId}`,
    params: {
      locationId,
    },
  };
  return useGenerateQuery<ILocation>({
    queryKey: queryKeys({ locationId }).details.location,
    apiCall: locationRequest,
    requestConfig,
  });
};
