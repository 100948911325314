// External Packages
import React from 'react';
import { View } from '@react-pdf/renderer';

// Components

// Utils
import {
  generateTextComponents,
  generateViewComponent,
  tableHeaders,
} from 'utils';

// Types
import { TEipTermLengthMonths, TTermLengthMonths } from 'types';

// Styles
import { styles } from './PdfDocumentStyles';

interface IPdfLocationTableHead {
  headerText: string;
  termMonth: TTermLengthMonths;
  eipTermMonth: TEipTermLengthMonths;
  includeRcPhonesDevices: boolean;
}

export const PdfLocationTableHead = ({
  headerText,
  termMonth,
}: IPdfLocationTableHead) => {
  return (
    <View style={styles.productRow}>
      <View style={styles.productCellHeader}>
        {generateTextComponents([headerText], 'productLabel')}
        {generateTextComponents([`(Term: ${termMonth} months)`], 'productTerm')}
      </View>

      {tableHeaders.map((head) =>
        generateViewComponent(head, 'tableCellHeader', 'table headers')
      )}
    </View>
  );
};
