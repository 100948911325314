export const defaultProductQuantity = [
  {
    id: '0b02a76a-60dc-45ac-b1ca-73ea00aea904',
    name: 'Auto Attendant',
    defaultValue: '1',
    category: 'UC Products'
  },
  {
    id: '8f3184aa-7b00-41a7-b158-2838f007ea5e',
    name: 'Unlimited Long Distance Plan for UC',
    defaultValue: '1',
    category: 'UC Products'
  }
]
