import Keycloak from 'keycloak-js';
import { setAuthToken } from '../../../utils/setAuthToken';
import { storeUserID } from 'redux/userIDSlice';

export const getAuthorization = async (
  setAuthorization,
  analytics,
  dispatch
) => {
  /** Setup for KeyCloak */
  let keyCloakConfiguration = {
    /** Should always be include */
    credentials: 'include',
    /** Will always be this URL */
    url: 'https://keycloak.spectrumtoolbox.com/auth',
    /** The realm your client is in, 'spectrumtoolbox' by default */
    realm: 'spectrumtoolbox',
    /** ClientId is the name of your client in KeyCloak */
    clientId: 'solution-pricing-tool',
  };

  let keycloakInstance = Keycloak(keyCloakConfiguration);

  keycloakInstance
    .init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    })
    .then(async (authenticated) => {
      if (authenticated) {
        let jwt = keycloakInstance.token;
        await setAuthToken(jwt);

        const payload = JSON.parse(window.atob(jwt.split('.')[1]));
        const pId = payload.sAMAccountName;
        const fullName = payload.name;
        const user = payload.preferred_username;
        const group = payload.resource_access['solution-pricing-tool'];
        const roles = group ? group.roles : null;

        const isAdmin = roles && roles.includes('admin');

        localStorage.setItem('charterEmployee', 'true');

        analytics.track('loginStop', {
          userToken: pId,
          accountGUID: pId,
          accountNumber: pId,
          opSuccess: true,
        });

        setAuthorization({ roles, user, fullName, isAdmin, pId });
        dispatch(storeUserID(pId));
      } else {
        analytics.track('loginStop', {
          opSuccess: false,
        });
      }
    })
    .catch((err) => console.log('err', err));
};
