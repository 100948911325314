import axios from 'axios';
import { env } from './env';
import { paramsSerializer } from 'utils';
import { throwQueryError } from './throwQueryError';

export const searchActiveDirectory = async (config: {
  params: { search: string };
}) => {
  if (env.apiUrl.includes('localhost')) {
    throw new Error('If running on dev, you cannot access AD data. Sorry 😥');
  }

  const { params } = config;

  return axios({
    url: `${env.apiUrl}/active-directory/user-lookup`,
    params,
    paramsSerializer,
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        // Return empty array if nothing is found in AD
        return [];
      }
      throwQueryError({
        error,
        message: `Something went wrong with Active Directory search`,
      });
    });
};
