import { RootState } from './store';
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

interface IHeaderLinkData {
  label: string;
  url: string;
  render?: any;
}

export const headerLinkDataSlice = createSlice<
  IHeaderLinkData[],
  SliceCaseReducers<IHeaderLinkData[]>
>({
  name: 'headerLinkData',
  initialState: [],
  reducers: {
    storeHeaderLinks: (_, action) => {
      return action.payload;
    },
  },
});

// Actions
export const { storeHeaderLinks } = headerLinkDataSlice.actions;

// State
export const headerLinkData = (state: RootState): IHeaderLinkData[] =>
  state.headerLinkData;

// Reducer
export default headerLinkDataSlice.reducer;
