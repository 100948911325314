// Packages
import React, { useCallback, useEffect, useState } from 'react';
import { useNodes, useReactFlow } from 'react-flow-renderer';

// Redux

// Components
import { KiteButton, KiteIcon, KiteLoader } from '@kite/react-kite';
import { UCQDesignFlow, DesignFlowNotes, NewMDFModal } from '..';

// Hooks
import { useGetDistros, usePreviousValue, useUpdateDistros } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { getHighestNodeId } from 'utils';

// Types
import { IDistro, IUCQDesignFlowNode, IUCQInputProps } from 'types';

// Styles
import './UCQDesignFlowLayout.scss';

export interface IUCQDesignFlowLayoutProps {
  /** Callback to update submissions when distros are changed */
  onFieldChange: IUCQInputProps['onFieldChange'];
  /** Field id for connected submission */
  fieldId: string;
}

/** Layout for MDF/IDF design, contains main UCQDesignFlow component + notes section & create new building button. */

const UCQDesignFlowLayout = ({
  onFieldChange,
  fieldId,
}: IUCQDesignFlowLayoutProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId = '' } = useParams();

  const { updateDistros } = useUpdateDistros({
    estimateId,
  });

  const { setViewport } = useReactFlow();

  const nodes = useNodes<IUCQDesignFlowNode>();

  const [distros, setDistros] = useState<Partial<IDistro>[]>([]);
  const [notesOpen, setNotesOpen] = useState(false);
  const [newMdfModalOpen, setNewMdfModalOpen] = useState(false);

  // Set to run onSuccess of distro GET, updates submission value based on res
  const updateSubmission = useCallback(
    (res: IDistro[]) => {
      if (res.length) {
        // timestamp is to make answer unique, won't update submission otherwise
        onFieldChange(fieldId, `Has Draft ${new Date().getTime()}`);
      } else {
        onFieldChange(fieldId, null);
      }
    },
    [fieldId, onFieldChange]
  );

  const { data: distrosData, isLoading } = useGetDistros({
    params: { estimateId },
    onUpdateSubmission: updateSubmission,
  });

  const prevDistrosLength = usePreviousValue(distros.length);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const sortDistros = useCallback((distros: Partial<IDistro>[]) => {
    return distros.sort((a, b) => {
      if (a.nodeType === 'bdg' && b.nodeType === 'bdg') {
        const aId = parseInt(a?.nodeId?.slice(1) || '');
        const bId = parseInt(b?.nodeId?.slice(1) || '');
        return aId < bId ? -1 : aId > bId ? 1 : 0;
      }

      if (a.nodeType === 'bdg' && b.nodeType !== 'bdg') {
        return -1;
      }
      return 0;
    });
  }, []);

  // =============================================
  // Interaction Handlers
  // =============================================
  const generateDistro = useCallback(
    (config: {
      nodeType: IDistro['nodeType'];
      distros: Partial<IDistro>[];
      bdgId?: string;
      distroData?: Partial<IDistro>;
    }): Partial<IDistro> => {
      const { nodeType, bdgId, distroData = {}, distros } = config;
      const targetId = getHighestNodeId(nodeType, distros) + 1;
      const buildingId = bdgId ?? `B${getHighestNodeId('bdg', distros) + 1}`;

      switch (nodeType) {
        case 'bdg': {
          return {
            ...distroData,
            nodeId: buildingId,
            name: distroData.name || '',
            nodeType,
          };
        }
        case 'mdf': {
          return {
            ...distroData,
            nodeId: distroData?.nodeId ?? `M${targetId}`,
            nodeType,
            name: distroData.name || '',
            parentNodeId: buildingId,
          };
        }
        case 'idf': {
          return {
            ...distroData,
            nodeId: distroData?.nodeId ?? `i${targetId}`,
            nodeType,
            name: distroData.name || '',
            parentNodeId: buildingId,
          };
        }
        default: {
          return { ...distroData };
        }
      }
    },
    []
  );

  const createNewMdf = useCallback(
    (formData: { [key: string]: string }) => {
      const newMdf = generateDistro({
        nodeType: 'mdf',
        distros,
        distroData: {
          estimateId,
          locationId: formData.locationId,
          phones: 0,
          switchPorts: 0,
          interconnectType:
            formData.interconnectType as IDistro['interconnectType'],
        },
      });
      const newBdg = generateDistro({
        nodeType: 'bdg',
        distros,
        distroData: { name: formData.buildingName, estimateId },
      });

      updateDistros([newBdg, newMdf]);

      const previousBdg = nodes.find(
        (n) => n.id === `B${getHighestNodeId('bdg', distros)}`
      );

      if (previousBdg) {
        const {
          position: { x, y },
          height,
        } = previousBdg;
        if (height) {
          setViewport(
            { x: x + 300, y: -(y - 50 + height), zoom: 1 },
            { duration: 800 }
          );
        }
      }
    },
    [distros, estimateId, generateDistro, nodes, setViewport, updateDistros]
  );

  const toggleNotes = useCallback(() => setNotesOpen(!notesOpen), [notesOpen]);

  const showMdfModal = useCallback(() => {
    setNewMdfModalOpen(true);
  }, []);

  const hideMdfModal = useCallback(() => {
    setNewMdfModalOpen(false);
  }, []);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (distrosData) {
      // Must sort distro data to put all buildings first or react-flow won't render properly
      setDistros(sortDistros(distrosData));
    }
  }, [distrosData, prevDistrosLength, sortDistros]);

  // =============================================
  // Early Return
  // =============================================
  if (isLoading) {
    return <KiteLoader />;
  }
  // =============================================
  // Return
  // =============================================
  return (
    <div className="ucq-design-flow-layout">
      {!!distros.length && (
        <UCQDesignFlow distros={distros} generateDistro={generateDistro} />
      )}
      {!!distros.some((d) => d.notes) && (
        <DesignFlowNotes isOpen={notesOpen} toggleNotes={toggleNotes} />
      )}
      <KiteButton
        maxWidth="min-content"
        minWidth="min-content"
        onClick={showMdfModal}
      >
        <KiteIcon name="office" />
        Add Building (MDF)
      </KiteButton>
      <NewMDFModal
        onSubmit={createNewMdf}
        canShow={newMdfModalOpen}
        onHide={hideMdfModal}
      />
    </div>
  );
};

export default UCQDesignFlowLayout;
