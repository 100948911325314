// External packages
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';
import dayjs from 'dayjs';

// Redux

// Components
import {
  KiteAlert,
  KiteButton,
  KiteCheckbox,
  KiteIcon,
  KiteModal,
} from '@kite/react-kite';
import { FilterSearch, SortableTable } from '@kite/react-kite-plus';
import { BundleNameLinkButton, BundleDetailDrawer } from 'components';

// Utils
import { collatorSort } from 'utils';

// Hooks
import { useNavigate } from 'react-router-dom';
import {
  useAnalytics,
  useGetBundles,
  useDeleteBundle,
  useUpdateBundle,
  useQueryData,
  useGenerateProductTotals,
} from 'hooks';

// Types
import { IBundle, IBundleTableRow } from 'types';

// Styles
import './BundlesDashboard.scss';

const BundlesDashboard = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const navigate = useNavigate();
  const { updateScenarioId, updateLocationId } = useQueryData();

  const [selectedBundle, setSelectedBundle] = useState<IBundle | null>(null);
  const [bundleToDelete, setBundleToDelete] = useState<IBundle | null>(null);
  const [bundleSearch, setBundleSearch] = useState('');
  const [showFeaturedAlert, setShowFeaturedAlert] = useState(false);

  const { data: allBundles, isLoading: allBundlesLoading } = useGetBundles();

  const featuredBundles = useMemo(() => {
    return allBundles?.filter((b) => b.featured) || [];
  }, [allBundles]);

  const moreBundles = useMemo(() => {
    return allBundles?.filter((b) => !b.featured) || [];
  }, [allBundles]);

  const { updateBundle } = useUpdateBundle();
  const { deleteBundle } = useDeleteBundle();
  const { generateProductTotals } = useGenerateProductTotals();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const columns = [
    {
      label: 'Bundle Name',
      sortEnabled: true,
      sortKey: 'name',
      render: (item: IBundleTableRow) => {
        const [name, bundle] = item.name;
        return (
          <BundleNameLinkButton
            name={name}
            onClick={() => setSelectedBundle(bundle)}
          />
        );
      },
    },
    {
      label: '36 Mos.',
      sortEnabled: true,
      sortKey: '36Mos',
    },
    {
      label: '48 Mos.',
      sortEnabled: true,
      sortKey: '48Mos',
    },
    {
      label: '60 Mos.',
      sortEnabled: true,
      sortKey: '60Mos',
    },
    {
      label: 'Updated',
      sortEnabled: true,
      sortKey: 'updatedAt',
    },
    {
      label: 'Published',
      sortEnabled: true,
      sortKey: 'published',
      render: (item: IBundleTableRow) => {
        const [publishType, name, id, featured] = item.published;
        const isPublished = publishType === 'a' ? true : false;

        return (
          <KiteCheckbox
            label=""
            id={`${name} published checkbox`}
            name={`${name} published checkbox`}
            checked={isPublished}
            onChange={handleToggleCheckbox(id, 'published')}
            disabled={featured}
          />
        );
      },
    },
    {
      label: 'Featured',
      sortEnabled: true,
      sortKey: 'featured',
      render: (item: IBundleTableRow) => {
        const [featureType, name, id, published] = item.featured;
        const isFeatured = featureType === 'a' ? true : false;
        return (
          <KiteCheckbox
            label=""
            aria-label={`${name} featured checkbox`}
            id={`${name} featured checkbox`}
            name={`${name} featured checkbox`}
            checked={isFeatured}
            onChange={handleToggleCheckbox(id, 'featured')}
            disabled={!isFeatured && !published}
          />
        );
      },
    },
    {
      label: 'Edit',
      sortEnabled: false,
      sortKey: 'edit',
    },
    {
      label: 'Delete',
      sortEnabled: false,
      sortKey: 'delete',
    },
  ];

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleBundleRoute = useCallback(
    (bundleId?: string) => () => {
      if (bundleId) {
        navigate(`/admin/bundles/editor/data/${bundleId}`);
      } else {
        navigate('/admin/bundles/editor/data/new-bundle');
      }
    },
    [navigate]
  );

  const handleToggleCheckbox = useCallback(
    (bundleId: string, property: 'featured' | 'published') =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;

        if (checked && property === 'featured' && featuredBundles.length > 2) {
          setShowFeaturedAlert(true);
        } else {
          const existingBundle = allBundles?.find((b) => b.id === bundleId);
          if (!existingBundle) return;

          const updatedBundle = {
            ...existingBundle,
            [property]: checked,
          };
          updateBundle(updatedBundle);
        }
      },
    [featuredBundles, allBundles, updateBundle]
  );

  const handleDeleteBundle = useCallback(async () => {
    if (bundleToDelete) {
      deleteBundle(bundleToDelete.id);
      setBundleToDelete(null);
    }
  }, [bundleToDelete, deleteBundle]);

  const calculateTermTotals = useCallback(
    (bundle: IBundle) => {
      const total36 = generateProductTotals({
        selections: bundle.selections,
        term: '36',
      });
      const total48 = generateProductTotals({
        selections: bundle.selections,
        term: '48',
      });
      const total60 = generateProductTotals({
        selections: bundle.selections,
        term: '60',
      });

      return { total36, total48, total60 };
    },
    [generateProductTotals]
  );

  // =============================================
  // Render Methods
  // =============================================
  const formatTableData = useCallback(
    (bundle: IBundle): IBundleTableRow => {
      const { total36, total48, total60 } = calculateTermTotals(bundle);

      return {
        name: [bundle.name, bundle],
        '36Mos': total36.rateMrc as string,
        '48Mos': total48.rateMrc as string,
        '60Mos': total60.rateMrc as string,
        published: bundle.published
          ? ['a', bundle.name, bundle.id, bundle.featured]
          : ['b', bundle.name, bundle.id, bundle.featured],
        featured: bundle.featured
          ? ['a', bundle.name, bundle.id, bundle.published]
          : ['b', bundle.name, bundle.id, bundle.published],
        updatedAt: dayjs(bundle.updatedAt).format('M/DD/YYYY'),

        // sort:
        //   /**
        //    * Display the controls if this is a "Featured" bundle, or if it is the first index of the "More"
        //    * array so that the user can move it into featured from the top of the "More" list.
        //    */
        //   (() => {
        //     const ordinal: 0 | 1 | 2 | undefined = (() => {
        //       if (bundle.featured) return index as 0 | 1 | 2;
        //       return undefined;
        //     })();

        //     return bundle.featured && ordinal !== undefined ? (
        //       <FeaturedOrderControls
        //         onDecrementOrdinal={async () =>
        //           handleMoveBundleUp(ordinal, bundle)
        //         }
        //         onIncrementOrdinal={async () =>
        //           handleMoveBundleDown(ordinal, bundle)
        //         }
        //         currentOrdinal={ordinal}
        //         featuredLength={data?.featured.length || 0}
        //       />
        //     ) : null;
        //   })(),

        edit: (
          <KiteIcon
            name="edit"
            size="16px"
            ariaLabel="Edit Bundle"
            onClick={handleBundleRoute(bundle.id)}
          />
        ),

        delete: (
          <KiteIcon
            name="trash"
            size="16px"
            ariaLabel="Delete Bundle"
            onClick={() => setBundleToDelete(bundle)}
          />
        ),
      };
    },
    [handleBundleRoute, calculateTermTotals]
  );

  const sortBundles = useCallback(
    (bundles: IBundle[]) =>
      bundles.sort((a, b) => {
        const { name: aName } = a;
        const { name: bName } = b;
        return collatorSort(aName, bName);
      }),
    []
  );

  // Create table rows from bundles
  const featuredRows = useMemo(() => {
    return sortBundles(featuredBundles).map((bundle) =>
      formatTableData(bundle)
    );
  }, [featuredBundles, formatTableData, sortBundles]);

  const moreRows = useMemo(() => {
    return sortBundles(moreBundles).reduce((acc: IBundleTableRow[], bundle) => {
      if (bundle.name.toLowerCase().includes(bundleSearch.toLowerCase())) {
        acc.push(formatTableData(bundle));
      }
      return acc;
    }, []);
  }, [sortBundles, moreBundles, bundleSearch, formatTableData]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('Bundle Dashboard'), [trackPageView]);

  useEffect(() => {
    // clear scenario/locationIds to prevent bundle updated errors
    updateScenarioId('');
    updateLocationId('');
  }, [updateLocationId, updateScenarioId]);

  // =============================================
  // Early Return
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="bundle-dashboard">
      <div className="bundle-dashboard__header-wrapper">
        <h2 className="kite-h2">Bundles</h2>

        <KiteButton onClick={handleBundleRoute()} type="primary" size="small">
          Add Bundle
        </KiteButton>
      </div>

      <div>
        <SortableTable
          loading={allBundlesLoading}
          headerTitle="Featured"
          columns={columns}
          tableData={featuredRows}
        />

        <FilterSearch
          initialData={[]}
          onChange={(_, value: string) => setBundleSearch(value)}
          startsWith={false}
          iconClass="search"
          placeholder="Search bundle name"
          showInvalidText={false}
          maxWidth="100%"
        />

        {showFeaturedAlert && (
          <KiteAlert
            level="page"
            onClose={() => setShowFeaturedAlert(false)}
            description="The Featured section is limited to three bundles"
            type="alert"
          />
        )}

        <SortableTable
          loading={allBundlesLoading}
          headerTitle="More"
          columns={columns}
          tableData={moreRows}
        />

        {/* Don't show pagination if only one page worth of data exits*/}
        {/* {numberOfPages > 1 && (
          <KitePagination
            totalPages={numberOfPages}
            currentPage={currentPaginationPage}
            onNextPage={() =>
              setCurrentPaginationPage(currentPaginationPage + 1)
            }
            onPrevPage={() =>
              setCurrentPaginationPage(currentPaginationPage - 1)
            }
            onPageSelect={(page: number) => setCurrentPaginationPage(page)}
          />
        )} */}
      </div>

      {selectedBundle && (
        <BundleDetailDrawer
          selectedBundle={selectedBundle}
          handleBundleRoute={handleBundleRoute}
          onClose={() => setSelectedBundle(null)}
        />
      )}

      <KiteModal
        modalId="delete-bundle"
        canShow={!!bundleToDelete}
        onHide={() => setBundleToDelete(null)}
        title={`Are you sure you would like to delete ${
          bundleToDelete ? bundleToDelete.name : 'this bundle'
        }?`}
        ctaCopy="Delete Bundle"
        ctaAction={handleDeleteBundle}
        secondaryCtaCopy="Keep"
        secondaryCtaAction={() => setBundleToDelete(null)}
      >
        <p>This action cannot be undone.</p>
      </KiteModal>
    </div>
  );
};

export default BundlesDashboard;
