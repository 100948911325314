// External packedges
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Components
import { LeftNavAdmin } from 'components';

// Hooks
import { useQueryData } from 'hooks';

// Types

// Styles
import './Admin.scss';

const Admin = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const queryData = useQueryData();

  useEffect(() => {
    if (pathname === '/admin') {
      navigate('/admin/product-pricing/data/dfi-speed', { replace: true });
    }
  }, [navigate, pathname]);

  return (
    <div className="admin">
      <div className="admin__content-wrapper">
        {!pathname.includes('editor') && <LeftNavAdmin />}
        <Outlet context={queryData} />
      </div>
    </div>
  );
};

export default Admin;
