// Packages
import React, { useMemo } from 'react';
import classNames from 'classnames';

// Redux

// Components
import { ProgressBar } from 'components';
import { KiteButton, KiteModal, KiteSelect } from '@kite/react-kite';

// Hooks

// Utils
import { renderFormIcon } from 'utils';

// Types
import { IForm, ILocation } from 'types';

// Styles
import './ImportModal.scss';

export interface IImportModalProps {
  /** Determines if modal is open */
  canShow: boolean;
  /** Currently selected import location id */
  importLocationId: string;
  /** Determines if modal is being used to import submissions or selections */
  importType: 'submission' | 'selection';
  /** Locations to import from */
  locations: ILocation[];
  /** Callback to handle import functionality */
  onImport: () => void;
  /** Callback to change selected import location id */
  onLocationSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  /** Callback to toggle modal visibility */
  onToggle: () => void;
  /** Current form if submissions are being imported */
  currentForm?: IForm;
  /** Current form complete percent if submissions are being imported */
  completePercent?: number;
  /** Modal should be disabled if scenario does not have more than one location */
  disabled?: boolean;
}

/** Import modal with button, allows user to import location submission answers or scenario selections */

const ImportModal = ({
  onToggle,
  canShow,
  onImport,
  importLocationId,
  importType,
  onLocationSelect,
  currentForm,
  locations,
  completePercent = 0,
  disabled = false,
}: IImportModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const showProgressBar = importType === 'submission';
  const label = `Import ${
    importType === 'submission' ? 'Answers' : 'Selections'
  }`;

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const formNameHeader = useMemo(() => {
    if (importType === 'submission' && currentForm) {
      return (
        <div className="import-modal__header">
          {renderFormIcon(currentForm.name, '24px')}
          <h2>{currentForm.name}</h2>
        </div>
      );
    }

    return null;
  }, [currentForm, importType]);

  const locationOptions = useMemo(() => {
    return locations.map((l) => (
      <option key={l.id} value={l.id}>
        {l.name}
      </option>
    ));
  }, [locations]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="import-modal">
      <KiteButton
        className={classNames({
          'import-modal__toggle': true,
          'import-modal__toggle--disabled': disabled,
        })}
        type="primary"
        maxWidth="min-content"
        minWidth="min-content"
        onClick={onToggle}
        disabled={disabled}
      >
        {label}
      </KiteButton>
      <KiteModal
        canShow={canShow}
        onHide={onToggle}
        title={label}
        ctaCopy="Import"
        ctaAction={onImport}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={onToggle}
      >
        {formNameHeader}
        <KiteSelect
          id="importLocation"
          name="Import Location"
          label="Select Import Location"
          value={importLocationId}
          onChange={onLocationSelect}
        >
          {locationOptions}
        </KiteSelect>
        {showProgressBar && (
          <ProgressBar
            className="import-modal__progress"
            percentage={completePercent}
            title="Form Progress"
          />
        )}
      </KiteModal>
    </div>
  );
};

export default ImportModal;
