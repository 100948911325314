import axios from 'axios';
import { env } from './env';
import { ILocationRequest } from './locationRequest';
import { throwQueryError } from './throwQueryError';

export const locationBySfId = async (config?: ILocationRequest) => {
  const { endpoint = 'locations/sf', method = 'GET', params } = config || {};
  const url = `${env.apiUrl}/${endpoint}/${params?.sfLocationId}/${params?.estimateId}`;

  return axios({
    url,
    method,
    validateStatus: function (status) {
      return status <= 404; // Reject only if the status code is greater than 400
    },
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong retrieving location from Salesforce with service locationIdId: ${params?.sfLocationId} `,
      })
    );
};
