import { IServiceCodes } from 'types';

export const mockServiceCodes: IServiceCodes[] = [
  {
    regionalCode: 'RG106',
    nationalCode: 'RG406',
    type: 'Public',
    name: 'Deluxe',
    category: 'Programming Package Codes',
    productId: '931b90ad-a49a-4fad-a01c-16b97ba60090',
    id: '73c8dc89-16af-4856-9356-a9dc59f2ccff',
    createdAt: '2022-04-15T16:57:12.106Z',
    updatedAt: '2022-04-15T16:57:12.106Z',
  },
  {
    regionalCode: 'RG146',
    nationalCode: 'RG446',
    type: 'Public',
    name: 'Latino',
    category: 'Programming Package Codes',
    productId: '4533ddf3-b987-421d-b390-5830f38f0afa',
    id: 'aa7df2ef-55c2-475a-81b5-5beae037a167',
    createdAt: '2022-04-15T16:57:12.106Z',
    updatedAt: '2022-04-15T16:57:12.106Z',
  },
];
