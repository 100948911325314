import { getActiveDirectory, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

interface IUseGetActiveDirectoryParams {
  pid?: string;
}

export interface IUseGetActiveDirectoryRes {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  enabled: boolean;
  pid: string;
  title: string;
  department: string;
  employee_type: string;
  avatar: string;
}

export const useGetActiveDirectory = (
  requestConfig: IUseGetActiveDirectoryParams
) => {
  const { pid } = requestConfig;
  return useGenerateQuery<IUseGetActiveDirectoryRes>({
    queryKey: queryKeys({ pid }).details.activeDirectory,
    apiCall: getActiveDirectory,
    requestConfig: { pid },
    opts: {
      enabled: !!pid || pid === 'N/A',
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retryOnMount: false,
    },
  });
};
