import { IProductFamily, IProductFamilyRequest } from 'types';
import { productFamilyRequest, queryKeys } from 'utils';
import { useGenerateQuery } from '../useGenerateQuery';

export const useGetProductFamily = (
  requestConfig: IProductFamilyRequest = {}
) => {
  const { params } = requestConfig;
  return useGenerateQuery<IProductFamily[]>({
    queryKey: params
      ? queryKeys(params).filter.productFamilies
      : queryKeys().all.productFamilies,
    apiCall: productFamilyRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
