import { IProductFamily } from 'types';

export const generateClassnames = (productFamily: IProductFamily) => {
  const pageClass = `${productFamily.category
    .split(' ')[0]
    .toLowerCase()}-page`;

  const componentClass = `${productFamily.name
    .replace(/ /g, '-')
    .toLowerCase()}`;

  return { pageClass, componentClass };
};
