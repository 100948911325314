import { useCallback } from 'react';
import { useAnalytics } from './useAnalytics';

export const useTrackOpenState = () => {
  const { trackSelectAction } = useAnalytics();

  const trackOpenState = useCallback(
    (params: { name: string; isOpen: boolean }) => {
      trackSelectAction(
        `${params.name}: ${!params.isOpen ? 'Open' : 'Close'}`,
        { opType: 'buttonClick' }
      );
    },
    [trackSelectAction]
  );

  return { trackOpenState };
};
