import axios from 'axios';
import { env } from './env';

export interface IRateCenterSearchData {
  rcAbbre: string;
  locState: string;
}

export const getRateCenterSearch = async (
  searchData: IRateCenterSearchData
) => {
  const { rcAbbre, locState } = searchData;
  const url = `${env.apiUrl}/rate-center/search`;

  try {
    const res = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      data: JSON.stringify({
        rcAbbre,
        locState,
      }),
    });

    return res.data.data;
  } catch (error) {
    console.log('error', error);
  }
};
