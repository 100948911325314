// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteCard, KiteInput, KiteModal, KiteSelect } from '@kite/react-kite';

// Hooks
import { useGetEstimateDetails } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { formatLocationAddress, interconnectTypes } from 'utils';

// Types

// Styles
import './NewMDFModal.scss';

export interface INewMDFModalProps {
  /** Callback for submitting form data and creating new distro */
  onSubmit: (formData: { [key: string]: string }) => void;
  /** Determines if modal is dispayed */
  canShow: boolean;
  /** Callback for when modal is closed */
  onHide: () => void;
}

/** Modal for creating a new building w/ MDF, also used as initial starting point if no distros */

const NewMDFModal = ({ onSubmit, canShow, onHide }: INewMDFModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const initFormData = useMemo(
    () => ({ buildingName: '', locationId: '', interconnectType: '' }),
    []
  );

  const [formData, setFormData] = useState(initFormData);
  const [errId, setErrId] = useState<keyof typeof formData | null>(null);

  const { estimateId } = useParams();

  const { estimateData } = useGetEstimateDetails(estimateId);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const currentAddress = useMemo(() => {
    const location = estimateData?.locations.find(
      (l) => l.id === formData.locationId
    );
    if (location) {
      return formatLocationAddress(location);
    }

    return '';
  }, [estimateData, formData.locationId]);

  const formComplete = useMemo(
    () =>
      Object.entries(formData)
        .filter(([key]) => key !== 'buildingName')
        .every(([_, value]) => !!value),
    [formData]
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const onChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData]
  );

  const handleSubmit = useCallback(() => {
    if (formComplete) {
      onSubmit(formData);
      onHide();
    }

    if (!formComplete) {
      const [incompleteId] = Object.entries(formData)
        .filter(([key]) => key !== 'buildingName')
        .find(([_, value]) => !value) || [''];

      if (incompleteId) {
        setErrId(incompleteId as keyof typeof formData);
      }
    }
  }, [formComplete, onSubmit, formData, onHide]);

  // =============================================
  // Render Methods
  // =============================================
  const generateSelectOptions = useCallback(
    (values: any[], valueKey?: string, labelKey?: string) => {
      if (valueKey && labelKey) {
        return values.map((v) => (
          <option key={v[valueKey]} value={v[valueKey]}>
            {v[labelKey]}
          </option>
        ));
      }
      return values.map((v) => (
        <option key={v} value={v}>
          {v}
        </option>
      ));
    },
    []
  );

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (errId && formData[errId]) {
      setErrId(null);
    }
  }, [errId, formData]);

  useEffect(() => {
    if (!canShow) {
      setFormData(initFormData);
    }
  }, [canShow, initFormData]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="new-mdf-modal"
      title={'New Building'}
      canShow={canShow}
      onHide={onHide}
      ctaAction={handleSubmit}
      ctaCopy="Create New Building"
      secondaryCtaAction={onHide}
      secondaryCtaCopy="Cancel"
    >
      <span className="new-mdf-modal__subtext">
        This is a new building. An MDF will automatically be applied.
      </span>
      <div className="new-mdf-modal__content">
        <KiteInput
          id="buildingName"
          name="buildingName"
          value={formData.buildingName}
          onChange={onChange}
          placeholder="Enter Building Name (optional)"
        />
        <KiteSelect
          value={formData.locationId}
          onChange={onChange}
          id="locationId"
          name="locationId"
          placeholder={!formData.locationId ? 'Make Selection' : ''}
          label="MDF Service Location"
          errorMessage={
            errId === 'locationId' ? 'Please select a service location.' : ''
          }
        >
          {generateSelectOptions(estimateData?.locations || [], 'id', 'name')}
        </KiteSelect>
        <div className="new-connection-modal__address">
          <span>Selected Location Address</span>
          <KiteCard>
            <span>{currentAddress}</span>
          </KiteCard>
        </div>
        <KiteSelect
          id="interconnectType"
          name="interconnectType"
          value={formData.interconnectType}
          onChange={onChange}
          label="Interconnect Type"
          placeholder={!formData.interconnectType ? 'Make Selection' : ''}
          errorMessage={
            errId === 'interconnectType'
              ? 'Please select an Interconnect Type.'
              : ''
          }
        >
          {generateSelectOptions(interconnectTypes)}
        </KiteSelect>
      </div>
    </KiteModal>
  );
};

export default NewMDFModal;
