import axios from 'axios';
import { IRequestConfig, IScenarioPromo, IRequestParams } from 'types';
import { paramsSerializer } from 'utils/format/paramSerializer';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IScenarioPromosRequestParams extends IRequestParams {
  scenarioId?: string;
  locationId?: string;
}

export interface IScenarioPromosRequest
  extends IRequestConfig<IScenarioPromo | string[]> {
  params?: IScenarioPromosRequestParams;
}

export const scenarioPromosRequest = async (
  config?: IScenarioPromosRequest
) => {
  const {
    endpoint = 'scenario-promos',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with scenario-promos ${method}`,
      })
    );
};
