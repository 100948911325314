import React, { useMemo } from 'react';

// Components
import { KiteButton } from '@kite/react-kite';
import { Drawer } from '@kite/react-kite-plus';

// Utils
import { formatPrice } from 'utils';

// Hooks
import { useQueryDataContext } from 'hooks';

// Types
import { IBundle } from 'types';

// Styles
import './BundleDetailDrawer.scss';

export interface IBundleDetailDrawerProps {
  selectedBundle: IBundle;
  handleBundleRoute: (id: string) => () => void;
  onClose: () => void;
}

const BundleDetailDrawer = ({
  selectedBundle,
  handleBundleRoute,
  onClose,
}: IBundleDetailDrawerProps) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const { allProductFamilies } = useQueryDataContext();

  const tableData = useMemo(() => {
    return selectedBundle.selections.map((selection) => {
      const productFamily = allProductFamilies.find(
        (f) => f.id === selection.familyId
      );
      let productName = `${selection.familyName}: ${selection.name}`;

      if (selection.familyCategory === 'SBB 1-19 Pub/Priv') {
        productName = `SBB 1-19 ${productName}`;
      }
      if (productFamily?.type === 'number') {
        productName = productName.concat(` (x ${selection.quantity})`);
      }

      const prices = selection.prices.reduce(
        (acc: { [key: string]: number }, price) => {
          if (price.type === 'rate') {
            acc[price.term] = price.price * selection.quantity;
          }
          return acc;
        },
        {}
      );

      return {
        product: productName,
        '36': prices['36'],
        '48': prices['48'],
        '60': prices['60'],
      };
    });
  }, [selectedBundle, allProductFamilies]);

  // =============================================
  // Render Methods
  // =============================================
  const { tableRows, totalsRow } = useMemo(() => {
    const bundleTotals = {
      product: '',
      '36': 0,
      '48': 0,
      '60': 0,
    };

    const tableRows = tableData.map((row, i) => {
      bundleTotals[36] += row[36];
      bundleTotals[48] += row[48];
      bundleTotals[60] += row[60];

      return (
        <tr key={`${row.product}-${i}`}>
          <td>{row.product}</td>
          <td>{formatPrice(row[36])}</td>
          <td>{formatPrice(row[48])}</td>
          <td>{formatPrice(row[60])}</td>
        </tr>
      );
    });

    const totalsRow = (
      <tr>
        <td></td>
        <td>{formatPrice(bundleTotals[36])}</td>
        <td>{formatPrice(bundleTotals[48])}</td>
        <td>{formatPrice(bundleTotals[60])}</td>
      </tr>
    );

    return { tableRows, totalsRow };
  }, [tableData]);

  // =============================================
  // Return
  // =============================================
  return (
    <Drawer
      isOpen={!!selectedBundle}
      onClose={onClose}
      className="bundle-detail-drawer"
      width="700px"
      disableBackgroundClose={false}
      showCloseButton={true}
    >
      <h2 className="kite-h2 bundle-detail-drawer__heading">Bundle Details</h2>
      <h3 className="bundle-detail-drawer__bundle-name">
        {selectedBundle.name}
      </h3>

      <table className="bundle-detail-drawer__table">
        <thead>
          <tr>
            <td>Products</td>
            <td>36 Mos.</td>
            <td>48 Mos.</td>
            <td>60 Mos.</td>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
        <tfoot>{totalsRow}</tfoot>
      </table>

      <div className="bundle-detail-drawer__actions">
        <KiteButton
          leftIcon="edit"
          size="small"
          type="outline"
          onClick={handleBundleRoute(selectedBundle.id)}
        >
          Edit
        </KiteButton>
        <KiteButton size="small" onClick={onClose}>
          Close
        </KiteButton>
      </div>
    </Drawer>
  );
};

export default BundleDetailDrawer;
