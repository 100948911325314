import { Edge, Node, MarkerType } from 'react-flow-renderer';
import { IDistro, IUCQDesignFlowNode } from 'types';

interface IFormatNodeData {
  distros: Partial<IDistro>[];
}

export const formatNodeData = ({ distros }: IFormatNodeData) => {
  const position = { x: 0, y: 0 };
  const type = 'simplebezier';
  const markerEnd = { type: MarkerType.ArrowClosed };

  return Array.from(distros).reduce(
    (
      acc: {
        nodes: Node<IUCQDesignFlowNode>[];
        edges: Edge[];
      },
      distro
    ) => {
      const { nodeId = '', nodeType, toNodeIds = [], parentNodeId } = distro;

      const node: Node<IUCQDesignFlowNode> = {
        id: nodeId,
        type: nodeType,
        position,
        data: distro,
      };

      if (parentNodeId) {
        node.parentNode = parentNodeId;
        node.extent = 'parent';
      }

      const toEdges = toNodeIds.map((toId) => ({
        id: `e${nodeId}-${toId}`,
        source: nodeId,
        target: toId,
        type,
        markerEnd,
      }));

      acc.nodes.push(node);
      acc.edges.push(...toEdges);

      return acc;
    },
    { nodes: [], edges: [] }
  );
};
