// Packages
import React, { useCallback } from 'react';

// Redux

// Components
import { NavLink, NavLinkProps } from 'react-router-dom';

// Hooks
import { useTrackNavClick } from 'hooks';

// Utils

// Types

// Styles
import './ButtonLink.scss';

export interface IButtonLinkProps {
  // Path link should navigate to
  to: NavLinkProps['to'];
  // Content to be displayed in the link
  linkContent: JSX.Element | string;
  // Link state
  state?: NavLinkProps['state'];
  // Button type (default = primary)
  type?: 'primary' | 'outline' | 'standalone';
  // Optional classname for addtl styling
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

/** A react router NavLink that emulates the look of a KiteButton, for links that need to look like buttons */

const ButtonLink = ({
  to,
  state,
  type = 'primary',
  linkContent,
  className = '',
  onClick,
}: IButtonLinkProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackNavClick } = useTrackNavClick();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const buttonClass = `button-link--${type}`;

  // =============================================
  // Interaction Handlers
  // =============================================
  const domain = typeof linkContent === 'string' ? linkContent : to;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (onClick) {
        onClick(e);
      }
      trackNavClick(`Navigate to: ${domain}`)();
    },
    [domain, onClick, trackNavClick]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <NavLink
      className={`${buttonClass} ${className}`}
      to={to}
      state={state}
      onClick={handleClick}
    >
      {linkContent}
    </NavLink>
  );
};

export default ButtonLink;
