// Packages
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';

// Components
import { KiteButton, KiteIcon, KiteInput } from '@kite/react-kite';
import { Drawer } from '@kite/react-kite-plus';
import {
  LocationCard,
  LocationForm,
  EstimateLocationCard,
  DeleteLocationModal,
} from 'components';

// Hooks

// Utils
import {
  deepCopy,
  generateDefaultConfiguration,
  getSeatsRateCard,
  removeEmojis,
} from 'utils';

// Hooks
import {
  useDeleteProfiles,
  useQueryData,
  useUpdateEstimate,
  useUpdateProfiles,
} from 'hooks';

// Types
import {
  IEstimateUpdate,
  ILocation,
  ILocationBase,
  ILocationSelection,
  IProfile,
  IScenario,
} from 'types';

// Styles
import './LocationsDrawer.scss';

export interface ILocationsDrawerProps {
  /** Determines if drawer is open or not */
  isOpen: boolean;
  /** Callback to change isOpen state to false */
  onClose: () => void;
  /** Determines if drawer is being used in sandbox mode */
  isSandbox: boolean;
}

/** A drawer containing location cards, cards can be edited if `isSandbox` is true */

const LocationsDrawer = ({
  isOpen = false,
  onClose,
  isSandbox,
}: ILocationsDrawerProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { currentEstimate, currentScenario, userId, allProfiles } =
    useQueryData();
  const { updateEstimate } = useUpdateEstimate();
  const { updateProfiles } = useUpdateProfiles();
  const { deleteProfiles } = useDeleteProfiles();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [locationCounter, setLocationCounter] = useState(
    isSandbox ? 1 : currentEstimate?.locations.length || 0
  );

  const [locationData, setLocationData] = useState<ILocationBase | null>(null);
  const [customerName, setCustomerName] = useState(
    currentEstimate?.customerName || ''
  );
  const [selectionsToCopy, setSelectionsToCopy] = useState<
    ILocationSelection[]
  >([]);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const locations = useMemo(() => {
    return currentEstimate?.locations || [];
  }, [currentEstimate]);

  const { newLocation: defaultLocation } = useMemo(() => {
    return generateDefaultConfiguration({
      userId,
    });
  }, [userId]);

  const bannerText = useMemo(() => {
    const locationNumber = currentEstimate?.locations.length || 0;
    const { seats: locationSeats } = getSeatsRateCard(
      currentScenario?.selections || []
    );

    const locationText = locationNumber === 1 ? 'Location' : 'Locations';
    const seatsText = locationSeats === 1 ? 'Seat' : 'Seats';

    return `${locationNumber} ${locationText} | ${locationSeats} ${seatsText}`;
  }, [currentEstimate, currentScenario]);

  const newDate = useMemo(() => new Date(Date.now()).toISOString(), []);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleAddLocation = useCallback(
    (location: ILocation) => {
      if (!currentEstimate || !currentScenario) return;

      const profileSelections = selectionsToCopy.filter((s) => s.profileId);
      const nonProfileSelections = selectionsToCopy.filter((s) => !s.profileId);
      const profileIds = new Set(profileSelections.map((s) => s.profileId));
      const { newProfiles, newProfileSelections } = allProfiles.reduce(
        (
          acc: {
            newProfileSelections: ILocationSelection[];
            newProfiles: IProfile[];
          },
          p
        ) => {
          if (profileIds.has(p.id)) {
            const newProfileId = v4();
            const newProfile = { ...p, id: newProfileId };
            const newProfileSelections = profileSelections.reduce(
              (acc: ILocationSelection[], s) => {
                if (s.profileId === p.id) {
                  acc.push({ ...s, profileId: newProfileId });
                }
                return acc;
              },
              []
            );
            acc.newProfiles.push(newProfile);
            acc.newProfileSelections.push(...newProfileSelections);
          }
          return acc;
        },
        { newProfiles: [], newProfileSelections: [] }
      );

      const newSelections: ILocationSelection[] = [
        ...nonProfileSelections,
        ...newProfileSelections,
      ].map((product) => {
        return { ...product, locationId: location.id, id: v4() };
      });

      const updatedScenario: IScenario = {
        ...currentScenario,
        locations: [...currentScenario.locations, { id: location.id }],
        selections: [...currentScenario.selections, ...newSelections],
      };

      const updatedEstimate: IEstimateUpdate = {
        ...currentEstimate,
        locations: [...currentEstimate.locations, location],
        scenarios: [updatedScenario],
      };

      updateEstimate(updatedEstimate);
      updateProfiles(newProfiles);
      setLocationData(null);
      setSelectionsToCopy([]);
      setLocationCounter((count) => count + 1);
    },
    [
      allProfiles,
      currentEstimate,
      currentScenario,
      selectionsToCopy,
      updateEstimate,
      updateProfiles,
    ]
  );

  const onAddLocation = useCallback(() => {
    const newLocation = {
      ...defaultLocation,
      id: `sandboxLocation${v4()}`,
      name: `Location ${locationCounter + 1}`,
    };

    setLocationData(newLocation);
  }, [defaultLocation, locationCounter]);

  const handleUpdateLocation = useCallback(
    (location: ILocation) => {
      if (!currentEstimate) return;

      const updatedEstimate = deepCopy(currentEstimate);
      const currentIndex = updatedEstimate.locations.findIndex(
        (l) => l.id === location.id
      );
      updatedEstimate.locations.splice(currentIndex, 1, location);

      updateEstimate(updatedEstimate);
      setLocationData(null);
    },
    [currentEstimate, updateEstimate]
  );

  const onSaveLocation = useCallback(
    (location: ILocationBase) => {
      if (!currentEstimate) return;

      const updatedLocation: ILocation = {
        ...location,
        estimateId: currentEstimate.id,
        createdAt: newDate,
        updatedAt: newDate,
      };
      const isExisting = !!currentEstimate?.locations.find(
        (l) => l.id === location.id
      );

      if (isExisting) {
        handleUpdateLocation(updatedLocation);
      } else {
        handleAddLocation(updatedLocation);
      }
    },
    [currentEstimate, newDate, handleUpdateLocation, handleAddLocation]
  );

  const onCopyLocation = useCallback(
    (id: string) => {
      const copyLocation = currentEstimate?.locations.find((l) => l.id === id);
      const selections =
        currentScenario?.selections.filter((s) => s.locationId === id) || [];

      if (copyLocation) {
        const newLocationId = `sandboxLocation${v4()}`;

        const newLocation: ILocationBase = {
          ...copyLocation,
          id: newLocationId,
          name: `${copyLocation.name} Copy`,
        };

        setLocationData(newLocation);
        setSelectionsToCopy(selections);
      }
    },
    [currentEstimate, currentScenario]
  );

  const onDeleteLocation = useCallback(() => {
    if (!currentEstimate) return;

    const updatedEstimate = deepCopy(currentEstimate);
    // Remove location from estimate.locations
    updatedEstimate.locations = updatedEstimate.locations.filter(
      (l) => l.id !== locationData?.id
    );

    // Always first scenario bc you can only delete locations from sandbox (only has one scenario)
    const sandboxScenario = updatedEstimate.scenarios[0];

    const updatedScenario = {
      ...sandboxScenario,
      selections: sandboxScenario.selections.filter(
        (s) => s.locationId !== locationData?.id
      ),
      // remove location from scenario.locations
      locations: sandboxScenario.locations.filter(
        (l) => l.id !== locationData?.id
      ),
    };
    updatedEstimate.scenarios = [updatedScenario];

    if (!updatedEstimate.locations.length) {
      updatedEstimate.locations.push(defaultLocation);
      updatedEstimate.scenarios[0].locations.push({ id: defaultLocation.id });
    }

    const locationProfileIds = new Set(
      sandboxScenario.selections.reduce((acc: string[], s) => {
        if (s.profileId && s.locationId === locationData?.id) {
          acc.push(s.profileId);
        }
        return acc;
      }, [])
    );

    updateEstimate(updatedEstimate);
    deleteProfiles(Array.from(locationProfileIds));
    setShowDeleteModal(false);
    setLocationData(null);
  }, [
    currentEstimate,
    updateEstimate,
    deleteProfiles,
    locationData,
    defaultLocation,
  ]);

  const onCancelSave = useCallback(() => {
    setLocationData(null);
    setSelectionsToCopy([]);
  }, []);

  const onCancelDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const toggleEditCustomerName = useCallback(() => {
    setEditingName(!editingName);
  }, [editingName]);

  const handleCustomerNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const nameWithoutEmojis = removeEmojis(value);
      setCustomerName(nameWithoutEmojis);
    },
    []
  );

  const onSaveCustomerName = useCallback(() => {
    if (!currentEstimate) return;

    updateEstimate({ ...currentEstimate, customerName });
    setEditingName(false);
  }, [customerName, currentEstimate, updateEstimate]);

  // =============================================
  // Render Methods
  // =============================================
  const renderLocationCards = useCallback(() => {
    return locations.reduce((cards: JSX.Element[], l) => {
      if (!isSandbox) {
        cards.push(<EstimateLocationCard key={l.id} location={l} />);
      } else if (locationData?.id !== l.id) {
        cards.push(
          <LocationCard
            key={l.id}
            locationData={l}
            setLocationData={setLocationData}
            setShowDeleteModal={setShowDeleteModal}
            onCopyLocation={onCopyLocation}
          />
        );
      }
      return cards;
    }, []);
  }, [isSandbox, locations, locationData, onCopyLocation]);

  const renderCustomerNameContainer = useCallback(() => {
    const customerNameStatic = (
      <h2 className="locations-drawer__title">
        {currentEstimate?.customerName || 'Customer/Prospect'}
      </h2>
    );
    const customerNameInput = (
      <KiteInput
        className="locations-drawer__title-input"
        value={customerName}
        onChange={handleCustomerNameChange}
        maxWidth="500px"
      />
    );

    if (isSandbox) {
      return (
        <div className="locations-drawer__title-container">
          {editingName ? customerNameInput : customerNameStatic}
          {editingName ? (
            <KiteIcon name="save" onClick={onSaveCustomerName} />
          ) : (
            <KiteIcon name="edit" onClick={toggleEditCustomerName} />
          )}
        </div>
      );
    }
    return customerNameStatic;
  }, [
    currentEstimate,
    editingName,
    customerName,
    isSandbox,
    handleCustomerNameChange,
    toggleEditCustomerName,
    onSaveCustomerName,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (!isOpen) {
      setLocationData(null);
    }
  }, [isOpen]);

  // =============================================
  // Return
  // =============================================
  return (
    <div>
      <Drawer
        id="locations-drawer"
        className="locations-drawer"
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="locations-drawer__header">
          {renderCustomerNameContainer()}
          <span>{bannerText}</span>
        </div>

        <div className="locations-drawer__body">
          {isSandbox && (
            <KiteButton leftIcon="plus" onClick={onAddLocation}>
              Add Location
            </KiteButton>
          )}

          <div className="locations-drawer__cards">
            {locationData && !showDeleteModal && (
              <LocationForm
                locationData={locationData}
                onSaveLocation={onSaveLocation}
                onCancelSave={onCancelSave}
              />
            )}

            {renderLocationCards()}
          </div>
        </div>
      </Drawer>

      <DeleteLocationModal
        locationData={locationData}
        showDeleteModal={showDeleteModal}
        onCancelDelete={onCancelDelete}
        onDeleteLocation={onDeleteLocation}
      />
    </div>
  );
};

export default LocationsDrawer;
