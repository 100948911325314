interface ISfOffer {
  OfferId: string;
  Quantity: number;
  SalePrice: string;
}

export interface ISfProductToAdd {
  OpportunityId: string;
  ServiceLocationId: string;
  OfferList: ISfOffer[];
}

export interface ISfProductAlert {
  show: boolean;
  description: string;
  title: string;
  type?: 'alert' | 'caution' | 'info' | 'confirm' | undefined;
}

export const defaultSfAlert: ISfProductAlert = {
  show: false,
  description: '',
  title: '',
};

export type TOpportunityStatus =
  | 'Unqualified Opportunity'
  | 'Qualified Opportunity'
  | 'Proposal Presented'
  | 'Verbal Agreement/Negotiations'
  | 'Submitted for Sale Complete'
  | 'Closed Won'
  | 'Closed - Archive'
  | 'Closed Lost'
  | 'Rejected- Needs Attention'
  | 'Requires Sales Correction'
  | 'Sale Complete'
  | 'Submitted for Customer Signature'
  | 'Submitted for Counter Signature'
  | 'Submitted to Siebel';

export interface ISalesforceOpportunity {
  id: string;
  billingAccountId: string;
  opportunityNumber: string;
  opportunityOwner: string;
  stageName: TOpportunityStatus;
  createdByName: string;
  ownerPID: string;
  ownerEmail: string;
  lastModifiedByName: string;
  name: string;
  redirectUrl?: string;
  error?: string;
}

export interface ISalesforceLocation {
  serviceLocationId: string;
  serviceAreaId: string | null;
  shippingAddress: string;
  fiberBuildingStatus: string;
  roeStatus: string;
  spectrumCustomers: string;
  totalEmployeesInBuilding: string;
  wireCenter: string;
  estimatedFiberServiceDelivery: string;
  serviceabilityCodeDescription: string;
  serviceability: string;
  mtuAggSwitch: string;
  coaxServiceabilityColor: string;
  buildingName: string;
  rateCenterAbbr: string;
  billingSystem: string;
  serviceAreaNumber: string;
  serviceAreaName: string;
  serviceLocationName: string;
  serviceLocationRegion: string;
}

export interface ISalesforceLocationReturn {
  message: string;
  redirectUrl: string;
  data: ISalesforceLocation[];
  error?: string;
}

export interface ISfFailedProduct {
  sfOpportunityId: string;
  sfLocationId: string;
  productName: string;
  productId: string;
  serviceCode: string;
  term: string;
  message: string;
  quantity: number;
  productFamily: string;
}

export interface IProductResponse {
  message: string;
  data: {
    failed: ISfFailedProduct[];
  };
}

export type TSfOppCode = '403' | '404' | '404-2' | '500' | null;

export interface ISaleforceLocalStorage {
  accessToken: string;
  refreshToken: string;
  issuedAt: string;
}
