// Packages
import React, { useMemo } from 'react';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks

// Utils
import { renderFormIcon } from 'utils';

// Types

// Styles
import './FieldValidationHeader.scss';

export interface IFieldValidationHeaderProps {
  /** Label to be displayed */
  label: string;
  /** Name of kite icon to render */
  iconName?: string;
}

/** Renders a header for sections on the field validation page.
 * If `iconName` is provided, that icon will be rendered.
 * If no `iconName` is provided,
 * the component will look for a match within the UCQ forms icon map based on the `label`. */

const FieldValidationHeader = ({
  label,
  iconName,
}: IFieldValidationHeaderProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const size = '24px';

  const icon = useMemo(() => {
    if (iconName) {
      return <KiteIcon name={iconName} size={size} />;
    }
    return renderFormIcon(label, size);
  }, [iconName, label]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <h2 className="field-validation-header">
      {icon}
      {label}
    </h2>
  );
};

export default FieldValidationHeader;
