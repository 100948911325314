// Packages
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

// Types
import { TableValues } from 'types';

// Styles
import './OutputTotalsRow.scss';

export interface IOutputTotalsRowProps {
  rowTitle: string;
  /** Output totals */
  outputTotals: TableValues<string | number>;
  /** Designates row styling and whether cell titles are present */
  primary?: boolean;
}

/** A table that displays prices for a scenario and its locations */

const OutputTotalsRow = ({
  rowTitle,
  outputTotals,
  primary = false,
}: IOutputTotalsRowProps) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const cellHeaders: TableValues<string> = useMemo(() => {
    return {
      rateMrc: 'Rate Card MRC',
      rateNrc: 'Rate Card NRC',
      solutionMrc: 'Solution MRC',
      solutionNrc: 'Solution NRC',
    };
  }, []);

  // =============================================
  // Render Methods
  // =============================================
  const generateRowCells = useCallback(() => {
    return Object.entries(outputTotals).map(([priceType, priceValue]) => {
      if (primary) {
        return (
          <td key={priceType}>
            <span>{cellHeaders[priceType as keyof TableValues<string>]}</span>{' '}
            <br /> {priceValue}
          </td>
        );
      }
      return <td key={priceType}>{priceValue}</td>;
    });
  }, [cellHeaders, outputTotals, primary]);

  // =============================================
  // Return
  // =============================================
  return (
    <tr
      className={classNames({
        'output-totals-row': true,
        'output-totals-row--top': primary,
        'output-totals-row--bottom': !primary,
      })}
    >
      <th>{rowTitle}</th>
      {generateRowCells()}
    </tr>
  );
};

export default OutputTotalsRow;
