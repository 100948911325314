import { queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetSfInfo = <T>(requestConfig: {
  opportunityId?: string;
  billingId?: string;
  apiCall: (id: string) => Promise<T>;
  enabled: boolean;
  onError?: (error: any) => void;
  onSuccess?: (data: T) => void;
}) => {
  const { opportunityId, billingId, apiCall, enabled } = requestConfig;
  const searchParam = opportunityId || billingId;
  return useGenerateQuery<T>({
    queryKey: queryKeys({ billingId, opportunityId }).details.salesforce,
    apiCall,
    requestConfig: searchParam,
    opts: {
      retry: false,
      enabled,
      onError: (error) => {
        if (requestConfig.onError) {
          requestConfig.onError(error);
        }
      },
      onSuccess: (res) => {
        if (requestConfig.onSuccess) {
          requestConfig.onSuccess(res);
        }
      },
    },
  });
};
