// Packages
import React, { useCallback } from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteButton } from '@kite/react-kite';

// Hooks

// Utils

// Types
import { TFilterValue } from 'types';

// Styles
import './FilterButton.scss';

export interface IFilterButtonProps {
  /** Callback to filter results, takes in a list of selected filter keys */
  onFilter: (filterKey: TFilterValue) => void;
  /** Filter to apply */
  filterValue: TFilterValue;
  /** Text to display for filter */
  text: string;
  /** Amount of items matching filterValue */
  total: number;
  /** Determines if current filter is active */
  isActive: boolean;
}

/** A Box-Style filter button */

const FilterButton = ({
  onFilter,
  filterValue,
  text,
  total,
  isActive,
}: IFilterButtonProps) => {
  // =============================================
  // State/Refs
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleFilterToggle = useCallback(() => {
    onFilter(filterValue);
  }, [filterValue, onFilter]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteButton
      className={classNames({
        'filter-button': true,
        'filter-button--active': isActive,
      })}
      onClick={handleFilterToggle}
      type="outline"
    >
      <span className="filter-button__total">{total}</span>
      <span className="filter-button__value">{text}</span>
    </KiteButton>
  );
};

export default FilterButton;
