import React, { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';

import './BusinessRules.scss';

const BusinessRulesPage = () => {
  const [file, setFile] = useState('');

  useEffect(() => {
    import(`./BusinessRules.md`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setFile(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });

  return (
    <div className="business-rules">
      <h1 className="business-rules__header">Business Rules</h1>
      <div>
        <Markdown>{file}</Markdown>
      </div>
    </div>
  );
};

export default BusinessRulesPage;
