// Packages
import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

// Redux

// Components
import { Drawer } from '@kite/react-kite-plus';
import { KiteLoader } from '@kite/react-kite';
import { UCQHistoryRow, UCQHistoryModal } from 'components';

// Hooks
import { useParams } from 'react-router-dom';
import {
  useAnalytics,
  useGetSubmissionHistory,
  useGetTeamMembersData,
} from 'hooks';

// Utils
import { formatTeamMember } from 'utils';
// Types
import { ISalesTeamMember, ISubmissionHistory } from 'types';

// Styles
import './UCQHistoryDrawer.scss';

export interface IUCQHistoryDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

type THistoryItem = { day: string; changes: JSX.Element[] };

/** Displays submission history for an estimate */

const UCQHistoryDrawer = ({ isOpen, onClose }: IUCQHistoryDrawerProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const { estimateId } = useParams();
  const {
    data: historyData,
    isLoading,
    isError,
  } = useGetSubmissionHistory({ params: { estimateId } });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentHistory, setCurrentHistory] =
    useState<ISubmissionHistory | null>(null);

  const editedByPids = useMemo(() => {
    return Array.from(new Set(historyData?.map((hd) => hd.editedBy) || []));
  }, [historyData]);

  // Fetches all AD data for associated PIDs within history
  const activeDirectoryData = useGetTeamMembersData({
    pids: editedByPids,
    enabled: !!historyData,
  });

  const teamMembers = useMemo(() => {
    return activeDirectoryData.reduce(
      (acc: { [pid: string]: ISalesTeamMember | undefined }, queryResult) => {
        const { isLoading, data } = queryResult;
        if (isLoading || !data) {
          return acc;
        }
        const { pid } = data;
        acc[pid] = formatTeamMember({ activeDirectoryData: data });
        return acc;
      },
      {}
    );
  }, [activeDirectoryData]);

  const currentHistoryTeamMember = useMemo(() => {
    if (currentHistory) {
      return teamMembers[currentHistory.editedBy];
    }
  }, [currentHistory, teamMembers]);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleModalChange = useCallback(
    (params: { isOpen: boolean; history: ISubmissionHistory | null }) => () => {
      const { isOpen, history } = params;
      setCurrentHistory(history);
      setModalIsOpen(isOpen);
      trackSelectAction(`History Modal ${isOpen ? 'Open' : 'Close'}`, {
        opType: 'buttonClick',
      });
    },
    [trackSelectAction]
  );

  // =============================================
  // Render Methods
  // =============================================
  const formattedHistoryData = useMemo(() => {
    return historyData?.reduce(
      (acc: THistoryItem[], history: ISubmissionHistory) => {
        const { id, createdAt, editedBy: pid } = history;

        const dayName = new Date(createdAt).toLocaleString('en-us', {
          weekday: 'long',
        });
        // Include year and remove when rendering so that we dont add to an existing day when the year turns over
        const formattedDayName =
          dayName + ', ' + dayjs(createdAt).format('MMMM Do YYYY');
        const existingDay = acc.find((obj) => obj.day === formattedDayName);

        const teamMember = teamMembers[pid];

        const historyRow = (
          <UCQHistoryRow
            key={id}
            teamMember={teamMember}
            historyData={history}
            onClick={handleModalChange({ isOpen: true, history })}
          />
        );

        // Check if there is already a section for that day
        if (existingDay) {
          existingDay.changes.push(historyRow);
        } else {
          // Create a new day header and array of changes
          acc.push({
            day: formattedDayName,
            changes: [historyRow],
          });
        }
        return acc;
      },
      []
    );
  }, [handleModalChange, historyData, teamMembers]);

  const renderHistoryList = useCallback(() => {
    if (isLoading) {
      return <KiteLoader />;
    }

    if (isError) {
      return <span>Something went wrong loading submission history.</span>;
    }

    if (formattedHistoryData) {
      return formattedHistoryData?.map(
        (historyItem: THistoryItem, i: number) => (
          <div key={i}>
            <h2>{historyItem.day.slice(0, -4)}</h2>
            {historyItem.changes}
          </div>
        )
      );
    }
  }, [isLoading, isError, formattedHistoryData]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <Drawer
      className="ucq-history-drawer"
      title="History"
      isOpen={isOpen}
      onClose={onClose}
    >
      {renderHistoryList()}
      <UCQHistoryModal
        isOpen={modalIsOpen}
        onClose={handleModalChange({ isOpen: false, history: null })}
        currentHistoryTeamMember={currentHistoryTeamMember}
        currentHistory={currentHistory}
      />
    </Drawer>
  );
};

export default UCQHistoryDrawer;
