// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteButton, KiteIcon, KiteModal, KiteSelect } from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';
import { ActiveDirSuggestionInput } from 'components';

// Hooks
import { useDebounce } from 'use-debounce/lib';
import {
  useSearchActiveDirectory,
  useGetTeamMembersData,
  useAnalytics,
} from 'hooks';

// Utils
import { deepCopy } from 'utils';

// Types
import {
  TActiveDirectorySearchRes,
  TSalesTeamRole,
  SalesTeamRoles,
  IEstimate,
} from 'types';

// Styles
import './EditSalesTeamModal.scss';

type TSearchResult = TActiveDirectorySearchRes[number];

export interface IEditSalesTeamModalProps {
  salesTeamData: { [pid: string]: TSalesTeamRole };
  canShow: boolean;
  toggleOpen: () => void;
  onSave: (salesTeam: IEstimate['salesTeam']) => void;
  className?: string;
}

/** A modal that lets you edit the sales team */

const EditSalesTeamModal = ({
  salesTeamData,
  canShow,
  toggleOpen,
  onSave,
}: IEditSalesTeamModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const [salesTeam, setSalesTeam] = useState(salesTeamData);
  const [searchValue, setSearchValue] = useState('');
  const [debounceSearchValue] = useDebounce(searchValue, 350);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [error, setError] = useState('');
  const { data: searchResults, isLoading } = useSearchActiveDirectory({
    search: searchValue.length > 2 ? debounceSearchValue : '',
    onError: () =>
      setError(
        'Something went wrong searching Active Directory. Please make sure you are connected to VPN or a Charter network.'
      ),
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const salesTeamPids = useMemo(() => Object.keys(salesTeam), [salesTeam]);

  const hasTeamChanges = useMemo(() => {
    const initEntries = Object.entries(salesTeamData);
    return (
      initEntries.length !== Object.entries(salesTeam).length ||
      Object.entries(salesTeam).some(
        ([pid, role]) => role !== salesTeamData[pid]
      )
    );
  }, [salesTeam, salesTeamData]);

  const activeDirectoryData = useGetTeamMembersData({ pids: salesTeamPids });

  const teamMemberNames = useMemo(() => {
    return activeDirectoryData.reduce(
      (acc: { [pid: string]: string }, queryResult) => {
        const { isLoading, data } = queryResult;
        if (isLoading || !data) {
          return acc;
        }
        const { pid, first_name, last_name } = data;
        const name = first_name ? `${first_name} ${last_name}` : '';
        acc[pid] = name;
        return acc;
      },
      {}
    );
  }, [activeDirectoryData]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleToggleModal = useCallback(() => {
    toggleOpen();
    if (canShow) {
      setSearchValue('');
      setError('');
      setSalesTeam(salesTeamData);
    }
  }, [canShow, salesTeamData, toggleOpen]);

  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (error) {
        setError('');
      }

      setSearchValue(e.target.value);

      if (e.target.value.length && !showSuggestions) {
        setShowSuggestions(true);
      } else if (!e.target.value.length && showSuggestions) {
        setShowSuggestions(false);
      }
    },
    [error, showSuggestions]
  );

  const handleRoleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { name: pid, value } = e.target;
      setSalesTeam({ ...salesTeam, [pid]: value as TSalesTeamRole });
      trackSelectAction(`Edit Sales Team Update Role: ${pid}/${value}`, {
        opType: 'inputFieldUpdate',
      });
    },
    [salesTeam, trackSelectAction]
  );

  const handleRemoveTeamMember = useCallback(
    (pid: string) => () => {
      const salesTeamCopy = deepCopy(salesTeam);
      if (salesTeamPids.includes(pid)) {
        delete salesTeamCopy[pid];
        setSalesTeam(salesTeamCopy);
      }
      trackSelectAction(`Edit Sales Team: Remove ${pid}`, {
        opType: 'buttonClick',
      });
    },
    [salesTeam, salesTeamPids, trackSelectAction]
  );

  const handleAddTeamMember = useCallback(
    (teamMember: TSearchResult) => {
      if (
        Object.entries(salesTeam).some(
          ([pid]) => pid.toLowerCase() === teamMember.pid.toLowerCase()
        )
      ) {
        setError('This person is already on the Sales Team for this estimate.');
      } else {
        setSalesTeam({ ...salesTeam, [teamMember.pid]: '' });
        setError('');
        setSearchValue('');
        setShowSuggestions(false);
        trackSelectAction(
          `Edit Sales Team: Add Team Member ${teamMember.pid}`,
          { opType: 'buttonClick' }
        );
      }
    },
    [salesTeam, trackSelectAction]
  );

  const handleSave = useCallback(() => {
    if (hasTeamChanges) {
      onSave(salesTeam);
      trackSelectAction('Edit Sales Team: Saved', { opType: 'buttonClick' });
    }
    handleToggleModal();
  }, [handleToggleModal, hasTeamChanges, onSave, salesTeam, trackSelectAction]);

  // =============================================
  // Render Methods
  // =============================================

  const { tableData, columns } = useMemo(() => {
    interface ISalesTeamTableData {
      name: string;
      pid: string;
      role: TSalesTeamRole;
    }

    const tableData: ISalesTeamTableData[] = Object.entries(
      deepCopy(salesTeam)
    ).map(([pid, role]) => ({
      name: teamMemberNames[pid] || pid,
      pid,
      role,
    }));

    const roleOptions = SalesTeamRoles.reduce((acc: JSX.Element[], role) => {
      if (role !== 'Owner') {
        acc.push(
          <option key={role} value={role}>
            {role}
          </option>
        );
      }
      return acc;
    }, []);

    const columns = [
      {
        label: 'Name/PID',
        sortKey: 'name',
      },
      {
        label: 'Role',
        sortKey: 'role',
        render: (item: ISalesTeamTableData) => {
          const { pid, role } = item;
          return (
            <div className="edit-sales-team-modal__select">
              {role === 'Owner' ? (
                <span>Owner</span>
              ) : (
                <KiteSelect
                  id={`${pid}-role`}
                  name={pid}
                  value={role}
                  onChange={handleRoleSelect}
                >
                  {roleOptions}
                </KiteSelect>
              )}
              {role !== 'Owner' && (
                <KiteButton
                  type="standalone-link"
                  minWidth="min-content"
                  maxWidth="min-content"
                  onClick={handleRemoveTeamMember(pid)}
                >
                  <KiteIcon name="trash" />
                </KiteButton>
              )}
            </div>
          );
        },
      },
    ];

    return { tableData, columns };
  }, [handleRemoveTeamMember, handleRoleSelect, salesTeam, teamMemberNames]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (canShow) {
      setSalesTeam(salesTeamData);
    }
  }, [canShow, salesTeamData]);

  useEffect(() => {
    if (debounceSearchValue) {
      trackSelectAction(`Edit Sales Team Search: ${debounceSearchValue}`, {
        opType: 'inputFieldUpdate',
      });
    }
  }, [debounceSearchValue, trackSelectAction]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="edit-sales-team-modal"
      canShow={canShow}
      onHide={handleToggleModal}
      title="Edit Sales Team"
      ctaCopy="Save"
      ctaAction={handleSave}
      secondaryCtaCopy="Cancel"
      secondaryCtaAction={handleToggleModal}
    >
      <div className="edit-sales-team-modal__search">
        <ActiveDirSuggestionInput
          showSuggestions={showSuggestions}
          toggleShowSuggestions={setShowSuggestions}
          isLoading={isLoading}
          error={error}
          suggestions={searchResults}
          onSelect={handleAddTeamMember}
          salesTeamPids={salesTeamPids}
          onSearch={onSearch}
          searchValue={searchValue}
        />
      </div>
      <SortableTable tableData={tableData} columns={columns} />
    </KiteModal>
  );
};

export default EditSalesTeamModal;
