// External Packages
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';

// Components
import { KiteLoaderOverlay } from '@kite/react-kite';
import { BundleInfoCard } from 'components';

// Hooks
import {
  useAnalytics,
  useGetBundles,
  useGetProductFamily,
  useSelections,
  useQueryDataContext,
  useScrollToTop,
} from 'hooks';

// Utils
import { filterPartnerLinkProducts } from 'utils';

// Types
import { IBundle, IBundleCardProduct, ILocationSelection } from 'types';

// Styles
import './Bundles.scss';

const Bundles = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView, trackSelectAction } = useAnalytics();
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1 });

  const { currentTerm, isPartnerLinkUser } = useQueryDataContext();
  const { data: allProductFamilies = [] } = useGetProductFamily();

  const { onBundleSelect, clearBundle } = useSelections();

  const {
    data: featuredBundles = [],
    error: featuredBundlesError,
    isLoading: featuredBundlesLoading,
  } = useGetBundles({
    params: {
      featured: true,
      published: true,
    },
  });

  const {
    data: moreBundles = [],
    error: moreBundlesError,
    isLoading: moreBundlesLoading,
  } = useGetBundles({
    params: {
      featured: false,
      published: true,
    },
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const [selectedBundle, setSelectedBundle] = useState<IBundle | null>(null);
  const [error, setError] = useState('');

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleApplyBundle = useCallback(
    (bundle: IBundle) => () => {
      const isSelected = !!(selectedBundle?.id === bundle.id);

      if (!isSelected) {
        // Add bundle products to current config
        onBundleSelect(bundle);
        setSelectedBundle(bundle);

        /** Construct a useful standard name for analytics */
        trackSelectAction(`Apply Bundle: ${bundle.name}`);
      } else {
        // Remove bundle products from current config
        clearBundle(bundle);
        setSelectedBundle(null);

        /** Construct a useful standard name for analytics */
        trackSelectAction(`Remove Bundle: ${bundle.name}`);
      }
    },
    [clearBundle, onBundleSelect, selectedBundle, trackSelectAction]
  );

  // =============================================
  // Render Methods
  // =============================================
  const generateBundleCards = useCallback(
    (bundles: IBundle[]) => {
      return bundles.map((bundle) => {
        if (!bundle.published) return null;

        const isSelected = selectedBundle
          ? selectedBundle.id === bundle.id
          : false;

        const getProductInfo = (selection: ILocationSelection) => {
          const {
            name,
            familyId,
            familyName,
            familyCategory: category,
            quantity,
          } = selection;
          const productFamily = allProductFamilies.find(
            (pf) => pf.id === familyId
          );
          const productName = `${familyName}: ${name}`;

          return {
            productName,
            quantity,
            category,
            subCategory: productFamily?.subcategory || false,
          };
        };

        const products = bundle.selections.reduce(
          (prods: IBundleCardProduct[], p) => {
            const productInfo = getProductInfo(p);
            prods.push({
              name: productInfo.productName,
              price:
                p.prices.find(
                  (price) => price.type === 'rate' && price.term === currentTerm
                )?.price || 0,
              quantity: p.quantity,
              category: productInfo.category,
              subCategory: productInfo.subCategory,
            });
            return prods;
          },
          []
        );

        if (!products.length) return null;
        return (
          <BundleInfoCard
            key={bundle.id}
            bundleName={bundle.name}
            products={products}
            isSelected={isSelected}
            onApplyBundle={handleApplyBundle(bundle)}
          />
        );
      });
    },
    [allProductFamilies, selectedBundle, currentTerm, handleApplyBundle]
  );

  const featuredBundlesCards = useMemo(() => {
    if (!featuredBundles.length) return null;
    const bundles = filterPartnerLinkProducts(
      featuredBundles,
      isPartnerLinkUser
    );
    return (
      <>
        <h3>Featured</h3>
        {generateBundleCards(bundles)}
      </>
    );
  }, [featuredBundles, isPartnerLinkUser, generateBundleCards]);

  const moreBundlesCards = useMemo(() => {
    if (!moreBundles.length) return null;
    const bundles = filterPartnerLinkProducts(moreBundles, isPartnerLinkUser);
    return (
      <>
        <h3>More Bundles</h3>
        {generateBundleCards(bundles)}
      </>
    );
  }, [moreBundles, isPartnerLinkUser, generateBundleCards]);

  // =============================================
  // Use Effect
  // =============================================
  useEffect(() => trackPageView('Bundles'), [trackPageView]);

  useEffect(() => {
    if (featuredBundlesError || moreBundlesError) {
      setError('Something went wrong retrieving bundle information.');
    }
  }, [featuredBundlesError, moreBundlesError, setError]);

  // =============================================
  // Early Return
  // =============================================
  if (featuredBundlesLoading || moreBundlesLoading) {
    return (
      <KiteLoaderOverlay
        size="large"
        loaderTitle="Loading Solution Pricing Tool"
      />
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} className="bundles">
      <h2>Grab N Go Bundles</h2>

      {featuredBundlesCards}
      {moreBundlesCards}
    </div>
  );
};

export default Bundles;
