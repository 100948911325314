import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IProfile } from 'types';
import { profileRequest, queryKeys, sandboxData } from 'utils';
import { useParams } from 'react-router-dom';

export const useUpdateProfiles = (config?: {
  onSuccess?: (profiles: IProfile[]) => void;
}) => {
  const { onSuccess } = config || {};
  const queryClient = useQueryClient();

  const { estimateId } = useParams();

  const isSandbox = estimateId === 'sandbox';

  const sandboxHandler = useCallback(async (profiles: Partial<IProfile>[]) => {
    const ids = profiles.map((p) => p?.id);
    sandboxData.profiles = [
      ...sandboxData.profiles.filter((p) => !ids.includes(p.id)),
      ...profiles,
    ] as IProfile[];
    return sandboxData.profiles;
  }, []);

  const queryKey = queryKeys().all.profiles;

  const { mutate: updateProfiles, isLoading: updateProfilesLoading } =
    useMutation(
      isSandbox
        ? sandboxHandler
        : (profiles: Partial<IProfile>[]) =>
            profileRequest({
              endpoint: 'product-profiles',
              method: 'PUT',
              data: profiles,
            }),
      {
        onMutate: async (profiles: Partial<IProfile>[]) => {
          await queryClient.cancelQueries(queryKey);
          const previousProfiles: Partial<IProfile>[] | undefined =
            queryClient.getQueryData(queryKey);

          const ids = new Set(profiles.map((p) => p.id));

          const newProfiles = profiles.map((p) => ({
            ...p,
            createdAt:
              previousProfiles?.find((pp) => pp.id === p.id)?.createdAt ||
              new Date(),
          }));

          queryClient.setQueryData(
            queryKey,
            previousProfiles
              ? [
                  ...previousProfiles.filter((p) => !ids.has(p.id)),
                  ...newProfiles,
                ]
              : [newProfiles]
          );

          return { previousProfiles };
        },

        onSettled: () => {
          queryClient.invalidateQueries(queryKey);
        },
        onSuccess: async (res: IProfile[]) => {
          if (res?.length) {
            await queryClient.cancelQueries(
              queryKeys({ scenarioId: res[0].scenarioId }).filter.profiles
            );
            await queryClient.invalidateQueries(
              queryKeys({ scenarioId: res[0].scenarioId }).filter.profiles
            );
          }
          if (onSuccess) {
            onSuccess(res);
          }
        },
      }
    );

  return { updateProfiles, updateProfilesLoading };
};
