import { ILocationSelection, IProduct } from 'types';

interface ISortLocationSelectionsParams {
  selections: ILocationSelection[];
  products: IProduct[];
}

export const sortLocationSelections = ({
  selections,
  products,
}: ISortLocationSelectionsParams) => {
  return selections.sort((a, b) => {
    const { productId: aId } = a;
    const { productId: bId } = b;
    const aOrder = products.find((p) => p.id === aId)?.order || 0;
    const bOrder = products.find((p) => p.id === bId)?.order || 0;

    return aOrder - bOrder;
  });
};
