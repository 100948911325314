import { IRule, IRequestConfig } from 'types';
import { queryKeys, rulesRequest } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetRules = (requestConfig: IRequestConfig<IRule> = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<IRule[]>({
    queryKey: params ? queryKeys(params).filter.rules : queryKeys().all.rules,
    apiCall: rulesRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
