import { IScenario } from 'types';
import { mockLocation } from './mockLocation';

export const mockScenario: { [key: string]: IScenario } = {
  '0e65b59d-7803-4504-9b9d-1af3e7660e87': {
    createdBy: 'P3059592',
    estimateId: '0e65b59d-7803-4504-9b9d-1af3e7660e84',
    primary: true,
    order: 1,
    id: '0e65b59d-7803-4504-9b9d-1af3e7660e87',
    name: 'Scenario 1',
    createdAt: '2022-04-08T19:15:52.091Z',
    updatedAt: '2022-04-08T19:15:52.091Z',
    term: '36',
    selections: [
      {
        locationId: 'sandboxLocation1',
        scenarioId: 'sandboxScenario1',
        productId: 'f8f0ee63-f08d-473b-bb77-0efad597aeec',
        familyName: 'DFI Speed',
        familyCategory: 'Data',
        familyId: '213be1ce-bf91-4a39-89ff-76742e2a72e7',
        name: '50M',
        prices: [],
        discretionValue36: 599,
        discretionValue48: 575,
        discretionValue60: 515,
        quantity: 1,
        filter: 'Interstate',
        discretionValue12: undefined,
        discretionValue24: undefined,
        discretionValue84: undefined,
        id: '2dd26a3f-6175-464a-988b-58e9b0a5a557',
      },
      {
        locationId: 'sandboxLocation1',
        scenarioId: 'sandboxScenario1',
        productId: 'd08a8404-7e94-4f2f-9cab-1887063ed258',
        familyName: 'Ethernet Speed',
        familyCategory: 'Data',
        familyId: '97d7de39-00db-48fd-a8b4-86a9b16271fa',
        name: '20M',
        prices: [],
        discretionValue36: 375,
        discretionValue48: 355,
        discretionValue60: 340,
        quantity: 1,
        filter: 'Interstate',
        discretionValue12: undefined,
        discretionValue24: undefined,
        discretionValue84: undefined,
        id: '740e8f56-b75d-4839-9368-0e7d962314e5',
      },
    ],
    locations: [{ id: Object.keys(mockLocation)[0] }],
  },
};
