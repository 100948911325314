import React, { FC } from 'react';
import { KiteButton } from '@kite/react-kite';

// Utils
import { truncate } from 'utils';

// Styles
import './BundleNameLinkButton.scss';

export interface IProps {
  name: string;
  onClick(): void;
}

const BundleNameLinkButton: FC<IProps> = ({ name, onClick }: IProps) => {
  return (
    <KiteButton
      buttonType="button"
      className="bundle-name-link-button"
      type="standalone-link"
      onClick={onClick}
    >
      {truncate(name, 40)}
    </KiteButton>
  );
};

export default BundleNameLinkButton;
