// parses salesforce address string and returns a readable object-- example string:
// "System.Address[getCity=Waxhaw;getCountry=null;getCountryCode=null;getGeocodeAccuracy=Address;getPostalCode=28173;getState=NC;getStateCode=null;getStreet=3901 Providence Rd S;]"

export const parseSfAddressData = (address: string) => {
  const array = address.substring(0, address.length - 2).split('[');
  const addressInfo = array[1].split(';');
  const arrayOfKeyValuePairs = addressInfo.map((entry) => entry.split('='));
  // format array keys into more readable form and consolidate into one address object
  const addressObj = arrayOfKeyValuePairs.reduce(
    (obj: { [key: string]: string }, [key, value]) => {
      const readableKey =
        key.substring(3).charAt(0).toLowerCase() + key.substring(4);
      return { ...obj, [readableKey]: value };
    },
    {}
  );
  return addressObj;
};
