import { IField, IFieldsRequest } from 'types';
import { fieldsRequest, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetFields = (requestConfig: IFieldsRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<IField[]>({
    queryKey: params ? queryKeys(params).filter.fields : queryKeys().all.fields,
    apiCall: fieldsRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
