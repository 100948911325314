import { ILocationSelection, IPromo } from 'types';

interface I3MonthPromoCheck {
  promo: IPromo;
  currentSelections: ILocationSelection[];
  selection?: ILocationSelection;
}

export const _3MonthsPromoCheck = ({
  promo,
  currentSelections,
  selection,
}: I3MonthPromoCheck) => {
  const dataProduct = [
    '213be1ce-bf91-4a39-89ff-76742e2a72e7', // DFI Speed
    '97d7de39-00db-48fd-a8b4-86a9b16271fa', // Ethernet Speed
  ];

  // DFI and Ethernet are valid combination for 3 month promo
  const hasDFIOrEthernet = dataProduct.filter((dp) =>
    currentSelections.some((cs) => dp === cs.familyId)
  );

  const promoProduct = currentSelections.filter((cs) => {
    const { includedFamilyIds, includedProductIds, excludedProductIds } = promo;
    if (excludedProductIds.includes(cs.productId)) {
      return false;
    }
    if (includedFamilyIds.includes(cs.familyId)) {
      // Promo only applies to UC Connect w/ Webex if
      // there are at least 5 seats configured
      if (cs.familyId === 'c886b29b-20f4-4244-9c29-2ccf415ce44b') {
        return !(cs.quantity < 5);
      }
      // WIA/WIB Only applicable if DFI or Ethernet are selected
      if (
        cs.familyId === '0ad66ef1-e855-4ad0-ad1e-8051a561ef5f' || // Wireless Internet
        cs.familyId === 'c07fe6b2-d148-43ff-ac0e-cc8df0db9f2f' // Wireless Backup
      ) {
        return hasDFIOrEthernet.length;
      }
      return true;
    }
    if (includedProductIds.includes(cs.productId)) {
      return true;
    }
    return false;
  });

  const productCategories = promoProduct.reduce(
    (acc: { [key: string]: number }, cs) => {
      if (acc[cs.familyCategory]) {
        acc[cs.familyCategory]++;
      }
      acc[cs.familyCategory] = 1;
      return acc;
    },
    {}
  );

  // hasDFIOrEthernet.length === 2 checks if both DFI & Ethernet selected
  if (selection) {
    return (
      (Object.values(productCategories).length >= 2 ||
        hasDFIOrEthernet.length === 2) &&
      promoProduct.some((cs) => selection.productId === cs.productId)
    );
  } else {
    return (
      Object.values(productCategories).length >= 2 ||
      hasDFIOrEthernet.length === 2
    );
  }
};
