// Packages
import React from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Redux

// Components
import { KiteButton, KiteIcon } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './NodeConnectorHandle.scss';

export interface INodeConnectorHandleProps {
  /** Callback for adding a new node (should open a modal w/ form) */
  onAddNode: () => void;
  disabled?: boolean;
}

/** Connector handle for MDF/IDF nodes (used by react flow to connect lines between nodes). Creates a new IDF connection. */

const NodeConnectorHandle = ({
  onAddNode,
  disabled = false,
}: INodeConnectorHandleProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <Handle
      className="node-connector-handle"
      type="source"
      position={Position.Bottom}
    >
      <KiteButton
        onClick={onAddNode}
        minWidth="min-content"
        maxWidth="min-content"
        disabled={disabled}
      >
        <KiteIcon name="plus" color="white" size="12px" />
      </KiteButton>
    </Handle>
  );
};

export default NodeConnectorHandle;
