import { ISfFailedProduct } from 'types';
import { mockFailedProducts } from 'utils';
import { IApiResponse } from './types';

export const salesforce = {
  post: {
    200: {
      message: 'success',
      data: {
        failed: mockFailedProducts,
      },
    },
    400: {
      message: 'failure',
    },
    500: {
      message: 'failure',
    },
  },
};

export const failedProducts: IApiResponse<ISfFailedProduct> = {
  get: {
    200: {
      message: 'success',
      total: mockFailedProducts.length,
      data: mockFailedProducts,
    },
  },
  post: {
    200: {
      message: 'success',
      data: mockFailedProducts[0],
    },
  },
  getOne: {},
  put: {},
};
