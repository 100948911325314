// Packages
import React from 'react';

// Components
import { ProgressBar as KiteProgressBar } from '@kite/react-kite-plus';

// Utils

// Types

// Styles
import './ProgressBar.scss';

export interface IProgressBarProps {
  className?: string;
  /** How far along a something is */
  percentage: number;
  /** Title to be displayed above bar */
  title: string;
}

/** Displays progress for UC questions */

const ProgressBar = ({
  className = '',
  percentage = 0,
  title = '',
}: IProgressBarProps) => {
  // =============================================
  // State/Refs
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className={`progress-bar ${className}`}>
      <span>{title}</span>
      <KiteProgressBar
        className="progress-bar__main"
        percentage={percentage}
        labelOrientation="bottom"
      />
    </div>
  );
};

export default ProgressBar;
