import axios from 'axios';
import {
  IRequestConfig,
  INewSelection,
  IRequestParams,
  IScenario,
} from 'types';
import { env } from './env';
import { throwQueryError, paramsSerializer } from 'utils';

interface IScenarioParams extends IRequestParams {
  estimateId?: string;
  locationId?: string;
}

interface IUpdatedScenario extends Omit<IScenario, 'selections'> {
  selections: INewSelection[];
}

export interface IScenarioRequest extends IRequestConfig<IScenario> {
  params?: IScenarioParams;
}

export const scenarioRequest = async (
  config?: IRequestConfig<IUpdatedScenario>
) => {
  const { endpoint = 'scenarios', method, data, params } = config || {};

  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with scenario ${method}`,
      })
    );
};
