// External Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Components
import { KiteSelect } from '@kite/react-kite';

// Hooks
import { useQueryData, useSelections } from 'hooks';
import { useDebouncedCallback } from 'use-debounce/lib';

// Utils
import {
  EipTermMonths,
  inFootprintIswId,
  outOfFootprintIswId,
  rcIswId,
  rcPhonesAndDevicesId,
} from 'utils';

// Types
import { TEipTermLengthMonths } from 'types';

// Styles
import './EipInput.scss';

export interface IEipInputProps {
  /** Determines is input is used for UC/RC */
  type?: 'UC' | 'RC';
}

const EipInput = ({ type = 'RC' }: IEipInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { locationId, currentEipTerm, currentSelections, currentTerm } =
    useQueryData();

  const { updateLocationSelectionEipTerm } = useSelections();

  const isTypeRC = type === 'RC';
  const label = isTypeRC
    ? 'Select EIP Term / Outright Purchase'
    : 'Select IP Term or One-time Payment';
  const defaultOption = isTypeRC ? 'Purchase' : 'One-time Payment';
  const finalEipTerm = useMemo(() => {
    return !isTypeRC && currentEipTerm === 'Purchase'
      ? 'One-time Payment'
      : currentEipTerm;
  }, [isTypeRC, currentEipTerm]);

  const isDisabled = useMemo(() => {
    if (isTypeRC) {
      const rcPhones = currentSelections.filter(
        (s) => s.familyId === rcPhonesAndDevicesId || s.productId === rcIswId
      );
      return rcPhones.length === 0;
    } else {
      const iswInstallations = currentSelections.filter(
        (s) =>
          s.productId === outOfFootprintIswId ||
          s.productId === inFootprintIswId
      );
      return iswInstallations.length === 0;
    }
  }, [currentSelections, isTypeRC]);

  const [inputValue, setInputValue] = useState({
    locationId,
    finalEipTerm,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const displayOptions = useMemo(() => {
    return Object.keys(EipTermMonths).map((value) => {
      const isValidOption =
        value === defaultOption ||
        (isTypeRC
          ? value <= currentTerm && value <= '36'
          : value <= currentTerm);
      if (isValidOption) {
        return (
          <option key={`EIP Term Option ${value}`} value={value}>
            {value}
          </option>
        );
      }
      return null;
    });
  }, [currentTerm, defaultOption, isTypeRC]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSave = useCallback(
    (eipTerm: TEipTermLengthMonths) => {
      updateLocationSelectionEipTerm(eipTerm);
    },
    [updateLocationSelectionEipTerm]
  );
  const debounceSave = useDebouncedCallback(onSave, 350);

  const handleDebounce = useCallback(
    (locId: string, eipTerm: TEipTermLengthMonths) => {
      if (locId === inputValue.locationId) {
        debounceSave(eipTerm);
      }
    },
    [debounceSave, inputValue.locationId]
  );

  const onTermChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value },
      } = e;
      const newTerm = value as unknown as TEipTermLengthMonths;
      setInputValue({ locationId: locationId, finalEipTerm: newTerm });
      handleDebounce(locationId, newTerm);
    },
    [handleDebounce, locationId]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (inputValue.locationId !== locationId) {
      debounceSave.cancel();
      setInputValue({ locationId: locationId, finalEipTerm: finalEipTerm });
    }
  }, [debounceSave, inputValue, locationId, finalEipTerm]);

  useEffect(() => {
    if (inputValue.finalEipTerm !== finalEipTerm && !debounceSave.isPending()) {
      setInputValue({ locationId, finalEipTerm });
    }
  }, [debounceSave, inputValue, locationId, finalEipTerm]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="term-input">
      <KiteSelect
        id="eipTermOptions"
        name="eipTermOptions"
        label={label}
        value={inputValue.finalEipTerm}
        onChange={onTermChange}
        inputProps={{
          'data-testid': 'Term',
        }}
        disabled={isDisabled}
      >
        {displayOptions}
      </KiteSelect>
    </div>
  );
};

export default EipInput;
