import { useCallback, useMemo } from 'react';
import { useGetFeatureFlagData } from './apiHooks';

const defaultFlagStates = {
  pqeFlag: true,
};

type TFeatureFlagMap = typeof defaultFlagStates;

const flagConversion: { [key: string]: keyof TFeatureFlagMap } = {
  'pqe-updates': 'pqeFlag',
};

export const useFeatureFlags = () => {
  const { data: allFeatureFlags, isLoading: featureFlagsLoading } =
    useGetFeatureFlagData();

  const getFeatureFlagValue = useCallback(
    (flagName: string) => {
      const flagByName = allFeatureFlags?.find(
        (flag) => flag.name === flagName
      );

      if (!flagByName) return true;
      return !!flagByName.active;
    },
    [allFeatureFlags]
  );

  const featureFlags = useMemo(() => {
    const flagNames = allFeatureFlags?.map((f) => f.name) || [];

    return flagNames.reduce((acc: TFeatureFlagMap, flagName) => {
      const status = getFeatureFlagValue(flagName);
      acc[flagConversion[flagName]] = status;

      return acc;
    }, defaultFlagStates);
  }, [allFeatureFlags, getFeatureFlagValue]);

  return { featureFlags, featureFlagsLoading };
};
