import { IBundle } from 'types';

export const filterPartnerLinkProducts = (
  bundles: IBundle[],
  isPartnerLink: boolean
) => {
  if (isPartnerLink) {
    return bundles.map((bundle) => {
      return {
        ...bundle,
        selections: bundle.selections.filter(
          (s) => s.familyCategory !== 'SBB 1-19 Pub/Priv'
        ),
      };
    });
  }
  return bundles;
};
