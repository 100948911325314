// External Packages
import React, { useCallback, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

// Components
import { KiteContextualMenu, KiteIcon } from '@kite/react-kite';
import Logo from './Logo';
import { Feedback } from 'components';

// Utils
import { deleteLogout } from 'utils';

// Redux
import { useSelector } from 'react-redux';

// Hooks
import { useFeatureFlags, useQueryData } from 'hooks';
import { headerLinkData } from 'redux/headerLinkDataSlice';
import { userNameData } from 'redux/userNameSlice';

//  Styles
import './Header.scss';

// =============================================
// Interfaces
// =============================================

// =============================================
// Component
// =============================================
const Header = () => {
  // =============================================
  // State/Hooks
  // =============================================
  // link data is dispatched in Keycloak/App component
  const { isPartnerLinkUser } = useQueryData();
  const headerMenuLinks = useSelector(headerLinkData);
  const userName = useSelector(userNameData);

  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const hideFeedback = useCallback(() => {
    setFeedbackOpen(false);
  }, []);

  const {
    featureFlags: { pqeFlag },
  } = useFeatureFlags();

  // =============================================
  // Interaction Handlers
  // =============================================
  const navigate = useNavigate();

  const handleItemClick = useCallback(
    async (item: typeof headerMenuLinks[number]) => {
      const { url } = item;
      if (url?.includes('/admin')) {
        navigate(url);
      } else if (url === 'Logout') {
        const logoutRes: boolean = await deleteLogout();

        if (!logoutRes) {
          window.location.reload();
        }
      } else if (url === 'feedback') {
        setFeedbackOpen(true);
      } else {
        window.open(url, '_blank');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, headerMenuLinks]
  );

  // =============================================
  // Return
  // =============================================
  return (
    <>
      <header className="header">
        {!isPartnerLinkUser ? (
          <NavLink
            to={
              pqeFlag && !isPartnerLinkUser
                ? '/dashboard'
                : 'estimate-builder/sandbox'
            }
            className="header__logo"
          >
            <Logo />
            <span className="header__name">Solution Pricing Tool</span>
          </NavLink>
        ) : (
          <div className="header__logo">
            <Logo />
          </div>
        )}
        {userName && (
          <KiteContextualMenu
            items={headerMenuLinks}
            onClick={handleItemClick as any}
            buttonContent={
              <>
                <span>{userName}</span>
                <KiteIcon name="more-down-f" size="12px" />
              </>
            }
          />
        )}
      </header>
      <Feedback isOpen={feedbackOpen} onHide={hideFeedback} />
    </>
  );
};

export default Header;
