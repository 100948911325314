// Packages
import React, { useCallback, useEffect, useRef, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';

// Hooks

// Components
import { KiteButton } from '@kite/react-kite';

// Utils
import { formatAddress } from 'utils';

// Types
import { IGoogleLocation, IDetailedAddress } from 'types';

// Styles
import './LocationAddressInput.scss';

interface ILocationAddressInputProps {
  address: IDetailedAddress;
  onAddressSelect: (address: IDetailedAddress) => void;
  addressError: string;
  clearAddress: () => void;
  label?: string;
}

const LocationAddressInput = ({
  address,
  onAddressSelect,
  addressError,
  clearAddress,
  label = 'Location Address',
}: ILocationAddressInputProps) => {
  const [addressInput, setAddressInput] = useState(formatAddress(address));

  const selectedAddress = formatAddress(address);

  const inputRefHeight = useRef<string>('auto');

  const [borderStyle, setBorderStyle] = useState('1px solid rgb(99, 115, 138)');

  const handleAddressChange = useCallback(
    async (results: IGoogleLocation) => {
      if (results) {
        const [place] = await geocodeByPlaceId(results.value.place_id);
        const addressComponent = place.address_components.find((c) =>
          c.types.includes('postal_code')
        );
        const { long_name: zipcode = '' } = addressComponent || {};

        const { main_text: address1, secondary_text } =
          results.value.structured_formatting;
        const [city, state] = secondary_text.split(', ');

        const detailedAddress: IDetailedAddress = {
          address1,
          address2: '',
          city,
          state: state.substring(0, 2),
          zipcode,
        };
        const newAddress = { ...address, ...detailedAddress };
        onAddressSelect(newAddress);
      }
    },
    [address, onAddressSelect]
  );

  const onInputChange = (inputValue: string, opts: { action: string }) => {
    // React Google Autocomplete uses React-Select as dependency
    // This solution was found on React-Select GH to fix input clearing on blur
    // Relevant React-Select docs: https://react-select.com/props#select-props
    if (opts.action === 'input-blur' && !!addressInput) {
      setAddressInput(addressInput);
    }

    if (opts.action === 'input-change') {
      setAddressInput(inputValue);
    }
  };

  // =============================================
  // Use Effect
  // =============================================
  useEffect(() => {
    // Sets address on location change
    setAddressInput(formatAddress(address));
  }, [address]);

  useEffect(() => {
    const newStyle = addressError
      ? '1px solid rgb(214, 49, 43)'
      : '1px solid rgb(99, 115, 138)';
    setBorderStyle(newStyle);
  }, [addressError]);

  // =============================================
  // Return
  // =============================================
  return selectedAddress ? (
    <div
      className="location-address-input__selection"
      style={{ height: inputRefHeight.current }}
    >
      <span>{selectedAddress}</span>
      <KiteButton
        onClick={clearAddress}
        type="outline"
        minWidth="min-content"
        maxWidth="min-content"
      >
        Clear
      </KiteButton>
    </div>
  ) : (
    <div
      className="location-address-input"
      ref={(element) =>
        (inputRefHeight.current =
          `${element?.clientHeight}px` || inputRefHeight.current)
      }
    >
      <label
        htmlFor="location-address"
        className="location-address-input__label"
      >
        {label}
      </label>
      <div aria-roledescription="input" id="location-address">
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          autocompletionRequest={{
            componentRestrictions: {
              country: 'us',
            },
          }}
          selectProps={{
            value: addressInput,
            inputValue: addressInput,
            onInputChange: onInputChange,
            onChange: handleAddressChange,
            isClearable: true,
            controlShouldRenderValue: false,
            placeholder: `${label}...`,
            styles: {
              control: (provided: any) => ({
                ...provided,
                borderRadius: '0',
                border: borderStyle,
                padding: '3px 0px',
              }),
              menu: (provided: any) => ({
                ...provided,
                textAlign: 'left',
              }),
            },
          }}
        />
        {addressError && (
          <p
            className="kite-form-control-feedback location-address-input__error"
            id="locationAddress-error"
          >
            {addressError}
          </p>
        )}
      </div>
    </div>
  );
};

export default LocationAddressInput;
