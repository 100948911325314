import { useMutation, useQueryClient } from 'react-query';
import { IEstimate } from 'types';
import { phoneNumbersRequest, queryKeys } from 'utils';

interface IDeletePhoneNumber {
  estimateId: string;
  locationId: string;
}

export const useDeletePhoneNumber = ({
  estimateId,
  locationId,
}: IDeletePhoneNumber) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys({ estimateId }).details.estimate;

  const { mutate: deletePhoneNumber, isLoading: deletePhoneNumberLoading } =
    useMutation(
      (phoneNumberId: string) =>
        phoneNumbersRequest({
          endpoint: `phone-numbers/${phoneNumberId}`,
          method: 'DELETE',
        }),
      {
        onMutate: async (phoneNumberId: string) => {
          await queryClient.cancelQueries(queryKey);

          const oldEstimate = queryClient.getQueryData<IEstimate>(queryKey);

          const location = oldEstimate?.locations.find(
            (l) => l.id === locationId
          );

          const filteredLocations =
            oldEstimate?.locations.filter((l) => l.id !== locationId) || [];
          const updatedNumbers = [
            ...(location?.phoneNumbers?.filter(
              (num) => num.id !== phoneNumberId
            ) || []),
          ];

          const updatedEstimate = {
            ...oldEstimate,
            locations: [
              ...filteredLocations,
              {
                ...location,
                phoneNumbers: updatedNumbers,
              },
            ],
          };

          queryClient.setQueryData(queryKey, updatedEstimate);
          return { oldEstimate };
        },
        onError: async (err, _, context) => {
          console.log(err);
          await queryClient.cancelQueries(queryKey);
          queryClient.setQueryData(queryKey, context?.oldEstimate);
        },
        onSettled: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }
    );

  return {
    deletePhoneNumber,
    deletePhoneNumberLoading,
  };
};
