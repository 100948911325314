import { IFeatureFlag } from 'types';

export const mockFeatureFlags: IFeatureFlag[] = [
  {
    active: true,
    name: 'mock-feature-flag',
    created_at: '',
    updated_at: '',
    description: 'For test purposes',
    version: 'new_version_flag',
    scopes: [],
    strategies: [
      {
        id: 298,
        name: 'default',
        parameters: {},
        scopes: [
          {
            id: 571,
            environment_scope: 'development',
          },
          {
            id: 572,
            environment_scope: 'stage',
          },
          {
            id: 765,
            environment_scope: 'production',
          },
        ],
      },
    ],
  },
];
