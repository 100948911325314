import { useMutation, useQueryClient } from 'react-query';
import { IEstimate, IPhoneNumberBody } from 'types';
import { phoneNumbersRequest, queryKeys } from 'utils';

export const usePostPhoneNumbers = (estimateId: string) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys({ estimateId }).details.estimate;

  const {
    mutate: postPhoneNumbers,
    isLoading: postPhoneNumbersLoading,
    error: postPhoneNumbersError,
    data: postPhoneNumbersRes,
  } = useMutation(
    (newPhoneNumbers: IPhoneNumberBody[]) =>
      phoneNumbersRequest({
        method: 'POST',
        data: newPhoneNumbers,
      }),
    {
      onMutate: async (newNumbers: IPhoneNumberBody[]) => {
        await queryClient.cancelQueries(queryKey);

        const oldEstimate = queryClient.getQueryData<IEstimate>(queryKey);

        const { locationId } = newNumbers[0];
        const location = oldEstimate?.locations.find(
          (l) => l.id === locationId
        );

        const filteredLocations =
          oldEstimate?.locations.filter((l) => l.id !== locationId) || [];
        const updatedNumbers = [...(location?.phoneNumbers || []), newNumbers];

        const updatedEstimate = {
          ...oldEstimate,
          locations: [
            ...filteredLocations,
            {
              ...location,
              phoneNumbers: updatedNumbers,
            },
          ],
        };

        queryClient.setQueryData(queryKey, updatedEstimate);
        return { oldEstimate };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(queryKey);
        queryClient.setQueryData(queryKey, context?.oldEstimate);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    postPhoneNumbers,
    postPhoneNumbersLoading,
    postPhoneNumbersError,
    postPhoneNumbersRes,
  };
};
