// Packages
import React, { useCallback, useMemo, useRef } from 'react';
import { useNodes } from 'react-flow-renderer';

// Redux

// Components
import { KiteButton, KiteCard } from '@kite/react-kite';
import { NodeLabel } from '..';

// Hooks

// Utils

// Types
import { IUCQDesignFlowNode } from 'types';

// Styles
import './DesignFlowNotes.scss';

export interface IDesignFlowNotesProps {
  /** Determines if notes section is open */
  isOpen: boolean;
  /** Toggles isOpen on/off */
  toggleNotes?: () => void;
}

/** Displays all distros with notes, sorted by nodeId */

const DesignFlowNotes = ({ isOpen, toggleNotes }: IDesignFlowNotesProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const nodes = useNodes<IUCQDesignFlowNode>();

  const notesRef = useRef<HTMLUListElement>(null);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const noteNodes = useMemo(() => nodes.filter((n) => n.data.notes), [nodes]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderNotes = useCallback(() => {
    return noteNodes.map((n) => {
      const {
        data: { nodeId, notes, isDemarc, nodeType },
      } = n;
      return (
        <li key={nodeId}>
          {nodeId && nodeType ? (
            <NodeLabel
              label={nodeType === 'idf' ? nodeId : nodeId.toUpperCase()}
              type={nodeType}
              className="design-flow-notes__label"
            />
          ) : (
            `${nodeId}: `
          )}
          {isDemarc && (
            <NodeLabel
              label="DEMARC"
              type="demarc"
              className="design-flow-notes__label"
            />
          )}
          <span
            style={{
              width: notesRef.current
                ? notesRef.current.clientWidth / 3
                : 'auto',
            }}
          >
            : {notes}
          </span>
        </li>
      );
    });
  }, [noteNodes]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Early Return
  // =============================================
  if (noteNodes.length === 0) {
    return null;
  }
  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="design-flow-notes">
      <div className="design-flow-notes__header">
        <span>Notes</span>
        {toggleNotes && (
          <KiteButton
            onClick={toggleNotes}
            type="standalone-link"
            minWidth="min-content"
            maxWidth="max-content"
          >
            {isOpen ? 'Close Notes' : 'Show Notes'}
          </KiteButton>
        )}
      </div>
      <div
        className="design-flow-notes__notes"
        style={{
          height: !isOpen ? 0 : notesRef.current?.clientHeight || 'auto',
          overflow: isOpen ? 'auto' : 'hidden',
        }}
      >
        <ul
          ref={notesRef}
          aria-hidden={!isOpen}
          style={{
            columnWidth: notesRef.current
              ? notesRef.current.clientWidth / 3
              : 'auto',
          }}
        >
          {renderNotes()}
        </ul>
      </div>
    </KiteCard>
  );
};

export default DesignFlowNotes;
