import React from 'react';
import { KiteIcon } from '@kite/react-kite';
import { UcQuestionIconsMap } from 'utils/defaultsAndConstants';
import { IForm } from 'types';

export const renderFormIcon = (iconKey: IForm['name'], size: string) => {
  if (UcQuestionIconsMap[iconKey]) {
    return <KiteIcon name={UcQuestionIconsMap[iconKey]} size={size} />;
  }
  return null;
};
