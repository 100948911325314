import { ResponseComposition, rest, RestContext } from 'msw';
import { env } from '../api/env';

import {
  bundles,
  estimates,
  failedProducts,
  featureFlags,
  fields,
  forms,
  locations,
  opportunities,
  productFamilies,
  productProfileCategories,
  productProfiles,
  products,
  rules,
  salesforce,
  scenarios,
  serviceCodes,
  submissionHistories,
  submissions,
} from './msw';
import { IApiResponse, TApiResponseMethod, TApiResult } from './msw/types';

const { apiUrl } = env;

const response = <T>(
  res: ResponseComposition,
  ctx: RestContext,
  contents: TApiResult<T> | { [key: string]: string } | null,
  status?: number
) => res(ctx.status(status || 200), ctx.delay(500), ctx.json(contents));

const setHandler = <T>(params: {
  handlerObject: IApiResponse<T>;
  path: string;
  method?: TApiResponseMethod;
  status?: number;
}) => {
  const { handlerObject, path, method = 'get', status = 200 } = params;

  let handler;
  switch (method) {
    case 'post':
      handler = rest.post(`${apiUrl}/${path}`, (_req, res, ctx) =>
        response(res, ctx, handlerObject[method][status], status)
      );
      break;

    case 'put':
      handler = rest.put(`${apiUrl}/${path}`, (_req, res, ctx) =>
        response(res, ctx, handlerObject[method][status])
      );
      break;

    default:
      handler = rest.get(`${apiUrl}/${path}`, (_req, res, ctx) =>
        response(res, ctx, handlerObject[method][status])
      );
      break;
  }
  return handler;
};

const handlers = [
  // Feature Flags
  setHandler({ handlerObject: featureFlags, path: 'feature-flags' }),
  // Bundles
  setHandler({ handlerObject: bundles, path: 'bundles' }),
  setHandler({
    handlerObject: bundles,
    path: 'bundles',
    method: 'post',
    status: 201,
  }),
  // Estimates
  setHandler({ handlerObject: estimates, path: 'estimates' }),
  setHandler({
    handlerObject: estimates,
    path: 'estimates/:estimateId',
    method: 'getOne',
  }),
  setHandler({
    handlerObject: estimates,
    path: 'estimates/:estimateId',
    method: 'put',
  }),
  // Scenarios
  setHandler({ handlerObject: scenarios, path: 'scenarios' }),
  // Locations
  setHandler({ handlerObject: locations, path: 'locations' }),
  setHandler({
    handlerObject: locations,
    path: 'locations/:locationId',
    method: 'getOne',
  }),
  // Opportunities
  rest.get(`${apiUrl}/salesforce/opportunities/test`, (_req, res, ctx) => {
    return response(res, ctx, opportunities.get[200]);
  }),

  // Products / Product Families / Profiles / Failed Products
  setHandler({ handlerObject: productFamilies, path: 'product-families' }),
  setHandler({ handlerObject: products, path: 'products' }),
  setHandler({ handlerObject: productProfiles, path: 'product-profiles' }),
  setHandler({
    handlerObject: productProfileCategories,
    path: 'product-profile-categories',
  }),
  setHandler({ handlerObject: failedProducts, path: 'failed-products' }),
  setHandler({
    handlerObject: failedProducts,
    path: 'failed-products',
    method: 'post',
  }),
  setHandler({ handlerObject: serviceCodes, path: 'service-codes' }),

  // Forms / Fields / Submissions
  setHandler({ handlerObject: forms, path: 'forms' }),
  setHandler({ handlerObject: fields, path: 'fields' }),
  setHandler({ handlerObject: submissions, path: 'submissions' }),
  setHandler({
    handlerObject: submissionHistories,
    path: 'submission-histories',
  }),
  setHandler({ handlerObject: rules, path: 'rules' }),

  // Salesforce
  rest.post(`${apiUrl}/salesforce/add-products`, (_req, res, ctx) => {
    return response(res, ctx, salesforce.post[200]);
    // return response(res, ctx, salesforce.post[400], 400);
    // return response(res, ctx, salesforce.post[500], 500);
  }),
  rest.get(
    'https://cdn.pi.spectrum.net/BundlePricingTool/Web/cfg_1.54.json',
    (_req, res, ctx) => {
      return res(ctx.status(200));
    }
  ),
  rest.get(`${apiUrl}/channel-partner/token`, (_req, res, ctx) => {
    return response(res, ctx, { accessToken: 'accessToken' });
  }),
  rest.get(`${apiUrl}/*`, (req, res, ctx) => {
    console.error(`Please add request handler for ${req.url.toString()}`);
    return res(
      ctx.status(500),
      ctx.json({ error: 'Please add request handler' })
    );
  }),
];

export default handlers;
