import {
  useGetProducts,
  useGetProductFamily,
  useGetServiceCode,
  // useGetEstimates,
  useGetProfileCategories,
  useGetPromos,
} from 'hooks';
import { useFeatureFlags } from './useFeatureFlags';

export const useGetInitialData = () => {
  const { data: productsQueryData, isLoading: productsIsLoading } =
    useGetProducts();
  const { data: productFamilyQueryData, isLoading: productFamilyIsLoading } =
    useGetProductFamily();
  const { data: serviceCodeQueryData, isLoading: serviceCodeIsLoading } =
    useGetServiceCode();
  // const { data: allEstimates, isLoading: allEstimatesLoading } =
  //   useGetEstimates({ params: { archived: false } });
  const { data: allProfileCategories, isLoading: profileCategoriesLoading } =
    useGetProfileCategories();
  const { featureFlags, featureFlagsLoading } = useFeatureFlags();
  const { data: allPromos, isLoading: promosLoading } = useGetPromos();

  const isLoading =
    productFamilyIsLoading ||
    productsIsLoading ||
    featureFlagsLoading ||
    serviceCodeIsLoading ||
    // allEstimatesLoading ||
    profileCategoriesLoading ||
    promosLoading;

  return {
    isLoading,
    featureFlags,
    productFamilyQueryData,
    productsQueryData,
    serviceCodeQueryData,
    // allEstimates,
    allProfileCategories,
    allPromos,
  };
};
