import { TProductList } from 'types';

export type ICustomProductConfig = {
  [Property in TProductList]: {
    rule: 'location' | 'scenario';
    dependentOption: {
      [DependentFamily: string]: {
        selectedProductName: string[];
        productFamilyName: string;
        inverse?: boolean;
      }[][];
    }[];
    in?: string[];
  };
};

export const disabledProductConfig: Partial<ICustomProductConfig> = {
  'RC Seats': {
    rule: 'scenario',
    dependentOption: [
      {
        Premium: [
          [
            {
              selectedProductName: ['Standard'],
              productFamilyName: 'RC Seats',
            },
          ],
        ],
        Standard: [
          [
            {
              selectedProductName: ['Premium'],
              productFamilyName: 'RC Seats',
            },
          ],
        ],
      },
    ],
  },
  'RC Additional NRC': {
    rule: 'location',
    dependentOption: [
      {
        'On Site Professional Install': [
          [
            {
              selectedProductName: [],
              productFamilyName: 'ENE Switches',
              inverse: true,
            },
            {
              selectedProductName: [],
              productFamilyName: 'RC Phones / Devices',
              inverse: true,
            },
          ],
          [
            {
              selectedProductName: [],
              productFamilyName: 'MNE Switches',
              inverse: true,
            },
            {
              selectedProductName: [],
              productFamilyName: 'RC Phones / Devices',
              inverse: true,
            },
          ],
        ],
        'Inside Wiring': [
          [
            {
              selectedProductName: [],
              productFamilyName: 'MNE Switches',
              inverse: true,
            },
          ],
          [
            {
              selectedProductName: [],
              productFamilyName: 'ENE Switches',
              inverse: true,
            },
          ],
        ],
      },
    ],
  },
  'Professional Services': {
    rule: 'location',
    dependentOption: [
      {
        'Inside Wiring Service (In Footprint Option)': [
          [
            {
              selectedProductName: [
                'Inside Wiring Service (Out of Footprint Option)',
              ],
              productFamilyName: 'Professional Services',
            },
          ],
        ],
        'Inside Wiring Service (Out of Footprint Option)': [
          [
            {
              selectedProductName: [
                'Inside Wiring Service (In Footprint Option)',
              ],
              productFamilyName: 'Professional Services',
            },
          ],
        ],
      },
    ],
  },
};
