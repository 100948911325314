// Packages
import React, { useCallback, useEffect, useRef } from 'react';

// Redux

// Components
import { CustomDropdown } from 'components';
import { KiteCard } from '@kite/react-kite';

// Hooks
import { useAnalytics, useQueryDataContext, useScrollToTop } from 'hooks';

// Utils

// Types

// Styles
import './TrunksPage.scss';

const TrunksPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { allProductFamilies } = useQueryDataContext();
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1 });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const generateComponents = useCallback(() => {
    const trunkProductFamilies = allProductFamilies.filter(
      (f) => f.category === 'Trunks'
    );
    return trunkProductFamilies.map((family) => {
      return (
        <CustomDropdown key={`${family.name} select`} productFamily={family} />
      );
    });
  }, [allProductFamilies]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('TrunksPage'), [trackPageView]);

  // =============================================
  // Early Return
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} className="trunks-page">
      <h3>Trunk Configuration Options</h3>
      <KiteCard>{generateComponents()}</KiteCard>
    </div>
  );
};

export default TrunksPage;
