// External Packages
import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';

// Utils
import { generateViewComponent } from 'utils';

// Types
import { TableValues } from 'types';

// Styles
import { styles } from './PdfDocumentStyles';

interface IPdfTotalsTable {
  tableTotals: TableValues<string>;
}

type Conversion = { [key: string]: string };

const headerConversion: Conversion = {
  rateMrc: 'Rate Card; MRC',
  rateNrc: 'Rate Card; NRC',
  solutionMrc: 'Solution; MRC',
  solutionNrc: 'Solution; NRC',
};

export const PdfTotalsTable = ({ tableTotals }: IPdfTotalsTable) => {
  const { totalHeaders, totalValues } = useMemo(() => {
    const totalHeaders: JSX.Element[] = [];
    const totalValues: JSX.Element[] = [];

    Object.keys(tableTotals).forEach((head, i) => {
      const style = i ? '' : 'First';
      totalHeaders.push(
        generateViewComponent(
          headerConversion[head],
          `totalCellHeader${style}`,
          `total header ${head}`
        )
      );
      totalValues.push(
        generateViewComponent(
          tableTotals[head as keyof TableValues<string>],
          `totalTableCell${style}`,
          `total value ${head}`
        )
      );
    });
    return { totalHeaders, totalValues };
  }, [tableTotals]);

  return (
    <View wrap={false} style={styles.totalWrapper}>
      <View>
        <Text style={styles.totalTextWrapper}>Totals:</Text>
      </View>

      <View>
        <View style={styles.productRow}>{totalHeaders}</View>

        <View style={styles.productRow}>{totalValues}</View>
      </View>
    </View>
  );
};
