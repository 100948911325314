// External Packages
import React, { useCallback, useMemo } from 'react';

// Components
import { KiteIcon } from '@kite/react-kite';

// Utils
import { formatAddress, truncate, ucSeatsProductFamilies } from 'utils';

// Hooks
import { useQueryData } from 'hooks';

// Types
import { ILocationBase, TProductList } from 'types';

// Styles
import './LocationCard.scss';

// =============================================
// Interfaces
// =============================================
interface IProps {
  locationData: ILocationBase;
  setLocationData: (location: ILocationBase) => void;
  setShowDeleteModal: (show: boolean) => void;
  onCopyLocation: (id: string) => void;
}

const LocationCard = ({
  locationData,
  setLocationData,
  setShowDeleteModal,
  onCopyLocation,
}: IProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { currentScenario } = useQueryData();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const seatsCount = useMemo(() => {
    return currentScenario?.selections.reduce((acc, curr) => {
      const familyName = curr.familyName as TProductList;
      if (
        curr.locationId === locationData.id &&
        ucSeatsProductFamilies.includes(familyName)
      ) {
        acc += curr.quantity;
      }
      return acc;
    }, 0);
  }, [currentScenario, locationData.id]);

  const formattedAddress = useMemo(() => {
    const { address1, address2, city, state, zipcode } = locationData;
    return formatAddress({
      address1,
      address2,
      city,
      state,
      zipcode,
    });
  }, [locationData]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onEditClick = useCallback(() => {
    setLocationData(locationData);
  }, [locationData, setLocationData]);

  const onDeleteClick = useCallback(() => {
    onEditClick();
    setShowDeleteModal(true);
  }, [setShowDeleteModal, onEditClick]);

  const onCopyClick = useCallback(() => {
    onCopyLocation(locationData.id);
  }, [locationData, onCopyLocation]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="location-card">
      {/* <KiteIcon name="menu" size="16px" className="location-card__drag-icon" /> */}
      <div className="location-card__info-wrapper">
        <div className="location-card__info-container">
          <p className="location-card__name">
            {truncate(locationData.name, 35)}
          </p>
          <p className="location-card__seats">{`${seatsCount} Seats`}</p>
        </div>

        <p className="location-card__address">
          {truncate(formattedAddress, 50)}
        </p>
      </div>

      <div className="location-card__input-icons-wrapper">
        <KiteIcon
          name="edit-f"
          size="16px"
          margin="0"
          className="location-card__info-icon"
          onClick={onEditClick}
        />
        <KiteIcon
          name="document"
          size="16px"
          margin="0"
          className="location-card__info-icon"
          onClick={onCopyClick}
        />
        <KiteIcon
          name="trash-f"
          size="16px"
          margin="0"
          className="location-card__info-icon"
          onClick={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default LocationCard;
