// Packages
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

// Components
import { KiteCheckbox } from '@kite/react-kite';

// Hooks
import {
  useDebounceInputSave,
  useQueryDataContext,
  useUpdateFocus,
} from 'hooks';

// Utils
import { NONE, sbbAddonNotes, sbbAddonNotesConfiguration } from 'utils';

// Types
import {
  IProductFamily,
  TSbbLocationOptions,
  TSbbAddonNotesStars,
  ISelectionController,
  IProduct,
  ILocationSelection,
} from 'types';

// Styles
import './CustomCheckboxes.scss';

export interface ICustomCheckboxesProps extends ISelectionController {
  /** Product family you want the checkboxes to build from */
  productFamily: IProductFamily;
  /** Determines which addons will be displayed */
  addonType: TSbbLocationOptions;
}

interface ICheckboxSelections {
  [productId: IProduct['id']]: ILocationSelection['id'] | '';
}

/** List of Checkboxes generated based on a Product Family */

const CustomCheckboxes = ({
  productFamily,
  addonType,
  onChange,
}: ICustomCheckboxesProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { updateFocus } = useUpdateFocus();
  const { currentSelections, scenarioId: currentScenarioId } =
    useQueryDataContext();

  const reduceSelections = useCallback(
    (
      products: IProduct[],
      selections: ILocationSelection[]
    ): ICheckboxSelections =>
      products.reduce((acc: { [productId: string]: string }, product) => {
        if (product.notes.includes(addonType)) {
          const selectionId =
            selections.find((s) => s.productId === product.id)?.id || '';
          acc[product.id] = selectionId;
        }
        return acc;
      }, {}),
    [addonType]
  );

  const checkboxSelections = useMemo(
    () => reduceSelections(productFamily.products, currentSelections),
    [currentSelections, productFamily.products, reduceSelections]
  );

  const { saveSelection, debounceSave } = useDebounceInputSave({ onChange });

  const [inputValues, setInputValues] = useState({
    scenarioId: currentScenarioId,
    values: checkboxSelections,
  });

  const hasDiff = useMemo(() => {
    const keys = Object.keys(checkboxSelections);
    return !keys.every(
      (key) => inputValues.values[key] === checkboxSelections[key]
    );
  }, [checkboxSelections, inputValues.values]);

  const [addonNotesDisplay, setAddonNotesDisplay] = useState<{
    [Property in TSbbAddonNotesStars]: boolean;
  }>({
    '*': false,
    '**': false,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const addonNotes = useMemo(() => {
    const notes = Object.entries(addonNotesDisplay).reduce(
      (acc: JSX.Element[], [key, display]) => {
        const noteKey = key as TSbbAddonNotesStars;

        if (display) {
          sbbAddonNotes[noteKey].forEach((note, i) => {
            acc.push(<li key={`note ${key} ${i}`}>{note}</li>);
          });
        }
        return acc;
      },
      []
    );
    return <ul className="custom-checkboxes__notes">{notes}</ul>;
  }, [addonNotesDisplay]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSave = useCallback(
    (params: {
      scenarioId: string;
      selectionId: string;
      productId: string;
      isDebounce?: boolean;
    }) => {
      const { scenarioId, selectionId, productId, isDebounce } = params;
      isDebounce
        ? debounceSave({
            selectionId,
            productId,
            selectionValues: { quantity: 1 },
            opType: 'toggleFlip',
            scenarioId,
          })
        : saveSelection({
            selectionId,
            productId,
            selectionValues: { quantity: 1 },
            opType: 'toggleFlip',
          });
    },
    [debounceSave, saveSelection]
  );

  const handleCheckboxSelection = useCallback(
    (selectionId: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const { id: productId, checked } = e.target;
      let newSelectionId = selectionId;

      if (!selectionId) {
        newSelectionId = `newSelection${productId}`;
      }

      onSave({
        scenarioId: currentScenarioId,
        selectionId: checkboxSelections[productId],
        productId: checked ? productId : NONE,
        isDebounce: false,
      });

      setInputValues({
        scenarioId: currentScenarioId,
        values: {
          ...inputValues.values,
          [productId]: checked ? newSelectionId : '',
        },
      });
    },
    [checkboxSelections, currentScenarioId, inputValues.values, onSave]
  );

  // =============================================
  // Render Methods
  // =============================================
  const generateCheckboxes = useCallback(() => {
    return Object.entries(inputValues.values).reduce(
      (acc: JSX.Element[], [productId, selectionId]) => {
        const product = productFamily.products.find((p) => p.id === productId);
        const isChecked = !!selectionId;
        const ratePrice = product?.prices.find((p) => p.type === 'rate')?.price;
        const noteStar =
          product?.name && sbbAddonNotesConfiguration[addonType][product?.name];

        if (noteStar && !addonNotesDisplay[noteStar]) {
          setAddonNotesDisplay({
            ...addonNotesDisplay,
            [noteStar]: true,
          });
        }

        const label = `${product?.name} | $${ratePrice} ${noteStar || ''}`;

        acc.push(
          <KiteCheckbox
            key={label}
            id={productId}
            name={label}
            label={label}
            checked={isChecked}
            onChange={handleCheckboxSelection(selectionId)}
            inputProps={{
              onFocus: () => updateFocus([productFamily.name]),
            }}
          />
        );
        return acc;
      },
      []
    );
  }, [
    productFamily.products,
    productFamily.name,
    addonType,
    inputValues.values,
    addonNotesDisplay,
    handleCheckboxSelection,
    updateFocus,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (
      inputValues.scenarioId !== currentScenarioId ||
      (hasDiff && !debounceSave.isPending())
    ) {
      setInputValues({
        scenarioId: currentScenarioId,
        values: checkboxSelections,
      });
    }
  }, [
    checkboxSelections,
    currentScenarioId,
    debounceSave,
    hasDiff,
    inputValues.scenarioId,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="custom-checkboxes">
      {generateCheckboxes()}
      {addonNotes}
    </div>
  );
};

export default CustomCheckboxes;
