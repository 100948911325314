// Packages
import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';
import { LocationTotalBadge, PriceTotalBadge } from 'components';

// Hooks
import { useGenerateProductTotals, useQueryData } from 'hooks';

// Utils

// Types
import { IScenario, TableValues } from 'types';

// Styles
import './ScenarioTab.scss';

export interface IScenarioTabProps {
  /** Scenario data for the specific tab (leave empty if is add tab) */
  scenarioData: IScenario;
  /** Toggles visibility state for scenario modal */
  toggleScenarioModal: (params: {
    id: string;
    modalType: 'edit' | 'new';
  }) => void;
}

/** Renders a tab to switch between scenario being displayed */

const ScenarioTab = ({
  scenarioData,
  toggleScenarioModal,
}: IScenarioTabProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    scenarioId,
    updateLocationId,
    updateScenarioId,
    updateScenarioTabs,
    locationId: currentLocationId,
    scenarioTabLocationIds,
  } = useQueryData();

  const { generateProductTotals } = useGenerateProductTotals();

  const scenarioLocationIds = useMemo(
    () => new Set(scenarioData.locations?.map((l) => l.id) || []),
    [scenarioData.locations]
  );

  const firstLocationId: string = scenarioLocationIds.values().next().value;

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const scenarioTotals = useMemo(() => {
    const { term, selections, id: scenarioId } = scenarioData;
    return generateProductTotals({
      term,
      selections,
      scenarioId,
    }) as TableValues<string>;
  }, [scenarioData, generateProductTotals]);

  const isActive = useMemo(() => {
    return scenarioId === scenarioData.id;
  }, [scenarioId, scenarioData]);

  /** Gets most recent location selected on tab from redux */
  const recentLocationId = scenarioTabLocationIds[scenarioData.id];
  /** Fallback locationId in case no recent is found */
  const fallbackLocationId = scenarioLocationIds.has(currentLocationId)
    ? currentLocationId
    : firstLocationId;

  // =============================================
  // Interaction Handlers
  // =============================================
  const editScenario = useCallback(() => {
    toggleScenarioModal({ id: scenarioData.id, modalType: 'edit' });
  }, [toggleScenarioModal, scenarioData.id]);

  /** Updates the current scenarioId and sets the locationId to the most recently selected in redux */
  const handleTabClick = useCallback(() => {
    const locationId = recentLocationId || fallbackLocationId;
    updateScenarioId(scenarioData.id);
    updateScenarioTabs(scenarioData.id, locationId);
    updateLocationId(locationId);
  }, [
    fallbackLocationId,
    recentLocationId,
    scenarioData.id,
    updateLocationId,
    updateScenarioId,
    updateScenarioTabs,
  ]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (!scenarioTabLocationIds[scenarioData.id]) {
      updateScenarioTabs(scenarioData.id, fallbackLocationId);
    }
  }, [
    fallbackLocationId,
    scenarioData.id,
    scenarioTabLocationIds,
    updateScenarioTabs,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={classNames({
        'scenario-tab': true,
        'scenario-tab--active': isActive,
      })}
      onClick={handleTabClick}
      onKeyDown={handleTabClick}
      role="button"
      aria-roledescription="Select Scenario"
      tabIndex={-1}
    >
      <div className="scenario-tab__content">
        <span>{scenarioData.name}</span>

        <KiteIcon
          className="scenario-tab__edit-btn"
          ariaLabel="Edit Scenario Button"
          name="edit"
          onClick={editScenario}
        />

        <PriceTotalBadge priceTotal={scenarioTotals.solutionMrc} />

        <LocationTotalBadge
          locationTotal={scenarioData.locations?.length || 0}
        />
      </div>
    </div>
  );
};

export default ScenarioTab;
