import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

// Components
import { PricingTableHead, PricingTableRow } from 'components';
import { KiteButton, KiteCard, KiteIcon } from '@kite/react-kite';

// Hooks
import { useQueryData, useSelections } from 'hooks';

// Utils
import { ProductCategoriesMap, _3MonthsPromoCheck } from 'utils';

// Types
import { IProductFamily, IPromo } from 'types';

// Styles
import './PricingTable.scss';

interface IPricingTable {
  /** Determines whether table is expanded or collapsed */
  isExpanded?: boolean;
  /** Function for toggling table expansion */
  onToggleExpand?: () => void;
  /** Determines whether banner is togglable or not */
  disableBanner?: boolean;
}

export const PricingTable = ({
  isExpanded = true,
  onToggleExpand = () => null,
  disableBanner,
}: IPricingTable) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    allProductFamilies,
    currentSelections,
    currentTerm,
    currentScenario,
    locationId,
    allPromos,
  } = useQueryData();
  const { removeDisabledPromos } = useSelections();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  useMemo(() => {
    return currentScenario?.promos?.length ? removeDisabledPromos() : false;
  }, [currentScenario, removeDisabledPromos]);

  const appliedPromos = useMemo(() => {
    return (
      currentScenario?.promos?.reduce((acc: IPromo[], sp) => {
        const promo = allPromos.find((p) => sp.promoId === p.id);
        if (promo) {
          if (promo.isLocationSpecific) {
            sp.locationId === locationId && acc.push(promo);
          } else {
            acc.push(promo);
          }
        }
        return acc;
      }, []) || []
    );
  }, [currentScenario, locationId, allPromos]);

  const promoText = useMemo(() => {
    const applicablePromo = appliedPromos.filter((p) =>
      currentSelections.some((cs) => {
        if (p.id === 'd4cf26a3-5785-427a-905a-7705dc00df6b') {
          return _3MonthsPromoCheck({ promo: p, currentSelections });
        }
        const { includedFamilyIds, includedProductIds, excludedProductIds } = p;
        if (excludedProductIds.includes(cs.productId)) {
          return false;
        }
        if (includedFamilyIds.includes(cs.familyId)) {
          // Promo only applies to UC Connect w/ Webex if
          // there are at least 5 seats configured
          if (cs.familyId === 'c886b29b-20f4-4244-9c29-2ccf415ce44b') {
            return !(cs.quantity < 5);
          }
          return true;
        }
        if (includedProductIds.includes(cs.productId)) {
          return true;
        }
        return false;
      })
    );
    return applicablePromo.length ? (
      <div style={{ textAlign: 'center' }}>
        {`* = Promotion(s) Applied: ${applicablePromo
          .map((p) => p.name)
          .join(', ')}`}
      </div>
    ) : (
      ''
    );
  }, [appliedPromos, currentSelections]);

  const tableData = useMemo(() => {
    if (!currentSelections.length) return {};

    return currentSelections.reduce(
      (acc: { [key: string]: JSX.Element[] }, selection, i) => {
        const { profileId, familyCategory, familyName, familyId, productId } =
          selection;

        if (
          !familyCategory ||
          !familyName ||
          !familyId ||
          !productId ||
          !allProductFamilies.find((f) => f.id === familyId)
        ) {
          return acc;
        }

        if (profileId && !acc[profileId]) {
          acc[profileId] = [];
        }

        if (!profileId && !acc[familyCategory]) {
          acc[familyCategory] = [];
        }

        if (profileId) {
          acc[profileId].push(
            <PricingTableRow
              key={`pricing table row ${familyName} ${productId}`}
              selection={selection}
              isSubRow={true}
              appliedPromos={appliedPromos}
            />
          );
        }

        if (acc[familyCategory] && !profileId) {
          const prodFamily = allProductFamilies.find((f) => f.id === familyId);

          const isSubRow = !!prodFamily?.subcategory;

          if (isSubRow) {
            const isFamilyPreviouslyRendered = currentSelections
              .slice(0, i)
              .map((p) => p.familyId)
              .includes(familyId);

            !isFamilyPreviouslyRendered &&
              acc[familyCategory].push(
                <PricingTableHead
                  key={`pricing table head ${prodFamily.name} ${productId}`}
                  category={familyCategory as IProductFamily['category']}
                  subCategory={familyName}
                />
              );
          }

          acc[familyCategory].push(
            <PricingTableRow
              key={`pricing table row ${familyName} ${productId}`}
              selection={selection}
              isSubRow={isSubRow}
              appliedPromos={appliedPromos}
            />
          );
        }

        return acc;
      },
      {}
    );
  }, [allProductFamilies, currentSelections, appliedPromos]);

  const banner = useMemo(() => {
    const expandIconClass = isExpanded
      ? 'chevron chevron--expanded'
      : 'chevron';

    return !disableBanner ? (
      <KiteButton
        className={classNames({
          'pricing-table__banner': true,
          'pricing-table__banner--expanded': isExpanded,
        })}
        onClick={onToggleExpand}
        minWidth="auto"
        maxWidth="none"
        type="standalone-link"
        aria-expanded={isExpanded}
        aria-controls="pricing-information"
        aria-label="expand pricing table information"
      >
        <span>Breakdown - Term: {currentTerm} mos.</span>
        <KiteIcon className={expandIconClass} name="chevron-right" />
      </KiteButton>
    ) : (
      <span
        className={classNames({
          'pricing-table__banner': true,
          'pricing-table__banner--text': true,
        })}
      >
        Breakdown - Term: {currentTerm} mos.
      </span>
    );
  }, [isExpanded, onToggleExpand, disableBanner, currentTerm]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const generateTableData = useCallback(() => {
    const tableItems = Object.entries(tableData).map(
      ([category, selections]) => {
        if (selections.length) {
          const isProfile =
            !Object.keys(ProductCategoriesMap).includes(category);

          return (
            <table
              key={`${category} table`}
              className={`pricing-table__table pricing-table__table--${
                isProfile ? 'UC Profile' : category
              }`}
            >
              <thead>
                <PricingTableHead
                  key={`${category} head`}
                  category={
                    isProfile
                      ? 'UC Products'
                      : (category as IProductFamily['category'])
                  }
                  profileId={isProfile ? category : undefined}
                />
              </thead>
              <tbody>{selections}</tbody>
            </table>
          );
        }
        return null;
      }
    );

    return <div>{tableItems}</div>;
  }, [tableData]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="pricing-table">
      {banner}
      <div className="pricing-table__table-group">{generateTableData()}</div>
      {appliedPromos?.length !== 0 && promoText}
    </KiteCard>
  );
};
