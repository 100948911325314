// External Packages
import React, { useEffect, useRef } from 'react';

// Components
import { NetworkEdgeContainer } from 'components';

// Utils

// Hooks
import { useAnalytics, useScrollToTop } from 'hooks';

// Types

// Styles
import './EnePage.scss';

// =============================================
// Component
// =============================================
const EnePage = () => {
  // =============================================
  // State/Refs
  // =============================================
  const { trackPageView } = useAnalytics();
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1 });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('ENE'), [trackPageView]);

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} data-testid="EnePage" className="ene-page">
      <h3>Enterprise Network Edge (ENE) Configuration</h3>
      <NetworkEdgeContainer className="ene-page" networkCategory="ENE" />
    </div>
  );
};

export default EnePage;
