// Packages
import React, { ChangeEvent, useCallback, useState } from 'react';

// Components
import { KiteInput, KiteModal, KiteSelect } from '@kite/react-kite';

// Utils
import {
  checkFormErrors,
  capitalizeFirstLetter,
  stateIsoCodes,
  removeEmojis,
  addressInputDetails,
} from 'utils';

// Types
import { IDetailedAddress } from 'types';

// Styles
import './CustomAddressForm.scss';

type FormInputs = {
  value: string;
  label: string;
}[];

interface ICustomAddressFormProps {
  locationName: string;
  nameError: string;
  checkNameError: () => boolean;
  handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  detailedAddress: IDetailedAddress;
  setDetailedAddress: (input: IDetailedAddress) => void;
  addressModalOpen: boolean;
  setAddressModalOpen: (input: boolean) => void;
  onSave: (address: IDetailedAddress) => void;
}

const CustomAddressForm = ({
  locationName,
  nameError,
  checkNameError,
  handleNameChange,
  detailedAddress,
  setDetailedAddress,
  addressModalOpen,
  setAddressModalOpen,
  onSave,
}: ICustomAddressFormProps) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const initialErrorState: IDetailedAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
  };
  const [formErrors, setFormErrors] = useState(initialErrorState);

  // =============================================
  // Interaction Handlers
  // =============================================
  const capitalizeEachLetter = useCallback((value: string) => {
    const pieces = value.split(' ');
    const capitalized = pieces.map((piece) => capitalizeFirstLetter(piece));

    return capitalized.join(' ');
  }, []);

  const formatAddress = useCallback(() => {
    const address1 = capitalizeEachLetter(detailedAddress.address1);
    const address2 = detailedAddress.address2
      ? capitalizeEachLetter(detailedAddress.address2)
      : '';
    const city = capitalizeEachLetter(detailedAddress.city);
    const state = capitalizeFirstLetter(detailedAddress.state);

    const formattedAddress: IDetailedAddress = {
      address1,
      address2,
      city,
      state,
      zipcode: detailedAddress.zipcode || '',
    };
    return formattedAddress;
  }, [capitalizeEachLetter, detailedAddress]);

  const submitAddressForm = useCallback(() => {
    const { hasErrors, errorObject } = checkFormErrors(
      detailedAddress,
      ['address1', 'city', 'state', 'zipcode'],
      { state: 'select' }
    );
    const errorCheck = checkNameError();

    if (hasErrors || errorCheck) {
      setFormErrors(errorObject as IDetailedAddress);
    } else {
      const address = formatAddress();

      setDetailedAddress(address);
      onSave(address);
    }
  }, [
    detailedAddress,
    checkNameError,
    formatAddress,
    onSave,
    setDetailedAddress,
  ]);

  const clearErrorMessage = useCallback(
    (selector: string) => {
      setFormErrors({
        ...formErrors,
        [selector]: '',
      });
    },
    [formErrors]
  );

  const handleAddressChange = useCallback(
    (selector: string) =>
      (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value } = e.target;
        const valueWithoutEmoji = removeEmojis(value);
        setDetailedAddress({
          ...detailedAddress,
          [selector]: value,
        });

        if (valueWithoutEmoji) {
          clearErrorMessage(selector);
        }
      },
    [detailedAddress, setDetailedAddress, clearErrorMessage]
  );

  // =============================================
  // Render Methods
  // =============================================
  const generateInputs = useCallback(
    (values: FormInputs) => {
      const inputs = values.map((input) => {
        const addressProp = input.value as keyof IDetailedAddress;

        if (input.label === 'State') {
          const stateOptions = stateIsoCodes.map((code) => {
            return (
              <option value={code} key={`US-${code}`}>
                {code}
              </option>
            );
          });
          return (
            <KiteSelect
              key={`address-form-${addressProp}`}
              id="us-states"
              name="us-states"
              label="State"
              className="custom-address-form__select"
              value={detailedAddress.state}
              errorMessage={formErrors[addressProp]}
              placeholder="Select State"
              onChange={handleAddressChange(addressProp)}
            >
              {stateOptions}
            </KiteSelect>
          );
        }

        return (
          <KiteInput
            key={`address-form-${addressProp}`}
            className="custom-address-form__input"
            id={input.value}
            label={input.label}
            value={detailedAddress[addressProp]}
            errorMessage={formErrors[addressProp]}
            maxWidth="800px"
            onChange={handleAddressChange(addressProp)}
          />
        );
      });
      return inputs;
    },
    [detailedAddress, formErrors, handleAddressChange]
  );

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="custom-address-form"
      canShow={addressModalOpen}
      onHide={() => setAddressModalOpen(false)}
      title="Custom Address Form"
      ctaCopy="Save Location"
      ctaAction={submitAddressForm}
      secondaryCtaCopy="Cancel"
      secondaryCtaAction={() => setAddressModalOpen(false)}
    >
      <form>
        <KiteInput
          id="location name"
          className="location-form__input"
          label="Location Name"
          value={locationName}
          onChange={handleNameChange}
          maxWidth="none"
          errorMessage={nameError}
        />

        <div className="custom-address-form__input-container">
          {generateInputs(addressInputDetails.slice(0, 2))}
        </div>

        <div className="custom-address-form__input-container">
          {generateInputs(
            addressInputDetails.slice(2, addressInputDetails.length)
          )}
        </div>
      </form>
    </KiteModal>
  );
};

export default CustomAddressForm;
