// Packages
import React, { useEffect, useMemo } from 'react';

// Redux

// Components
import { KiteLoader } from '@kite/react-kite';
import { UCQLayout } from 'components/pageLayout';

// Hooks
import { useAnalytics, useGetEstimateDetails, useGetForms } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { UCQFORMS } from 'utils';

// Types
import { IFormsRequest } from 'types';

// Styles
import './UcQuestionsPage.scss';

const UcQuestionsPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { estimateId, locationId } = useParams();
  const {
    estimateData,
    estimateLoading,
    error: estimateError,
  } = useGetEstimateDetails(estimateId);

  const formsQueryParams: IFormsRequest['params'] = useMemo(() => {
    if (locationId === 'general') {
      return { location: 0, type: UCQFORMS };
    }
    return { location: 1, type: UCQFORMS };
  }, [locationId]);

  const {
    data: formsData,
    isLoading: formsLoading,
    error: formsError,
  } = useGetForms({
    params: formsQueryParams,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const isError = !!formsError || !!estimateError;

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('UcQuestionsPage'), [trackPageView]);

  // =============================================
  // Early Return
  // =============================================
  if (estimateLoading && formsLoading) {
    return <KiteLoader className="uc-questions-page__loader" />;
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <UCQLayout
      forms={formsData}
      estimate={estimateData}
      formsLoading={formsLoading}
    />
  );
};

export default UcQuestionsPage;
