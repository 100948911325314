// Packages
import React, { ChangeEvent, useCallback } from 'react';

// Redux

// Components
import { KiteAlert, KiteButton, KiteInput } from '@kite/react-kite';
import { AutosaveBadge } from 'components';

// Utils

// Hooks

// Types

// Styles
import './BundleDataBar.scss';

export interface IBundleDataBarProps {
  handleSave: () => void;
  handleBack: () => void;
  onNameChange: (value: string) => void;
  bundleName: string;
  errorMessage: string;
  hasChanges: boolean;
  isSaved: boolean;
  alert: string;
  closeAlert: () => void;
}

const BundleDataBar = ({
  handleSave,
  handleBack,
  onNameChange,
  errorMessage,
  bundleName = '',
  hasChanges,
  isSaved,
  alert,
  closeAlert,
}: IBundleDataBarProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onNameChange(value);
    },
    [onNameChange]
  );

  const saveChanges = useCallback(() => {
    if (!errorMessage && hasChanges) {
      handleSave();
    }
  }, [errorMessage, handleSave, hasChanges]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="bundle-data-bar" data-testid="BundleDataBar">
      <KiteButton
        onClick={handleBack}
        leftIcon="arrow-left"
        type="standalone-link"
        size="small"
        minWidth="min-content"
        maxWidth="min-content"
      >
        Back
      </KiteButton>
      <KiteButton
        onClick={saveChanges}
        type="primary"
        size="small"
        disabled={!!errorMessage}
        minWidth="min-content"
        maxWidth="min-content"
      >
        Save Changes
      </KiteButton>
      <KiteInput
        id="bundleName"
        className="bundle-data-bar__name-input"
        name="bundleName"
        label="Name:"
        type="text"
        value={bundleName}
        onChange={handleNameChange}
        errorMessage={errorMessage}
      />
      {isSaved && <AutosaveBadge />}
      {alert && (
        <KiteAlert
          className="bundle-data-bar__alert"
          type="alert"
          description={alert}
          onClose={closeAlert}
        />
      )}
    </div>
  );
};

export default BundleDataBar;
