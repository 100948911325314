// Packages
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';

// Components
import {
  KiteModal,
  KiteInput,
  KiteRadio,
  KiteCheckbox,
  KiteAlert,
  KiteButton,
} from '@kite/react-kite';

// Utils
import { deepCopy } from 'utils';

// Hooks
import {
  useDeleteProfiles,
  useGetProfiles,
  usePreviousValue,
  useQueryData,
  useSelections,
  useUpdateEstimate,
} from 'hooks';

// Types
import {
  IEstimateUpdate,
  ILocation,
  ILocationSelection,
  INewScenario,
  IProfile,
  IScenario,
  IScenarioPromo,
} from 'types';
import { IUseUpdateEstimate } from 'hooks/apiHooks/estimates/useUpdateEstimate';

// Styles
import './ScenarioModal.scss';

interface ISelectedOption {
  id: string;
  label: string;
  checked: boolean;
}

export interface IScenarioModalProps {
  /** Determines if modal is visible */
  isModalOpen: boolean;
  /** Method used to set isModalOpen to false (and thus close the modal) */
  closeModal: () => void;
  /** Model used to toggle which modal is open */
  onDeleteScenario: () => void;
  /** Determines wether edit or new modal form will display */
  modalType: 'edit' | 'new';
}

const initialRadioOptions: ISelectedOption = {
  id: '-1',
  label: 'Start from Scratch',
  checked: true,
};

/** Modal displaying options for creating a new scenario. */

const ScenarioModal = ({
  isModalOpen,
  closeModal,
  onDeleteScenario,
  modalType,
}: IScenarioModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    currentEstimate,
    estimateScenarios,
    userId,
    locationId,
    allProfiles,
    updateLocationId,
    currentScenario,
    updateScenarioTabs,
    isScenarioDuplicate,
  } = useQueryData();
  const {
    applyProfilesAndSelections,
    updateProfilesLoading,
    updateScenarioProductsLoading,
  } = useSelections();
  const { deleteProfiles } = useDeleteProfiles();

  const [scenarioName, setScenarioName] = useState('');
  const [checkboxOptions, setCheckboxOptions] = useState<ISelectedOption[]>([]);
  const [radioOptions, setRadioOptions] = useState([initialRadioOptions]);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [scenarioNameError, setScenarioNameError] = useState('');

  const onNewScenarioSuccess =
    useRef<IUseUpdateEstimate['onNewScenarioSuccess']>();
  const onEditScenarioSuccess =
    useRef<IUseUpdateEstimate['onEditScenarioSuccess']>();

  const { updateEstimate, updateEstimateLoading } = useUpdateEstimate({
    onNewScenarioSuccess: onNewScenarioSuccess.current,
    onEditScenarioSuccess: onEditScenarioSuccess.current,
  });

  const mutationIsLoading =
    updateEstimateLoading ||
    updateProfilesLoading ||
    updateScenarioProductsLoading;

  const prevMutationIsLoading = usePreviousValue(mutationIsLoading);

  const isEdit = modalType === 'edit';

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const modalTitle = isEdit ? 'Edit Scenario' : 'New Scenario';

  const createOption = useCallback(
    (option: IScenario | ILocation, checked: boolean) => {
      return {
        id: option.id,
        label: option.name,
        checked,
      };
    },
    []
  );

  const { scenarioOptions, locationOptions } = useMemo(() => {
    const scenarios: ISelectedOption[] =
      estimateScenarios.map((s) => createOption(s, false)) || [];

    const locations: ISelectedOption[] =
      currentEstimate?.locations.map((l) => {
        if (isEdit && currentScenario?.id) {
          const isScenarioLocation = !!estimateScenarios
            .find((s) => s.id === currentScenario.id)
            ?.locations?.find((sl) => sl.id === l.id);
          return createOption(l, isScenarioLocation);
        }
        return createOption(l, false);
      }) || [];
    return { scenarioOptions: scenarios, locationOptions: locations };
  }, [
    estimateScenarios,
    currentEstimate,
    createOption,
    isEdit,
    currentScenario,
  ]);

  const { locationIdsToCopy, selectedLocations } = useMemo(() => {
    const selectedLocations = checkboxOptions.reduce(
      (ids: INewScenario['locations'], l) => {
        if (l.checked === true) {
          ids.push({ id: l.id, '#ref': l.id });
        }
        return ids;
      },
      []
    );

    const locationIdsToCopy = new Set(
      selectedLocations.reduce((acc: string[], l) => {
        if (l.id) acc.push(l.id);
        return acc;
      }, [])
    );

    return { selectedLocations, locationIdsToCopy };
  }, [checkboxOptions]);

  const scenarioToCopy = useMemo(() => {
    const scenarioOption = radioOptions.find((r) => r.checked === true);

    if (!scenarioOption || scenarioOption.id === '-1') {
      return;
    }

    const scenarioMatch = estimateScenarios.find(
      (s) => s.id === scenarioOption.id
    );

    if (locationIdsToCopy && scenarioMatch) {
      return {
        ...scenarioMatch,
        selections: scenarioMatch.selections.filter(
          (s) => s.locationId && locationIdsToCopy.has(s.locationId)
        ),
      };
    }

    return scenarioMatch;
  }, [estimateScenarios, locationIdsToCopy, radioOptions]);

  const { data: scenarioToCopyProfile = [] } = useGetProfiles({
    params: { scenarioId: scenarioToCopy?.id || '' },
  });

  const hasLocationChanges = useMemo(() => {
    if (!isEdit) {
      return true;
    }
    const currentLocations = currentScenario?.locations || [];
    return !(
      currentLocations.length === locationIdsToCopy.size &&
      currentLocations.every((l) => locationIdsToCopy.has(l.id))
    );
  }, [currentScenario, isEdit, locationIdsToCopy]);

  const hasNameChange = isEdit && currentScenario?.name !== scenarioName;

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setScenarioName(value);
      setScenarioNameError('');
    },
    []
  );

  const handleRadioChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const options = deepCopy<ISelectedOption[]>(radioOptions);

      const optionToUncheck = options.find(
        (o: ISelectedOption) => o.checked === true
      );
      const indexToModify = options.find(
        (o: ISelectedOption) => o.label === value
      );

      optionToUncheck && (optionToUncheck.checked = false);
      indexToModify && (indexToModify.checked = true);
      setRadioOptions(options);
    },
    [radioOptions]
  );

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      const options = deepCopy(checkboxOptions);
      const indexToUpdate = options.findIndex(
        (l: ISelectedOption) => l.label === name
      );
      options[indexToUpdate].checked = !options[indexToUpdate].checked;
      setCheckboxOptions(options);
      setErrorDisplay(false);
    },
    [checkboxOptions]
  );

  const applySuccessHandlers = useCallback(
    async (params: {
      onNewSuccess?: IUseUpdateEstimate['onNewScenarioSuccess'];
      onEditSuccess?: IUseUpdateEstimate['onEditScenarioSuccess'];
    }) => {
      onEditScenarioSuccess.current = params.onEditSuccess;
      onNewScenarioSuccess.current = params.onNewSuccess;
    },
    []
  );

  const handleUpdateEstimate = useCallback(
    async (params: {
      newScenario: INewScenario;
      onNewSuccess?: IUseUpdateEstimate['onNewScenarioSuccess'];
      onEditSuccess?: IUseUpdateEstimate['onEditScenarioSuccess'];
    }) => {
      if (!currentEstimate) return;
      const { newScenario, onNewSuccess, onEditSuccess } = params;

      const newScenarios = isEdit
        ? [
            ...currentEstimate.scenarios.filter(
              (s) => s.id !== currentScenario?.id
            ),
            isEdit ? { ...newScenario, id: currentScenario?.id } : newScenario,
          ]
        : [...currentEstimate.scenarios, newScenario];

      const updatedEstimate: IEstimateUpdate = {
        ...currentEstimate,
        locations: currentEstimate.locations.map((l) => ({
          ...l,
          '#id': l.id,
        })),
        scenarios: newScenarios,
      };

      /** Update redux locationId and affiliated scenario tabs if current selected locationId is being removed */
      if (!locationIdsToCopy.has(locationId) && isEdit && currentScenario) {
        const updatedLocationId = locationIdsToCopy.values().next().value;
        updateLocationId(updatedLocationId);
        isEdit && updateScenarioTabs(currentScenario.id, updatedLocationId);
      }
      //Set duplicate value for exosting Scenario to false
      isEdit &&
        currentScenario?.id &&
        isScenarioDuplicate(currentScenario?.id, false);

      await applySuccessHandlers({ onNewSuccess, onEditSuccess });
      updateEstimate(updatedEstimate);
      setCheckboxOptions(locationOptions);
    },
    [
      currentEstimate,
      isEdit,
      currentScenario,
      locationIdsToCopy,
      locationId,
      applySuccessHandlers,
      updateEstimate,
      locationOptions,
      updateLocationId,
      updateScenarioTabs,
      isScenarioDuplicate,
    ]
  );

  const formatNewScenarioData = useCallback(() => {
    interface IFormatScenario extends Omit<INewScenario, 'selections'> {
      selections: Partial<ILocationSelection>[];
      promos?: Partial<IScenarioPromo>[];
    }

    const profilesToCopy: Partial<IProfile>[] = [];
    const profileIdsToRemove: string[] = [];

    const scenario: IFormatScenario = {
      name: scenarioName,
      createdBy: '',
      primary: false,
      order: -1,
      term: '36',
      locations: selectedLocations,
      selections: [],
    };

    if (isEdit && currentScenario) {
      scenario.order = currentScenario.order;
      scenario.createdBy = currentScenario.createdBy;

      profileIdsToRemove.push(
        ...allProfiles.reduce((acc: string[], p) => {
          if (
            p.scenarioId === currentScenario.id &&
            !locationIdsToCopy.has(p.locationId)
          ) {
            acc.push(p.id);
          }
          return acc;
        }, [])
      );

      scenario.selections =
        currentScenario.selections.reduce((acc: ILocationSelection[], s) => {
          if (s.profileId && !profileIdsToRemove.includes(s.profileId)) {
            acc.push(s);
          }
          if (
            !s.profileId &&
            s.locationId &&
            locationIdsToCopy.has(s.locationId)
          ) {
            acc.push(s);
          }
          return acc;
        }, []) || [];
    } else {
      const numberOfScenarios = estimateScenarios.sort(
        (a, b) => a.order - b.order
      ).length;
      const lastScenario = estimateScenarios[numberOfScenarios - 1];
      const order = lastScenario.order + 1;
      scenario.order = order;
      scenario.createdBy = userId;

      if (scenarioToCopy) {
        const selectionsToCopy =
          scenarioToCopy?.selections.reduce(
            (acc: Partial<ILocationSelection>[], s) => {
              if (
                s.locationId &&
                locationIdsToCopy.has(s.locationId) &&
                !s.profileId // profile selections are handled in onNewScenarioSuccess
              ) {
                acc.push({
                  ...s,
                  id: undefined,
                  scenarioId: undefined,
                });
              }
              return acc;
            },
            []
          ) || [];

        scenario.selections = selectionsToCopy;
        scenario.promos = scenarioToCopy.promos?.reduce(
          (acc: Partial<IScenarioPromo>[], sp) => {
            const { locationId, promoId, estimateId } = sp;
            if (
              !locationId ||
              (locationId && locationIdsToCopy.has(locationId))
            ) {
              const spCopy = { locationId, promoId, estimateId };
              acc.push(spCopy);
            }

            return acc;
          },
          []
        );

        profilesToCopy.push(
          ...scenarioToCopyProfile.reduce((acc: Partial<IProfile>[], p) => {
            if (
              p.scenarioId === scenarioToCopy.id &&
              locationIdsToCopy.has(p.locationId)
            ) {
              acc.push({ ...p, scenarioId: undefined });
            }
            return acc;
          }, [])
        );
      }
    }
    return {
      newScenario: scenario as INewScenario,
      profilesToCopy,
      profileIdsToRemove,
    };
  }, [
    allProfiles,
    currentScenario,
    estimateScenarios,
    locationIdsToCopy,
    scenarioName,
    scenarioToCopy,
    selectedLocations,
    userId,
    isEdit,
    scenarioToCopyProfile,
  ]);

  const handleNameErrorCheck = useCallback(() => {
    const otherScenarioNames = estimateScenarios.reduce(
      (acc: string[], scenario) => {
        if (scenario.id !== currentScenario?.id) {
          acc.push(scenario.name);
        }
        return acc;
      },
      []
    );

    if (otherScenarioNames.includes(scenarioName)) {
      setScenarioNameError('Scenario names must be unique.');
      return true;
    } else {
      setScenarioNameError('');
      return false;
    }
  }, [estimateScenarios, scenarioName, currentScenario]);

  const handleSubmit = useCallback(async () => {
    const isNameError = handleNameErrorCheck();

    if (isNameError) {
      return;
    }

    if (!locationIdsToCopy.size) return setErrorDisplay(true);

    // Nothing different so close modal
    if (isEdit && !hasNameChange && !hasLocationChanges) {
      closeModal();
    }

    const { newScenario, profileIdsToRemove, profilesToCopy } =
      formatNewScenarioData();

    // Update current scenario (no new scenario being created)
    if (isEdit && (hasNameChange || hasLocationChanges)) {
      // Delete any profiles if location is de-selected
      if (profileIdsToRemove.length) {
        handleUpdateEstimate({
          newScenario,
          onEditSuccess: () => {
            deleteProfiles(profileIdsToRemove);
            closeModal();
          },
        });
      } else {
        // No edit success handler needed if no profiles to remove
        handleUpdateEstimate({
          newScenario,
          onEditSuccess: () => closeModal(),
        });
      }
      return;
    }

    // Create new scenario (update estimate success handler)
    const onNewSuccess = (newScenarioId?: string) => {
      if (!newScenarioId) {
        closeModal();
        return;
      }

      const profileIds = profilesToCopy.map((p) => p.id);

      const profileSelectionsToCopy =
        scenarioToCopy?.selections.reduce((acc: ILocationSelection[], s) => {
          if (s.profileId && profileIds.includes(s.profileId)) {
            acc.push({
              ...s,
              id: `newSelection${s.id}`,
              scenarioId: newScenarioId,
            });
          }
          return acc;
        }, []) || [];

      if (profilesToCopy.length) {
        applyProfilesAndSelections({
          profiles: profilesToCopy.map((p) => ({
            ...p,
            scenarioId: newScenarioId,
          })),
          selections: profileSelectionsToCopy,
          newScenarioId,
        });
      }
      //Set duplicate value for new Scenario true if scenarioToCopy is present
      isScenarioDuplicate(newScenarioId, !!scenarioToCopy);
      closeModal();
    };

    handleUpdateEstimate({
      newScenario,
      onNewSuccess,
    });
  }, [
    applyProfilesAndSelections,
    closeModal,
    deleteProfiles,
    formatNewScenarioData,
    handleUpdateEstimate,
    hasLocationChanges,
    hasNameChange,
    locationIdsToCopy.size,
    scenarioToCopy,
    isEdit,
    handleNameErrorCheck,
    isScenarioDuplicate,
  ]);

  // =============================================
  // Render Methods
  // =============================================
  const locationsToDisplay = useMemo(
    () =>
      checkboxOptions.map((l) => (
        <KiteCheckbox
          key={l.id}
          id={l.id}
          label={l.label}
          name={l.label}
          checked={l.checked || false}
          onChange={handleCheckboxChange}
        />
      )),
    [handleCheckboxChange, checkboxOptions]
  );

  const deleteButton = useMemo(() => {
    return estimateScenarios.length > 1 && isEdit && !mutationIsLoading ? (
      <KiteButton
        onClick={onDeleteScenario}
        type="standalone-link"
        leftIcon="trash"
      >
        Delete Scenario
      </KiteButton>
    ) : null;
  }, [estimateScenarios.length, mutationIsLoading, onDeleteScenario, isEdit]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (mutationIsLoading) return;
    if (isEdit) {
      const name =
        currentEstimate?.scenarios.find((s) => s.id === currentScenario?.id)
          ?.name || '';
      setScenarioName(name);
    } else {
      const scenarioNum = currentEstimate?.scenarios
        ? currentEstimate.scenarios.length + 1
        : 1;
      let name = `Scenario ${scenarioNum}`;
      if (new Set(estimateScenarios.map((s) => s.name)).has(name)) {
        name = `Scenario ${scenarioNum + 1}`;
      }
      setScenarioName(name);
    }
  }, [
    currentEstimate,
    isModalOpen,
    mutationIsLoading,
    prevMutationIsLoading,
    estimateScenarios,
    isEdit,
    currentScenario,
  ]);

  useEffect(() => {
    if (mutationIsLoading) return;
    setRadioOptions([initialRadioOptions, ...scenarioOptions]);
    setCheckboxOptions(locationOptions);
  }, [
    scenarioOptions,
    locationOptions,
    mutationIsLoading,
    prevMutationIsLoading,
    currentScenario,
  ]);

  useEffect(() => {
    if (prevMutationIsLoading && !mutationIsLoading && isModalOpen) {
      closeModal();
    }
  }, [closeModal, isModalOpen, mutationIsLoading, prevMutationIsLoading]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="scenario-modal">
      <KiteModal
        modalId="scenario"
        title={modalTitle}
        onHide={closeModal}
        canShow={isModalOpen}
        ctaCopy={isEdit ? 'Update Scenario' : 'Add Scenario'}
        ctaAction={handleSubmit}
        ctaLoading={mutationIsLoading}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={closeModal}
        footerContent={deleteButton}
      >
        <KiteInput
          className="scenario-modal__text"
          id="scenarioName"
          name="scenarioName"
          label="Scenario Name"
          value={scenarioName}
          onChange={handleTextChange}
          errorMessage={scenarioNameError}
        />
        {(!isEdit || prevMutationIsLoading) && (
          <KiteRadio
            className="scenario-modal__radio"
            groupLabel="Select which scenario to duplicate"
            name="scenariosToDuplicate"
            radioButtons={radioOptions}
            onChange={handleRadioChange}
          />
        )}
        <hr />
        <div className="scenario-modal__locations">
          <h3>Locations</h3>
          <p>Please select at least one (1) location</p>
          {locationsToDisplay}
        </div>
        {errorDisplay && (
          <KiteAlert
            type="alert"
            description="Please select at least one (1) location"
          />
        )}
      </KiteModal>
    </div>
  );
};

export default ScenarioModal;
