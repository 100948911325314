import { ISubmission, ISubmissionsRequest } from 'types';
import { queryKeys, submissionsRequest } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetSubmissions = (requestConfig: ISubmissionsRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<ISubmission[]>({
    queryKey: params
      ? queryKeys(params).filter.submissions
      : queryKeys().all.submissions,
    apiCall: submissionsRequest,
    requestConfig,
    opts: {
      enabled: !!params && params?.estimateId !== 'sandbox',
      staleTime: Infinity,
    },
  });
};
