// External Packages
import React from 'react';

// Components
import { KiteButton } from '@kite/react-kite';

// Hooks
import { useAnalytics } from 'hooks';

// Styles
import './DiscretionaryButton.scss';

interface IDiscretionaryButtonProps {
  children?: React.ReactNode;
  productName: string;
  discretionState: boolean;
  isDisabled: boolean;
  testId: string;
  onToggleButton: () => void;
}
// =============================================
// Component
// =============================================
const DiscretionaryButton = ({
  children,
  productName,
  discretionState,
  isDisabled,
  testId,
  onToggleButton,
}: IDiscretionaryButtonProps) => {
  const { trackSelectAction } = useAnalytics();

  const handleClick = () => {
    onToggleButton();
    trackSelectAction(productName, { opType: 'buttonClick' });
  };

  // =============================================
  // Return
  // =============================================
  return (
    <div data-testid={testId} className="discretionary-button">
      {children}

      <KiteButton
        className="discretionary-button__button"
        type="outline"
        size="medium"
        onClick={handleClick}
        disabled={isDisabled}
      >
        {discretionState ? 'Cancel' : 'Discretion'}
      </KiteButton>
    </div>
  );
};

export default DiscretionaryButton;
