// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

// Components
import { KiteButton, KiteCard, KiteIcon } from '@kite/react-kite';
import { ExpandButton, PriceTotalBadge } from 'components';

// Utils
import { formatPrice, TableCategoryIconMap } from 'utils';

// Types
import { IBundleCardProduct, TTableCategory } from 'types';

// Styles
import './BundleInfoCard.scss';

export interface IBundleInfoCardProps {
  /** Name of bundle */
  bundleName: string;
  /** Products included in bundle, w/ name, price & product category for each product */
  products: IBundleCardProduct[];
  /** Determines whether bundle is currently selected */
  isSelected?: boolean;
  /** Callback to select & apply current bundle */
  onApplyBundle: () => void;
}

/** Displays bundle product & pricing info at a glance */

const BundleInfoCard = ({
  bundleName = '',
  products = [],
  isSelected,
  onApplyBundle,
}: IBundleInfoCardProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const [isOpen, setIsOpen] = useState(false);
  const [listHeight, setListHeight] = useState(0);
  const listRef = useRef<HTMLUListElement>(null);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const contentHeight = isOpen ? `${listHeight}px` : '0';

  const productCategorySet: string[] = useMemo(
    () => Array.from(new Set(products.map((p) => p.category))),
    [products]
  );

  const priceTotal = useMemo(() => {
    const total = products.reduce((acc, curr) => {
      const { price, quantity } = curr;
      return acc + price * quantity;
    }, 0);

    return formatPrice(total);
  }, [products]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderProductIcons = useCallback(() => {
    const order = Object.keys(TableCategoryIconMap);

    return productCategorySet
      .sort((a, b) => {
        const indexA = order.indexOf(a);
        const indexB = order.indexOf(b);
        return indexA < indexB ? -1 : indexB < indexA ? 1 : 0;
      })
      .map((p) => (
        <KiteIcon key={p} name={TableCategoryIconMap[p as TTableCategory]} />
      ));
  }, [productCategorySet]);

  const renderHeader = useCallback(
    () => (
      <div className="bundle-info-card__header">
        <div className="bundle-info-card__header-upper">
          <span>{bundleName}</span>
          <KiteButton
            className={classNames({
              'bundle-info-card__header-upper-btn': true,
              'bundle-info-card__header-upper-btn--selected': isSelected,
            })}
            onClick={onApplyBundle}
            minWidth="min-content"
            maxWidth="min-content"
          >
            {isSelected ? 'Remove' : 'Apply'}
          </KiteButton>
        </div>
        <div className="bundle-info-card__header-lower">
          <PriceTotalBadge priceTotal={priceTotal} />
          <div className="bundle-info-card__header-icons">
            {renderProductIcons()}
          </div>
        </div>
      </div>
    ),
    [bundleName, isSelected, renderProductIcons, priceTotal, onApplyBundle]
  );

  const renderContent = useCallback(() => {
    const productItems = products.map((p) => {
      let productName = p.name;

      if (p.category === 'SBB 1-19 Pub/Priv') {
        productName = `SBB 1-19 ${p.name}`;
      }

      return (
        <li key={productName}>
          <span>{productName}</span>
          <span>{formatPrice(p.price * p.quantity)}</span>
        </li>
      );
    });

    return (
      <ul
        ref={listRef}
        aria-hidden={!isOpen}
        className="bundle-info-card__content"
      >
        {productItems}
      </ul>
    );
  }, [products, isOpen]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (listRef.current) {
      setListHeight(listRef.current.clientHeight);
    }
  }, [listRef, products]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="bundle-info-card">
      {renderHeader()}
      <div
        className="bundle-info-card__content-container"
        style={{ height: contentHeight }}
      >
        {renderContent()}
      </div>
      <ExpandButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        ariaLabel="expand bundle info card"
      />
    </KiteCard>
  );
};

export default BundleInfoCard;
