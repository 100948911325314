import axios from 'axios';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IGetSignedUrlParams {
  fileType: string;
  fileKey?: string;
  operator?: 'getObject' | 'putObject' | 'deleteObject';
}

interface IGetSignedUrlResponse {
  signedUrl: string;
  objectUrl: string;
  objectKey: string;
}

export const getSignedUrl = async ({
  fileType,
  fileKey,
  operator = 'putObject',
}: IGetSignedUrlParams) => {
  return axios({
    method: 'POST',
    url: `${env.apiUrl}/files`,
    data: {
      fileType,
      fileKey,
      operator,
    },
  })
    .then((res) => res.data as IGetSignedUrlResponse)
    .catch((error) =>
      throwQueryError({
        error,
        message: 'Something went wrong getting signed url',
      })
    );
};
