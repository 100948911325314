import { useCallback } from 'react';
import { TProductList, TDisableConfigFamily, ILocationSelection } from 'types';
import { disabledRules } from 'utils';

interface IHandleDisableCheck {
  family: TDisableConfigFamily;
  selections: ILocationSelection[];
}

export const useDisabledCheck = () => {
  const handleDisabledCheck = useCallback(
    ({ family, selections }: IHandleDisableCheck) => {
      if (!disabledRules[family]) return false;

      const dfiLowSpeeds = ['20M', '30M', '50M', '100M', '200M'];

      const findDependentSelection = (familyName: TProductList) => {
        return selections.find((s) => s.familyName === familyName);
      };

      const highSpeedStatus = (familyName: TProductList) => {
        return false;
      };

      const rulesRelationship = disabledRules[family].relationship || 'None';
      const disabled: boolean[] = [];

      disabledRules[family].rules.forEach((ruleSet) => {
        const { status, dependentFamily } = ruleSet;
        const selection = findDependentSelection(dependentFamily);

        const checkProduct = () => {
          // Returns whether current selection should be disabled
          // based on current ruleSet in forEach
          if (
            (status.includes('Off') && !selection) ||
            (status.includes('On') && !!selection) ||
            (status.includes('HighSpeed') &&
              highSpeedStatus(dependentFamily)) ||
            (status.includes('LowSpeed') &&
              !!selection &&
              !dfiLowSpeeds.includes(selection.name))
          ) {
            return true;
          }
          return false;
        };

        const check = checkProduct();

        if (check) {
          disabled.push(true);
        } else {
          disabled.push(false);
        }
      });

      if (rulesRelationship === 'And' && !disabled.includes(false)) {
        return true;
      } else if (rulesRelationship !== 'And' && disabled.includes(true)) {
        return true;
      }
      return false;
    },
    []
  );

  return { handleDisabledCheck };
};
