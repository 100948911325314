// Packages
import React from 'react';
import classNames from 'classnames';

// Components
import { KiteCard } from '@kite/react-kite';
import { OutputTotalsRow } from 'components';

// Types
import { TableValues } from 'types';

// Styles
import './OutputTotals.scss';

export interface IOutputTotalsProps {
  /** Output totals for a given scenario */
  scenarioTotals?: TableValues<string | number>;
  /** Output totals for a given location */
  locationTotals?: TableValues<string | number>;
  /** Value determines whether bundle styling is used */
  isBundle?: boolean;
  /** Output totals for a given bundle */
  bundleTotals?: TableValues<string | number>;
}

/** A table that displays prices for a scenario and its locations */

const OutputTotals = ({
  scenarioTotals,
  locationTotals,
  isBundle,
  bundleTotals,
}: IOutputTotalsProps) => {
  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard
      className={classNames({
        'output-totals': true,
        'output-totals--bundle': isBundle,
      })}
    >
      <table>
        <tbody>
          {!isBundle && scenarioTotals && locationTotals && (
            <>
              <OutputTotalsRow
                rowTitle="Scenario Totals"
                outputTotals={scenarioTotals}
                primary={true}
              />

              <OutputTotalsRow
                rowTitle="Location Totals"
                outputTotals={locationTotals}
              />
            </>
          )}
          {isBundle && bundleTotals && (
            <OutputTotalsRow
              rowTitle="Bundle Totals:"
              outputTotals={bundleTotals}
              primary={true}
            />
          )}
        </tbody>
      </table>
    </KiteCard>
  );
};

export default OutputTotals;
