// Packages
import React from 'react';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks

// Utils
import { capitalizeFirstLetter } from 'utils';

// Types

// Styles
import './SparcBadge.scss';

interface ISparcBadgeProps {
  contractType?: 'pre' | 'post';
}

/** SPARC UC Question Badge */

const SparcBadge = ({ contractType = 'pre' }: ISparcBadgeProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const label = `SPARC ${capitalizeFirstLetter(contractType)}-Contract`;

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={`sparc-badge ${
        contractType === 'pre' ? 'sparc-badge--pre' : ''
      }`}
    >
      <KiteIcon
        name={contractType === 'pre' ? 'caution-alert' : 'like-f'}
        margin="0 11px 0 0"
      />
      {label}
    </div>
  );
};

export default SparcBadge;
