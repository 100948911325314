import { IEstimate, IEstimateRequest } from 'types';
import { estimateBySfId, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetEstimateBySfId = (requestConfig: IEstimateRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<IEstimate>({
    queryKey: queryKeys(params).details.estimateBySfId,
    apiCall: estimateBySfId,
    requestConfig,
    opts: { enabled: !!params?.opportunityId },
  });
};
