import { useMutation } from 'react-query';
import { postFileToS3 } from 'utils/api';
import { IPostFileToS3Params } from 'utils/api/postFileToS3';

interface IUploadToS3Params {
  onSuccess: (res: any) => void;
  onError: () => void;
}
export const useUploadToS3 = ({ onSuccess, onError }: IUploadToS3Params) => {
  const {
    mutate: uploadToS3,
    isLoading: uploadToS3Loading,
    isSuccess: uploadToS3Success,
    isError: uploadToS3Error,
  } = useMutation(
    ({ file, fileKey }: IPostFileToS3Params) => postFileToS3({ file, fileKey }),
    {
      onSuccess,
      onError,
    }
  );

  return {
    uploadToS3,
    uploadToS3Loading,
    uploadToS3Success,
    uploadToS3Error,
  };
};
