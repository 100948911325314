import axios from 'axios';
import { IRequestConfig, IProduct } from 'types';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

export const productRequest = async (config?: IRequestConfig<IProduct>) => {
  const { endpoint = 'products', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with product ${method}`,
      })
    );
};
