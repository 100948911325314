import { useCallback } from 'react';
import {
  ILocationSelection,
  TProductCategory,
  TProductList,
  TableValues,
  TTermLengthMonths,
  IPromo,
  TEipTermLengthMonths,
} from 'types';
import {
  formatOutputValues,
  installProductId,
  EstimateBuilderOffNetRateCard,
  inFootprintIswId,
  outOfFootprintIswId,
} from 'utils';
import { useQueryData } from 'hooks';

/**
 *
 * Properties provided to function for broad and granular calculations
 *
 * @param selections
 * Should be provided when calculation output totals for selections other than currentScenario.selections or currentBundle.selections
 *
 * @param scenarioId
 * Should be specified when calculating output totals for a scenario other than currentScenario
 *
 * @param locationId
 * Should be provided when calculating for one location in a scenario
 *
 * @param category
 * Should be provided when calculating for a specific product category (pricing table header rows)
 *
 * @param subCategory
 * Should be provided when calculating for a specific product (MNE Wifi, Switches)
 *
 * @param term
 * Term in months, required for finding term prices
 *
 * @param format
 * Defaults to true, see return description below
 *
 * @return
 * If format is true, output values will be converted to formatted prices.
 * If format is false, output values will be floats.
 *
 */

export interface IGenerateProductTotals {
  selections?: ILocationSelection[];
  scenarioId?: string;
  locationId?: string;
  category?: TProductCategory | '';
  subCategory?: TProductList | '';
  term?: TTermLengthMonths;
  eipTerm?: TEipTermLengthMonths;
  format?: boolean;
}

export const useGenerateProductTotals = (config?: { isBundle: boolean }) => {
  const { isBundle = false } = config || {};
  const {
    allProductFamilies,
    currentScenario,
    currentSelections,
    currentBundle,
    currentTerm,
    allPromos,
    currentEstimate,
  } = useQueryData();
  const { scenarioPromos: allScenarioPromos = [] } = currentEstimate || {};

  const generateProductTotals = useCallback(
    ({
      selections = isBundle
        ? currentBundle?.selections || []
        : currentScenario?.selections || [],
      scenarioId = '',
      locationId = '',
      category = '',
      subCategory = '',
      term = currentTerm,
      format = true,
    }: IGenerateProductTotals) => {
      const defaultOutputs = {
        rateMrc: 0,
        rateNrc: 0,
        solutionMrc: 0,
        solutionNrc: 0,
      };

      /**  If a location ID is provided, filter products by ID */
      const locationSelections = locationId
        ? selections.filter((s) => s.locationId === locationId)
        : selections;

      // Calculates Onboarding Fees as per the selected DM Products
      // For price calculation using '36' months term
      const calculateOnboardingFees = (dmProducts: ILocationSelection[]) => {
        const dmTotals = dmProducts?.reduce((acc, product) => {
          const price = product.prices.find((p) => p.term === '36')?.price || 0;
          acc += product.quantity * price;
          return acc;
        }, 0);
        return dmTotals;
      };

      /** For each product, add up prices for given term */
      const totals =
        locationSelections?.reduce((acc: TableValues<number>, selection) => {
          /** Subcategory uses product name for filtering. Used for MNE/ENE products (Wifi, Switches, etc) */
          const family = allProductFamilies.find((f) => {
            if (category && subCategory) {
              return (
                f.id === selection.familyId &&
                f.category === category &&
                f.name === subCategory
              );
            } else if (category) {
              return f.id === selection.familyId && f.category === category;
            }
            return f.id === selection.familyId;
          });
          const product = family?.products.find(
            (p) => p.id === selection.productId
          );

          if (!family || !product) return defaultOutputs;

          /** Rate price for a given selection. If rate card is specified, filtered by value */
          const ratePrice =
            selection.prices?.find((p) => {
              if (selection.rateCard && p.rateCard) {
                return (
                  p.term === term &&
                  p.type === 'rate' &&
                  p.rateCard === selection.rateCard
                );
              }
              return p.term === term && p.type === 'rate';
            })?.price || 0;

          /** Discount price for a given selection. If rate card is specified, filtered by value */
          const discountPrice =
            selection.prices?.find((p) => {
              if (selection.rateCard && p.rateCard) {
                return (
                  p.term === term &&
                  p.type === 'discount' &&
                  p.rateCard === selection.rateCard
                );
              }
              return p.term === term && p.type === 'discount';
            })?.price || 0;

          const multiplier = selection.quantity;

          // MNE Device Management products are not sold for 84 months contract term
          if (selection.name === 'Meraki Onboarding' && term !== '84') {
            // Location id present, pricing calcultion is triggered for location totals
            if (locationId) {
              const dmProducts = locationSelections.filter(
                (p) => p.familyName === 'MNE Device Management'
              );
              product.rateNrc = calculateOnboardingFees(dmProducts);
            }
            // Location id not present and Location selection length is 1
            // pricing calcultion is triggered for pricing table row and pdf location table row
            else if (!locationId && locationSelections.length === 1) {
              const dmProducts = currentScenario?.selections.filter(
                (p) =>
                  p.familyName === 'MNE Device Management' &&
                  p.locationId === selection.locationId
              );
              if (dmProducts) {
                product.rateNrc = calculateOnboardingFees(dmProducts);
              }
            }
            // Location id not present, pricing calculation is triggered for scenario totals
            else if (!locationId) {
              const dmOnboarding = locationSelections.filter(
                (p) => p.name === 'Meraki Onboarding'
              );
              dmOnboarding.forEach((o) => {
                const dmProducts = locationSelections.filter(
                  (p) =>
                    p.familyName === 'MNE Device Management' &&
                    p.locationId === o.locationId
                );
                if (o.locationId === selection.locationId) {
                  product.rateNrc = calculateOnboardingFees(dmProducts);
                }
              });
            }
          }

          // ENE Device Management products are not sold for 84 months contract term
          if (selection.name === 'Fortinet Onboarding' && term !== '84') {
            // Location id present, pricing calcultion is triggered for location totals
            if (locationId) {
              const dmProducts = locationSelections.filter(
                (p) => p.familyName === 'ENE Device Management'
              );
              product.rateNrc = calculateOnboardingFees(dmProducts);
            }
            // Location id not present and Location selection length is 1
            // pricing calcultion is triggered for pricing table row / pdf location table row
            else if (!locationId && locationSelections.length === 1) {
              const dmProducts = currentScenario?.selections.filter(
                (p) =>
                  p.familyName === 'ENE Device Management' &&
                  p.locationId === selection.locationId
              );
              if (dmProducts) {
                product.rateNrc = calculateOnboardingFees(dmProducts);
              }
            }
            // Location id not present, pricing calculation is triggered for scenario totals
            else if (!locationId) {
              const dmOnboarding = locationSelections.filter(
                (p) => p.name === 'Fortinet Onboarding'
              );
              dmOnboarding.forEach((o) => {
                const dmProducts = locationSelections.filter(
                  (p) =>
                    p.familyName === 'ENE Device Management' &&
                    p.locationId === o.locationId
                );
                if (o.locationId === selection.locationId) {
                  product.rateNrc = calculateOnboardingFees(dmProducts);
                }
              });
            }
          }

          /** Output values by priority
           * For MRC values: product specific value takes priority over price table values
           * This is for products that don't have term pricing
           * For NRC values: product value used first, default to family value
           */
          const outputs = {
            rateMrc: (product.rateMrc || ratePrice) * multiplier,
            rateNrc:
              (product.rateNrc && product.rateNrc * multiplier) ||
              family?.rateNrc ||
              0,
            solutionMrc:
              (product.rateMrc || discountPrice || ratePrice) * multiplier,
            solutionNrc:
              (product.rateNrc && product.rateNrc * multiplier) ||
              family?.discountNrc ||
              family?.rateNrc ||
              0,
          };

          // Inside Wiring Out Of Footprint Prices
          if (
            selection.productId === 'fdafdad6-72c3-447b-923a-8f7b5e2efc72' &&
            multiplier <= 12
          ) {
            outputs.rateNrc = EstimateBuilderOffNetRateCard[multiplier];
            outputs.solutionNrc = EstimateBuilderOffNetRateCard[multiplier];
          }

          // Installment Plans for ISW (UC Add-ons)
          if (
            (selection.productId === inFootprintIswId ||
              selection.productId === outOfFootprintIswId) &&
            selection.eipTerm !== null &&
            selection.eipTerm !== 'One-time Payment'
          ) {
            const rate = 0.008333;
            const currentRateNrc = Number(outputs.rateNrc);
            const ipPlan = Number(selection.eipTerm);
            const payment = Math.round(
              (Number(currentRateNrc) * rate * (1 + rate) ** ipPlan) /
                ((1 + rate) ** ipPlan - 1)
            );
            const newRateNrc = Math.round(ipPlan * payment);
            outputs.rateNrc = newRateNrc;
            outputs.solutionNrc = newRateNrc;
          }

          //only for Outlets (sbb video product)
          if (
            selection.productId === '5a0e41ea-8f32-48a0-b79a-7452b012bff1' &&
            multiplier <= 4
          ) {
            outputs.rateNrc = 100;
            outputs.solutionNrc = 100;
          }

          /** Overwrite solution MRC if discretion value is defined */
          if (family.hasDiscretion && ratePrice) {
            outputs.solutionMrc =
              selection[`discretionValue${term}`] || ratePrice;
          }

          const ucConnectSelection = currentSelections.find(
            (p) =>
              p.familyId === selection.familyId &&
              p.productId !== selection.productId
          );

          /** UC Connect w/ Webex family has specific override configurations */
          if (family.name === 'UC Connect w/ Webex') {
            const installProduct = currentSelections.find(
              (p) => p.productId === installProductId
            );

            /** UC NRC price is accounted for by installation product */
            if (
              (ucConnectSelection && selection.name.includes('Plus')) ||
              installProduct
            ) {
              outputs.rateNrc = 0;
              outputs.solutionNrc = 0;
            }
          }

          /** Check if promo is active, then adjust NRC/MRC prices for products in promo array */
          if (allScenarioPromos) {
            const promos = allScenarioPromos.reduce((acc: IPromo[], sp) => {
              if (sp.scenarioId !== (scenarioId || currentScenario?.id)) {
                return acc;
              }

              const promo = allPromos.find((p) => p.id === sp.promoId);

              if (promo) {
                if (
                  promo.isLocationSpecific &&
                  sp.locationId !== selection.locationId
                ) {
                  return acc;
                } else {
                  acc.push(promo);
                }
              }
              return acc;
            }, []);

            const productPromos = promos.filter((promo) => {
              // Check if selection's product family or product exists in promotion, and is not excluded
              const isIncludedProduct =
                (promo.includedFamilyIds.includes(family.id) ||
                  promo.includedProductIds.includes(product.id)) &&
                !promo.excludedProductIds.includes(product.id);

              // 3 Month Promo has minimum 5 seat requirement for UC Connect w/ Webex seats
              if (
                isIncludedProduct &&
                family.name === 'UC Connect w/ Webex' &&
                promo.name === '3 Month Promo'
              ) {
                const ucSeats =
                  selection.quantity + (ucConnectSelection?.quantity || 0);

                return ucSeats > 4 ? true : false;
              }

              return isIncludedProduct ? true : false;
            });

            // Remove Nrc/Mrc pricing depending on promotion type
            productPromos.forEach((promo) => {
              if (promo.isMrc) {
                outputs.rateMrc = 0;
                outputs.solutionMrc = 0;
              }

              if (promo.isNrc) {
                outputs.rateNrc = 0;
                outputs.solutionNrc = 0;
              }
            });
          }

          /** Add final output values to accumulator */
          acc.rateMrc += outputs.rateMrc;
          acc.rateNrc += outputs.rateNrc;
          acc.solutionMrc += outputs.solutionMrc;
          acc.solutionNrc += outputs.solutionNrc;

          return acc;
        }, defaultOutputs) || defaultOutputs;

      /** If format property is specified, output values will be converted to prices. If not, values will be left as floats
       */
      return format ? formatOutputValues(totals) : totals;
    },
    [
      allProductFamilies,
      allPromos,
      allScenarioPromos,
      currentBundle,
      currentScenario,
      currentSelections,
      currentTerm,
      isBundle,
    ]
  );

  return {
    generateProductTotals,
  };
};
