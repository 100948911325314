// Packages
import React, { useCallback, useEffect, useState } from 'react';

// Redux

// Components
import { KiteButton, KiteIcon } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './SearchBar.scss';

export interface ISearchBarProps {
  /** Search method that is passed the search query on form submission */
  onSearch: (query: string) => void;
  /** Callback to submit search on enter */
  onSubmit: (query: string) => void;
  /** Dynamic placeholder text, pass an empty string ("") if no placeholder is needed */
  placeholder?: string;
  /** Value of user's search input query */
  query?: string;
}

/** A search bar used to filter through data */

const SearchBar = ({
  onSearch,
  placeholder,
  query,
  onSubmit,
}: ISearchBarProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const [value, setValue] = useState(query ? query : '');

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleKeydown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSubmit(value);
      }
    },
    [onSubmit, value]
  );

  const handleClear = useCallback(() => {
    setValue('');
  }, [setValue]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    onSearch(value);
  }, [value, onSearch]);

  // =============================================
  // Return
  // =============================================
  // <label htmlFor="search" className="input-label">
  //   Search
  // </label>
  return (
    <div className="search-bar">
      <div className="search-bar__input-wrapper">
        <input
          onChange={handleChange}
          onKeyDown={handleKeydown}
          className="search-bar__input"
          placeholder={placeholder}
          value={value}
          id="search"
        />
        <KiteIcon className="search-bar__icon" name="search" size="24px" />
        {value && (
          <KiteButton
            className="search-bar__clear"
            onClick={handleClear}
            rightIcon="x-circle"
            type="standalone-link"
            size="small"
          >
            <p>Clear Results</p>
          </KiteButton>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
