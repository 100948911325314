// Packages
import React from 'react';

// Hooks

// Components
import { KiteModal } from '@kite/react-kite';

// Utils

// Types
import { ILocationBase } from 'types';

// Styles
import './DeleteLocationModal.scss';

interface IDeleteLocationModalProps {
  locationData: ILocationBase | null;
  showDeleteModal: boolean;
  onCancelDelete: () => void;
  onDeleteLocation: () => void;
}

const DeleteLocationModal = ({
  locationData,
  showDeleteModal,
  onCancelDelete,
  onDeleteLocation,
}: IDeleteLocationModalProps) => {
  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="location-card__delete-modal"
      modalId="delete-location"
      canShow={showDeleteModal}
      onHide={onCancelDelete}
      title="Delete Location?"
      ctaCopy="Delete Location"
      ctaAction={onDeleteLocation}
      secondaryCtaCopy="Cancel"
      secondaryCtaAction={onCancelDelete}
    >
      {locationData && (
        <p>{`You will lose all configurations for ${locationData.name}`}</p>
      )}
    </KiteModal>
  );
};

export default DeleteLocationModal;
